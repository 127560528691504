export function UploadFileLabel(props) {
  return (
    <>
      <label htmlFor={props.forLabel}>
        <div className={props.altDivClass || 'btn btn-primary'}>
          <i className="thx-upload thx-icon me-2" />
          {props.label}
        </div>
      </label>
    </>
  )
}
