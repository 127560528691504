import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Constants from '../../../../config/Constants'
import { searchComureiSubject } from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// TABLE
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'

// MODAL
import { CommunicationsHistoryModal } from '../communications/NewCommunicationsHistoryModal'
// import { ComureiSubjectModal } from './NewComureiSubjectModal'

// FORM
import { ComureiForm } from './NewComureiSubjectForm'

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '200%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '150%'
  return '100%'
}

const getOffset = () => {
  if (window.innerHeight < Constants.BREAKPOINT_HEIGHT_FORM_ON_BOTTOM) return 400
  if (window.innerWidth >= Constants.COMUREI_BREAKPOINT_LG) return 445
  return 510
}

export function ComureiSubjectList(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [subjects, setSubjects] = useState([])
  const [loading, setLoading] = useState(false)
  const communicationTypes = useSelector((state) => state.comureiCommunicationTypes.data)

  const [show] = useState(false)
  const [rows, setRows] = useState([])

  const subjectTypes = useSelector((state) => state.comureiSubjectTypes.data)
  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    subjectType: null,
    communicationType: null,
    denomination: null,
    fiscalCode: null,
    responsibleName: null,
    section: null
  })

  const [id, setId] = useState(null)
  const tableUtils = TableUtils
  const tableName = 'comurei-subject'

  const header = [
    tableUtils.composeHeader({
      fieldName: 'subjectType',
      type: fieldTypes.SELECTION,
      options: subjectTypes,
      displayedName: labels.SUBJECT_TYPE
    }),
    tableUtils.composeHeader({
      fieldName: 'communicationType',
      type: fieldTypes.SELECTION,
      options: communicationTypes,
      displayedName: labels.COMMUNICATION_TYPE,
      maxWidth: '13rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'denomination',
      displayedName: labels.DENOMINATION_OBLIGATED_SUBJECT,
      maxWidth: '20rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: labels.FISCAL_CODE,
      maxWidth: '12rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'responsibleName',
      displayedName: labels.RESPONSIBLE,
      maxWidth: '12rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'section',
      sortable: false,
      searchable: false,
      displayedName: labels.SECTION,
      maxWidth: '20rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'actions',
      sortable: false,
      searchable: false,
      maxWidth: '100px',
      additionalClass: 'justify-content-center',
      displayedName: labels.HISTORY
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          subjectType: tableUtils.composeCell({
            fieldName: 'subjectType',
            fieldValue: c.subjectType
          }),
          communicationType: tableUtils.composeCell({
            fieldName: 'communicationType',
            fieldValue: c.communicationType
          }),
          denomination: tableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: c.denomination
          }),
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          responsibleName: tableUtils.composeCell({
            fieldName: 'responsibleName',
            fieldValue: c.responsibleName
          }),
          section: tableUtils.composeCell({
            fieldName: 'section',
            fieldValue: c.section
          }),
          actions: tableUtils.composeCell({
            fieldName: 'actions',
            additionalClass: 'd-flex justify-content-center',
            component: () => {
              return (
                <div className="d-flex justify-content-center p-0">
                  <CommunicationsHistoryModal subjectId={c.id} subjectName={c.denomination} />
                </div>
              )
            }
          })
        }
      })
    })
  }

  const reloadRows = (searchObject) => {
    searchComureiSubject(searchObject).then((res) => {
      setSubjects(res.content || res.data)
    })
  }

  const setNewSubjects = (newSubjects) => {
    setSubjects(newSubjects)
    props.setSubjects(newSubjects)
  }

  useEffect(() => {
    setRows(prepareRows(subjects))
    setId(id ? id : subjects?.[0]?.id)
  }, [subjects])

  useEffect(() => {
    setId()
  }, [props.resetForm])

  const renderTable = () => {
    return (
      <StarTable
        id={id}
        application="COMUREI"
        headerComponent={props.headerComponent}
        headerColumns={header}
        rows={rows}
        formOnBottom={true}
        tableContainerWidth={getTableContainerWidth()}
        getOffset={getOffset}
        loading={show}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => {
          setId()
          return searchComureiSubject(searchObject)
        }}
        onExecutedSearch={(content) => setNewSubjects(content)}
        usePagination={true}
        setId={setId}
        formComponent={ComureiForm}
        formComponentProps={{
          disabled: props.disabled || loading,
          formOnBottom: true,
          subjectId: id,
          locations: props.locations,
          subjectTypes: props.subjectTypes,
          openCreateCommunicationModal: props.openCreateCommunicationModal,
          setLoading: setLoading,
          close: (newId) => setId(newId || id ? id : undefined),
          getSubjects: () => {
            props.reloadList()
            reloadRows(searchObject)
          }
        }}
        // dialogComponent={
        //   id !== null &&
        //   id !== undefined &&
        //   !historyModal && (
        //     <ComureiSubjectModal
        //       id={id}
        //       showModal={!!id}
        //       hideButton={true}
        //       locations={props.locations}
        //       subjectTypes={subjectTypes}
        //       openCreateCommunicationModal={props.openCreateCommunicationModal}
        //       getSubjects={() => {
        //         props.reloadList()
        //         reloadRows(searchObject)
        //       }}
        //       closeModal={setId}
        //     />
        //   )
        // }
      />
    )
  }

  return <>{subjectTypes?.length > 0 && renderTable()}</>
}
