import { useSelector } from 'react-redux'
import getText from './i18n/labels'

export function ErrorPage() {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <div id="errorPage">
      <div className="text-center mt-5">
        <h1>{labels.SERVER_MAINTENANCE}</h1>
        <h3>{labels.PLEASE_TRY_AGAIN_IN_FIVE_MINUTES}</h3>

        <span
          className="text-primary"
          role="button"
          onClick={
            () => window.location.reload(true)
            /**in caso di nuove pubblicazioni, è bene pulire la cache per evitare che venga utilizzata la vecchia versione
             * il reload forza axios a fare una nuova chiamata che, se ha successo, porterà alla pagina di login*/
          }>
          {labels.RETURN_TO_MAIN_PAGE}
        </span>
      </div>
    </div>
  )
}
