import { useSelector } from 'react-redux'
import getText from '../../shared/i18n/labels'

export default function BlueHelpModal() {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <>
      <div className="container px-0">
        {labels.HELP.map((h) => (
          <div key={`HELP_${h.name}`}>
            <strong>{h.name}</strong>
            <br />
            <ul>
              {h.text.map((t, i) => (
                <li key={`HELP_${h.name}_${i}`}>{t}</li>
              ))}
            </ul>
            <br />
          </div>
        ))}
      </div>
    </>
  )
}
