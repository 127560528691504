import { useSelector } from 'react-redux'
import moment from 'moment'
import { downloadFile } from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { ComureiEmailForm } from './NewComureiEmailForm'

// COMPONENTS
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

import '../../../../styles/alertComm.css'
import 'react-confirm-alert/src/react-confirm-alert.css'

export function CreateComureiCommunicationModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const closeModal = () => {
    if (props.closeModalComm) props.closeModalComm()
  }

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        downloadFile(response.data, file)
        PopupSuccess({ text: labels.FILE_DOWNLOADED })
      }
    })
  }

  const downloadTxt = (communication) => {
    let url = `/api/comurei/custom/comurei-communications/downloadTxt/${communication.subjectId}/${communication.id}`
    callDownloadService(url, communication.filename)
  }

  const downloadPdf = (communication) => {
    let url = `/api/comurei/custom/comurei-communications/downloadPdf/${communication.subjectId}/${communication.id}`
    callDownloadService(url, communication.filename.replace('.txt', '.pdf'))
  }

  const renderRows = (communications) => {
    return communications.map((communication, index) => {
      let section = ''
      if (communication.registryReason) {
        section = section + 'Indagini Bancarie/'
      }

      if (communication.monitoringReason) {
        section = section + 'Monitoraggio Fiscale/'
      }

      if (communication.factaReason) {
        section = section + 'FATCA/CRS'
      }

      if (section.endsWith('/')) {
        section = section.substring(0, section.length - 1)
      }
      return (
        <div key={index} className={'text-start row-table'}>
          <div className="w-15 div-td text-truncate">
            {moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}
          </div>
          <div className="w-30 div-td text-truncate">{communication.creationUser}</div>
          <div className="w-20 div-td text-truncate">
            {communication.communicationType.code} - {communication.communicationType.description}
          </div>
          <div className="w-30 div-td text-truncate">{section}</div>
          <div className="w-10 div-td text-truncate text-center containerPopOver">
            <div className="txt" onClick={() => downloadTxt(communication)}>
              <i className="thx-text-file thx-icon bg-primary" />
            </div>
            <div className="pdf" onClick={() => downloadPdf(communication)}>
              <i className="thx-pdf thx-icon bg-primary" />
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={props.showModal}
        onHide={closeModal}
        dialogClassName="modal-70w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {labels.COMMUNICATION_TO_R_E_I_FOR}
              <strong className="ms-1">
                {props.subject.personType === Constants.PNF
                  ? props.subject.companyName
                  : props.subject.lastName + ' ' + props.subject.firstName}
              </strong>
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="star-table">
            <div className="text-start header-table bg-white">
              <div className="w-15 div-td">{labels.CREATION_DATE}</div>
              <div className="w-30 div-td">{labels.USER}</div>
              <div className="w-20 div-td">{labels.COMMUNICATION_TYPE}</div>
              <div className="w-30 div-td">{labels.SECTION}</div>
              <div className="w-10 div-td">{labels.DOWNLOAD}</div>
            </div>
            {renderRows(props.communications)}
          </div>
          <ComureiEmailForm
            subjectId={props.communication?.subjectId}
            sentCommunications={props.communications}
            emailAddress={props.user.login}
            closeModal={closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
