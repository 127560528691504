import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatDateForDisplay } from '../shared/Utility'
import moment from 'moment'
import * as actions from '../../actions'
import getText from '../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// COMPONENTS
import { PopupError } from '../shared/popups/PopupError'
import { PopupSuccess } from '../shared/popups/PopupSuccess'

import crsPositive from '../../styles/images/app/positive/Crs.png'
import coraPositive from '../../styles/images/app/positive/Cora.png'
import comureiPositive from '../../styles/images/app/positive/Comurei.png'
import corproPositive from '../../styles/images/app/positive/Corpro.png'
import arcoPositive from '../../styles/images/app/positive/Arco.png'
import bluePositive from '../../styles/images/app/positive/Blue.png'

export const ApplicationList = ({ applications, getApplication }) => {
  const [showDemoModal, setShowDemoModal] = useState(false)
  const [selectedApplicationForModal, setSelectedApplicationForModal] = useState('')

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const checkExpire = (expire) => {
    const format = formatDateForDisplay(expire)
    if (moment(expire).add(1, 'days').isSameOrAfter(moment())) {
      return `${labels.UP_TO} ${format}`
    } else {
      return `${labels.EXPIRED_ON} ${format}`
    }
  }

  const openModal = (applicationName) => {
    setShowDemoModal(true)
    setSelectedApplicationForModal(applicationName)
  }

  const closeModal = () => {
    setShowDemoModal(false)
  }

  const activateDemo = (applicationName) => {
    actions.activateDemoForApplication(applicationName).then(
      (response) => {
        if (response) {
          closeModal()
          getApplication()
          PopupSuccess({
            text: labels.DEMO_LICENSE_ACTIVATED,
            handleClickConfirm: () => window.location.reload()
          })
        }
      },
      (errors) => PopupError({ text: labels.GENERIC_ERROR_IN_DATA_SAVING })
    )
  }

  const renderApplications = () => {
    return applications.map((application) => {
      let icon = ''
      let modalFunc
      const applicationName = application.application.name

      switch (applicationName.toLowerCase()) {
        case 'cora':
          icon = coraPositive
          modalFunc = renderCoraModal
          break
        case 'comurei':
          icon = comureiPositive
          modalFunc = renderComureiModal
          break
        case 'crs':
          icon = crsPositive
          modalFunc = renderCRSModal
          break
        case 'corpro':
          icon = corproPositive
          modalFunc = renderCorproModal
          break
        case 'arco':
          icon = arcoPositive
          modalFunc = renderArcoModal
          break
        case 'blue':
          icon = bluePositive
          modalFunc = renderBlueModal
          break
        case 'dac7':
          // eslint-disable-next-line no-restricted-globals
          modalFunc = renderDac7Modal
          icon = '/app/dac7/assets/dac7-logo.png'
          break
        default:
      }

      let licenseString = ''
      switch (application.licenseType) {
        case 'BOUGHT':
          licenseString = `${labels.SUBSCRIPTION} ${checkExpire(application.expiration)}`
          break
        case 'DEMO':
          licenseString = `${labels.DEMO} ${checkExpire(application.expiration)}`
          break
        case 'DEMOAVAILABLE':
          licenseString = labels.DEMO_AVAILABLE_FOR_30_DAYS
          break
      }

      return (
        <div key={applicationName}>
          {modalFunc()}
          {application.licenseType === 'DEMOAVAILABLE' ? (
            <button className="card" onClick={() => openModal(applicationName)}>
              <div className="col">
                <div className="row coraStar-img">
                  <img src={icon} alt={`${applicationName}`} className="image-app" />
                </div>
                <div className="row coraStar-subtitle">
                  <span>{application.application.description}</span>
                </div>
                <div className="row coraStar-scadenza">
                  {applicationName === 'COMUREI' ? (
                    <span>{`"${labels.ACTIVATE_THE_FIRST_SUBJECT_FOR_FREE}"`}</span>
                  ) : (
                    <span>{licenseString}</span>
                  )}
                </div>
              </div>
            </button>
          ) : (
            <Link className="card" to={`/app/${applicationName.toLowerCase()}/dashboard`}>
              <div className="col">
                <div className="row coraStar-img">
                  <img src={icon} alt={`${applicationName}`} className="image-app" />
                </div>
                <div className="row coraStar-subtitle">
                  <span>{application.application.description}</span>
                </div>
                {renderLabel(application, licenseString)}
              </div>
            </Link>
          )}
        </div>
      )
    })
  }

  const renderBlueModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'BLUE'}
        onHide={closeModal}
        applicationName={selectedApplicationForModal}
        labels={labels}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              {`${labels.WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION} ${selectedApplicationForModal} ${labels.FOR_30_DAYS}?`}
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderArcoModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'ARCO'}
        onHide={closeModal}
        applicationName={selectedApplicationForModal}
        labels={labels}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              {`${labels.WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION} ${selectedApplicationForModal} ${labels.FOR_30_DAYS}?`}
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderCorproModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'CRS'}
        onHide={closeModal}
        applicationName={selectedApplicationForModal}
        labels={labels}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              {`${labels.WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION} ${selectedApplicationForModal} ${labels.FOR_30_DAYS}?`}
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderDac7Modal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'DAC7'}
        onHide={closeModal}
        applicationName={selectedApplicationForModal}
        labels={labels}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              {`${labels.WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION} ${selectedApplicationForModal} ${labels.FOR_30_DAYS}?`}
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderCoraModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'CORA'}
        onHide={closeModal}
        applicationName="CORA"
        labels={labels}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              {labels.WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION_OF_CORA}
            </div>
            <div className="d-flex justify-content-center">
              <strong>{labels.THE_CORA_WEB_DEMO}</strong>
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderCRSModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'CRS'}
        onHide={closeModal}
        applicationName="CRS"
        labels={labels}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              {labels.WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION_OF_CRS}
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderComureiModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'COMUREI'}
        onHide={closeModal}
        applicationName="COMUREI"
        labels={labels}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              {labels.WOULD_YOU_LIKE_TO_ACTIVATE_COMUREI}
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderLabel = (application, licenseString) => {
    if (application.application.name.toLowerCase() === 'comurei') {
      return (
        <div className="row coraStar-scadenza">
          <span>
            {labels.SUBSCRIPTION_WITHOUT_EXPIRATION_FOR} <b>{application.maxSubjects}</b>{' '}
            {labels.SUBJECTS}
          </span>
        </div>
      )
    }
    if (application.licenseType === 'BOUGHT' || application.licenseType === 'DEMO') {
      return (
        <div className="row coraStar-scadenza">
          <span>
            {licenseString} {labels.FOR} <b>{application.maxSubjects}</b> {labels.SUBJECTS}
          </span>
        </div>
      )
    }
  }

  return (
    <div className="container-dash">
      <div className={applications?.length > 0 ? 'panel-dash' : ''}>{renderApplications()}</div>
    </div>
  )
}

function DemoActivationModal({
  show = false,
  onHide = () => {},
  applicationName = '',
  BodyComponent = () => <></>,
  buttonText,
  titleText,
  onActivateDemo = () => {},
  labels
}) {
  return (
    <Modal
      key={'modal' + applicationName}
      backdrop="static"
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}>
      <div className="bar" />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {titleText || `${labels.DEMO_ACTIVATION} ${applicationName}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="font-size-big">
          <BodyComponent />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between">
          <button className="btn btn-outline-primary " onClick={onHide}>
            {labels.CLOSE}
          </button>
          <button className="btn btn-primary" onClick={onActivateDemo}>
            {buttonText || labels.DEMO_ACTIVATION}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
