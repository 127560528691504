import { useState } from 'react'
import { useSelector } from 'react-redux'
import getText from '../../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// COMPONENTS
import MessageWrongFile from './MessageWrongFile'
import MessageCorrectFile from './MessageCorrectFile'

/**
 * Questa funzione ha il compito di visualizzare il popup
 * @returns popup singolo con i sati passsati in input
 */
export default function ModalProcessingOutComeReceipt(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showReceiptModal, setshowReceiptModal] = useState(true)

  const closeReceiptModal = () => {
    setshowReceiptModal(false)
  }

  return (
    <Modal
      backdrop="static"
      show={showReceiptModal}
      onHide={closeReceiptModal}
      dialogClassName="modal-70w"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.subjectName && (
            <h2>
              {labels.SUBJECT}: {props.subjectName}
            </h2>
          )}
          <h2>
            {labels.PROCESSING_FILE_RESULT}: "{props.fileName}"
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.communication?.id ? (
          <MessageCorrectFile
            closeReceiptModal={props.closeReceiptModal}
            communication={props.communication}
            receiptType={props.receiptType}
            fileName={props.fileName}
          />
        ) : (
          <MessageWrongFile communicationError={props.communication.communicationError} />
        )}
      </Modal.Body>
    </Modal>
  )
}
