import { confirmAlert } from 'react-confirm-alert'
import getText from '../../shared/i18n/labels'

// BUTTONS
import { BtnFill } from '../BtnFill'

import 'react-confirm-alert/src/react-confirm-alert.css'

export const titleColors = {
  ALERT: 'popup-title-alert',
  SUCCESS: 'popup-title-success',
  ERROR: 'popup-title-delete'
}

export function PopupConfirm(props) {
  const Component = props.component || (() => <></>)

  const labels = getText(localStorage.getItem('language'))

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup popup-large modal-content">
            <div className="w-100 d-flex justify-content-end">
              <button type="button" className="close btn-close" onClick={onClose}>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <div className={`row show-grid ${props.titleColor} text-center`}>
              <div className="col-12">
                <h5 className="fw-bold">{props.title || 'Attenzione'}</h5>
              </div>
            </div>
            <div className={'row show-grid text-center'}>
              <div className="col-12" dangerouslySetInnerHTML={{ __html: props.innerHtml }}></div>
              <div className="col-12">{props.text}</div>
              <div className="col-12">
                <Component />
              </div>
            </div>
            <div
              className={`pt-5 d-flex align-items-center ${props.showCancelButton !== false && props.showConfirmButton !== false ? 'justify-content-between' : 'justify-content-center'}`}>
              {props.showCancelButton !== false && (
                <button
                  type="button"
                  className="btn btn-outline-primary "
                  onClick={() => {
                    if (props.onClickCancel) props.onClickCancel()
                    onClose()
                  }}>
                  {props.cancel || labels.CANCEL}
                </button>
              )}
              {props.showConfirmButton !== false && (
                <BtnFill
                  text={props.confirm || labels.CONTINUE}
                  handlerOnClick={() => {
                    if (props.handleClickConfirm) props.handleClickConfirm()
                    onClose()
                  }}
                />
              )}
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
