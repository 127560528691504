import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import getText from '../../../shared/i18n/labels'

// FORM
import { Form } from 'react-bootstrap'

import './ModalPasswordBytes.css'

export default function ModalPasswordBits({ certificateDto, fiscalCode }) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const subjectFiscalCode = fiscalCode
  const [dateClass, setDateClass] = useState('')
  const [bitsText, setBitsText] = useState('')
  const [bitsClass, setBitsClass] = useState('')
  const [cnText, setCnText] = useState('')
  const [cnClass, setCnClass] = useState('')

  useEffect(() => {
    setBitsText(updateTextBytes())
    setBitsClass(computeBitsColor())
    setCnClass(computeCnColor())
    setCnText(updateTextCn())
    setDateClass(computeDateColor())
  }, [])

  /**
   * Funzione che rielabora la data passata dalla props in un formato piu leggibile
   * @returns data in formato stringa
   */
  function elaborateDate() {
    let text = ''
    let format = new Date(certificateDto.endDate)
    text = format.toLocaleDateString()
    const isNotValid = format < new Date()
    if (isNotValid) {
      text = text + ` - ${labels.CERTIFICATES_EXPIRED}.`
    }
    return (
      <Form.Text className={dateClass + ' d-block'}>
        <strong>{text}</strong>
        {isNotValid && (
          <>
            <br></br>
            <strong>
              {`${labels.INSTRUCTIONS_FOR_RENEWAL} in `}
              <a
                target="_blank"
                href="https://www.starsoluzioni.it/verifica-certificati-firma-digitale/">
                {labels.THIS_PAGE}
              </a>
            </strong>
          </>
        )}
      </Form.Text>
    )
  }
  /**
   * Questa funzione controlla i bits passati come input se corrispondono a 4096,
   * se no restituisce un messaggio di errore
   * @returns funzione che ritorna un messaggio a seconda se i bits sono aggiornati o no
   */
  function updateTextBytes() {
    let bits = certificateDto.econdingBits.trim()
    if (bits.includes('4096')) {
      return bits /*  + " (nessun aggiornamento richiesto)" */
    }
    return bits + ` (${labels.UPDATE_REQUIRED_BY_31_01_2023})`
  }

  /**
   * Questa funzione controlla se il soggetto nel certificato corrisponde al codice fiscale inserito dall'utente.
   * @returns il messaggio a schermo
   */
  function updateTextCn() {
    if (!computeCn()) return `${labels.INCORRECT_FISCAL_CODE}.`

    return 'Codice fiscale corretto'
  }

  /**
   * Questa funzione ritorna un nome di una proprieta css,
   * a seconda se il messaggio e accettabile o meno.
   * In questo modo si potra cambiare lore in modo piu automatico nelle scritte
   * @returns a seconda del messaggio una proprieta css differente
   */
  function computeBitsColor() {
    let bits = certificateDto.econdingBits.trim()
    if (bits.includes('4096')) {
      return 'correct'
    }
    return 'error'
  }

  /**
   * Questa funzione ritorna un nome di una proprieta css,
   * a seconda se il messaggio e accettabile o meno.
   * In questo modo si potra cambiare lore in modo piu automatico nelle scritte
   * @returns a seconda del messaggio una proprieta css differente
   */
  function computeCnColor() {
    if (computeCn()) return 'correct'
    return 'error'
  }

  /**
   * Questa funzione controlla se il soggetto nel certificato corrisponde al codice fiscale inserito dall'utente.
   * @returns vero se c'è corrispondenza, altrimenti falso
   */
  function computeCn() {
    if (!certificateDto.cn) return false

    return certificateDto.cn.includes(subjectFiscalCode)
  }

  function computeDateColor() {
    if (new Date(certificateDto.endDate) < new Date()) {
      return 'error'
    }
    return 'correct'
  }

  return (
    <Form className="password-bits">
      <Form.Group className="mb-3">
        <h4>{certificateDto.certificateType}</h4>
      </Form.Group>
      <Form.Group className="mb-3 form-group">
        <Form.Label>{labels.ISSUANCE_DATE}</Form.Label>
        <Form.Text className={'normal d-block'}>
          {new Date(certificateDto.startDate).toLocaleDateString()}
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 form-group">
        <Form.Label>{labels.EXPIRATION_DATE}</Form.Label>
        {elaborateDate()}
      </Form.Group>
      <Form.Group className="mb-3 form-group">
        <Form.Label>{labels.PASSWORD}</Form.Label>
        <Form.Text className={'correct d-block'}>{'CORRETTA'}</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 form-group">
        <Form.Label>{labels.PUBLIC_KEY}</Form.Label>
        <Form.Text className={bitsClass + ' d-block'}>{bitsText}</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 form-group">
        <Form.Label>{labels.SUBJECT}</Form.Label>
        <Form.Text className={cnClass + ' d-block'}>
          {certificateDto.cn.replace('-FOL', '')}
          {cnText && <div>{cnText}</div>}
        </Form.Text>
      </Form.Group>
    </Form>
  )
}
