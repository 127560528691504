import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { saveSid, validateSid } from '../../../shared/SidCertsHandler'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import { CreateCommunicationModal } from '../communications/NewCreateCommunicationModal'

// FORM
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear'
import ComureiSubjectNotPresentAlert from '../../../shared/popups/ComureiSubjectNotPresentAlert'

import radioSel from '../../../../styles/images/radiobutton-selected.png'
import radioUnsel from '../../../../styles/images/radiobutton-unselected.png'

import '../../../../styles/comurei-form.css'
import '../../../../styles/subject-crs.css'

const defaultSize = (size = null) => ({
  leftSide: Utility.getSize(size, { default: '60', lg: '60', md: '58' }),
  rightSide: Utility.getSize(size, { default: '40', lg: '40', md: '42' }),
  subjectCode: Utility.getSize(size, { default: '10', lg: '15' }),
  fiscalCode: Utility.getSize(size, { default: '24', lg: '35' }),
  address: Utility.getSize(size, { default: '64', lg: '50' }),
  location: Utility.getSize(size, { default: '30', lg: '40', md: '38' }),
  province: Utility.getSize(size, { default: '8', lg: '12', md: '14' }),
  countryCode: Utility.getSize(size, { default: '60', lg: '48' }),
  companyName: Utility.getSize(size, { default: '76' }),
  flagOicrTrust: Utility.getSize(size, { default: '24' }),
  communicationType: Utility.getSize(size, { default: '35', lg: '100' }),
  referenceYear: Utility.getSize(size, { default: '17', lg: '27' }),
  lastCommunication: Utility.getSize(size, { default: '28', lg: '42' }),
  idFiscalOicrTrust: Utility.getSize(size, { default: '18', lg: '31' }),
  // SID FORM
  sidNumber: Utility.getSize(size, { default: '22', lg: '100' }),
  password: Utility.getSize(size, { default: '42', lg: '50' }),
  expirationDate: Utility.getSize(size, { default: '36', lg: '50' }),
  email: Utility.getSize(size, { default: '55' }),
  flagPec: Utility.getSize(size, { default: '45' })
})

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export function CrsForm(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)

  const [subject, setSubject] = useState({})
  const [defaultEmail, setDefaultEmail] = useState('')

  const [coraSubjectSid, setCoraSubjectSid] = useState({
    id: null,
    certificate1: '',
    certificate2: '',
    certificate3: '',
    password: '',
    email: '',
    sidNumber: '',
    flagPec: false,
    expirationDate: null
  })

  const [errorsSid, setErrorsSid] = useState({})

  const retrieveSubject = (id) => {
    setLoading(true)
    setErrorsSid({})
    if (id) {
      actions
        .getCrsSubject(id)
        .then((response) => {
          setSubject(response)
          setNewInitialValues(response)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    } else {
      setSubject({})
      setNewInitialValues(undefined)
      setLoading(false)
    }
  }

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  const setNewInitialValues = (subjectItem = {}) => {
    setTouched(false)
    if (subjectItem?.id) setCoraSubjectSid(subjectItem.coraSubjectSid)
    else
      setCoraSubjectSid({
        id: null,
        certificate1: '',
        certificate2: '',
        certificate3: '',
        password: '',
        email: '',
        sidNumber: '',
        flagPec: false,
        expirationDate: null
      })
    if (isNotEmpty(auth)) {
      if (isEmpty(coraSubjectSid?.email)) {
        setDefaultEmail(auth?.user?.login)
      }
    }

    setInitialValues({
      id: subjectItem?.id,
      subjectCode: subjectItem?.subjectCode || '0' + props.newSubjectCode,
      fiscalCode: subjectItem?.fiscalCode || '',
      companyName: subjectItem?.companyName || '',
      address: subjectItem?.address || '',
      location: subjectItem?.location || '',
      province: subjectItem?.province || '',
      flagOicrTrust: subjectItem?.flagOicrTrust || false,
      idFiscalOicrTrust: subjectItem?.idFiscalOicrTrust || '',
      countryCode: subjectItem?.countryCode || 'IT',
      country: subjectItem?.country || 'ITALIA (REPUBBLICA ITALIANA)',
      communicationTypeCode: subjectItem?.communicationTypeCode || '1',
      referenceYear: subjectItem?.referenceYear || new Date().getFullYear() - 1,
      lastCommunication: subjectItem?.lastCommunication || '',
      errorFiscalCode: false
    })
  }

  const validationSchema = Yup.object().shape({
    subjectCode: Yup.string().required(labels.REQUIRED_FIELD),
    companyName: Yup.string().required(labels.REQUIRED_FIELD),
    fiscalCode: Yup.string().required(labels.REQUIRED_FIELD),
    address: Yup.string().required(labels.REQUIRED_FIELD),
    location: Yup.string().required(labels.REQUIRED_FIELD),
    province: Yup.string().required(labels.REQUIRED_FIELD),
    flagOicrTrust: Yup.boolean(),
    idFiscalOicrTrust: Yup.string().when('flagOicrTrust', {
      is: true, // When flagOicrTrust is true (checked)
      then: (schema) => schema.required(labels.REQUIRED_FIELD), // Make idFiscalOicrTrust required
      otherwise: (schema) => schema.notRequired() // Otherwise, it's optional
    }),
    countryCode: Yup.string().required(labels.REQUIRED_FIELD),
    country: Yup.string().required(labels.REQUIRED_FIELD),
    communicationTypeCode: Yup.string().required(labels.REQUIRED_FIELD),
    referenceYear: Yup.string().required(labels.REQUIRED_FIELD),
    lastCommunication: Yup.string(),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'subjectCode',
      errorLabel: labels.CODE
    },
    {
      errorKey: 'fiscalCode',
      errorLabel: labels.FISCAL_CODE
    },
    {
      errorKey: 'companyName',
      errorLabel: labels.LEGAL_NAME
    },
    {
      errorKey: 'address',
      errorLabel: labels.ADDRESS
    },
    {
      errorKey: 'location',
      errorLabel: labels.LEGAL_SEAT
    },
    {
      errorKey: 'province',
      errorLabel: labels.PROVINCE
    },
    {
      errorKey: 'idFiscalOicrTrust',
      errorLabel: labels.ID_FISCAL_OICR_TRUST
    },
    {
      errorKey: 'country',
      errorLabel: labels.COUNTRY
    },
    {
      errorKey: 'countryCode',
      errorLabel: labels.COUNTRY_CODE
    },
    {
      errorKey: 'referenceYear',
      errorLabel: labels.REFERENCE_YEAR
    },
    {
      errorKey: 'lastCommunication',
      errorLabel: labels.LAST_COMMUNICATION
    },
    {
      errorKey: 'errorSidNumber',
      errorLabel: labels.SID_NUMBER
    },
    {
      errorKey: 'errorPassword',
      errorLabel: labels.PROTECTION_PASSWORD
    },
    {
      errorKey: 'errorInSubject',
      errorLabel: labels.SUBJECT
    },
    {
      errorKey: 'errorInConfigSid',
      errorLabel: labels.SID_CONFIGURATION
    },
    {
      errorKey: 'errorEmail',
      errorLabel: labels.EMAIL
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: labels.FISCAL_CODE
    }
  ]

  const getError = (errors, key, removeOldErrors) => {
    if (errorsSid[key]?.isValid === false) {
      errors[key] = errorsSid[key].msg
    } else if (removeOldErrors) {
      delete errors[key]
    }
    return errors
  }

  const getTotalErrors = (errors = {}, removeOldErrors = false) => {
    errors = getError(errors, 'errorCertificates', removeOldErrors)
    errors = getError(errors, 'errorPassword', removeOldErrors)
    errors = getError(errors, 'errorSidNumber', removeOldErrors)
    errors = getError(errors, 'errorEmail', removeOldErrors)
    errors = getError(errors, 'errorInConfigSid', removeOldErrors)
    return errors
  }

  const getErrors = (errors) => {
    const totalErrors = getTotalErrors(errors, true)
    return Utility.extractErrors(totalErrors, formErrorsTabMapping)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}>
      {({ errors }) => (
        <Form className={props.formOnBottom ? 'p-2 form-on-bottom border-top' : ''}>
          {showErrorAlert && touched && (
            <div className={props.formOnBottom ? 'crs-form-on-bottom-error' : ''}>
              <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
            </div>
          )}
          <FormBody
            {...props}
            loading={loading}
            touched={touched}
            subject={subject}
            initialValues={initialValues}
            defaultEmail={defaultEmail}
            coraSubjectSid={coraSubjectSid}
            errorsSid={errorsSid}
            setSubject={setSubject}
            setErrorsSid={setErrorsSid}
            setCoraSubjectSid={setCoraSubjectSid}
            setShowErrorAlert={setShowErrorAlert}
            setLoading={setLoading}
            setTouched={setTouched}
            retrieveSubject={retrieveSubject}
            getTotalErrors={getTotalErrors}
          />
        </Form>
      )}
    </Formik>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const createCommunicationRefData = useRef()

  const [certificateFiles, setCertificateFiles] = useState()
  const [isCertSidOpened, setIsCertSidOpened] = useState(false)

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const nameApplication = Constants.APPLICATION_CRS
  const id = props.subjectId

  const configurationSidValidation = () => {
    return validateSid(
      props.coraSubjectSid?.sidNumber,
      props.coraSubjectSid?.email,
      props.errorsSid.errorSidNumber,
      props.errorsSid.errorEmail,
      labels
    )
  }

  const close = (retrieve = false, response) => {
    props.setTouched(false)
    props.setErrorsSid({})
    if (retrieve) props.retrieveSubject(props.subjectId)
    if (props.close) props.close(props.subjectId ? null : response)
  }

  const handleChangeSidValues = (newValues) => {
    props.setCoraSubjectSid({ ...props.coraSubjectSid, ...newValues })
  }

  const handleCommunicationTypeClick = (val) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    form.communicationTypeCode = val
    setValues(form)
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = ![null, undefined, ''].includes(val) ? val : null
    setValues(form)
  }

  const handleInputAutocompile = (newLocation) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        form.location = newLocation
        if (loc) form.province = loc.province
        setValues(form)
      },
      () => {
        const form = Object.assign({}, values)
        form.location = ''
        form.province = ''
        setValues(form)
      }
    )
  }

  const handleInputAutocompileCountry = (newCountry) => {
    const form = Object.assign({}, values)
    if (!newCountry || newCountry === '') {
      form.countryCode = newCountry
      form.country = ''
      setValues(form)
      return
    }
    newCountry = newCountry ? newCountry.toUpperCase() : ''
    let countriesFilter = props.countries.filter((country) => {
      return country.name === newCountry
    })
    if (countriesFilter.length === 1) {
      form.countryCode = countriesFilter[0].name
      form.country = countriesFilter[0].key
    }
    if (countriesFilter.length === 0) {
      form.countryCode = newCountry
      form.country = ''
    }
    setValues(form)
  }

  // TODO ASPETTARE API DI EDOARDO
  const checkSid = (sidNumber) => {
    const allSubjects = props.subjects || []
    for (const subject of allSubjects) {
      const sid = subject.coraSubjectSid?.sidNumber
      if (subject.id != props.subjectId && sid === sidNumber) {
        PopupError({ text: labels.SID_NUMBER_ALREADY_USED })
        return false
      }
    }
    return true
  }

  /* Validation onChange */
  const handleChangeSid = (val, errorKey, errorMessage) => {
    let value = val.target.value
    let name = val.target.name
    let newCoraSubjectSid = cloneDeep(props.coraSubjectSid)
    if (!newCoraSubjectSid) newCoraSubjectSid = {}
    const error = {}
    error[errorKey] = {}
    if (isEmpty(value)) {
      error[errorKey].isValid = false
      error[errorKey].msg = errorMessage
    } else {
      error[errorKey].isValid = true
      error[errorKey].msg = null
    }
    props.setErrorsSid({
      ...props.errorsSid,
      errorInConfigSid: { isValid: true, msg: '' },
      ...error
    })
    if (name === 'password') {
      newCoraSubjectSid.password = value.toUpperCase().trim()
    }
    if (name === 'sidNumber') {
      if (checkSid(value.toUpperCase().trim())) {
        newCoraSubjectSid.sidNumber = value.toUpperCase().trim()
      } else {
        PopupError({
          text: `${labels.SID_NUMBER}: ${value}<br> ${labels.SID_NUMBER_ALREADY_USED}`,
          labels
        })
      }
    }
    if (name === 'email') {
      newCoraSubjectSid.email = value ? value.toUpperCase().trim() : value
    }
    props.setCoraSubjectSid(newCoraSubjectSid)
  }

  const handleFiscalCode = (fiscalCode) => {
    const form = Object.assign({}, values)
    if (fiscalCode) {
      actions.getBaseRegistryByFiscalCode(fiscalCode).then(
        (baseRegistry) => {
          if (baseRegistry) {
            form.companyName = baseRegistry?.companyName || ''
            form.location = baseRegistry?.location?.location || ''
            form.province = baseRegistry?.location?.province || ''
            form.lastName = baseRegistry?.lastName || ''
            form.firstName = baseRegistry?.firstName || ''
            form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
            form.gender = baseRegistry?.gender
          }
          form.errorFiscalCode = false
          setValues(form)
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
      actions.getCoraSubjectSidByFiscalCode(fiscalCode, 'crs').then(
        (_coraSubjectSid) => {
          if (_coraSubjectSid?.id) {
            props.setCoraSubjectSid(_coraSubjectSid)
          }
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
    }
  }

  const formValidation = () => {
    if (!isCertSidOpened) {
      return {
        errorSidNumber: { isValid: true, msg: '' },
        errorEmail: { isValid: true, msg: '' }
      }
    }
    return configurationSidValidation()
  }

  const addCrsSubject = (newSubject, sendComm, sid) => {
    const shouldVerifyOnComurei =
      isEmpty(newSubject.id) || props.subject.fiscalCode !== newSubject.fiscalCode
    newSubject.coraSubjectSid = sid
    props.setLoading(true)
    actions.addCrsSubject(newSubject).then(
      (response) => {
        props.setLoading(false)
        props.setSubject(response)
        setValues(response)
        if (shouldVerifyOnComurei) {
          // TODO Check right behave
          actions.verifyCrsSubjectOnComurei(newSubject.fiscalCode).then(
            (res) => {
              if (res === false) {
                ComureiSubjectNotPresentAlert({
                  fiscalCode: newSubject.fiscalCode,
                  denomination: newSubject.companyName
                })
              } else {
                PopupSuccess({ text: labels.SUBJECT_SAVED_CORRECTLY })
              }
            },
            (errors) => props.setLoading(false)
          )
        } else if (sendComm) {
          if (document.getElementById('subject-modal')) {
            document.getElementById('subject-modal').style.display = 'none'
          }
          createCommunicationRefData.current.clickButtonSend()
        } else {
          PopupSuccess({ text: labels.SUBJECT_SAVED_CORRECTLY })
        }
        props.setLoading(false)
        close(false, response)
        props.getSubjects()
      },
      (errors) => {
        props.setLoading(false)
        if (errors.status === 511) {
          PopupError({
            text:
              errors.status === 511
                ? labels.MAX_SUBJECTS_REACHED
                : Constants.APPLICATION_GENERIC_ERROR
          })
        } else PopupError({ text: errors?.data?.title || Constants.APPLICATION_GENERIC_ERROR })
      }
    )
  }

  const saveCrsSubject = (form, sendComm) => {
    props.setLoading(true)
    saveSid(
      certificateFiles,
      props.coraSubjectSid,
      form?.fiscalCode,
      'crs',
      (res) => {
        addCrsSubject(form, sendComm, res)
        props.setLoading(false)
      },
      (errors) => {
        props.setLoading(false)
        PopupError({
          text: errors.detail ? errors.detail : Constants.APPLICATION_GENERIC_ERROR,
          labels
        })
      }
    )
  }

  const updateAll = (sendComm) => {
    const form = Object.assign({}, values)
    if (sendComm === true) {
      const lastYear = new Date().getFullYear() - 1
      if (form.referenceYear < lastYear) {
        PopupAlertCommunicationYear(
          values.referenceYear,
          lastYear,
          () => saveCrsSubject(form, sendComm),
          () => {
            form.referenceYear = lastYear
            props.setSubject(form)
            setValues(form)
            saveCrsSubject(form, sendComm)
          }
        )
        return
      }
    }
    saveCrsSubject(form, sendComm)
  }

  const handleFormSubmit = async (sendComm) => {
    const errors = await validateForm(values)
    props.setTouched(true)
    const { errorSidNumber, errorEmail } = formValidation()
    const totalErrors = props.getTotalErrors({
      ...errors,
      errorSidNumber: errorSidNumber?.msg,
      errorEmail: errorEmail?.msg
    })

    const thereAreErrors =
      Object.keys(totalErrors).length > 0 &&
      Object.values(totalErrors).filter((e) => !!e)?.length > 0

    props.setErrorsSid({ ...props.errorsSid, errorSidNumber, errorEmail })
    if (thereAreErrors) {
      props.setShowErrorAlert(true)
    } else {
      updateAll(sendComm)
      props.setShowErrorAlert(false)
    }
  }

  return (
    <div className="row row-detail bg-gray d-flex">
      <div className={`${sizesClass.leftSide} pe-3`} id={`anagrafica-${props.subject?.id || 0}`}>
        <div className="configurazioneSID p-1 pt-3">
          <div id={`anagrafica-${id || 0}`}>
            <div className="row">
              <div className={`${sizesClass.subjectCode} pe-2`}>
                <BwmInput
                  name="subjectCode"
                  className="form-control"
                  label={labels.CODE}
                  maxLength="16"
                  disabled={true}
                  value={values.subjectCode}
                  error={errors.subjectCode}
                  touched={props.touched}
                />
              </div>
              <div className={`${sizesClass.fiscalCode} mb-2 pe-2`}>
                <BwmInput
                  disabled={props.disabled}
                  name="fiscalCode"
                  label={
                    !values.flagOicrTrust
                      ? `${labels.FISCAL_CODE}*`
                      : `${labels.COMMUNICANT_FISCAL_CODE}*`
                  }
                  className="form-control"
                  maxLength="11"
                  value={values.fiscalCode}
                  error={errors.fiscalCode || errors.errorFiscalCode}
                  touched={props.touched}
                  onBlur={(e) => handleFiscalCode(e.target.value)}
                  onChange={(e) => handleChangeValue(e.target.value, 'fiscalCode', true, true)}
                />
              </div>

              <div className={`${sizesClass.address} pe-2`}>
                <BwmInput
                  disabled={props.disabled}
                  name="address"
                  label={`${labels.ADDRESS}*`}
                  className="form-control"
                  maxLength="100"
                  value={values.address}
                  error={errors.address}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'address', true)}
                />
              </div>
              <div className={`${sizesClass.location} pe-2 mb-2`}>
                <AutoCompileCustom
                  disabled={props.disabled}
                  label={`${labels.LEGAL_SEAT}*`}
                  id="location"
                  filter={props.locations}
                  value={values.location}
                  error={errors.location}
                  touched={props.touched}
                  handleInputAutocompile={(e) => handleInputAutocompile(e)}
                />
              </div>
              <div className={`${sizesClass.province} pe-2`}>
                <ProvinceField
                  disabled={props.disabled}
                  label={`${labels.PROVINCE}*`}
                  placeholder={labels.PROVINCE_PLACEHOLDER}
                  province={values.province || ''}
                  error={errors.province}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'province', true, true)}
                />
              </div>
              <div className={`${sizesClass.countryCode} pe-2`}>
                <BwmSelect
                  disabled={props.disabled}
                  options={props.countries}
                  name="country"
                  id="country"
                  label={`${labels.COUNTRY}*`}
                  className="form-control"
                  showCode={true}
                  nameKey={true}
                  value={values.countryCode || ''}
                  error={errors.countryCode}
                  touched={props.touched}
                  onChange={(e) => handleInputAutocompileCountry(e.target.value)}
                />
              </div>
              <div className={`${sizesClass.companyName} pe-2`}>
                <BwmInput
                  disabled={props.disabled}
                  name="companyName"
                  label={
                    !values.flagOicrTrust
                      ? `${labels.LEGAL_NAME}*`
                      : `${labels.LEGAL_NAME_OICR_TRUST}*`
                  }
                  className="form-control font-weight-bold font-size-big"
                  maxLength="70"
                  value={values.companyName}
                  error={errors.companyName}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'companyName', true)}
                />
              </div>
              <div className={`${sizesClass.flagOicrTrust} mt-3`}>
                <BwmCheckbox
                  disabled={props.disabled}
                  name={`flagOicrTrust`}
                  label={labels.OICR_OR_TRUST}
                  className="form-control"
                  checked={values.flagOicrTrust}
                  error={errors.flagOicrTrust}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.checked, 'flagOicrTrust')}
                />
              </div>
              <div className="col-12">
                <div className={`mt-2 row h-100 ${!props.id ? 'mb-3' : ''}`}>
                  {/* Radio Buttons Section */}
                  <div className={`${sizesClass.communicationType} ms-2 align-items-center`}>
                    <div className="pb-1 pt-1">
                      <strong>{labels.COMMUNICATION_TYPE}</strong>
                    </div>
                    <div className="d-flex">
                      <div className="inputRadio me-3 pe-2">
                        <input
                          hidden={true}
                          className="form-check-input"
                          type="radio"
                          name={`communicationTypeCode`}
                          checked={values.communicationTypeCode === '1'}
                          id={`ct1`}
                          value={'1'}
                          onChange={(e) => handleCommunicationTypeClick(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor={`ct1`}>
                          <img
                            width="20"
                            className="pe-2"
                            src={values.communicationTypeCode === '1' ? radioSel : radioUnsel}
                            alt=""
                          />
                          {labels.NEW_DATA}
                        </label>
                      </div>
                      <div className="col-auto me-3">
                        <div className="inputRadio">
                          <input
                            hidden={true}
                            className="form-check-input"
                            type="radio"
                            name={`communicationTypeCode`}
                            checked={values.communicationTypeCode === '2'}
                            id={`ct2`}
                            value={'2'}
                            disabled={true}
                            onChange={(e) => handleCommunicationTypeClick(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor={`ct2`}>
                            <img
                              width="20"
                              className="pe-2"
                              src={values.communicationTypeCode === '2' ? radioSel : radioUnsel}
                              alt=""
                            />
                            {labels.SUBSTITUTIVE}
                          </label>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="inputRadio">
                          <input
                            hidden={true}
                            className="form-check-input"
                            type="radio"
                            name={`communicationTypeCode`}
                            checked={values.communicationTypeCode === '3'}
                            id={`ct3`}
                            value={'3'}
                            disabled={true}
                            onChange={(e) => handleCommunicationTypeClick(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor={`ct3`}>
                            <img
                              width="20"
                              className="pe-2"
                              src={values.communicationTypeCode === '3' ? radioSel : radioUnsel}
                              alt=""
                            />
                            {labels.CANCELLATION_1}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Fields Section */}
                  <div className={`${sizesClass.referenceYear} pe-2`}>
                    <div className="form-group">
                      <BwmInput
                        disabled={props.disabled}
                        name="referenceYear"
                        label={labels.REFERENCE_YEAR}
                        className="form-control"
                        maxLength="4"
                        value={values.referenceYear}
                        error={errors.referenceYear}
                        touched={props.touched}
                        onChange={(e) => handleChangeValue(e.target.value, 'referenceYear')}
                      />
                    </div>
                  </div>

                  <div className={`${sizesClass.lastCommunication} pe-2`}>
                    <div className="form-group">
                      <BwmInput
                        name="lastCommunication"
                        label={labels.LAST_COMMUNICATION}
                        className="form-control"
                        value={values.lastCommunication}
                        error={errors.lastCommunication}
                        touched={props.touched}
                        maxLength="100"
                        disabled={true}
                        onChange={(e) => handleChangeValue(e.target.value, 'lastCommunication')}
                      />
                    </div>
                  </div>

                  <div className={`${sizesClass.idFiscalOicrTrust}`}>
                    <div
                      className={`form-group ${values.flagOicrTrust && 'show'}`}
                      data-tip={labels.OICR_TRUST_ID_WITHOUT_LEGAL_PERSON}>
                      <div className="input-group">
                        <BwmInput
                          disabled={props.disabled}
                          name="idFiscalOicrTrust"
                          label={labels.ID_FISCAL_OICR_TRUST}
                          type="text"
                          value={values.idFiscalOicrTrust}
                          error={errors.idFiscalOicrTrust}
                          touched={props.touched}
                          onChange={(e) =>
                            handleChangeValue(e.target.value, 'idFiscalOicrTrust', true, true)
                          }
                        />
                      </div>
                      {!errors.idFiscalOicrTrust && props.touched && (
                        <div className="text-danger">{errors.idFiscalOicrTrust}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${sizesClass.rightSide}`}>
        <div className="configurazioneSID pt-2 pb-1">
          <SidContactFormContainer
            disabled={props.disabled}
            id={props.subjectId || 0}
            sizes={sizesClass}
            errors={props.errorsSid}
            nameApplication={nameApplication}
            subject={props.subject}
            defaultEmail={props.defaultEmail}
            coraSubjectSid={props.coraSubjectSid}
            certificateFiles={certificateFiles}
            fiscalCode={props.subject?.fiscalCode}
            setCertificateFiles={setCertificateFiles}
            setIsCertSidOpened={setIsCertSidOpened}
            setErrorCertificates={(errorCertificates) =>
              props.setErrorsSid({ ...props.errorsSid, errorCertificates })
            }
            handleChangeSid={handleChangeSid}
            handleChangeSidValues={handleChangeSidValues}
          />
          {values.id && (
            <div className="panel-info align-items-end">
              <h5>
                {labels.SUBJECT_CODE}: {props.subject?.id}
              </h5>
              {props.coraSubjectSid && (
                <h5>
                  {labels.SID_CODE}: {props.coraSubjectSid?.id}
                </h5>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="form-row row align-item-center mt-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              disabled={props.disabled}
              type="button"
              className="btn btn-outline-primary me-4"
              onClick={() => close(true)}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CANCEL}
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
            {window.innerWidth > Constants.BREAKPOINT_LG && (
              <div className="ms-4">
                <b className="me-2">*</b>
                <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <CreateCommunicationModal
              {...props}
              ref={createCommunicationRefData}
              subjectId={values?.id}
              emailAddress={props.coraSubjectSid?.email}
              generateEmpty={true}
              classCustom={'display-none'}
              closeSubjectModal={close}
              subject={props.subject}
            />
            <button
              type="button"
              disabled={props.disabled}
              className="btn btn-outline-primary me-2"
              onClick={() => handleFormSubmit(true)}>
              <i className="thx-text-file thx-icon me-2" />
              {labels.CREATE_EMPTY_COMMUNICATION}
            </button>
            <button
              type="submit"
              disabled={props.disabled}
              className="btn btn-primary"
              onClick={() => handleFormSubmit()}>
              <i className="thx-floppy-disk thx-icon me-2" />
              {labels.SAVE}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
