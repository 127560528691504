import { useState, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import * as Constants from '../../../../config/Constants'
import '../../../../styles/alertComm.css'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import { ImportModal } from '../../../shared/popups/ImportModal'
import { CrsCommunicationModal } from './CrsCommunicationModal'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupConfirm, titleColors } from '../../../shared/popups/PopupConfirm'

import 'react-confirm-alert/src/react-confirm-alert.css'

export const CreateCommunicationModal = forwardRef((props, ref) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sentCommunications, setSentCommunications] = useState([])
  const [showImportModal, setShowImportModal] = useState(false)

  const closeModal = () => {
    if (props.reloadRelationships) props.reloadRelationships(props.subjectId)
    if (props.closeSubjectModal) props.closeSubjectModal()
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  useImperativeHandle(ref, () => ({
    clickButtonSend() {
      confirmCommunications()
    }
  }))

  const confirmCommunications = () => {
    if (props.subject?.coraSubjectSid) {
      if (props.generateEmpty) {
        setLoading(true)
        actions
          .createCrsCommunicationEmpty(props.subjectId)
          .then(
            (res) => {
              if (res?.data?.length > 0) {
                setSentCommunications(res.data)
                openModal()
              } else {
                handleCreateCommunicationErrors()
              }
            },
            (errors) => {
              handleCreateCommunicationErrors(errors)
            }
          )
          .then(() => setLoading(false))
      } else {
        if (props.selectedRelationship?.length > 0) {
          PopupConfirm({
            titleColor: titleColors.ALERT,
            title: labels.CREATE_COMMUNICATION,
            text: `${labels.DO_YOU_WANT_TO_CREATE_A_COMMUNICATION_WITH_ONLY_THE_SELECTED_RELATIONSHIPS}? (${props.selectedRelationship?.length})`,
            handleClickConfirm: () =>
              createCrsCommunicationWithRelationships(props.subjectId, props.selectedRelationship)
          })
        } else {
          createCrsCommunicationWithRelationships(props.subjectId)
        }
      }
    } else {
      // SID non inserito
      PopupError({
        text: labels.CHECK_CERTIFICATES_AND_SID_INSERTED_IN_SUBJECT,
        labels
      })
    }
  }

  const handleCreateCommunicationErrors = (errors) => {
    PopupError({ text: handleCreateCommunicationErrorsText(errors) })
  }

  const createCrsCommunicationWithRelationships = (subjectId, relationshipIds = []) => {
    setLoading(true)
    actions
      .createCrsCommunication(subjectId, relationshipIds)
      .then(
        (res) => {
          if (res?.data?.length > 0) {
            setSentCommunications(res.data)
            openModal()
          } else {
            handleCreateCommunicationErrors()
          }
        },
        (errors) => {
          handleCreateCommunicationErrors(errors)
        }
      )
      .then(() => setLoading(false))
  }

  /**
   * valore booleano per capire se visualizzare il popup
   * @param {boolean} value
   */
  const openImportModal = (value) => {
    setShowImportModal(value)
  }

  /**
   *  Invia i file caricati al backend per essere rielaborati
   * @param {*} dataFile i file caricati poi saranno inviati
   */
  const uploadXMLFile = (dataFile, subjectId) => {
    const json = JSON.stringify(subjectId)
    const blob = new Blob([json], {
      type: 'application/json'
    })
    let formData = new FormData()
    if (dataFile?.length > 0) {
      setLoading(true)

      formData.append('file', dataFile[0])
      formData.append('subjectId', blob)
      actions
        .importCrsDocumentsXmlToElaborateTheFile(formData)
        .then(
          (response) => {
            setSentCommunications(response)
            if (props.getHistory) {
              props.getHistory(subjectId)
            }

            openModal()
          },
          (err) => {
            handleCreateCommunicationErrors(err)
          }
        )
        .then(() => setLoading(false))
    }
    openImportModal(false)
  }

  return (
    <>
      {loading && <PageSpinner />}
      {!props.upload && (
        <button
          id={'btn-fill'}
          onClick={(e) => confirmCommunications()}
          className={props.classCustom ? props.classCustom : ' btn btn-primary '}
          disabled={props.disabled}>
          <i className="thx-text-file thx-icon me-2" />
          {props.label ? props.label : labels.CREATE_COMMUNICATION}
        </button>
      )}
      {props.upload && (
        <button
          type="button"
          className={`btn btn-outline-primary `}
          data-tip={labels.PROCESS_XML_COMMUNICATION}
          aria-label={labels.PROCESS_XML_COMMUNICATION}
          onClick={() => openImportModal(true)}>
          <i className="thx-text-file thx-icon me-2" />
          {labels.PROCESS_XML_COMMUNICATION}
        </button>
      )}
      {showModal && (
        <CrsCommunicationModal
          sentCommunications={sentCommunications}
          showModal={showModal}
          closeModal={closeModal}
          subject={props.subject}
          emailAddress={props.emailAddress}
        />
      )}

      {showImportModal && (
        <ImportModal
          key={'import-xms-' + props.subjectId}
          show={true}
          allowedFileExtensions={['xml']}
          title={labels.SELECT_XML_FILE_TO_PROCESS}
          onHide={() => {
            openImportModal(false)
          }}
          onSend={(dataFile) => uploadXMLFile(dataFile, props.subjectId)}
        />
      )}
    </>
  )
})

export function handleCreateCommunicationErrorsText(errors) {
  const labels = getText(localStorage.getItem('language'))

  let text = errors?.title
  const buyLink = Constants.APPLICATIONS.find((app) => app.id === 3).buyLink
  if (errors?.errorKey === 'invalid-license-demo') {
    text = `</br>${labels.PURCHASE_LICENSE_TO_CREATE_COMMUNICATIONS}
</br><button className="btn btn-primary mt-5" onclick="location.href='${buyLink}'" type="button">${labels.PURCHASE_LICENSE}</button>`
  } else if (errors?.errorKey === 'invalid-license') {
    text = `</br>${labels.LICENSE_EXPIRED_PURCHASE_OR_RENEW_LICENSE}
</br><button className="btn btn-primary mt-5" onclick="location.href='${buyLink}'" type="button">${labels.PURCHASE_LICENSE}</button>`
  }
  return text || Constants.APPLICATION_GENERIC_ERROR
}
