import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { isNotEmpty } from '../../../shared/Utility'
import { cloneDeep } from 'lodash'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// COMPONENTS
import { PaymentRow } from './NewPaymentRow'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

export const PaymentList = forwardRef((props, ref) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [paymentRows, setPaymentRows] = useState([])
  const [relationshipPaymentTypes, setRelationshipPaymentTypes] = useState([])
  const paymentRefs = useRef([])

  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)

  useImperativeHandle(ref, () => ({
    getCrsPaymentList(id) {
      return _getCrsPaymentList(id)
    }
  }))

  const _getCrsPaymentList = (id) => {
    let relId = props.relationship?.id || id
    const axiosInstance = axiosService.getInstance()
    if (relId && props.subject?.id) {
      axiosInstance
        .get(`/api/crs/custom/crs-payments/${props.subject?.id}/${relId}`)
        .then((res) => {
          setPaymentRows(res.data)
          setTimeout(() => {
            const element = document.getElementById('payment-list')
            element.scrollTop += 200
          }, 400)
        })
    }
  }

  const getRelationshipPaymentTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-payment-types').then((res) => {
      setRelationshipPaymentTypes(res.data)
    })
  }

  const renderRows = () => {
    return [
      paymentRows.map((payment, index) => {
        return (
          <PaymentRow
            key={'payment' + payment.id}
            ref={(el) => (paymentRefs.current[index + 1] = el)}
            sizes={props.sizes}
            payment={payment}
            currencies={props.currencies}
            relationship={props.relationship}
            relationshipPaymentTypes={relationshipPaymentTypes}
            checkbox={checkedItems[payment.progressive]}
            onClickCheckBox={(e) => handleCheckChange(e, payment.progressive)}
          />
        )
      }),
      <PaymentRow
        key={'payment_0'}
        ref={(el) => (paymentRefs.current[0] = el)}
        sizes={props.sizes}
        currencies={props.currencies}
        relationship={props.relationship}
        relationshipPaymentTypes={relationshipPaymentTypes}
      />
    ]
  }

  const addCrsPayment = (id) => {
    const relId = props.relationship?.id || id

    const newPaymentRows = cloneDeep(paymentRefs.current)
      .filter((ref) => !!ref)
      .map((ref) => ref.getData())

    const promises = []

    newPaymentRows.forEach(async (data) => {
      if (isNotEmpty(data?.amount) && isNotEmpty(data?.paymentType?.id)) {
        try {
          promises.push(actions.addCrsPayment(data, relId))
        } catch (errors) {
          PopupError({ text: errors })
        }
      }
    })

    Promise.all(promises).finally(() => {
      paymentRefs.current[0]?.resetFields()
      _getCrsPaymentList()
    })
  }

  const deleteElement = () => {
    let relId = props.relationship?.id

    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      data: Object.keys(checkedItems),
      url: `/api/crs/custom/crs-payments/${relId}`,
      method: 'DELETE'
    }).then(
      () => {
        getRelationshipPaymentTypes()
        _getCrsPaymentList()
        resetCheckBox()
        PopupSuccess()
      },
      (errors) => {
        PopupError({ text: errors })
      }
    )
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newPaymentRows = cloneDeep(paymentRows)
      newPaymentRows.forEach((payment) => (items[payment.progressive] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, progressive) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[progressive] = true
      else delete newCheckedItems[progressive]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === paymentRows?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  return (
    <>
      <div>
        <div id="payment-list" className="star-table payment-list">
          <div className="text-start header-table">
            <div
              className={`${props.sizes.checkbox} pb-1 text-center div-td d-flex align-items-center justify-content-center`}>
              <input
                disabled={!paymentRows?.length > 0}
                type="checkbox"
                checked={allCheck}
                onChange={(e) => handleAllCheckChange(e)}
              />
            </div>
            <div className={`${props.sizes.progressive} div-td pb-1`}>Prog.</div>
            <div className={`${props.sizes.paymentType} div-td pb-1`}>{labels.PAYMENT_TYPE}</div>
            <div className={`${props.sizes.amount} div-td pb-1 justify-content-end d-flex`}>
              {labels.AMOUNT}
            </div>
            <div className={`${props.sizes.currency} div-td pb-1`}>{labels.CURRENCY}</div>
          </div>
          {renderRows()}
        </div>
        <div className={'row-payment mt-3'}>
          <button
            className="btn btn-outline-primary me-2  btn-cell"
            disabled={Object.keys(checkedItems)?.length === 0 && !allCheck}
            onClick={() => deleteElement()}>
            <i className="thx-trash thx-icon me-2" />
            {labels.DELETE}
          </button>
          <button className="btn btn-primary  btn-cell" onClick={() => addCrsPayment()}>
            <i className="thx-plus thx-icon me-2" />
            {labels.ADD}
          </button>
        </div>
      </div>
    </>
  )
})
