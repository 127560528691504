export function BtnFill(props) {
  const { text, handlerOnClick, classCustom, disabled, title } = props
  return (
    <div className={classCustom ? classCustom : ''}>
      <button
        id={'btn-fill'}
        type="button"
        className="btn btn-primary "
        onClick={handlerOnClick}
        disabled={disabled === true ? disabled : false}
        title={title}>
        {text}
      </button>
    </div>
  )
}
