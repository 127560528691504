import { FloatLabelTextBox } from '../../shared/form/input-boxes/FloatLabelTextBox'
import { useSelector } from 'react-redux'
import getText from '../../shared/i18n/labels'

export function SubjectCredentialsSection(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <>
      <div className={props.sectionTitleStyle}>{labels.SUBJECT_CREDENTIALS}</div>
      <div className="row">
        <div className="col-5">
          <FloatLabelTextBox
            value={props.pinFiscoOnline}
            label={labels.PIN_FISCONLINE_ENTRATEL}
            onChange={(e) => props.setPinFiscoOnline(e.target.value)}
          />
        </div>
        <div className="col-5">
          <FloatLabelTextBox
            value={props.pinRevoca}
            label={labels.PIN_REVOCA_AMBIENTE_DI_SICUREZZA_ATTUALE}
            onChange={(e) => props.setPinRevoca(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <FloatLabelTextBox
            value={props.progressivoSede}
            label={labels.PROGRESSIVE_SEDE}
            onChange={(e) => props.setProgressivoSede(e.target.value)}
          />
        </div>
        <div className="col-6">
          <FloatLabelTextBox
            value={props.numeroRichiestaRegistrazione}
            label={labels.NUMBER_REQUEST_REGISTRATION_SERVICE_FISCONLINE}
            onChange={(e) => props.setNumeroRichiestaRegistrazione(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}
