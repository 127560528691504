import { isEmpty, isNotEmpty, testEmail } from './Utility'

import * as actions from '../../actions'
import { PopupError } from './popups/PopupError'
import { PopupConfirm, titleColors } from './popups/PopupConfirm'
import { APPLICATION_GENERIC_ERROR } from '../../config/Constants'
import getText from './i18n/labels'
/**
 * Questa funzione ritorna un booleano per riuscire ha capire se i file importati sono corretti
 * @param {*} files lista di file presente nello state, quelli caricati dall'utente
 * @returns ritorna false se i file non sono corretti, o manca qualcosa, true invece se è tutto corretto
 */
export function checkIfAllFileHaveBeenGivenByTheUser(files) {
  if (files.length > 3 || files === 2) return false

  let hasUtecP12 = false
  let hasUtefP12 = false
  /**
   * Il file "UTENTE.KS" non è piu obbligatorio (Giugno 2022)
   */

  for (let i = 0; i < files.length; i++) {
    const fileName = files[i].name?.toUpperCase()
    if (!fileName?.includes('.P12') && !fileName?.includes('.KS')) return false

    if (fileName?.includes('UTEC.P12')) hasUtecP12 = true
    if (fileName?.includes('UTEF.P12')) hasUtefP12 = true
    //if (files[i].name?.toUpperCase().includes("UTENTE.KS")) utenteKS = true;
  }
  if (hasUtecP12 && hasUtefP12) {
    return true
  }
  return false
}

/**
 * Questa funzione gestisce il caricamento dei certificati mettendoli sullo state, e controlla se sono corretti
 *
 */
export function handleChangeCerts(
  certificateFiles,
  setErrorCertificates = () => undefined,
  setCoraSubjectSid = () => undefined,
  coraSubjectSid,
  oldCoraSubjectSid,
  defaultEmail,
  onSave = () => undefined,
  labels
) {
  let files = certificateFiles || []
  let checkAllFile = checkIfAllFileHaveBeenGivenByTheUser(files)
  if (!(certificateFiles?.length > 0) || checkAllFile === false) {
    setErrorCertificates({
      isValid: false,
      msg: labels.SELECT_CERTIFICATES_UTEC_UTEF_UTENTE_KS
    })
  } else {
    let coraSubjectSidId =
      coraSubjectSid.sidNumber === oldCoraSubjectSid?.sidNumber ? oldCoraSubjectSid?.id : ''
    if (coraSubjectSidId === '') coraSubjectSidId = null
    const newCoraSubjectSid = {
      ...coraSubjectSid,
      id: coraSubjectSidId,
      password: coraSubjectSid.password,
      email: coraSubjectSid.email || defaultEmail,
      sidNumber: coraSubjectSid.sidNumber,
      expirationDate: coraSubjectSid.expirationDate
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].name.toUpperCase().includes('UTEC.P12'))
        coraSubjectSid.certificate1 = files[i].name
      if (files[i].name.toUpperCase().includes('UTEF.P12'))
        coraSubjectSid.certificate2 = files[i].name
      if (files[i].name.toUpperCase().includes('UTENTE.KS'))
        coraSubjectSid.certificate3 = files[i].name
    }
    setErrorCertificates({ isValid: true, msg: '' })
    setCoraSubjectSid(newCoraSubjectSid)
    if (isNotEmpty(coraSubjectSidId)) {
      saveSid(
        files,
        newCoraSubjectSid,
        newCoraSubjectSid.fiscalCode,
        newCoraSubjectSid.application,
        () => onSave(),
        () => PopupError({ text: labels.ERROR_SAVING_CERTIFICATES })
      )
    } else {
      onSave()
    }
  }
}

/**
 * Salva il SID, è possibile passare dei callback in caso di successo, errore, e post chiamata
 * @param {Array} files opzionale
 * @param {Object} coraSubjectSid
 * @param {string} fiscalCode
 * @param {string} application
 * @param {function} successCallback opzionale
 * @param {function} errCallback opzionale
 * @param {function} thenCallback opzionale
 */
export function saveSid(
  files = [],
  coraSubjectSid,
  fiscalCode,
  application,
  successCallback = (res) => undefined,
  errCallback = (err) => undefined,
  thenCallback = () => undefined
) {
  if (isEmpty(coraSubjectSid?.sidNumber)) {
    Promise.resolve()
      .then(() => successCallback(undefined))
      .then(() => thenCallback())
    return
  }
  let formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i])
  }
  coraSubjectSid.application = application
  coraSubjectSid.fiscalCode = fiscalCode
  formData.append(
    'coraSubjectSid',
    new Blob([JSON.stringify(coraSubjectSid)], { type: 'application/json' })
  )

  if (isEmpty(coraSubjectSid.id)) {
    //verifico numero SID
    actions.verifySidNumber(coraSubjectSid.sidNumber).then(
      (res) => {
        if (!isEmpty(res)) {
          PopupConfirm({
            titleColor: titleColors.ALERT,
            text: res,
            handleClickConfirm: () => create(formData, successCallback, errCallback, thenCallback)
          })
        } else {
          create(formData, successCallback, errCallback, thenCallback)
        }
      },
      (err) => {
        if (err.errorKey === 'sidnumber_invalid') {
          PopupError({ text: err.title })
        } else {
          PopupError({ text: APPLICATION_GENERIC_ERROR })
        }
      }
    )
  } else {
    actions
      .updateCoraSubjectSid(formData)
      .then(
        (res) => {
          successCallback(res)
        },
        (err) => {
          errCallback(err)
        }
      )
      .then(() => thenCallback())
  }
}

function create(formData, successCallback, errCallback, thenCallback) {
  actions
    .createCoraSubjectSid(formData)
    .then(
      (res) => {
        successCallback(res)
      },
      (err) => {
        errCallback(err)
      }
    )
    .then(() => thenCallback())
}

/**
 * valida numero ed emailSid, setta lo stato del componente chiamante
 * @param {string} sidNumber
 * @param {string} sidEmail
 * @param {Object} errorSidNumber
 * @param {Object} errorSidEmail
 * @returns
 */
export function validateSid(sidNumber, sidEmail, errorSidNumber, errorSidEmail) {
  const labels = getText(localStorage.getItem('language'))

  let errors = {}
  errors.errorSidNumber = errorSidNumber
  errors.errorEmail = errorSidEmail

  if (isNotEmpty(sidNumber) && sidNumber.indexOf('S') !== 0) {
    errors.errorSidNumber = { isValid: false, msg: labels.SID_MUST_START_WITH_S }
  } else if (isNotEmpty(sidNumber) && sidNumber.length !== 8) {
    errors.errorSidNumber = { isValid: false, msg: labels.SID_MUST_BE_8_CHARACTERS }
  }
  if (isNotEmpty(sidEmail)) {
    let lastAtPos = sidEmail.lastIndexOf('@')
    let lastDotPos = sidEmail.lastIndexOf('.')

    if (!testEmail(sidEmail)) {
      errors.errorEmail = { isValid: false, msg: labels.INVALID }
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        sidEmail.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        sidEmail.length - lastDotPos > 2
      )
    ) {
      errors.errorEmail = { isValid: false, msg: labels.INVALID }
    } else if (sidEmail.toUpperCase().indexOf('AGENZIAENTRATE') !== -1) {
      errors.errorEmail = {
        isValid: false,
        msg: labels.INSERT_A_DIFFERENT_DESTINATION_FROM_THE_AGENCY_OF_INCOMES
      }
    }
  }
  return errors
}
