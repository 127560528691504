import {
  SUBJECTS_BY_PERSONTYPE_SUCCESS,
  NEW_CORA_SUBJECT_SUCCESS,
  GET_CORA_SUBJECT_SUCCESS,
  UPDATE_CORA_SUBJECT_SUCCESS,
  DELETE_CORA_SUBJECT_SUCCESS,
  DELETE_CORA_SUBJECT_ERROR
} from '../../actions/types'

const INITIAL_STATE = {
  subjects: {
    data: [],
    errors: []
  },
  subject: {
    data: [],
    errors: []
  }
}

export const subjectReducer = (state = INITIAL_STATE.subjects, action) => {
  switch (action.type) {
    case SUBJECTS_BY_PERSONTYPE_SUCCESS:
      return { ...state, data: action.subjects }

    default:
      return state
  }
}

export const selectedSubjectReducer = (state = INITIAL_STATE.subject, action) => {
  switch (action.type) {
    case NEW_CORA_SUBJECT_SUCCESS:
      return { ...state, data: action.subject }
    case GET_CORA_SUBJECT_SUCCESS:
      return { ...state, data: action.subject }
    case UPDATE_CORA_SUBJECT_SUCCESS:
      return { ...state, data: action.updatedSubject }
    case DELETE_CORA_SUBJECT_SUCCESS:
      return { ...state, data: action.subject }
    default:
      return state
  }
}

export const subjectError = (state = INITIAL_STATE.subject, action) => {
  switch (action.type) {
    case DELETE_CORA_SUBJECT_ERROR:
      return { ...state, data: action.error }
    default:
      return state
  }
}
