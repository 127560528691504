import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility'
import * as actions from '../../../../actions'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

// COMPONENTS
import { CreateCommunicationModal } from './NewCreateCommunicationModal'
import { PopupError } from '../../../shared/popups/PopupError'
import RowSpinner from '../../../shared/spinner/Spinner'

import '../../../../styles/monthlyList.css'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

const defaultSize = (size = null) => ({
  checkbox: Utility.getSize(size, { default: '3' }),
  period: Utility.getSize(size, { default: '8' }),
  registry: Utility.getSize(size, { default: '20' }),
  fiscalCode: Utility.getSize(size, { default: '10' }),
  role: Utility.getSize(size, { default: '10' }),
  startDate: Utility.getSize(size, { default: '7' }),
  endDate: Utility.getSize(size, { default: '7' }),
  relationship: Utility.getSize(size, { default: '13' }),
  uniqueCode: Utility.getSize(size, { default: '21' })
})

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '175%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '130%'
  return '100%'
}

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth < Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export function MonthlyList(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [allCheck, setAllCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})

  const subject = useSelector((state) => state.subject.data)
  const [communicationsToSend, setCommunicationsToSend] = useState([])
  const communicationTypes = useSelector((state) => state.communicationTypes.data)
  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  const [sizesClass, setSizesClass] = useState(getSize())

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    getMonthlyCommunications(props.subjectId)
  }, [])

  const getMonthlyCommunications = (subjectId) => {
    setLoading(true)
    actions.getCommunicationsToSendBySubjectId(subjectId, delayedCommunicationDate).then((res) => {
      setLoading(false)
      setCommunicationsToSend(res)
    })
  }

  const getCount = (communicationsToSend) => {
    let count = 0
    Object.keys(communicationsToSend).map((key) => {
      communicationsToSend[key].map((relationship, index) => {
        count += 1
      })
    })
    return count
  }

  const handleAllCheckChangeCommunications = (event, communicationsToSend) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = new Map()
      Object.keys(communicationsToSend).map((key) => {
        communicationsToSend[key].map((relationship, index) => {
          items[relationship.id] = true
        })
      })
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChangeCommunications = (event, communicationsToSend) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[event.target.value] = true
      else delete newCheckedItems[event.target.value]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === getCount(communicationsToSend))
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handlerDontSendCommunication = () => {
    for (const checkedItem of Object.keys(checkedItems)) {
      if (checkedItem) {
        actions.removeNextCommunication([parseInt(checkedItem)]).then(
          () => {
            PopupSuccess()
            getMonthlyCommunications(props.subjectId)
          },
          (errors) => PopupError({ text: errors })
        )
      }
    }
    resetCheckBox()
  }

  /**
   *
   * @param {string} year
   * @param {string} month
   * @returns {boolean} true se precedente a mese anno corrente, se no false
   */
  const isPeriodValid = (year, month) => {
    const now = isNotEmpty(delayedCommunicationDate)
      ? new Date(delayedCommunicationDate)
      : new Date()
    const currYear = now.getFullYear()
    const currMonth = now.getMonth() + 1
    const intYear = parseInt(year)
    const intMonth = parseInt(month)
    if (intYear === currYear) {
      if (intMonth >= currMonth) {
        return false
      }
    } else if (intYear > currYear) {
      return false
    }
    return true
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
    document.getElementsByName('relationshipCheck').forEach((el) => {
      if (el.checked) {
        el.checked = false
      }
    })
  }

  const renderGroups = (groupedRelationships, communicationTypes) => {
    if (loading) return <RowSpinner />

    if (!Object.keys(groupedRelationships)?.length > 0) {
      return (
        <div className={'empty-state'} style={{ height: 'unset', width: '99%' }}>
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
            style={{ width: '56px', height: '56px' }}
          />
          <h3 className="mt-3">{labels.NO_RELATIONSHIPS_TO_SEND}</h3>
        </div>
      )
    }
    return Object.keys(groupedRelationships).map((key, index) => {
      let splitted = key.split('-')

      const year = splitted[0]
      const month = splitted[1]
      let yearAndMonth = year + '-' + month
      let communicationTypeDesc = ''
      let communicationTypeCode = ''
      for (var i = 0; i < communicationTypes.length; i++) {
        if (communicationTypes[i].code === splitted[3]) {
          communicationTypeDesc = communicationTypes[i].description
          communicationTypeCode = communicationTypes[i].code
          break
        }
      }

      let dispatchType = splitted[2]
      let dispatchTypeDesc = ''
      if (dispatchType === '1') {
        dispatchTypeDesc = labels.ORDINARY
      } else {
        dispatchTypeDesc = labels.EXTRAORDINARY
      }

      return (
        <div key={'gruppo-' + index}>
          <div className="monthly bg-blue">
            <div className="me-5">
              <strong className="me-1">{labels.REFERENCE_PERIOD}:</strong>
              <span>{yearAndMonth}</span>
            </div>
            <div className="me-5">
              <strong className="me-1">{labels.COMMUNICATION_TYPE}:</strong>
              <span>
                {communicationTypeCode === '2'
                  ? communicationTypeCode + ' - ' + labels.UPDATE_REPORT_DELAYED
                  : communicationTypeCode + ' - ' + communicationTypeDesc}
              </span>
            </div>
            <div className="me-5">
              <strong className="me-1">{labels.DISPATCH_TYPE}:</strong>
              <span>{dispatchTypeDesc}</span>
            </div>

            {!isPeriodValid(year, month) && (
              <div className="me-5">
                <strong className="period-error">
                  {`${labels.ATTENTION}: ${labels.THE_PERIOD} ${yearAndMonth} ${labels.CAN_BE_COMMUNICATED_FROM_THE_FIRST_DAY_OF_THE_FOLLOWING_MONTH}`}
                </strong>
              </div>
            )}
          </div>
          <div className="monthly-row">{renderRows(key, groupedRelationships[key])}</div>
        </div>
      )
    })
  }

  const renderRowHeader = (communicationsToSend) => {
    return (
      <>
        <div className={`${sizesClass.checkbox} text-center div-td`}>
          <input
            type="checkbox"
            checked={!!allCheck}
            onChange={(e) => handleAllCheckChangeCommunications(e, communicationsToSend)}
          />
        </div>
        <div className={`${sizesClass.period} div-td`}>{labels.PERIOD}</div>
        <div className={`${sizesClass.registry} div-td`}>{labels.REGISTRY}</div>
        <div className={`${sizesClass.fiscalCode} div-td`}>{labels.FISCAL_CODE}</div>
        <div className={`${sizesClass.role} div-td`}>{labels.ROLE}</div>
        <div className={`${sizesClass.startDate} div-td`}>{labels.START_DATE}</div>
        <div className={`${sizesClass.endDate} div-td`}>{labels.END_DATE}</div>
        <div className={`${sizesClass.relationship} div-td`}>{labels.RELATIONSHIP}</div>
        <div className={`${sizesClass.uniqueCode} div-td`}>{labels.UNIQUE_CODE}</div>
      </>
    )
  }

  const renderRows = (key, relationships = []) => {
    return relationships.map((relationship, index) => {
      return (
        <div
          key={'rapporto-' + index}
          className={`text-start row-table row-table-without-click ${relationships.length - 1 === index ? 'border-radius-bottom' : ''}`}
          id={`row-${index}`}>
          <div className={`${sizesClass.checkbox} text-center div-td`}>
            <input
              type="checkbox"
              aria-label="Checkbox for following text input"
              id={`row-${index}`}
              name="relationshipCheck"
              value={relationship.id}
              checked={checkedItems[relationship.id] || false}
              onChange={(e) => handleCheckChangeCommunications(e, communicationsToSend)}
            />
          </div>
          <div className={`${sizesClass.period} div-td text-truncate`}>{key}</div>
          <div className={`${sizesClass.registry} div-td text-truncate`}>
            {relationship.completeName}
          </div>
          <div className={`${sizesClass.fiscalCode} div-td text-truncate`}>
            {relationship.fiscalCode}
          </div>
          <div className={`${sizesClass.role} div-td text-truncate`}>
            {relationship.coraRole
              ? relationship.coraRole.code + ' - ' + relationship.coraRole.description
              : ''}
          </div>
          <div className={`${sizesClass.startDate} div-td text-truncate`}>
            {formatDateForDisplay(relationship.startDate)}
          </div>
          <div className={`${sizesClass.endDate} div-td text-truncate`}>
            {relationship.endDate ? formatDateForDisplay(relationship.endDate) : ''}
          </div>
          <div className={`${sizesClass.relationship} div-td text-truncate`}>
            {relationship.relationshipType.code} - {relationship.relationshipType.description}
          </div>
          <div className={`${sizesClass.uniqueCode} div-td text-truncate`}>
            {relationship.identifier}
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="d-flex justify-content-between header-btn-relationship mb-2">
        <div>
          <div className="btn-group" role="group" aria-label="action buttons">
            <button
              type="button"
              className={`btn btn-outline-primary w-100 ${Object.keys(checkedItems)?.length === 0 && 'disabled'}`}
              disabled={Object.keys(checkedItems)?.length === 0}
              onClick={() => handlerDontSendCommunication()}>
              <i className="thx-exclude thx-icon me-2" />
              {labels.EXCLUDE_FROM_COMMUNICATION}
            </button>
          </div>
        </div>

        <div>
          <CreateCommunicationModal
            type="MONTHLY"
            subjectId={props.subjectId}
            subject={subject}
            reloadList={getMonthlyCommunications}
            disabled={Object.keys(communicationsToSend).length <= 0}
            delayedCommunicationDate={delayedCommunicationDate}
          />
        </div>
      </div>
      <div className="star-table-recipient">
        <div className="star-table" style={{ width: getTableContainerWidth(), height: '66vh' }}>
          <div className="text-start header-table">{renderRowHeader(communicationsToSend)}</div>
          {renderGroups(communicationsToSend, communicationTypes)}
        </div>
      </div>
    </>
  )
}
