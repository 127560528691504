import { PopupConfirm, titleColors } from './PopupConfirm'

export default function ComureiSubjectNotPresentAlert({ denomination, fiscalCode }, lang) {
  return PopupConfirm({
    titleColor: titleColors.SUCCESS,
    title: 'Soggetto salvato correttamente',
    showCancelButton: false,
    component: () => (
      <>
        {lang === 'it' && (
          <div>
            Il soggetto {denomination} <br />
            codice fiscale {fiscalCode}
            <br></br>
            <strong className="text-danger">NON RISULTA</strong>
            <br></br>
            nel NOSTRO programma <strong className="text-danger">ComuREI</strong> (Registro
            Elettronico Indirizzi)
            <br></br>
            se la mail pec è stata iscritta con un altro programma considerate nullo questo avviso
            <br />
            <br />
            se invece la mail pec NON è stata ancora inscritta al REI
            <br />
            vi invitiamo a fare l'iscrizione PRIMA (almeno 24 ore) di inviare le comunicazioni CORA
            o CRS, pena lo scarto delle stesse.
            <br />
            <br />
            Noi NON possiamo interrogare il REI: notiamo solo l'assenza del codice fiscale nel
            nostro programma.
            <br></br>
            Trovate maggiori dettagli in
            <a
              target="_blank"
              href="https://www.starsoluzioni.it/cosa-bisogna-fare-per-essere-in-regola/">
              questa pagina
            </a>
          </div>
        )}
        {lang !== 'it' && (
          <div>
            The subject {denomination} <br />
            fiscal code {fiscalCode}
            <br></br>
            <strong className="text-danger">DOES NOT APPEAR</strong>
            <br></br>
            in OUR program <strong className="text-danger">ComuREI</strong> (Electronic Address
            Register)
            <br></br>
            if the pec email was registered with another program, please disregard this notice
            <br />
            <br />
            if instead the pec email has NOT yet been registered with the REI
            <br />
            we invite you to register it BEFORE (at least 24 hours) sending CORA or CRS
            communications, otherwise they will be rejected.
            <br />
            <br />
            We CANNOT query the REI: we only notice the absence of the fiscal code in our program.
            <br></br>
            You can find more details on
            <a
              target="_blank"
              href="https://www.starsoluzioni.it/cosa-bisogna-fare-per-essere-in-regola/">
              this page
            </a>
          </div>
        )}
      </>
    )
  })
}
