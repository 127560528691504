import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../../shared/Utility'
import { testEmail } from '../../../shared/Utility'
import axiosService from '../../../../services/axios-service'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'

export function CoraSubjectsExportModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseModal, setResponseModal] = useState('')
  const [responseKo, setResponseKo] = useState('')
  const [responseOk, setResponseOk] = useState('')
  const [emailAddress, setEmailAddress] = useState(props.email)
  const [emailModified, setEmailModified] = useState(false)
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: '' })

  let email = isEmpty(emailAddress) && !emailModified ? props.email : emailAddress

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const prevEmailAddressRef = useRef(emailAddress)

  useEffect(() => {
    const newEmailAddress = props.email

    if (newEmailAddress && newEmailAddress !== prevEmailAddressRef.current) {
      setEmailAddress(newEmailAddress)
      prevEmailAddressRef.current = newEmailAddress // Update the ref to reflect the current state
    }
  }, [props.checkedItems])

  const closeModal = () => {
    setShowModal(false)
    setResponseModal(false)
    setEmailAddress(props.email)
  }

  const openModal = () => {
    handlerEmail(email)
    setShowModal(true)
  }

  const handlerEmail = (value) => {
    handlerValidation(value)
    setEmailAddress(value)
    setEmailModified(true)
  }

  const handlerValidation = (_emailAddress) => {
    if (isEmpty(_emailAddress)) {
      setErrorEmail({ isValid: false, msg: labels.REQUIRED_FIELD })
      return false
    }
    if (!testEmail(email)) {
      setErrorEmail({ isValid: false, msg: labels.EMAIL_NOT_FORMALLY_CORRECT })
      return false
    }
    setErrorEmail({ isValid: true, msg: '' })
    return true
  }

  const exportCoraSubjects = () => {
    if (handlerValidation(email)) {
      setLoading(true)
      const axiosInstance = axiosService.getInstance()
      axiosInstance({
        data: Object.values(props.checkedItems),
        url: `/api/cora/custom/cora-subjects/export/${emailAddress}`,
        method: 'POST'
      })
        .then(
          (response) => {
            if (response) {
              if (response?.status === 200) {
                closeModal()
                setResponseOk(labels.OK)
                setResponseModal(true)
              } else {
                closeModal()
                setResponseKo(labels.ERROR_EXPORTING_SUBJECTS)
              }
            }
          },
          (err) => {
            closeModal()
            PopupError({
              text: labels.ERROR_EXPORTING_SUBJECTS
            })
          }
        )
        .then(() => setLoading(false))
    }
  }

  return (
    <>
      {loading ? <PageSpinner /> : ''}
      <button
        type="button"
        disabled={props.disabled}
        className={`btn btn-outline-primary ${props.disabled && 'disabled'}`}
        data-tip={labels.EXPORT_SELECTED_SUBJECTS}
        onClick={openModal}>
        <i className="thx-export thx-icon me-2 mt-1" />
        {labels.EXPORT}
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.EXPORT_SUBJECTS}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p style={{ fontSize: '16px' }}>
              {labels.YOU_CAN_DOWNLOAD_ALL_THE_DATA_RELATED_TO_THE_SELECTED_SUBJECTS}
            </p>
          </div>
          <div className="mt-2 d-flex radius">
            <div className="tab-content col-12">
              <div className="form-row">
                <div className="col-8 subDetailComurei">
                  <div className="row">
                    <div className="col-11">
                      <BwmInput
                        id="emailAddress"
                        type="email"
                        className="form-control"
                        maxLength="100"
                        label={`${labels.EMAIL_ADDRESS_TO_SEND_EXPORT_FILE}*`}
                        value={email}
                        error={errorEmail}
                        onChange={(e) => handlerEmail(e.target.value)}
                      />
                      <span className="text-danger">{errorEmail?.msg}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorEmail?.isValid}
              type="button"
              className="btn btn-primary"
              onClick={exportCoraSubjects}>
              {labels.EXPORT}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {responseModal && (
        <Modal
          backdrop="static"
          show={responseModal}
          onHide={closeModal}
          dialogClassName="modal-70w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>{labels.START_EXPORT_SUBJECTS_PROCEDURE}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {responseOk ? (
              <p>
                {labels.EXPORT_SUBJECTS_STARTED}:{' '}
                {labels.YOU_WILL_RECEIVE_THE_FILES_AT_YOUR_EMAIL_ADDRESS}
              </p>
            ) : (
              <div className="popup communication">
                <div className="row">
                  <div className="col-12">
                    <p className={'color-red'}>
                      {labels.ERROR_EXPORTING_SUBJECTS} {responseKo}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty
                text={labels.CANCEL}
                classCustom="float-start"
                handlerOnClick={closeModal}
              />
              <button type="button" className="btn btn-primary" onClick={() => closeModal()}>
                {labels.OK}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
