import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import * as Utility from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

export const ControllingPersonForm = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const dispatch = useDispatch()

  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [touched, setTouched] = useState(false)

  const countries = useSelector((state) =>
    state.countries?.data.map((country) => {
      return { name: country.code, key: country.description }
    })
  )

  const [controllingPersonTypes, setControllingPersonTypes] = useState([])

  useEffect(() => {
    getCountries()
    getControllingPersonTypes()
    setNewInitialValues()
  }, [])

  useEffect(() => {
    setNewInitialValues()
  }, [props.controllingPerson])

  const setNewInitialValues = () => {
    setInitialValues({
      id: props.controllingPerson?.id,
      fiscalIdentifier: props.controllingPerson?.fiscalIdentifier || '',
      controllingPersonType: props.controllingPerson?.controllingPersonType?.id || '',
      controllingPersonTypeCode: props.controllingPerson?.controllingPersonType?.code || '',
      firstName: props.controllingPerson?.firstName || '',
      lastName: props.controllingPerson?.lastName || '',
      address: props.controllingPerson?.address || '',
      birthDate: props.controllingPerson?.birthDate
        ? new Date(props.controllingPerson?.birthDate)
        : new Date(),
      birthLocation: props.controllingPerson?.birthLocation || '',
      countryCode: props.controllingPerson?.countryCode || 'IT',
      country: props.controllingPerson?.country || 'ITALIA (REPUBBLICA ITALIANA)'
    })
  }

  const getControllingPersonTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-controlling-person-types').then((res) => {
      setControllingPersonTypes(res.data)
    })
  }

  const getCountries = () => {
    dispatch(actions.getAllCountries())
  }

  const validationSchema = Yup.object().shape({
    controllingPersonType: Yup.string().required(labels.REQUIRED_FIELD),
    lastName: Yup.string().required(labels.REQUIRED_FIELD),
    firstName: Yup.string().required(labels.REQUIRED_FIELD),
    address: Yup.string().required(labels.REQUIRED_FIELD),
    birthDate: Yup.date(labels.INVALID_DATE)
      .required(labels.REQUIRED_FIELD)
      .test(
        'is-valid-date',
        labels.INVALID_DATE,
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    birthLocation: Yup.string().required(labels.REQUIRED_FIELD),
    countryCode: Yup.string().required(labels.REQUIRED_FIELD)
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalIdentifier',
      errorLabel: labels.TIN
    },
    {
      errorKey: 'controllingPersonType',
      errorLabel: labels.TYPE
    },
    {
      errorKey: 'firstName',
      errorLabel: labels.FIRST_NAME
    },
    {
      errorKey: 'lastName',
      errorLabel: labels.LAST_NAME
    },
    {
      errorKey: 'birthDate',
      errorLabel: labels.BIRTH_DATE
    },
    {
      errorKey: 'birthLocation',
      errorLabel: labels.BIRTH_LOCATION
    },
    {
      errorKey: 'address',
      errorLabel: labels.ADDRESS
    },
    {
      errorKey: 'country',
      errorLabel: labels.COUNTRY
    }
  ]

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <div className="mt-2">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <div className={props.formOnBottom ? 'p-2 form-on-bottom' : ''}>
            {showErrorAlert && props.activeTab === 'tab-controlling-person' && touched && (
              <div className={props.formOnBottom ? 'crs-controlling-form-on-bottom-error' : ''}>
                <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
              </div>
            )}
            <FormBody
              {...props}
              touched={touched}
              countries={countries}
              controllingPersonTypes={controllingPersonTypes}
              setShowErrorAlert={setShowErrorAlert}
              setTouched={setTouched}
            />
          </div>
        )}
      </Formik>
    </div>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const handleChangeTIN = (e) => {
    const form = Object.assign({}, values)
    form.fiscalIdentifier = e.target.value?.toUpperCase() ? e.target.value.toUpperCase() : ''
    actions
      .getBaseRegistryByFiscalCode(form.fiscalIdentifier)
      .then((baseRegistry) => {
        if (Utility.isNotEmpty(baseRegistry)) {
          form.lastName = baseRegistry?.lastName || ''
          form.firstName = baseRegistry?.firstName || ''
          form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
          form.birthLocation =
            baseRegistry?.location?.location + '  (' + baseRegistry?.location?.province + ')' || ''
          setValues(form)
        }
      })
      .catch(() => {})
  }

  const handlerChangeControllingPersonType = (e) => {
    let code = e.target[e.target.selectedIndex].dataset.cod
    const form = Object.assign({}, values)
    form.controllingPersonType = e.target.value
    form.controllingPersonTypeCode = code
    setValues(form)
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = ![null, undefined, ''].includes(val) ? val : null
    setValues(form)
  }

  const handleInputAutocompileCountry = (e) => {
    let newCountry = e.target.value || ''
    const form = Object.assign({}, values)
    form.countryCode = newCountry.toUpperCase()
    let countriesFilter = props.countries.filter((country) => country.name === newCountry)
    if (countriesFilter.length === 1) {
      form.countryCode = countriesFilter[0].name
      form.country = countriesFilter[0].key
    }
    if (countriesFilter.length === 0) {
      form.countryCode = newCountry.toUpperCase()
      form.country = ''
    }
    setValues(form)
  }

  const getData = () => {
    return {
      id: props.controllingPerson?.id || null,
      fiscalIdentifier: values.fiscalIdentifier,
      controllingPersonType: { id: values.controllingPersonType },
      lastName: values.lastName,
      firstName: values.firstName,
      address: values.address,
      birthDate: new Date(values.birthDate),
      birthLocation: values.birthLocation,
      countryCode: values.countryCode,
      country: values.country,
      crsRelationship: { id: props.relationship?.id || null }
    }
  }

  const addCrsControllingPerson = () => {
    let relationshipId = ''
    if (props.relationship?.id) {
      relationshipId = props.relationship?.id
    } else {
      relationshipId = props.controllingPerson?.crsRelationship?.id || ''
    }
    let newControllingPerson = getData()
    newControllingPerson.crsRelationship = { id: relationshipId }

    if (props.controllingPerson?.id) {
      newControllingPerson.controllingPersonType = {
        id: parseFloat(values.controllingPersonType)
      }
      actions.updateCrsControllingPeople(newControllingPerson, relationshipId).then(
        (response) => {
          PopupSuccess({ text: labels.SAVESUCCESS })
          props.handlerShowPersonForm()
        },
        (errors) => PopupError({ text: errors })
      )
    } else {
      actions.addCrsControllingPeople(newControllingPerson, relationshipId).then(
        (response) => {
          PopupSuccess({ text: labels.SAVESUCCESS })
          props.setTabWithError({})
          props.handlerShowPersonForm()
        },
        (errors) => PopupError({ text: errors })
      )
    }
  }

  const handleFormSubmit = async () => {
    const errors = await validateForm(values) // Validazione dei valori
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      addCrsControllingPerson(values) // Esegui l'aggiornamento se non ci sono errori
      props.setShowErrorAlert(false) // Nascondi l'alert
    }
  }

  const close = () => {
    props.setTouched(false)
    if (props.handlerShowPersonForm) props.handlerShowPersonForm()
  }

  return (
    <div className="controlPersonForm w-all">
      <div className="row">
        <div className="w-100 d-flex align-items-center justify-content-between me-4 mb-2">
          <h5 className="p-0 m-0">
            {labels.HOLDER}: <strong>{props.crsRelationshipHolder?.companyName || ''}</strong>
          </h5>
          <div className={'d-flex align-items-center'}>
            <button className="btn btn-outline-primary me-2" onClick={close}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CANCEL}
            </button>
            <button
              id={'controlling-person-save-button'}
              type="submit"
              className="btn btn-primary ml-2"
              onClick={() => handleFormSubmit()}>
              <i className="thx-floppy-disk thx-icon me-2" />
              {labels.SAVE}
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`${props.sizes.form_fiscalIdentifier} pe-2 mb-2`}>
          <BwmInput
            name="fiscalIdentifier"
            label={labels.TIN}
            className="form-control"
            value={values.fiscalIdentifier}
            error={errors.fiscalIdentifier}
            touched={props.touched}
            onChange={(e) => handleChangeValue(e.target.value, 'fiscalIdentifier', true)}
            onBlur={(e) => handleChangeTIN(e)}
          />
        </div>
        <div className={`${props.sizes.form_personType} pe-2 mb-2`}>
          <BwmSelect
            options={props.controllingPersonTypes}
            name="controllingPersonType"
            label={`${labels.TYPE}*`}
            className="form-control"
            value={values.controllingPersonType}
            error={errors.controllingPersonType}
            touched={props.touched}
            onChange={(e) => handlerChangeControllingPersonType(e)}
          />
        </div>
        <div className={`${props.sizes.form_last_name} pe-2 mb-2`}>
          <BwmInput
            name="lastName"
            label={`${labels.LAST_NAME}*`}
            maxLength={25}
            className="form-control font-weight-bold font-size-big"
            value={values.lastName}
            error={errors.lastName}
            touched={props.touched}
            onChange={(e) => handleChangeValue(e.target.value, 'lastName', true)}
          />
        </div>
        <div className={`${props.sizes.form_first_name} mb-2`}>
          <BwmInput
            name="firstName"
            label={`${labels.FIRST_NAME}*`}
            maxLength={25}
            className="form-control font-weight-bold font-size-big"
            value={values.firstName}
            error={errors.firstName}
            touched={props.touched}
            onChange={(e) => handleChangeValue(e.target.value, 'firstName', true)}
          />
        </div>
        <div className="row">
          <div className={`${props.sizes.form_birthDate} pe-2 mb-2`}>
            <DateField
              label={`${labels.BIRTH_DATE}*`}
              customOptions={{ maxDate: new Date() }}
              name="birthDate"
              date={values.birthDate}
              error={errors.birthDate}
              touched={props.touched}
              onChange={(date) => handleChangeValue(date, 'birthDate')}
            />
          </div>
          <div className={`${props.sizes.form_birthLocation} pe-2 mb-2`}>
            <BwmInput
              name="birthLocation"
              label={`${labels.BIRTH_LOCATION}*`}
              className="form-control"
              value={values.birthLocation}
              error={errors.birthLocation}
              touched={props.touched}
              onChange={(e) => handleChangeValue(e.target.value, 'birthLocation', true)}
            />
          </div>
          <div className={`${props.sizes.form_address} pe-2 mb-2`}>
            <BwmInput
              name="address"
              label={`${labels.ADDRESS_}*`}
              className="form-control"
              value={values.address}
              error={errors.address}
              touched={props.touched}
              onChange={(e) => handleChangeValue(e.target.value, 'address', true)}
            />
          </div>
          <div className={`${props.sizes.form_country} mb-2`}>
            <BwmSelect
              options={props.countries}
              name="country"
              id="country"
              label={`${labels.COUNTRY}*`}
              className="form-control"
              showCode={true}
              nameKey={true}
              value={values.countryCode}
              error={errors.countryCode}
              touched={props.touched}
              onChange={(e) => handleInputAutocompileCountry(e)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
