export function BtnEmpty(props) {
  const { text, handlerOnClick, classCustom } = props
  return (
    <div className={classCustom != undefined ? classCustom : ''}>
      <button type="button" className="btn btn-outline-primary px-4" onClick={handlerOnClick}>
        {text}
      </button>
    </div>
  )
}
