import { confirmAlert } from 'react-confirm-alert'
import getText from '../../shared/i18n/labels'

// BUTTONS
import { BtnEmpty } from '../BtnEmpty'
import { BtnFill } from '../BtnFill'

import 'react-confirm-alert/src/react-confirm-alert.css'

export function PopupDelete(props) {
  const labels = getText(localStorage.getItem('language'))

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup modal-content">
            <div className="w-100 d-flex justify-content-end">
              <button type="button" className="close btn-close" onClick={onClose}>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <div className={'row show-grid popup-title-delete text-center font-weight-bold'}>
              <div className="col-12 py-3">
                {props.title ? <h5>{props.title}</h5> : <h4>Elimina {props.operazione}</h4>}
              </div>
            </div>
            <div className={'row show-grid text-center'}>
              {props.text ? (
                <div className="col-12">
                  <div className="text-center">{props.text}</div>
                </div>
              ) : (
                ''
              )}

              <div className="col-12">
                {props.list?.map((item, index) => (
                  <div key={index} className="text-center">
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="row pt-4 justify-content-between">
              <BtnEmpty
                classCustom={'col me-3'}
                text={props.cancelText || labels.CANCEL}
                handlerOnClick={onClose}
              />
              <BtnFill
                classCustom={'col d-flex justify-content-end'}
                text={props.confirmText || labels.CONFIRM}
                handlerOnClick={() => {
                  props.handleClickConfirm()
                  onClose()
                }}
              />
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
