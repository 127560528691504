import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// BUTTONS
import { BtnDelete } from '../../../shared/BtnDelete'

// COMPONENTS
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupDelete } from '../../../shared/popups/PopupDelete'
import RowSpinner from '../../../shared/spinner/Spinner'

import * as styles from '../../../../config/styles'

export const ControllingPersonList = forwardRef((props, ref) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const SECOND_TAB = labels.CONTROLLING_PERSONS

  const [allCheck, setAllCheck] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})
  const [loading, setLoading] = useState(false)
  const [controllingPersonList, setControllingPersonList] = useState([])
  const [errorControllingPerson, _setErrorControllingPerson] = useState('')

  useImperativeHandle(ref, () => ({
    countControllingPerson() {
      if (controllingPersonList) return controllingPersonList.length
      else return 0
    },
    setErrorControllingPerson() {
      _setErrorControllingPerson('')
    },
    getAllCrsControllingPeople() {
      return _getAllCrsControllingPeople
    }
  }))

  useEffect(() => {
    if (props.activeTab === 'tab-controlling-person') _getAllCrsControllingPeople()
    resetCheckBox()
  }, [props.activeTab])

  const _getAllCrsControllingPeople = () => {
    let relId = props.relationship?.id
    if (relId) {
      setLoading(true)
      actions
        .getAllCrsControllingPeople(props.subject.id, relId)
        .then((resp) => {
          setLoading(false)
          setControllingPersonList(resp)
          if (resp.length > 0) {
            _setErrorControllingPerson('')
          } else {
            _setErrorControllingPerson(
              labels.IS_MANDATORY_TO_INSERT_AT_LEAST_ONE_CONTROLLING_PERSON_FOR_THE_SELECTED_TYPE_OF_OWNER
            )
          }
          setTimeout(() => {
            const element = document.getElementById('controlling-person-list')
            element.scrollTop += 200
          }, 400)
          return resp.length
        })
        .catch(() => setLoading(false))
    }
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: `${Object.keys(checkedItems).length} ${labels.CONTROLLING_PERSONS} ${labels.SELECTED}?`,
      handleClickConfirm: () => handlerDeleteControllingPerson(Object.keys(checkedItems))
    })
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newControllingPersonRows = cloneDeep(controllingPersonList)
      newControllingPersonRows.forEach((cp) => (items[cp.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === controllingPersonList?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handlerDeleteControllingPerson = (ids = []) => {
    let relId = props.relationship?.id
    actions.deleteControllingPeople(props.subject.id, relId, ids).then(
      (res) => {
        _getAllCrsControllingPeople()
        resetCheckBox()
        PopupSuccess()
      },
      (err) => PopupError({ text: APPLICATION_GENERIC_ERROR })
    )
  }

  const renderRows = () => {
    if (loading) return <RowSpinner />

    return controllingPersonList.map((cp, index) => {
      return (
        <div key={index} className="text-start row-table" id={index}>
          <div
            className={`${props.sizes.checkbox} mt-0 pt-1 text-center div-td d-flex justify-content-center`}>
            <input
              type="checkbox"
              name="rowCheckBox"
              id={`cpDetail${cp.id || '-1'}`}
              checked={checkedItems[cp.id] || false}
              onChange={(e) => handleCheckChange(e, cp?.id)}
            />
          </div>
          <div
            title={cp.fiscalIdentifier}
            className={`${props.sizes.fiscalIdentifier} div-td text-truncate`}
            onClick={() => props.handlerShowPersonForm(cp)}>
            {cp.fiscalIdentifier}
          </div>
          <div
            title={cp.lastName + ' ' + cp.firstName}
            className={`${props.sizes.name} div-td text-truncate`}
            onClick={() => props.handlerShowPersonForm(cp)}>
            {cp.lastName + ' ' + cp.firstName}
          </div>
          <div
            title={cp.controllingPersonType?.code + ':' + cp.controllingPersonType?.description}
            className={`${props.sizes.personType} div-td text-truncate`}
            onClick={() => props.handlerShowPersonForm(cp)}>
            {cp.controllingPersonType?.code + ':' + cp.controllingPersonType?.description}
          </div>
          <div
            title={cp.countryCode + ' - ' + cp.country}
            className={`${props.sizes.country} div-td text-truncate`}
            onClick={() => props.handlerShowPersonForm(cp)}>
            {cp.countryCode + ' - ' + cp.country}
          </div>
        </div>
      )
    })
  }

  let relId = props.relationship?.id

  useEffect(() => {
    if (props.activeTab !== SECOND_TAB) _setErrorControllingPerson('')
  }, [props.activeTab])

  return (
    <div className="row mb-2 ms-2">
      <div className="w-100 d-flex align-items-center justify-content-between mt-3">
        <h5>
          {labels.LIST_OF_PHYSICAL_PERSONS} <strong>{labels.CONTROLLING}</strong>{' '}
          {labels.THE_RELATIONSHIP}
        </h5>
        <div className="ms-auto d-flex mb-2">
          <BtnDelete
            handlerOnClick={() => openPopupDelete()}
            className="me-2"
            disabled={Object.keys(checkedItems)?.length === 0 && !allCheck}
            classCustom="me-2"
          />
          <button
            className={`${styles.btnFillPillSm}`}
            onClick={() => props.handlerShowPersonForm()}>
            <i className="thx-plus thx-icon me-2" />
            {labels.ADD}
          </button>
        </div>
      </div>
      <div id="controlling-person-list" className="mb-3 w-all star-table controlling-person-list">
        <div className="col-12 header-table p-0">
          <div
            className={`${props.sizes.checkbox} text-center div-td d-flex align-items-center justify-content-center`}>
            <input
              disabled={!(controllingPersonList?.length > 0)}
              name="tableCheckBox"
              type="checkbox"
              checked={allCheck}
              onChange={(e) => handleAllCheckChange(e)}
            />
          </div>
          <div className={`${props.sizes.fiscalIdentifier} div-td`}>{labels.TIN}</div>
          <div className={`${props.sizes.name} div-td`}>{labels.REGISTRY}</div>
          <div className={`${props.sizes.personType} div-td`}>{labels.PERSON_TYPE}</div>
          <div className={`${props.sizes.country} div-td`}>
            {labels.COUNTRY_OF_RESIDENTIAL_ADDRESS}
          </div>
        </div>

        {relId ? renderRows() : ''}
      </div>
      <div className="form-group col-12">
        <div className="text-danger pb-2">{errorControllingPerson}</div>
      </div>
    </div>
  )
})
