import { MY_APPS_LINK } from '../../../../config/Constants'
import { PopupError } from '../../popups/PopupError'
import getText from '../../i18n/labels'

export function LicenseExpiredPopup({ language = 'it', link = MY_APPS_LINK }) {
  const labels = getText(language)
  return PopupError({
    text: labels.EXPIREDLICENCE(link)
  })
}
