import { useEffect, useRef, useState } from 'react'
import { StarTooltip } from '../tooltips/Tooltip'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'

export const BwmInput = ({
  label,
  type,
  Icon,
  onIconClick = () => undefined,
  iconTip,
  className,
  onChange,
  name,
  value,
  onKeyPress,
  onFocus,
  maxLength,
  showErrorMessage = false,
  error,
  touched,
  pattern,
  placeholder,
  step,
  onBlur = () => {},
  readOnly,
  id,
  onInput,
  disabled,
  autoComplete,
  autofocus = false
}) => {
  //in certi casi, il cursore può saltare alla fine. questo stato riporta il cursore al posto giusto
  const [cursor, setCursor] = useState(null)
  const ref = useRef(null)

  //Focus su primo render, se richiesto
  useEffect(() => {
    if (autofocus) {
      ref.current.focus()
    }
  }, [])

  useEffect(() => {
    const input = ref.current
    if (input && ['text', 'search', 'URL', 'tel', 'password'].includes(input.type))
      input.setSelectionRange(cursor || 0, cursor || 0)
  }, [ref, cursor, value])

  const handleChange = (e) => {
    setCursor(e.target.selectionStart)
    onChange && onChange(e)
  }

  let inputWrapperClass = `input-wrapper d-flex w-100 flex-column align-items-start justify-content-center ${touched && error !== undefined ? 'has-error' : ''}`
  if (Icon) inputWrapperClass += ' with-icon'

  return (
    <div className={inputWrapperClass}>
      <StarTooltip text={placeholder}>
        <FloatingLabel title={label} id="floatingLabel" className="w-100 d-flex" label={label}>
          <>
            <Form.Control
              key={name}
              ref={ref}
              id={id}
              type={type}
              className={`${className} ${touched && error !== undefined ? 'is-invalid' : ''}`}
              name={name}
              value={value || ''}
              placeholder=" "
              maxLength={maxLength}
              pattern={pattern}
              step={step}
              readOnly={readOnly}
              disabled={disabled}
              autoComplete={autoComplete}
              onKeyDown={onKeyPress}
              onBlur={onBlur}
              onInput={onInput}
              onFocus={onFocus}
              onChange={handleChange}
            />
            {Icon && (
              <div className="input-group-append">
                <StarTooltip text={iconTip}>
                  <div className={`${className} input-group-text py-0 px-1`} onClick={onIconClick}>
                    <Icon />
                  </div>
                </StarTooltip>
              </div>
            )}
          </>
        </FloatingLabel>
      </StarTooltip>

      {showErrorMessage && error !== undefined && !error.isValid && (
        <div className="text-danger">{error.msg}</div>
      )}
    </div>
  )
}
