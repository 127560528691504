import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CodiceFiscale from 'codice-fiscale-js'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import { Formik, Form, useFormikContext } from 'formik'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import * as Yup from 'yup'

import 'react-datepicker/dist/react-datepicker.css'

const defaultSize = (size = null) => ({
  startDate: Utility.getSize(size, { default: '12' }),
  endDate: Utility.getSize(size, { default: '12' }),
  role: Utility.getSize(size, { default: '50' }),
  personType: Utility.getSize(size, { default: '100' }),
  fiscalCode: Utility.getSize(size, { default: '22' }),
  companyName: Utility.getSize(size, { default: '78' }),
  location: Utility.getSize(size, { default: '40' }),
  province: Utility.getSize(size, { default: '9' }),
  lastName: Utility.getSize(size, { default: '40' }),
  firstName: Utility.getSize(size, { default: '40' }),
  gender: Utility.getSize(size, { default: '12' }),
  birthDate: Utility.getSize(size, { default: '18' })
})

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth < Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const ModalCreateDelegate = (props) => {
  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const setNewInitialValues = () => {
    setInitialValues({
      id: props.delegatePerson?.id,
      personType: props.delegatePerson?.personType || Constants.PF,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      startDate: props.delegatePerson?.startDate
        ? new Date(props.delegatePerson.startDate)
        : props.relationship?.startDate
          ? new Date(props.relationship.startDate)
          : null,
      endDate: props.delegatePerson?.endDate ? new Date(props.delegatePerson.endDate) : null,
      companyName: props.delegatePerson?.companyName || '',
      role: props.delegatePerson?.role ? props.delegatePerson.role : { id: 5 },
      fiscalCode: props.delegatePerson?.fiscalCode ? props.delegatePerson.fiscalCode : '',
      lastName: props.delegatePerson?.lastName ? props.delegatePerson.lastName : '',
      firstName: props.delegatePerson?.firstName ? props.delegatePerson.firstName : '',
      gender: props.delegatePerson?.gender || Constants.genderList[0].id,
      birthDate: props.delegatePerson?.birthDate ? new Date(props.delegatePerson.birthDate) : null,
      location: props.delegatePerson?.location ? props.delegatePerson.location : '',
      province: props.delegatePerson?.province ? props.delegatePerson.province : '',
      errorFiscalCode: false
    })
  }

  useEffect(() => {
    setNewInitialValues()
  }, [props])

  const handlerClose = () => {
    props.onHide()
  }

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required(labels.SELECT_PERSON_TYPE),
    startDate: Yup.date(labels.INSERT_VALID_DATE)
      .required(labels.REQUIRED_FIELD)
      .test(
        'is-valid-date',
        labels.INSERT_VALID_DATE,
        (value) => !value || Utility.isValidDate(new Date(value))
      )
      .test(
        'is-after-startDate',
        labels.START_DATE_MUST_BE_EQUAL_OR_AFTER_RELATIONSHIP_START_DATE,
        function (value) {
          return value >= new Date(props.relationship.startDate)
        }
      ),
    endDate: Yup.date(labels.INSERT_VALID_DATE)
      .nullable()
      .min(Yup.ref('startDate'), labels.END_DATE_MUST_BE_AFTER_START_DATE)
      .test(
        'is-valid-date',
        labels.INSERT_VALID_DATE,
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    role: Yup.object().shape({
      id: Yup.string()
        .required(labels.REQUIRED_FIELD)
        .test('role-error', labels.A_PHYSICAL_PERSON_CANNOT_HAVE_AN_ACTUAL_TITLE, function (value) {
          const { personType } = this.options.context
          return !(value == 5 && personType === Constants.PF)
        })
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date(labels.INSERT_VALID_DATE).when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required(labels.REQUIRED_FIELD)
          .test(
            'is-valid-date',
            labels.INSERT_VALID_DATE,
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().nullable(),
    location: Yup.string().required(labels.REQUIRED_FIELD),
    province: Yup.string().required(labels.REQUIRED_FIELD),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      labels.INVALID_FISCAL_CODE,
      (value) => !value
    )
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: labels.FISCAL_CODE
    },
    {
      errorKey: 'firstName',
      errorLabel: labels.FIRST_NAME
    },
    {
      errorKey: 'lastName',
      errorLabel: labels.LAST_NAME
    },
    {
      errorKey: 'companyName',
      errorLabel: labels.LEGAL_NAME
    },
    {
      errorKey: 'location',
      errorLabel: labels.LEGAL_ADDRESS
    },
    {
      errorKey: 'province',
      errorLabel: labels.PROVINCE
    },
    {
      errorKey: 'gender',
      errorLabel: labels.GENDER
    },
    {
      errorKey: 'birthDate',
      errorLabel: labels.BIRTH_DATE
    },
    {
      errorKey: 'role.id',
      errorLabel: labels.ROLE
    },
    {
      errorKey: 'startDate',
      errorLabel: labels.START_DATE
    },
    {
      errorKey: 'endDate',
      errorLabel: labels.END_DATE
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: labels.FISCAL_CODE
    }
  ]

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[4].errorLabel = labels.BIRTH_PLACE
    else formErrorsTabMapping[4].errorLabel = labels.LEGAL_ADDRESS
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      size="xl"
      centered
      onHide={handlerClose}
      bsPrefix="modal-create modal">
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {labels.FAMILY}
          <h4>
            {props.relationship.companyName !== null && props.relationship.companyName !== ''
              ? props.relationship.companyName
              : props.relationship.lastName + ' ' + props.relationship.firstName}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}>
          {({ values, errors }) => (
            <Form>
              {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
              {showErrorAlert &&
                errors &&
                typeof errors === 'object' &&
                Object.keys(errors).length > 0 && (
                  <div>
                    <ErrorListAlert
                      errors={getErrors(errors, values.personType)}
                      hide={() => setShowErrorAlert(false)}
                    />
                  </div>
                )}
              <FormBody
                {...props}
                setShowErrorAlert={setShowErrorAlert}
                handlerClose={handlerClose}
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [touched, setTouched] = useState(false)
  const [displayPf, setDisplayPf] = useState(true)
  const [displayPnf, setDisplayPnf] = useState(false)

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const handlePersonTypeClick = (val) => {
    const form = Object.assign({}, values)
    if (val === Constants.PF) {
      form.personType = val
      form.gender = 'FEMALE'
      form.companyName = null
      form.location = null
      setDisplayPf(true)
      setDisplayPnf(false)
    } else if (val === Constants.PNF) {
      form.personType = val
      form.gender = null
      form.firstName = null
      form.lastName = null
      form.birthDate = null
      form.location = null
      setDisplayPf(false)
      setDisplayPnf(true)
    }
    setValues(form)
  }

  const handleInputAutocompile = (location) => {
    const form = Object.assign({}, values)
    if (!location || location === '') {
      form.location = location
      setValues(form)
    }
    if (form.province?.toUpperCase() === 'EE') {
      form.location = location?.toUpperCase() || ''
      form.province = form.province?.toUpperCase() || ''
      return
    }
    actions.getLocation(location?.toUpperCase()).then(
      (loc) => {
        if (loc) {
          form.province = loc.province
          form.location = location?.toUpperCase() || ''
          setValues(form)
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
    form.location = location?.toUpperCase() || ''
    setValues(form)
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = ![null, undefined, ''].includes(val) ? val : null
    setValues(form)
  }

  const handlerRole = (e) => {
    const form = Object.assign({}, values)
    if (e.target.value) {
      form.role = {
        id: e.target.value.trim(),
        code: e.target.getAttribute('data-cod'),
        description: e.target[e.target.value].text
      }
    } else {
      form.role = {}
    }
    setValues(form)
  }

  const handleFiscalCode = (val) => {
    val = val && val?.toUpperCase() ? val.toUpperCase() : null
    const form = Object.assign({}, values)
    form.fiscalCode = val
    actions.getBaseRegistryPersonByFiscalCode(val).then(
      (baseRegistry) => {
        if (baseRegistry !== undefined) {
          if (baseRegistry.personType === Constants.PF) {
            form.companyName = null
            form.lastName = form.lastName || baseRegistry?.lastName || ''
            form.firstName = form.firstName || baseRegistry?.firstName || ''
            form.birthDate = form.birthDate || baseRegistry?.birthDate || ''
            form.gender = baseRegistry?.gender || Constants.genderList[0].id
            if (baseRegistry?.location) {
              form.location = form.location || baseRegistry?.location?.location || ''
              form.province = form.province || baseRegistry?.location?.province || ''
            } else {
              const cf = new CodiceFiscale(val).toJSON()
              form.location = form.location || cf?.birthplace?.toUpperCase() || ''
              form.province = form.province || cf?.birthplaceProvincia || ''
            }
          } else {
            form.companyName = form.companyName || baseRegistry?.companyName || ''
            form.location = form.location || baseRegistry?.location?.location || ''
            form.province = form.province || baseRegistry?.location?.province || ''
            form.firstName = null
            form.lastName = null
            form.gender = null
          }
          form.errorFiscalCode = false
          setValues(form)
        } else {
          if (form.personType === Constants.PF) {
            try {
              const cf = new CodiceFiscale(val).toJSON()
              if (cf) {
                getFiscalCodeData(cf, form)
              }
              form.errorFiscalCode = false
            } catch (e) {
              form.errorFiscalCode = true
            }
            setValues(form)
          }
        }
      },
      () => {
        if (form.personType === Constants.PF) {
          try {
            const cf = new CodiceFiscale(val).toJSON()
            if (cf) {
              getFiscalCodeData(cf, form)
            }
            form.errorFiscalCode = false
          } catch (e) {
            form.errorFiscalCode = true
          }
          setValues(form)
        }
      }
    )
  }

  const getFiscalCodeData = (cf, form) => {
    form.birthDate = form.birthDate || cf?.birthday ? new Date(cf?.birthday) : null
    form.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
    if (cf.birthplaceProvincia !== 'EE') {
      form.location = form.location || cf?.birthplace.toUpperCase()
    }
    form.province = form.province || cf?.birthplaceProvincia || ''
    setValues(form)
  }

  const handleFormSubmit = async () => {
    setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false)
      props.handlerSubmitDelegate({ ...values, errorFiscalCode: undefined })
      props.handlerClose()
    }
  }

  return (
    <div>
      <div className="row mb-3">
        <div className={`${sizesClass.startDate} pe-2`}>
          <DateField
            disabled={props.disabled}
            label={`${labels.START_DATE}*`}
            date={values.startDate}
            error={errors.startDate}
            touched={touched}
            onChange={(date) => handleChangeValue(date, 'startDate')}
          />
        </div>
        <div className={`${sizesClass.endDate} pe-2`}>
          <DateField
            disabled={props.disabled}
            label={labels.END_DATE}
            date={values.endDate}
            error={errors.endDate}
            touched={touched}
            onChange={(date) => handleChangeValue(date, 'endDate')}
          />
        </div>
        <div className={`${sizesClass.role} pe-0`}>
          <BwmSelect
            disabled={props.disabled}
            options={props.roles}
            name="role"
            label={`${labels.ROLE}*`}
            className="form-control"
            value={values.role?.id}
            error={errors.role}
            touched={touched}
            onChange={(e) => handlerRole(e)}
          />
        </div>
      </div>

      <div className="w-100">
        <div className="input-group">
          <div className="inputRadio me-5" style={{ fontSize: '15px' }}>
            <label
              disabled={props.disabled}
              className="form-check-label d-flex align-items-center"
              htmlFor={`pType1${values?.id}`}
              onClick={() => handlePersonTypeClick(Constants.PNF)}>
              <input
                disabled={props.disabled}
                className="form-check-input pe-1 me-2 mt-0"
                style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                type="radio"
                checked={values.personType === Constants.PNF}
                onChange={() => {}}
              />
              {labels.LEGAL_PERSON}
            </label>
          </div>
          <div className="inputRadio" style={{ fontSize: '15px' }}>
            <label
              disabled={props.disabled}
              className="form-check-label d-flex align-items-center"
              htmlFor={`pType2${values?.id}`}
              onClick={() => handlePersonTypeClick(Constants.PF)}>
              <input
                disabled={props.disabled}
                className="form-check-input pe-1 me-2 mt-0"
                style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                type="radio"
                checked={values.personType === Constants.PF}
                onChange={() => {}}
              />
              {labels.PHYSICAL_PERSON}
            </label>
          </div>
        </div>
      </div>

      <div className="row pt-3">
        <div id="infoPnf" className={'col-11' + (!displayPnf ? ' d-none' : '')}>
          <div className="row">
            <div className={`${sizesClass.fiscalCode} pe-2 mb-2`}>
              <BwmInput
                disabled={props.disabled}
                name="fiscalCode"
                label={`${labels.FISCAL_CODE}`}
                className="form-control"
                value={values.fiscalCode}
                error={errors.fiscalCode || errors.errorFiscalCode}
                touched={touched}
                maxLength="16"
                onBlur={(e) => handleFiscalCode(e.target.value)}
                onChange={(e) => handleChangeValue(e.target.value, 'fiscalCode', true, true)}
              />
            </div>
            <div className={`${sizesClass.companyName} mb-2 pe-4`}>
              <BwmInput
                disabled={props.disabled}
                name="companyName"
                label={`${labels.LEGAL_NAME}*`}
                className="form-control font-weight-bold font-size-big"
                maxLength="60"
                value={values.companyName}
                error={errors.companyName}
                touched={touched}
                onChange={(e) => handleChangeValue(e.target.value, 'companyName', true, true)}
              />
            </div>
            <div className={`${sizesClass.location} pe-2`}>
              <AutoCompileCustom
                disabled={props.disabled}
                label={`${labels.LEGAL_ADDRESS}*`}
                id="location"
                value={values.location}
                error={errors.location}
                touched={touched}
                filter={props.relationshipLocations}
                handleInputAutocompile={(e) => handleInputAutocompile(e)}
              />
            </div>
            <div className={`${sizesClass.province}`}>
              <ProvinceField
                label={`${labels.PROVINCE}*`}
                placeholder={labels.EE_FOR_FOREIGN_COUNTRY}
                province={values.province}
                error={errors.province}
                touched={touched}
                onChange={(e) => handleChangeValue(e.target.value, 'province', true)}
              />
            </div>
          </div>
        </div>
        <div id="infoPf" className={'col-10' + (!displayPf ? ' d-none' : '')}>
          <div className="form-row col-12 mb-2">
            <div className={`${sizesClass.fiscalCode} pe-2`}>
              <BwmInput
                disabled={props.disabled}
                name="fiscalCode"
                label={`${labels.FISCAL_CODE}`}
                className="form-control"
                maxLength="16"
                value={values.fiscalCode}
                error={errors.fiscalCode || errors.errorFiscalCode}
                touched={touched}
                onBlur={(e) => handleFiscalCode(e.target.value, values)}
                onChange={(e) => handleChangeValue(e.target.value, 'fiscalCode', true, true)}
              />
            </div>

            <div className={`${sizesClass.lastName} pe-2`}>
              <BwmInput
                disabled={props.disabled}
                name="lastName"
                label={`${labels.LAST_NAME}*`}
                className="form-control font-weight-bold font-size-big"
                maxLength="26"
                value={values.lastName}
                error={errors.lastName}
                touched={touched}
                onChange={(e) => handleChangeValue(e.target.value, 'lastName', true)}
              />
            </div>
            <div className={`${sizesClass.firstName} pe-2`}>
              <BwmInput
                disabled={props.disabled}
                name="firstName"
                label={`${labels.FIRST_NAME}*`}
                className="form-control font-weight-bold font-size-big"
                maxLength="25"
                value={values.firstName}
                error={errors.firstName}
                touched={touched}
                onChange={(e) => handleChangeValue(e.target.value, 'firstName', true)}
              />
            </div>
          </div>
          <div className="form-row col-12">
            <div className={`${sizesClass.gender} pe-2`}>
              <BwmSelect
                disabled={props.disabled}
                options={Constants.genderList}
                name="gender"
                label={`${labels.GENDER}*`}
                className="form-control"
                value={values.gender}
                error={errors.gender}
                touched={touched}
                onChange={(e) => handleChangeValue(e.target.value, 'gender')}
              />
            </div>
            <div className={`${sizesClass.birthDate} pe-2`}>
              <DateField
                disabled={props.disabled}
                name={'birthDate'}
                label={`${labels.BIRTH_DATE}*`}
                customOptions={{ maxDate: new Date() }}
                date={values.birthDate}
                error={errors.birthDate}
                touched={touched}
                onChange={(date) => handleChangeValue(date, 'birthDate')}
              />
            </div>
            <div className={`${sizesClass.location} pe-2`}>
              <AutoCompileCustom
                disabled={props.disabled}
                label={`${labels.BIRTH_PLACE}*`}
                id="location"
                filter={props.relationshipLocations}
                value={values.location}
                error={errors.location}
                touched={touched}
                handleInputAutocompile={(e) => handleInputAutocompile(e)}
              />
            </div>
            <div className={`${sizesClass.province}`}>
              <ProvinceField
                disabled={props.disabled}
                label={`${labels.PROVINCE}*`}
                placeholder={labels.EE_FOR_FOREIGN_COUNTRY}
                province={values.province}
                error={errors.province}
                touched={touched}
                onChange={(e) => handleChangeValue(e.target.value, 'province', true)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-4 d-flex justify-content-between">
        <button className="btn btn-outline-primary" onClick={props.handlerClose}>
          <i className="thx-cancel thx-icon me-2" />
          {labels.CANCEL}
        </button>
        <div className="d-flex align-items-center">
          <b className="me-2">*</b>
          <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
        </div>
        <button onClick={() => handleFormSubmit()} className="btn btn-primary">
          <i className="thx-floppy-disk thx-icon me-2" />
          {labels.SAVE}
        </button>
      </div>
    </div>
  )
}
