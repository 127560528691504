import { useState } from 'react'
import { useSelector } from 'react-redux'
import { testEmail } from '../../shared/Utility'
import * as actions from '../../../actions'
import getText from '../../shared/i18n/labels'

// FORM
import { BwmInput } from '../../shared/form/BwmInputB5'

// POPUPS
import { PopupError } from '../../shared/popups/PopupError'
import { PopupSuccess } from '../../shared/popups/PopupSuccess'

export default function BlueFileImportModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)
  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({
    isValid: true,
    msg: ''
  })

  const [email, setEmail] = useState(props?.subject?.email)
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: '' })

  const checkFileName = (name) => {
    return (
      name.split('.').pop().toLowerCase() === 'csv' || name.split('.').pop().toLowerCase() === 'txt'
    )
  }

  const handleUploadFile = (val) => {
    if (!val) {
      setErrorDataFile({ isValid: false, msg: labels.FILEREQUIRED })
    }

    let files = []
    for (let i = 0; i < val.target.files.length; i++) {
      if (!checkFileName(val.target.files[i].name)) {
        setErrorDataFile({
          isValid: false,
          msg: labels.FILE_EXTENSION_ERROR
        })
      } else {
        setErrorDataFile({ isValid: true, msg: '' })
        files.push(val.target.files[i])
      }
    }
    if (files.length > 0) {
      setDataFile(files)
    }
  }

  const validateFormData = () => {
    let isValid = true
    if (!email) {
      setErrorEmail({ isValid: false, msg: labels.REQUIREDFIELD })
      isValid = false
    }
    if (dataFile?.length < 1) {
      setErrorDataFile({ isValid: false, msg: labels.FILEREQUIRED })
      isValid = false
    }
    return isValid
  }

  const handleSendDataFile = () => {
    if (!validateFormData()) {
      return
    }
    let formData = new FormData()
    formData.append('email', email)
    formData.append('precision', props.precision)
    formData.append('language', lang)
    if (dataFile?.length === 1) {
      for (let i = 0; i < dataFile.length; i++) {
        formData.append('files', dataFile[i])
      }
    } else {
      setErrorDataFile({
        isValid: false,
        msg: labels.SINGLE_CSV_FILE_ALLOWED
      })
      return
    }

    actions.importBLUEFile(props?.subject?.id, formData).then(
      (res) => {
        if (!res.error) {
          PopupSuccess({
            title: labels.SUCCESSTITLE,
            text: labels.MASSIVEPOPUPDROPFILE[3],
            ok: labels.SUCCESSOK
          })
          props.closeModalExt()
        } else {
          setDataFile([])
        }
      },
      (err) => {
        if (err?.errorKey === 'massiveSearchNotAuthorized') {
          PopupError({
            text: labels.MASSIVESEARCHNOTAUTHORIZED,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE,
            labels
          })
        } else if (err?.title) {
          PopupError({
            text: err.title,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE,
            labels
          })
        } else {
          PopupError({
            text: labels.FILEERROR,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE,
            labels
          })
        }
      }
    )
  }

  const handleDownloadClick = () => {
    dowloadLocalDocument('blue_example.csv')
  }

  const handleinputEmail = (e) => {
    setEmail(e.target.value)
    if (e.target.value && testEmail(e.target.value)) {
      setErrorEmail({ isValid: true, msg: '' })
    } else {
      if (e.target.value && e.target.value !== '') {
        setErrorEmail({
          isValid: false,
          msg: lang === 'it' ? 'Email non valida' : 'Email not valid'
        })
      } else {
        setErrorEmail({
          isValid: false,
          msg: lang === 'it' ? 'Campo obbligatorio' : 'Required field'
        })
      }
    }
  }

  const dowloadLocalDocument = (fileName) => {
    const link = document.createElement('a')
    link.href = process.env.PUBLIC_URL + '/docs/' + fileName
    link.setAttribute('download', fileName)
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <div className="">
        <div className="text-start">
          <h4>
            {labels.MASSIVEPOPUPTEXT[0]} <b>CSV</b> {labels.MASSIVEPOPUPTEXT[1]}
          </h4>
          <h6>{labels.MASSIVE100LIMIT}</h6>

          <div
            className="cursor-pointer text-primary text-underline"
            onClick={() => handleDownloadClick()}>
            <b>{labels.MASSIVEPOPUPTEXT[2]}</b>
          </div>

          <div className="certificatiLoaded ps-0 ms-0 text-start">
            {!errorDataFile.isValid ? (
              <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
            ) : (
              <div>
                {dataFile.length > 0 &&
                  dataFile.map((file, index) => {
                    return (
                      <div key={'blue-import' + { index }} className="green-label">
                        <i className="thx-check thx-icon me-2" />
                        {file.name}
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
          <div className="col-12 text-center mt-3">
            <h6 className="line-around"></h6>
          </div>
          <div className="form-group mt-3">
            <form>
              <BwmInput
                name="fiscalCode"
                label={`${labels.MASSIVEPOPUPEMAIL}*`}
                placeholder={labels.MASSIVEPOPUPEMAIL}
                className="form-control"
                value={email}
                error={errorEmail}
                onChange={(e) => handleinputEmail(e)}
              />
              <span className="text-danger">{errorEmail?.msg}</span>
            </form>
          </div>

          <div className="text-center mt-3">
            <div className=" btngroup d-flex mt-5 col-12 justify-content-between">
              <button onClick={() => props.closeModalExt()} className="btn btn-outline-primary">
                <i className="thx-cancel thx-icon me-2" />
                {labels.CANCEL}
              </button>
              <div className="image-upload text-center">
                <label htmlFor={`dataFile`}>
                  <div className="btn btn-outline-primary">
                    <i className="thx-upload thx-icon me-2" />
                    {labels.UPLOADCSV}
                  </div>
                </label>
                <input
                  id={`dataFile`}
                  type="file"
                  name={`dataFile`}
                  onChange={(e) => handleUploadFile(e)}
                />
              </div>
              <button
                onClick={() => handleSendDataFile()}
                disabled={!errorDataFile.isValid || !dataFile || !errorEmail?.isValid}
                className="btn btn-primary">
                <i className="thx-search thx-icon me-2" />
                {labels.CONFIRM}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
