import { useSelector } from 'react-redux'
import * as Constants from '../../../config/Constants'
import getText from '../../shared/i18n/labels'

export default function BlueDBListModal() {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <>
      <div className="mt-2">
        <h4>
          <b>{labels.DBPOPUPTITLE}</b>
        </h4>
        <p>
          {labels.DBPOPUPTEXT[0]} <u>{labels.DBPOPUPTEXT[1]}</u>&nbsp;
          {labels.DBPOPUPTEXT[2]}
        </p>
        <p>{labels.DBPOPUPTEXT2}</p>
        <p>
          {labels.DBPOPUPTEXT3[0]}
          <a
            href="http://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:decreto.legislativo:2007-06-22;109!vig="
            target="_blank">
            d.lgs 109/2007.
          </a>
          {labels.DBPOPUPTEXT3[1]}
          <a
            href="https://uif.bancaditalia.it/adempimenti-operatori/contrasto/index.html"
            target="_blank">
            {labels.DBPOPUPTEXT3[2]}
          </a>
        </p>
        <div className="col-12 text-center">
          <h6 className="line-around"></h6>
        </div>
        <div className="row mx-auto align-items-center mb-2 mt-4">
          <div className="col-6 text-start">
            <b>EU: European Commission Sanctions List</b>
          </div>
          <div className="col-6 text-start d-flex">
            <a
              href={Constants.BLUE_DATABASES[0].website}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-outline-primary col-6 mt-2 me-2"
              role="button">
              {labels.DBPOPUPBUTTONTEXT[0]}
            </a>
            <a
              href={Constants.BLUE_DATABASES[0].url}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-primary col-6 mt-2"
              role="button">
              {labels.DBPOPUPBUTTONTEXT[1]}
            </a>
          </div>
        </div>
        <div className="row mx-auto align-items-center mb-2">
          <div className="col-6 text-start">
            <b>TALEB: United Nations Security Council Sanctions List</b>
          </div>
          <div className="col-6 text-start d-flex">
            <a
              href={Constants.BLUE_DATABASES[1].website}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-outline-primary col-6 mt-2 me-2"
              role="button">
              {labels.DBPOPUPBUTTONTEXT[0]}
            </a>
            <a
              href={Constants.BLUE_DATABASES[1].url}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-primary col-6 mt-2"
              role="button">
              {labels.DBPOPUPBUTTONTEXT[1]}
            </a>
          </div>
        </div>
        <div className="row mx-auto align-items-center mb-2">
          <div className="col-6 text-start">
            <b>USA: U.S. Department of Treasury Sanctions List</b>
          </div>
          <div className="col-6 text-start d-flex">
            <a
              href={Constants.BLUE_DATABASES[2].website}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-outline-primary col-6 mt-2 me-2"
              role="button">
              {labels.DBPOPUPBUTTONTEXT[0]}
            </a>
            <a
              href={Constants.BLUE_DATABASES[2].url}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-primary col-6 mt-2"
              role="button">
              {labels.DBPOPUPBUTTONTEXT[1]}
            </a>
          </div>
        </div>
        <div className="row mx-auto align-items-center">
          <div className="col-6 text-start">
            <b>UK: HM Treasury Sanctions List</b>
          </div>
          <div className="col-6 text-start d-flex">
            <a
              href={Constants.BLUE_DATABASES[3].website}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-outline-primary col-6 mt-2 me-2"
              role="button">
              {labels.DBPOPUPBUTTONTEXT[0]}
            </a>
            <a
              href={Constants.BLUE_DATABASES[3].url}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-primary col-6 mt-2"
              role="button">
              {labels.DBPOPUPBUTTONTEXT[1]}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
