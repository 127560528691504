import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import * as Constants from '../../../config/Constants'
import * as Utility from '../../shared/Utility'
import { blueDownloadAntiTerrorism } from '../../../actions'
import getText from '../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'
import BlueDBListModal from './BlueDBListModal'

// POPUPS
import BlueResultDetailsPopup from './BlueResultDetailsPopup'
import { PopupError } from '../../shared/popups/PopupError'
import { PopupSuccess } from '../../shared/popups/PopupSuccess'

const defaultSize = (size = null) => ({
  name: Utility.getSize(size, { default: '47' }),
  birthDate: Utility.getSize(size, { default: '16' }),
  citizenship: Utility.getSize(size, { default: '14' }),
  notes: Utility.getSize(size, { default: '23' })
})

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth < Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '200%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '120%'
  return '100%'
}

export default function BlueResultForm(props) {
  const { subject, results } = props
  const [showDBListModal, setShowDBListModal] = useState(false)
  const [hasResults, setHasResults] = useState(false)
  const [showDetails, setShowdetails] = useState(false)
  const [itemDetails, setItemDetails] = useState(null)
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [sizesClass, setSizesClass] = useState(getSize())

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const closeModal = () => {
    setShowDBListModal(!showDBListModal)
  }

  useEffect(() => {
    setHasResults(results?.result?.length > 0)
  }, [results])

  const showDetailsModal = (item) => {
    setItemDetails(item)
    setShowdetails(true)
  }

  const closeDetailsModal = () => {
    setShowdetails(false)
  }

  const downloadDocument = (subject, results, entityId) => {
    blueDownloadAntiTerrorism(subject?.id, results?.savedEventId, entityId, lang).then(
      (response) => {
        if (response) {
          let ext = 'pdf'
          Utility.downloadFile(
            Utility.base64ToArrayBuffer(response),
            `${subject.denomination}-${moment(results.searchDate).format('YYYY-MM-DD')}.${ext}`
          )
          PopupSuccess({
            title: labels.SUCCESSTITLE,
            text: labels.DOWLOADOK,
            ok: labels.SUCCESSOK
          })
        } else {
          PopupError({
            text: labels.FILEERROR,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          })
        }
      },
      (err) => {
        PopupError({
          text: labels.FILEERROR,
          title: labels.WARNING,
          request: labels.CONTACTSUPPORT,
          close: labels.CLOSE
        })
      }
    )
  }

  useEffect(() => {
    // Imposta la variabile CSS iniziale
    handleResize()

    // Aggiunge l'evento resize
    window.addEventListener('resize', handleResize)

    // Rimuove l'evento al termine del ciclo di vita del componente
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleResize = () => {
    const blueSearchForm = document.getElementById('blue-search-form')
    document.documentElement.style.setProperty(
      '--blue-result-height',
      `${blueSearchForm.offsetHeight - 107}px`
    )
  }

  const renderRows = () => {
    if (hasResults) {
      return props?.results?.result[0]?.entity?.map((o, index) => {
        if (index < 100) {
          let name = o?.nameJoin
          let birthDate = o?.birthList && Utility.formatDateForDisplay(o?.birthList[0]?.date)
          let citizenship = o?.citizenList && o?.citizenList[0]?.country
          let notes = o?.note

          return (
            <div key={o?.id} className={'text-start row-table '}>
              <div
                className={`${sizesClass.name} div-td text-start text-truncate`}
                onClick={() => showDetailsModal(o)}>
                {name}
              </div>
              <div
                className={`${sizesClass.birthDate} div-td text-start text-truncate`}
                onClick={() => showDetailsModal(o)}>
                {birthDate}
              </div>
              <div
                className={`${sizesClass.citizenship} div-td text-start text-truncate`}
                onClick={() => showDetailsModal(o)}>
                {citizenship}
              </div>
              <div
                className={`${sizesClass.notes} div-td text-start text-truncate`}
                onClick={() => showDetailsModal(o)}>
                {notes}
              </div>
            </div>
          )
        }
      })
    }
  }

  return (
    <>
      <div className="container px-0 mx-0 blue-result border">
        <div className="row me-0 ms-0 ">
          <div className="star-table-recipient no-box-shadow blue-result">
            <div className="ps-0 pe-0 star-table" style={{ width: getTableContainerWidth() }}>
              <div className="text-start header-table">
                <div className={`${sizesClass.name} div-td text-uppercase`}>{labels.NAME}</div>
                <div className={`${sizesClass.birthDate} div-td text-uppercase`}>
                  {labels.BIRTHDATE}
                </div>
                <div className={`${sizesClass.citizenship} w-lg-15 div-td text-uppercase`}>
                  {labels.CITIZENCOUNTRY}
                </div>
                <div className={`${sizesClass.notes} div-td text-uppercase`}>{labels.NOTE}</div>
              </div>
              {renderRows()}
            </div>
          </div>
        </div>
      </div>
      <div className="row me-0 ms-0 mb-3 pb-2 border-top-none border border-bottom">
        <div className="col-12 ">
          <div className="d-flex me-0 ms-0 text-start  justify-content-between align-items-center mt-2">
            <div className="ms-2">{hasResults && labels.FIRST100}</div>
            <div className="text-end me-2">
              <button
                className="btn btn-primary"
                disabled={!hasResults}
                onClick={() => downloadDocument(subject, results)}>
                <i className="thx-pdf thx-icon me-2" />
                {labels.VIEWPDF}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex me-0 ms-0">
        <div className="me-2 text-end">
          <button
            className="btn btn-outline-primary w-100"
            onClick={() => setShowDBListModal(true)}>
            {labels.ORIGINLIST}
          </button>
        </div>
        <div className="me-2 text-end">
          <a
            href={Constants.BLUE_DATABASES[0].url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-outline-primary w-100"
            role="button">
            EU
          </a>
        </div>
        <div className="col-1 me-2 text-end">
          <a
            href={Constants.BLUE_DATABASES[1].url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-outline-primary w-100"
            role="button">
            TALEB
          </a>
        </div>
        <div className="col-1 me-2 text-end">
          <a
            href={Constants.BLUE_DATABASES[2].url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-outline-primary w-100"
            role="button">
            USA
          </a>
        </div>
        <div className="col-1 me-2 text-end">
          <a
            href={Constants.BLUE_DATABASES[3].url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-outline-primary w-100"
            role="button">
            UK
          </a>
        </div>
      </div>

      <Modal
        backdrop="static"
        show={showDBListModal}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.ORIGINLIST}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueDBListModal key="DBListModal" />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={showDetails}
        onHide={closeDetailsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable>
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.SEARCHRESULTTITLE}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueResultDetailsPopup item={itemDetails} />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex mt-4 w-100 align-items-center justify-content-between">
            <button className="btn btn-primary" onClick={closeDetailsModal}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CLOSE}
            </button>
            <button
              className="btn btn-primary me-2"
              onClick={() => downloadDocument(subject, results, itemDetails._id)}>
              <i className="thx-pdf thx-icon me-2" />
              {labels.VIEWPDF}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
