import { useSelector } from 'react-redux'
import getText from '../../../../shared/i18n/labels'

/**
 * Ritorna un messaggio di errore dato dall'utente
 * @returns
 */
export default function MessageWrongFile({ communicationError }) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  let error_status = labels.ERROR_PROCESSING_FILE
  if (communicationError !== '' && communicationError !== null) {
    error_status = communicationError
  }
  return (
    <div className="popup communication">
      <div className="row">
        <>{error_status}</>
      </div>
    </div>
  )
}
