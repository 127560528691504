import { useState } from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../../../actions'
import { GenerateNameFileWhenIsend } from './GenerateNameFileWhenIsend'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// COMPONENTS
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { ShowManyMofalTogheter } from './NewShowManyMofalTogheter'
import { handleUploadReceiptCrsCora } from '../../../shared/UploadReceipt'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'
import { BREAKPOINT_MD } from '../../../../config/Constants'

export const UploadReceiptModal = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [receiptFile, setReceiptFile] = useState([])
  const [errorReceipt, setErrorReceipt] = useState({ isValid: true, msg: '' })
  const [loading, setLoading] = useState(false)
  const [communication, setCommunication] = useState([])

  const closeModal = () => {
    setShowModal(false)
    setReceiptFile([])
  }

  const openModal = () => {
    setErrorReceipt({ isValid: true, msg: '' })
    setShowModal(true)
  }

  const setCertificateFile = () => {}

  /**
   * Funzione che cambia lo state e che mi da la possibilita di cambiare lo stato anche da altre funzioni
   * @param {*} val valore dell'oggetto che dovra' essere cambiato
   */
  const setReceiptFileData = ({ receiptFile, errorReceipt }) => {
    setReceiptFile(receiptFile)
    setErrorReceipt(errorReceipt)
  }

  /**
   * controlla estensione e nome file
   * @param {*} files
   * @returns
   */
  const checkFileName = (files) => {
    for (let x = 0; x < files.length; x++) {
      let file = files[x]
      let name = file.name
      let checkIfNameIsCorrect =
        name.slice(0, 7) === 'ATPECRQ' &&
        (name.split('.').pop().toLowerCase() === 'p7m' ||
          name.split('.').pop().toLowerCase() === 'run')
      if (!checkIfNameIsCorrect) {
        return false
      }
      return true
    }
  }

  const handleSendReceipt = () => {
    setShowModal(false)
    setLoading(true)
    let length_file = receiptFile.length
    const formData = new FormData()
    for (var x = 0; x < length_file; x++) {
      let file = receiptFile[x]
      formData.append('receiptFiles', file)
    }
    if (communication.length !== 0) {
      //NOTE: questo id serve a controllare che la lista delle ricevute sia vuota in partenza quando faccio la chiamta, cosi si se si rifa la richiesta e ci sono ricevute nuove si riparte da zero
      setCommunication([])
    }
    actions
      .createCrsReceipt(formData)
      .then((communication) => {
        setLoading(false)
        setCommunication(communication)
        setReceiptFile([])
      })
      .catch(() => {
        setLoading(false)
        setReceiptFile([])
        PopupError({ text: labels.ERROR_DURING_THE_VERIFICATION_OF_THE_RECEIPT })
      })
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        className="btn btn-outline-primary "
        data-tip={labels.LOAD_RECEIPT_FILE_TIP}
        onClick={openModal}
        disabled={props.disabled}>
        <i className="thx-text-file thx-icon" />
        {window.innerWidth > BREAKPOINT_MD && (
          <span className="ms-2">{labels.PROCESS_RECEIPTS}</span>
        )}
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.PROCESS_RECEIPTS}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mt-2">
            <div className="image-upload text-start">
              <label htmlFor={`receiptFile`}>
                <div className="certificateFiles">
                  <span>{labels.LOAD_RECEIPT_FILE}</span>
                  <i className="thx-upload thx-icon ms-2" />
                </div>
              </label>
              <input
                id={`receiptFile`}
                type="file"
                name={`receiptFile`}
                multiple
                ref={(ref) => setCertificateFile(ref)}
                onChange={(e) => {
                  const data = handleUploadReceiptCrsCora(e, checkFileName)
                  setReceiptFileData(data)
                }}
              />
              <div className="certificatiLoaded text-start">
                {!errorReceipt.isValid ? (
                  <div className="certificatiLoaded-error">{errorReceipt.msg}</div>
                ) : (
                  <GenerateNameFileWhenIsend files={receiptFile} />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorReceipt.isValid || receiptFile?.length === 0}
              onClick={handleSendReceipt}
              type="button"
              className="btn btn-primary">
              {labels.CONFIRM}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ShowManyMofalTogheter communication={communication} />
    </>
  )
}
