import { useState } from 'react'
import { useSelector } from 'react-redux'
import { downloadFile } from '../../../shared/Utility'
import { downloadRelationshipsCsvTemplate } from '../relationships/utils/CoraTemplateUtils'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import { ImportModal } from '../../../shared/popups/ImportModal'

// COMPONENTS
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel'
import { BREAKPOINT_MD } from '../../../../config/Constants'

export function YearlyBalanceImportModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataFile, setDataFile] = useState([])
  const [charSeparator, setCharSeparator] = useState(';')

  const closeModal = () => {
    setShowModal(false)
    setDataFile('')
  }

  const openModal = () => {
    setShowModal(true)
  }

  const handleSendDataFile = (dataFiles) => {
    let formData = new FormData()
    formData.append('subjectId', props.subjectId)
    formData.append('separator', charSeparator)
    if (dataFiles?.length > 0) {
      for (let i = 0; i < dataFiles.length; i++) {
        const file = dataFiles[i]
        formData.append('file', file)
      }
    } else {
      return
    }
    setLoading(true)
    setShowModal(false)
    actions
      .importCoraYearlyBalances(formData)
      .then(
        (res) => {
          PopupSuccess({ dataList: [getImportReport(res)] })
          props.getYearlyBalance()
        },
        (errors) => {
          if (errors?.title) {
            PopupError({ text: errors.title })
          } else {
            PopupError({ text: labels.ERROR_IMPORTING_YEARLY_BALANCES })
          }
        }
      )
      .then(() => {
        setDataFile('')
        setLoading(false)
      })
  }

  const getImportReport = (importResultDTO) => {
    if (importResultDTO.lineMessages.length > 0) {
      //compongo un file di testo con i messaggi
      const file = importResultDTO.lineMessages
        .map((el) => {
          return `${labels.LINE} ${el.line}: ${el.message}`
        })
        .join('\r\n')

      return (
        <>
          <div className="text-center">{labels.FILE_PROCESSED_CORRECTLY}</div>
          <div className="text-center">
            {labels.PROCESSED_LINES}: {importResultDTO.elaborated}
          </div>
          <div className="text-center">
            {labels.NON_PROCESSED_LINES}: {importResultDTO.rejected}
          </div>
          <div className={'mt-4 text-center'}>{labels.ERRORS_IN_FILE}</div>
          <div className="text-center">
            <button
              className={'btn btn-primary mt-3 text-center'}
              onClick={() => {
                downloadFile(file, 'risultati_import_saldi.log')
              }}>
              {labels.CHECK_LOG_FILE}
            </button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="text-center">{labels.FILE_PROCESSED_CORRECTLY}</div>
          <div className="text-center">
            {labels.PROCESSED_LINES}: {importResultDTO.elaborated}
          </div>
          <div className="text-center">
            {labels.NON_PROCESSED_LINES}: {importResultDTO.rejected}
          </div>
        </>
      )
    }
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        data-tip={labels.IMPORT_YEARLY_BALANCES_DESCRIPTION}
        className={`btn btn-outline-primary `}
        onClick={openModal}>
        <i className="thx-import thx-icon" />
        {window.innerWidth > BREAKPOINT_MD && (
          <span className="ms-2">{labels.IMPORT_YEARLY_BALANCES}</span>
        )}
      </button>
      {showModal && (
        <ImportModal
          show={showModal}
          onHide={() => closeModal()}
          onSend={(dataFiles) => handleSendDataFile(dataFiles)}
          allowedFileExtensions={['csv']}
          buttonLoad={() => (
            <>
              <div className="row">
                <div className="col">
                  <UploadFileLabel forLabel={'dataFile'} label={labels.LOAD_FILE} />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col pt-1 pb-1">
                  <label className="form-group label me-1">{labels.DATA_SEPARATOR}:</label>
                  <input
                    style={{ maxWidth: '20px', textAlign: 'center' }}
                    maxLength="1"
                    type="text"
                    value={charSeparator}
                    onChange={(e) => setCharSeparator(e.target.value)}></input>
                </div>
              </div>
            </>
          )}
          title={labels.IMPORT_YEARLY_BALANCES}
          extraButtons={(_, index) => [
            <button
              key={index}
              onClick={() => downloadRelationshipsCsvTemplate()}
              className="btn btn-outline-primary ms-2 ">
              <i className="thx-export thx-icon me-2 mt-1" />
              {labels.DOWNLOAD_CSV_TEMPLATE}
            </button>
          ]}
        />
      )}
    </>
  )
}
