import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { base64ToArrayBuffer, downloadFile } from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// FORM
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// COMPONENTS
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

const TIPO_SOGG_COMPROORO = '36'

export function ArcoRegistryPrintForm(props) {
  const lang = useSelector((state) => state.language.language)

  registerLocale(lang, lang === 'it' ? it : en)

  const arcoCollaboratorsFromStore = useSelector((state) => state.arcoCollaborators.data)

  const [initialValues, setInitialValues] = useState({})

  const arcoSubject = useSelector((state) => state.arcoSubject.data)

  const hasSignatories =
    props.selectedPrintItem?.arcoRegistrySignatories &&
    props.selectedPrintItem?.arcoRegistrySignatories?.length > 0

  useEffect(() => {
    setNewInitialValues()
  }, [props.selectedPrintItem])

  const setNewInitialValues = () => {
    setInitialValues({
      arcoOperationId: props?.arcoRegistriesOperations?.id,
      arcoCollaboratorId: arcoCollaboratorsFromStore[0]?.id,
      arcoRegistryId: props?.selectedPrintItem?.id,
      includeDossier: false,
      includePhysicalPersonDeclaration:
        !hasSignatories && props.selectedPrintItem?.personType === Constants.PF,
      includeRiskAnalysis: true,
      includePhysicalPersonAndAttorneyDeclaration:
        hasSignatories && props.selectedPrintItem?.personType === Constants.PF,
      includeAntiterrorismCheck: true,
      includePrivacyPolicy: shouldPrivacyBeEnabled(),
      includeLegalPersonDeclaration:
        hasSignatories && props.selectedPrintItem?.personType === Constants.PNF,
      includeSchedaNumerata: arcoSubject?.arcoSubjectType?.code === TIPO_SOGG_COMPROORO,
      operationNature: props.arcoRegistriesOperations?.nature,
      operationPurpose: props.arcoRegistriesOperations?.purpose,
      printDate: props.arcoRegistriesOperations?.profilingDate
        ? new Date(props.arcoRegistriesOperations?.profilingDate)
        : new Date()
    })
  }

  const shouldPrivacyBeEnabled = () => {
    if (props.selectedPrintItem?.personType === Constants.PNF && !hasSignatories) {
      return false
    }
    return true
  }

  const [formData, updateFormData] = useState({})

  useEffect(() => {
    updateFormData({ ...formData, arcoRegistryId: props.selectedPrintItem?.id })
  }, [props.selectedPrintItem])

  const validationSchema = Yup.object().shape({})

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        <Form className={props.formOnBottom ? 'p-2 form-on-bottom' : ''}>
          <FormBody {...props} initialValues={initialValues} />
        </Form>
      </Formik>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues } = useFormikContext()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const arcoCollaboratorsFromStore = useSelector((state) => state.arcoCollaborators.data)

  const [loading, setLoading] = useState(false)

  const setArcoCollaborators = () => {
    let newObjects = {}
    let newObjectLists = []

    arcoCollaboratorsFromStore.map((collaborator) => {
      if (collaborator.disabled === true) {
        newObjects = {
          id: collaborator.id,
          description: collaborator.name
        }
        newObjectLists.push(newObjects)
      }
    })
    return newObjectLists
  }

  const arcoSubject = useSelector((state) => state.arcoSubject.data)
  const arcoCollaborators = setArcoCollaborators()

  const [collaborator, setCollaborator] = useState(arcoCollaboratorsFromStore[0])

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  const hasSignatories =
    props.selectedPrintItem?.arcoRegistrySignatories &&
    props.selectedPrintItem?.arcoRegistrySignatories?.length > 0

  const naturalPersonEnabled =
    !hasSignatories && props.selectedPrintItem?.personType === Constants.PF

  const naturalPersonAttorneyInFact =
    hasSignatories && props.selectedPrintItem?.personType === Constants.PF

  const legalEntityEnabled = hasSignatories && props.selectedPrintItem?.personType === Constants.PNF

  const getSurnameOrDenomination = (item) => {
    return Constants.PF === item.personType
      ? item.lastName + ' ' + item.firstName
      : item.denomination
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = ![null, undefined, ''].includes(val) ? val : null
    setValues(form)
  }

  const handleCollaborator = (val) => {
    const _collaborator = arcoCollaborators.find((el) => el?.id?.toString() === val?.toString())
    setCollaborator(_collaborator)
  }

  const handleFormSubmit = () => {
    setLoading(true)
    if (!props.arcoRegistriesOperations?.id) {
      PopupError({ text: labels.ERROR_DOWNLOADING_OPERATION_NOT_SAVED })
      setLoading(false)
    } else if (
      !values.includeAntiterrorismCheck &&
      !values.includeDossier &&
      !values.includePhysicalPersonDeclaration &&
      !values.includePhysicalPersonAndAttorneyDeclaration &&
      !values.includePrivacyPolicy
    ) {
      PopupError({ text: labels.SELECT_AT_LEAST_ONE_OPTION })
      setLoading(false)
    } else {
      actions
        .printArcoOperationDocument(values)
        .then(
          (response) => {
            if (response) {
              downloadFile(
                base64ToArrayBuffer(response),
                'DOCUMENTI - ' +
                  props.arcoRegistriesOperations.description.substring(0, 20) +
                  ' ' +
                  getSurnameOrDenomination(props?.selectedPrintItem).substring(0, 30) +
                  '.zip'
              )
              PopupSuccess({ text: labels.FILE_DOWNLOADED })
              setLoading(false)
            } else {
              PopupError({ text: labels.FILE_NOT_FOUND })
              setLoading(false)
            }
          },
          (err) => {
            PopupError({ text: labels.FILE_NOT_FOUND })
            setLoading(false)
          }
        )
        .then(() => {
          setLoading(false)
          props.onHide()
        })
    }
  }

  return (
    <div className="row-detail bg-gray d-flex row-form big">
      {loading && <PageSpinner />}
      <div>
        <div className="row">
          <h3 className="col-12 text-center">{labels.PRINT_DOCUMENTS_OF_THE_SUBJECT}</h3>
        </div>
        <div className="soggetto-crs">
          <form id={`stampa-${values.arcoOperationId}`}>
            <div>
              <div className="row col-12">
                <div className="col-6 pt-1 pb-1">
                  <BwmCheckbox
                    name="fascicolo"
                    label={labels.FILE}
                    className="form-control"
                    checked={values.includeDossier}
                    errors={errors.includeDossier}
                    touched={props.touched}
                    onChange={(e) => handleChangeValue(e.target.checked, 'includeDossier')}
                  />
                </div>
                <div className="col-6 pt-1 pb-1">
                  <BwmCheckbox
                    name="rischio"
                    label={labels.RISK_ANALYSIS_SCHEDULE}
                    className="form-control"
                    checked={values.includeRiskAnalysis}
                    errors={errors.includeRiskAnalysis}
                    touched={props.touched}
                    onChange={(e) => handleChangeValue(e.target.checked, 'includeRiskAnalysis')}
                  />
                </div>
              </div>
              <div className="row col-12">
                <div className="col-6 pt-1 pb-1">
                  <BwmCheckbox
                    disabled={!naturalPersonEnabled}
                    name="pf"
                    label={labels.PHYSICAL_PERSON_DECLARATION}
                    className="form-control"
                    checked={values.includePhysicalPersonDeclaration}
                    errors={errors.includePhysicalPersonDeclaration}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeValue(e.target.checked, 'includePhysicalPersonDeclaration')
                    }
                  />
                </div>
                <div className="col-6 pt-1 pb-1">
                  <BwmCheckbox
                    name="privacy"
                    label={labels.PRIVACY_INFORMATION}
                    className="form-control"
                    checked={values.includePrivacyPolicy}
                    errors={errors.includePrivacyPolicy}
                    touched={props.touched}
                    onChange={(e) => handleChangeValue(e.target.checked, 'includePrivacyPolicy')}
                  />
                </div>
              </div>
              <div className="row col-12">
                <div className="col-6 pt-1 pb-1">
                  <BwmCheckbox
                    disabled={!naturalPersonAttorneyInFact}
                    name="pf e procuratore"
                    label={labels.PHYSICAL_PERSON_AND_ATTORNEY_DECLARATION}
                    className="form-control"
                    checked={values.includePhysicalPersonAndAttorneyDeclaration}
                    errors={errors.includePhysicalPersonAndAttorneyDeclaration}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeValue(
                        e.target.checked,
                        'includePhysicalPersonAndAttorneyDeclaration'
                      )
                    }
                  />
                </div>
                <div className="col-6 pt-1 pb-1">
                  <BwmCheckbox
                    name="antiterrorismo"
                    label={labels.ANTITERRORISM_CHECK}
                    className="form-control"
                    checked={values.includeAntiterrorismCheck}
                    errors={errors.includeAntiterrorismCheck}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeValue(e.target.checked, 'includeAntiterrorismCheck')
                    }
                  />
                </div>
              </div>
              <div className="row col-12 pb-3">
                <div className="col-6 pt-1 pb-1">
                  <BwmCheckbox
                    disabled={!legalEntityEnabled}
                    name="pg"
                    label={labels.LEGAL_PERSON_DECLARATION}
                    className="form-control"
                    checked={values.includeLegalPersonDeclaration}
                    errors={errors.includeLegalPersonDeclaration}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeValue(e.target.checked, 'includeLegalPersonDeclaration')
                    }
                  />
                </div>
                {arcoSubject?.arcoSubjectType?.code === TIPO_SOGG_COMPROORO && (
                  <div className="col-6 pt-1 ">
                    <BwmCheckbox
                      name="schedanumerata"
                      label={labels.NUMERATED_CV_RECEIVED}
                      className="form-control"
                      checked={values.includeSchedaNumerata}
                      errors={errors.includeSchedaNumerata}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.checked, 'includeSchedaNumerata')}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row col-12 mt-3">
              <h6 className="col-12">
                <b>{labels.ONLY_FOR_THIS_PRINT}:</b>
              </h6>
            </div>
            <div className="row col-12 mt-1">
              <div className="col-1 me-2">
                <DateField
                  label={labels.PRINT_DATE}
                  date={values.printDate}
                  error={errors.printDate}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'printDate')}
                />
              </div>
              <div className="col-3 me-2">
                <BwmInput
                  name="natura"
                  label={labels.OPERATION_NATURE}
                  className="form-control"
                  value={values.operationNature}
                  error={errors.operationNature}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'operationNature')}
                />
              </div>
              <div className="col-3">
                <BwmInput
                  name="scopo"
                  label={labels.OPERATION_PURPOSE}
                  className="form-control"
                  value={values.operationPurpose}
                  error={errors.operationPurpose}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'operationPurpose')}
                />
              </div>
            </div>
            <div className="row col-12 mt-3">
              <div className="col-2 mt-2 d-flex align-items-center">
                <BwmCheckbox
                  name="collaboratore"
                  label={labels.PRINT_COLLABORATOR}
                  className="form-control"
                  checked={values.includeCollaborator}
                  errors={errors.includeCollaborator}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.checked, 'includeCollaborator')}
                />
              </div>
              <div className="col-3">
                {values.includeCollaborator && (
                  <div className="inputSelect">
                    <BwmSelect
                      options={arcoCollaborators}
                      name="collaboratori"
                      label={labels.COLLABORATOR}
                      className="form-control"
                      value={collaborator?.id}
                      onChange={(e) => handleCollaborator(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          </form>

          <div className="mt-2 form-relationship-button justify-content-between align-item-center p-0 pt-3">
            <button className="btn btn-outline-primary " onClick={props.onHide}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CANCEL}
            </button>

            <button onClick={handleFormSubmit} className="btn btn-primary">
              <i className="thx-floppy-disk thx-icon me-2" />
              {labels.SAVE}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
