import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { computeIsError, computeStatusText, isReceipt } from './CommunicationRow'
import getText from '../../../shared/i18n/labels'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'

import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { ElaborateCommunicationModal } from './NewElaborateCommunicationModal'

const defaultSize = (size = null) => ({
  checkbox: Utility.getSize(size, { default: '3' }),
  creationDate: Utility.getSize(size, { default: '8', lg: '10' }),
  period: Utility.getSize(size, { default: '5' }),
  communication: Utility.getSize(size, { default: '15' }),
  dispatchType: Utility.getSize(size, { default: '10' }),
  status: Utility.getSize(size, { default: '50', lg: '30', md: '40' }),
  download: Utility.getSize(size, { default: '10', lg: '13' })
})

const getTableContainerWidth = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return '160%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '130%'
  return '100%'
}

const getTableContainerHeight = () => {
  if (window.innerWidth < 1200) return '62vh'
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return '68vh'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '68vh'
  return '68vh'
}

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export function CommunicationHistoryList(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [allChecked, setAllChecked] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(true)
  const [checkedCommunications, setCheckedCommunications] = useState(new Map())

  const [sizesClass, setSizesClass] = useState(getSize())

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const filterReceipts = () => {
    return props.communications.filter((c) => isReceipt(c) === false)
  }

  //rivaluto la flag del bottone di eliminazione, e la flag di check delle righe
  useEffect(() => {
    if (checkedCommunications.size > 0) {
      setDisableDeleteButton(false)
    } else {
      setDisableDeleteButton(true)
    }
    setAllChecked(
      checkedCommunications.size === filterReceipts().length && checkedCommunications.size > 0
    )
  }, [checkedCommunications])

  //resetto la lista di comunicazioni selezionate al ricaricamento della lista
  useEffect(() => {
    setCheckedCommunications(new Map())
  }, [props.communications])

  const handleAllChecked = (e) => {
    let checkedComms = new Map()
    if (e.target.checked) {
      filterReceipts().forEach((element) => {
        //le comunicazioni con ricevuta non sono eliminabili
        checkedComms.set(element.id, element)
      })
    }
    setCheckedCommunications(checkedComms)
  }

  const handleCheckChange = (e, communication) => {
    let checkedComms = new Map(checkedCommunications)
    if (e.target.checked) {
      checkedComms.set(communication.id, communication)
    } else {
      checkedComms.delete(communication.id)
    }
    setCheckedCommunications(checkedComms)
  }

  const renderRowHeader = () => {
    return (
      <>
        <div className={`${sizesClass.checkbox} text-center div-td`}>
          <input type="checkbox" checked={allChecked} onChange={(e) => handleAllChecked(e)} />
        </div>
        <div className={`${sizesClass.creationDate} div-td`}>{labels.CREATION_DATE}</div>
        <div className={`${sizesClass.period} div-td`}>{labels.PERIOD}</div>
        <div className={`${sizesClass.communication} div-td`}>{labels.COMMUNICATION}</div>
        <div className={`${sizesClass.dispatchType} div-td`}>{labels.DISPATCH_TYPE}</div>
        <div className={`${sizesClass.status} div-td`}>{labels.STATUS}</div>
        <div className={`${sizesClass.download} div-td`}>{labels.DOWNLOAD}</div>
      </>
    )
  }

  const renderReelaborateCommunicationButton = () => {
    return (
      <ElaborateCommunicationModal
        subjectId={props.subjectId}
        downloadTxt={(communication) => props.downloadTxt(communication)}
        downloadPecTz={(communication) => props.downloadPecTz(communication)}
        downloadDgn={(communication) => props.downloadDgn(communication)}
        downloadPdf={(communication) => props.downloadPdf(communication)}
        downloadErrorReport={(communication) => props.downloadErrorReport(communication)}
      />
    )
  }

  const renderRows = (communications = []) => {
    if (!communications?.length > 0) {
      return (
        <div className={'empty-state'} style={{ height: 'unset', width: '98%' }}>
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon bg-primary"
            style={{ width: '56px', height: '56px' }}
          />
          <h3 className="mt-3">{labels.NO_COMMUNICATION_HISTORY_FOUND}</h3>
        </div>
      )
    }
    return communications.map((communication, index) => {
      let status = computeStatusText(communication)
      let receipt = isReceipt(communication)
      let deletable = receipt || Utility.isEmpty(communication?.pecSentDate)
      let created =
        communication.communicationStatus === CommunicationStatus.CREATED ||
        communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
      let isError = computeIsError(communication.communicationStatus)
      let canDownloadPecat =
        communication.communicationStatus === CommunicationStatus.CREATED ||
        communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED ||
        communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR

      const isChecked = checkedCommunications.has(communication.id)

      return (
        <div
          key={`key-comm-history-${communication.id}`}
          className={`${index % 2 !== 0 ? 'bg-color' : ''} text-start row-table row-table-without-click`}
          id={`history-row-${communication.id}`}>
          <div className={`${sizesClass.checkbox} text-center div-td`}>
            {deletable === true && (
              <input
                type="checkbox"
                aria-label="Checkbox for following text input"
                id={`history-row-check-${communication.id}`}
                name="communicationCheck"
                value={communication.id}
                checked={isChecked}
                onChange={(e) => handleCheckChange(e, communication)}
              />
            )}
          </div>
          <div className={`${sizesClass.creationDate} div-td text-truncate`}>
            {Utility.formatDateForDisplay(communication?.creationDate)}
          </div>
          <div className={`${sizesClass.period} div-td text-truncate`}>
            {communication?.referencePeriod}
          </div>
          <div className={`${sizesClass.communication} div-td text-truncate`}>
            {communication.communicationType?.code} -{communication?.communicationType?.description}
          </div>
          <div className={`${sizesClass.dispatchType} div-td text-truncate`}>
            {communication?.dispatchType === 'ORDINARY' ? labels.ORDINARY : labels.EXTRAORDINARY}
          </div>
          <div
            data-toggle="tooltip"
            data-placement="top"
            title={status}
            className={`${sizesClass.status} div-td text-truncate ${isError && 'color-red'}`}>
            {status}
          </div>
          <div
            className={`${sizesClass.download} div-td text-truncate d-flex justify-content-around containerPopOver`}>
            {(created || receipt) && (
              <span
                className="me-2"
                data-tip={labels.SEND_COMMUNICATION}
                onClick={() => props.openEmailModal(communication)}>
                <i className="thx-envelope thx-icon bg-primary" />
              </span>
            )}
            {receipt && (
              <span
                className="me-2"
                data-tip={labels.DOWNLOAD_RECEIPT}
                onClick={() => props.downloadReceiptTxt(communication)}>
                <i className="thx-text-file thx-icon bg-primary" />
              </span>
            )}
            <span
              className="me-2"
              data-tip={labels.DOWNLOAD_COMMUNICATION_TXT}
              onClick={() => props.downloadTxt(communication)}>
              <i className="thx-text-file thx-icon bg-primary" />
            </span>
            {canDownloadPecat && (
              <div
                className="me-2"
                data-tip={labels.DOWNLOAD_COUPLE_FILE}
                onClick={() => props.downloadPecTz(communication)}>
                <i className="thx-zip thx-icon bg-primary" />
              </div>
            )}
            {communication.communicationStatus !== CommunicationStatus.ERROR_SID &&
              !communication.elaboratedTxt && (
                <span
                  className="me-2"
                  data-tip={labels.DOWNLOAD_PRINT_COMMUNICATION_PDF}
                  onClick={() => props.downloadPdf(communication)}>
                  <i className="thx-pdf thx-icon bg-primary" />
                </span>
              )}
            <span
              data-tip={labels.REELABORATE_TXT_FILE}
              onClick={() => props.reelaborateCommunicationTxt(communication)}>
              <i className="thx-rotate-right thx-icon bg-primary" />
            </span>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      {props.hasRowsToShow ? (
        <>
          <div className="row header-btn-history">
            <div
              className={`${window.innerWidth > 1100 ? 'col-9' : 'col-12 '} d-flex align-items-center`}>
              {labels.COMMUNICATION_HISTORY}
              {props.renderDeleteButton(disableDeleteButton, checkedCommunications)}
              <div className="ps-2">{props.SearchBar()}</div>
            </div>
            <div
              className={`d-flex ${window.innerWidth > 1100 ? 'col-3 justify-content-end' : 'justify-content-start mt-2'}`}>
              {renderReelaborateCommunicationButton()}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col d-flex justify-content-center">
              {renderReelaborateCommunicationButton()}
            </div>
          </div>
        </>
      )}
      <div className="star-table-recipient mt-2">
        <div
          className="star-table"
          style={{ width: getTableContainerWidth(), height: getTableContainerHeight() }}>
          <div className="text-start header-table">{renderRowHeader()}</div>
          {renderRows(props.communications)}
        </div>
      </div>
    </>
  )
}
