import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isNotEmpty } from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import { searchCoraSubjects } from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// TABLE
import { StarTable } from '../../../shared/tables/StarTable'
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'

// FORM
import { CoraSubjectForm } from './NewCoraSubjectForm'

// COMPONENTS
import { LicenseExpiredPopup } from '../../../shared/auth/license/LicenseExpiredPopup'
import { PopupError } from '../../../shared/popups/PopupError'

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '150%'
  return '100%'
}

export function CoraSubjectList(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [defaultEmail, setDefaultEmail] = useState('')
  const [id, setId] = useState()
  const history = useHistory()

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => {
      return { name: location.location, key: index }
    })
  )

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    fiscalCode: null,
    denomination: null,
    location: null,
    province: null,
    sidNumber: null,
    sidExpiration: null,
    sidEmail: null,
    version: 1
  })

  useEffect(() => {
    getCurrentUser()
  }, [])

  const getCurrentUser = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get(`/api/common/users/current`).then((res) => {
      if (isNotEmpty(res)) {
        setDefaultEmail(res.data.email)
      }
    })
  }

  const tableUtils = TableUtils
  const tableName = 'cora-subject'

  const header = [
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      maxWidth: '190px',
      displayedName: labels.FISCAL_CODE
    }),
    tableUtils.composeHeader({
      fieldName: 'denomination',
      orderingName: 'completeName',
      maxWidth: '460px',
      displayedName: labels.LEGAL_NAME
    }),
    tableUtils.composeHeader({
      fieldName: 'location',
      minWidth: '200px',
      displayedName: labels.LEGAL_ADDRESS
    }),
    tableUtils.composeHeader({
      fieldName: 'province',
      displayedName: labels.PROVINCE,
      maxWidth: '100px'
    }),
    tableUtils.composeHeader({
      fieldName: 'sidNumber',
      maxWidth: '130px',
      displayedName: labels.SID_NUMBER
    }),
    tableUtils.composeHeader({
      fieldName: 'sidExpiration',
      type: fieldTypes.DATE,
      maxWidth: '180px',
      displayedName: labels.SID_EXPIRATION
    }),
    tableUtils.composeHeader({
      fieldName: 'sidEmail',
      maxWidth: '350px',
      displayedName: labels.SID_EMAIL
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          denomination: tableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: c.completeName
          }),
          location: tableUtils.composeCell({
            fieldName: 'location',
            fieldValue: c.location
          }),
          province: tableUtils.composeCell({
            fieldName: 'province',
            fieldValue: c.province,
            minWidth: '200px'
          }),
          sidNumber: tableUtils.composeCell({
            fieldName: 'sidNumber',
            fieldValue: c.sidNumber
          }),
          sidExpiration: tableUtils.composeCell({
            fieldName: 'sidExpiration',
            fieldValue: c.sidExpiration
          }),
          sidEmail: tableUtils.composeCell({
            fieldName: 'sidEmail',
            fieldValue: c.sidEmail
          })
        }
      })
    })
  }

  const openDetail = (subject) => {
    const couldSendPec = subject?.coraSubjectSid?.couldSendPec
    const isDelayedCommunicationDateSet = isNotEmpty(props.delayedCommunicationDate)
    const cannotSendPec = couldSendPec !== true && isDelayedCommunicationDateSet === true
    const disableLink = cannotSendPec || props.disabled
    if (cannotSendPec) {
      const name =
        subject.personType === Constants.PNF
          ? subject.companyName
          : subject.lastName + ' ' + subject.firstName
      PopupError({
        text: `${labels.PEC_NOT_CONFIGURED_FOR_THE_SUBJECT}</br>${name}</br> ${labels.COMPLETE_THE_CONFIGURATION_OR_DELETE_THE_DELAYED_SENDING_DATA_TO_CONTINUE}.`
      })
      return
    }
    if (props.disabled) {
      LicenseExpiredPopup({})
      return
    }
    const path = disableLink
      ? '#'
      : {
          pathname: `/app/cora/relationship/${subject.id}`,
          state: { licenseExpired: props.disabled }
        }
    history.push(path)
  }

  useEffect(() => {
    setRows(prepareRows(props.subjects))
    setId(id ? id : props.subjects?.[0]?.id)
  }, [props.subjects])

  useEffect(() => {
    setId(props.id)
  }, [props.id])

  useEffect(() => {
    setId()
  }, [props.resetForm])

  const renderTable = () => {
    return (
      <StarTable
        id={id}
        headerColumns={header}
        checkedItems={props.checkedItems}
        rows={rows}
        offset={window.innerWidth > Constants.BREAKPOINT_MD ? '470' : '480'}
        tableContainerWidth={getTableContainerWidth()}
        loading={show}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => searchCoraSubjects(searchObject)}
        onExecutedSearch={(content) => {
          setId()
          return props.setSubjects(content)
        }}
        usePagination={true}
        setId={setId}
        withCheckBoxes={true}
        onDoubleClickRow={(row) => openDetail(row)}
        onClickCheck={props.setCheckedItems}
        actionsComponent={props.actionsComponent}
        formComponent={CoraSubjectForm}
        formComponentProps={{
          disabled: props.disabled || loading,
          subjectId: id,
          defaultEmail,
          formOnBottom: true,
          locations,
          setLoading: setLoading,
          addOrUpdateCoraSubject: props.addOrUpdateCoraSubject,
          close: () => setId(id ? id : undefined)
        }}
      />
    )
  }

  return <>{renderTable()}</>
}
