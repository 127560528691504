import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Utility from '../../../shared/Utility'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'
import { getLatestConsistency, importFromLatestConsistency } from '../../../../actions'
import getText from '../../../shared/i18n/labels'
// MODAL
import { Modal } from 'react-bootstrap'

// FORM
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { BtnFill } from '../../../shared/BtnFill'

// COMPONENTS
import RowSpinner from '../../../shared/spinner/Spinner'
import { InfoBox } from '../../../shared/InfoBox'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'
import { isValid } from 'date-fns'

export default function ImportConsitencyRelationshipsForm({ show = false, onHide, onSuccess }) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const subject = useSelector((state) => state.subject.data)
  const subjectId = subject?.id
  const defaultEmail = subject?.customer?.user?.login
  const [updateExisting, setUpdateExisting] = useState(false)
  const [latestConsistency, setLatestConsistency] = useState(null)
  const [exportEmail, setExportEmail] = useState()
  const [errorExportEmail, setErrorExportEmail] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isElaborating, setIsElaborating] = useState(false)

  useEffect(() => {
    changeExportEmail(defaultEmail)
    getLatestConsistency(subjectId)
      .then(
        (res) => {
          if (res) {
            setLatestConsistency(res)
          }
        },
        (err) => {
          console.log(err)
        }
      )
      .then(() => setIsLoading(false))

    return () => {}
  }, [defaultEmail, subjectId])

  const validate = (_exportEmail) => {
    if (Utility.isEmpty(_exportEmail) || !Utility.testEmail(_exportEmail)) {
      setErrorExportEmail({
        isValid: false,
        msg: labels.INSERT_A_VALID_EMAIL_FOR_THE_EXPORT
      })
      return false
    }
    setErrorExportEmail({ isValid: true, msg: '' })
    return true
  }

  const changeExportEmail = (_exportEmail) => {
    validate(_exportEmail)
    setExportEmail(_exportEmail)
  }

  const onConfirm = () => {
    if (validate(exportEmail)) {
      setIsElaborating(true)
      importFromLatestConsistency(subjectId, exportEmail, updateExisting)
        .then(
          (res) => {
            Utility.downloadFile(res, `IMPORT - ${latestConsistency?.txtFilename}`)
            PopupSuccess()
            //ricaricare rapporti
            onSuccess()
          },
          (err) => {
            if (err?.errorKey === 'import-error') {
              PopupError({ text: err.title })
            } else {
              PopupError({ text: APPLICATION_GENERIC_ERROR })
            }
          }
        )
        .then(() => setIsElaborating(false))
    }
  }

  const renderInfoBox = () => {
    return (
      <InfoBox
        simplePointsArr={[
          {
            title: labels.INFO,
            text: labels.IMPORT_DATA_FROM_THE_LATEST_CONSISTENCY
          },
          {
            title: labels.UPDATE_EXISTING_RELATIONSHIPS,
            text: labels.UPDATE_EXISTING_RELATIONSHIPS_TEXT
          }
        ]}
      />
    )
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        size="lg"
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onHide}
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <h3>{labels.IMPORT_REPORTS_FROM_THE_LATEST_CONSISTENCY_TITLE}</h3>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <RowSpinner />}
          {isElaborating && <PageSpinner />}
          <div className="row">
            {Utility.isNotEmpty(latestConsistency) ? (
              <div className="col">
                <div className="row">
                  <h5>
                    {labels.FILE_NAME}: {latestConsistency.txtFilename}
                  </h5>
                </div>
                <div className="row">
                  <h5>
                    {labels.ELABORATED_IN_DATE}:
                    {Utility.formatDateTimeForDisplay(latestConsistency.creationDate)}
                  </h5>
                </div>
                <div className="row mt-2">
                  <div className={'d-flex flex-1 flex-column justify-content-center '} lg={6}>
                    <div className="pb-3">
                      <BwmCheckbox
                        name={`update-existing`}
                        label={labels.UPDATE_EXISTING_RELATIONSHIPS}
                        className="form-control"
                        checked={updateExisting}
                        onChange={(e) => setUpdateExisting(e.target.checked)}
                      />
                    </div>
                    <div className="row">
                      <BwmInput
                        label={labels.EMAIL_ADDRESS_TO_SEND_THE_EXPORT}
                        type={'email'}
                        className="form-control"
                        showErrorMessage={true}
                        value={exportEmail?.toLowerCase()}
                        error={errorExportEmail?.isValid === false ? errorExportEmail : undefined}
                        touched={true}
                        onChange={(e) => changeExportEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-3">{renderInfoBox()}</div>
                </div>
              </div>
            ) : (
              <>
                <h5>{labels.NO_LATEST_CONSISTENCY_FOUND}</h5>
                {renderInfoBox()}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={onHide} />
          {Utility.isNotEmpty(latestConsistency) && (
            <BtnFill
              disabled={errorExportEmail?.isValid === false}
              text={labels.CONFIRM}
              classCustom="float-end text-end"
              handlerOnClick={onConfirm}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
