import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isBefore } from 'date-fns'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

// MODAL
import { Modal } from 'react-bootstrap'

// FORM
import { DateField } from '../../../shared/form/DateFieldB5'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { BtnFill } from '../../../shared/BtnFill'

export function DelayedCommunicationModal({
  show = false,
  licenseExpiration,
  onHide = () => {},
  onConfirm = (date) => {}
}) {
  const [dateLocal, setDateLocal] = useState(undefined)
  const [error, setError] = useState('')
  const [touched, setTouched] = useState(false)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  useEffect(() => {
    setError(getError(dateLocal))
    setTouched(true)
  }, [dateLocal])

  const isDateAfterToday = (date) => {
    if (isNotEmpty(date)) {
      return new Date(date) > new Date()
    }
    return true
  }

  const getError = (date) => {
    const isAfterToday = isDateAfterToday(date)
    const isBeforeLicenseExpiration = isEmpty(date)
      ? true
      : isBefore(new Date(date), new Date(licenseExpiration))

    if (!isAfterToday) {
      return labels.SELECT_DATE_AFTER_TODAY
    }
    if (!isBeforeLicenseExpiration) {
      return labels.LICENSE_EXPIRATION_BEFORE_SELECTED_DATE
    }
  }

  return (
    <Modal
      centered
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onHide}>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title>{labels.SET_DELAYED_SENDING}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-3 h-100">
          <div className="col-12" style={{ fontSize: '16px' }}>
            {labels.SET_DELAYED_SENDING_DESCRIPTION}
            <br />
            {labels.SET_DELAYED_SENDING_DESCRIPTION_2}
          </div>

          <div className="col-12">
            <div className="mt-3">
              <DateField
                label={`${labels.DATE_OF_SENDING}*`}
                date={dateLocal}
                error={error}
                touched={touched}
                onChange={(value) => setDateLocal(value)}
              />
              <span className="text-danger">{error}</span>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row align-items-center justify-content-between">
          <BtnEmpty classCustom="col" text={labels.CANCEL} handlerOnClick={onHide} />
          <BtnFill
            classCustom="col d-flex justify-content-end"
            text={labels.CONFIRM}
            disabled={isEmpty(dateLocal) || isNotEmpty(error)}
            handlerOnClick={() => onConfirm(dateLocal)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}
