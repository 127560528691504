import {
  BLUE_GET_EVENTS_SUCCESS,
  BLUE_GET_SUBJECTS_SUCCESS,
  BLUE_GET_SUBJECT_SUCCESS
} from '../../actions/types'
const INITIAL_STATE = {
  blueSubjects: {
    data: [],
    errors: []
  },

  blueSubject: {
    data: null,
    error: []
  },

  blueSearchEvents: {
    data: [],
    errors: []
  }
}

export const blueSubjectsReducer = (state = INITIAL_STATE.blueSubjects, action) => {
  if (action.type === BLUE_GET_SUBJECTS_SUCCESS) {
    return { ...state, data: action.blueSubjects }
  }
  return state
}

export const blueSubjectReducer = (state = INITIAL_STATE.blueSubject, action) => {
  if (action.type === BLUE_GET_SUBJECT_SUCCESS) {
    return { ...state, data: action.blueSubject }
  }
  return state
}

export const blueSearchEventsReducer = (state = INITIAL_STATE.blueSearchEvents, action) => {
  if (action.type === BLUE_GET_EVENTS_SUCCESS) {
    return { ...state, data: action.blueSearchEvents }
  }
  return state
}
