import { isValid } from 'date-fns'
import Flatpickr from 'react-flatpickr'
import { useSelector } from 'react-redux'
import { Italian } from 'flatpickr/dist/l10n/it'
import { english } from 'flatpickr/dist/l10n/default'
import 'flatpickr/dist/flatpickr.css'

export const DateField = (props) => {
  const { label, date, disabled, onChange, customOptions = {} } = props

  const lang = useSelector((state) => state.language.language)

  const inputWrapperClass = `input-wrapper w-100 ${props.touched && props.error !== undefined ? 'has-error' : ''}`

  return (
    <>
      <div key={`date-picker-${disabled}`} className={inputWrapperClass}>
        <div className="form-group">
          {label && <span className="floatingLabel-datepickr">{label}</span>}
          <div className="input-group">
            <Flatpickr
              className={`form-control form-date ${props.className || ''}`}
              disabled={disabled || false}
              placeholder={lang === 'it' ? 'gg/mm/aaaa' : 'dd/mm/yyyy'}
              options={{
                maxDate: '2500-01-01',
                allowInput: true,
                dateFormat: 'd/m/Y',
                language: lang === 'it' ? Italian : english,
                locale: lang,
                ...customOptions
              }}
              value={date !== null && isValid(new Date(date)) ? date : ''}
              onChange={([date]) => onChange(date)}
              onClose={([date]) => onChange(date)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
