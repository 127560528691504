import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import authService from '../../services/auth-service'
import { getCurrentApplicationName } from './Utility'
import { FAKE_USER } from '../../config/Constants'
import getText from './i18n/labels'
// APPS
import starMulticolor from '../../styles/images/star_multicolor.png'
import menuOpen from '../../styles/images/menu_open.png'
import arrowL from '../../styles/images/arrow_l.png'
import crsPositive from '../../styles/images/app/positive/Crs.png'
import coraPositive from '../../styles/images/app/positive/Cora.png'
import comureiPositive from '../../styles/images/app/positive/Comurei.png'
import corproPositive from '../../styles/images/app/positive/Corpro.png'
import arcoPositive from '../../styles/images/app/positive/Arco.png'
import bluePositive from '../../styles/images/app/positive/Blue.png'
import facciamoNoiLogo from '../../styles/images/app/negative/FacciamoNoi.png'

// COMPONENTS
import AppBox from './AppBox'
import { CustomerBox } from './CustomerBox'
import { Overlay } from 'react-bootstrap'
import LanguageSelector from './i18n/LanguageSelector'

const Header = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showHeader, setShowHeader] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [hasAppBox, setHasAppBox] = useState(false)
  const [fakeUser, setFakeUser] = useState(undefined)
  const [showAppBox, setShowAppBox] = useState(false)
  const [showCustomerBox, setShowCustomerBox] = useState(false)

  const customerBoxButtonRef = useRef(null)
  const appBoxButtonRef = useRef(null)

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const selectedLogin = queryParameters.get('asUser')
    if (selectedLogin) {
      authService.setHeader(FAKE_USER, selectedLogin)
    }
  }, [])

  useEffect(() => {
    const updateState = () => {
      setShowHeader(location.pathname !== '/login' && location.pathname !== '/')
      setIsAdmin(auth?.isAdmin)
      setIsLoggedIn(auth?.isAuth)
      setHasAppBox(
        ['cora', 'comurei', 'crs', 'corpro', 'arco', 'blue'].some((e) =>
          location.pathname.includes(e)
        )
      )
      setFakeUser(authService.getHeader(FAKE_USER))
    }

    updateState()
  }, [location, auth])

  const handleClickMenuApp = () => {
    if (location.pathname !== '/dashboard') {
      setShowAppBox((prev) => !prev)
    }
  }

  const handleClickMenuCustomer = () => {
    setShowCustomerBox((prev) => !prev)
  }

  const logout = () => {
    setShowCustomerBox(false)
    authService.deleteCookie()
    dispatch({ type: 'LOGOUT' }) // Adjust based on your redux setup
  }

  const renderBrand = (title) => {
    switch (getCurrentApplicationName()) {
      case 'cora':
        return renderNavbar(coraPositive, 'CORA', title)
      case 'comurei':
        return renderNavbar(comureiPositive, 'COMUNEI', title)
      case 'crs':
        return renderNavbar(crsPositive, 'CRS', title)
      case 'corpro':
        return renderNavbar(corproPositive, 'CORPRO', title)
      case 'arco':
        return renderNavbar(arcoPositive, 'ARCO', title)
      case 'blue':
        return renderNavbar(bluePositive, 'BLUE', title)
      case 'facciamo-noi':
        return renderNavbar(facciamoNoiLogo, 'FACCIAMONOI', title)
      default:
        return (
          <ul className="navbar-nav">
            <li className="nav-item">
              {hasAppBox && (
                <div className="m-auto">
                  <img src={menuOpen} alt={`${title}`} width="28" height="18" />
                </div>
              )}
              <Link className="navbar-brand logo-dashboard" to="/">
                <img src={starMulticolor} alt={`${title}`} width="181" />
              </Link>
            </li>
          </ul>
        )
    }
  }

  const renderNavbar = (logo, altText, title) => (
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link className="navbar-brand logo-app" to="/">
          <img src={starMulticolor} alt={`${title}`} width="181" />
        </Link>
        <div className="m-auto">
          <div className="vertical-line me-3 "></div>
        </div>
        <div className="m-auto">
          <img src={logo} alt={altText} width="160" />
        </div>
        {hasAppBox && (
          <div className="m-auto margin-left-2-percent" role="button" onClick={handleClickMenuApp}>
            <img src={menuOpen} alt={`${title}`} width="28" height="18" ref={appBoxButtonRef} />
          </div>
        )}
        <Overlay target={appBoxButtonRef.current} show={showAppBox} placement={'bottom'}>
          {({ ...props }) => (
            <div className="app-box-overlay" {...props}>
              <AppBox mouseLeave={handleClickMenuApp} />
            </div>
          )}
        </Overlay>
      </li>
    </ul>
  )

  const renderOwnerSection = (user) => {
    if (isLoggedIn && user) {
      const displayName = user.firstName
        ? `${user.firstName} ${user.lastName || ''}`
        : user.customer?.companyName

      return (
        <>
          <LanguageSelector />
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link nav-item clickable"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.starsoluzioni.it/assistenza-remota/">
                <button type="button" className="btn btn-light btn-xs">
                  {labels.ASSISTANCE}
                </button>
              </a>
            </li>
            <li className="nav-item">
              <div className="dropdown-customer">
                <a
                  className="nav-link nav-item clickable"
                  onClick={handleClickMenuCustomer}
                  ref={customerBoxButtonRef}>
                  <span className="notranslate">{displayName}</span>
                  <img src={arrowL} width={10} height={18} className="rotate-down" />
                </a>
              </div>

              <Overlay
                target={customerBoxButtonRef.current}
                show={showCustomerBox}
                placement={'bottom'}>
                {({ ...props }) => (
                  <div className="customer-box-overlay" {...props}>
                    <CustomerBox logout={logout} mouseLeave={handleClickMenuCustomer} />
                  </div>
                )}
              </Overlay>
            </li>
          </ul>
        </>
      )
    }
  }

  if (showHeader) {
    return (
      <nav id="header" className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid p-0">
          <div id="navbarContent">
            {renderBrand('StarSoluzioni')}
            {isAdmin && !fakeUser && !location.pathname.endsWith('admin') && (
              <Link
                id="admin"
                className="nav-link active text-white btn-dashboard-admin"
                to="/admin">
                <button type="button" className="btn btn-light">
                  DASHBOARD ADMIN
                </button>
              </Link>
            )}
            {fakeUser && (
              <>
                <span>
                  <strong className="pulse p-2 text-light">
                    {labels.LOGGED_IN_AS} {fakeUser}
                  </strong>
                </span>
                <div className="d-flex me-4">
                  <div className="me-4">
                    <LanguageSelector />
                  </div>
                  <Link
                    className="nav-link active text-white"
                    onClick={() => authService.setHeader(FAKE_USER, '')}
                    to={'/admin'}>
                    <button type="button" className="btn btn-light border-round">
                      <span>{labels.LOGOUT}</span>
                      <i className="thx-icon thx-log-out ms-2 text-primary" />
                    </button>
                  </Link>
                </div>
              </>
            )}
            {!fakeUser && <ul className="navbar-nav">{renderOwnerSection(auth.user)}</ul>}
          </div>
        </div>
      </nav>
    )
  }

  return null
}

export default Header
