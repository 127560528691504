import { useSelector } from 'react-redux'
import getText from '../../../../shared/i18n/labels'
import { CommunicationStatus } from '../../../../../config/CommunicationStatus'

/**
 * Genera un messaggio che identifica lo stato del file analizzato
 * @param {*} props
 * @returns
 */
export default function GenerateStatus(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const dispatchType = props.dispatchType
  const communicationStatus = props.communicationStatus
  const communicationError = props.communicationError
  const isConsistencyReceipt = dispatchType === 'CONSISTENCY_RECEIPT'
  let status = ''
  let color = ''

  if (communicationStatus === CommunicationStatus.CREATED) {
    status = labels.CREATED + ' - ' + labels.WAITING_FOR_RECEIPT
  } else if (communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
    status = labels.CREATED_WITH_ERROR
  } else if (communicationStatus === CommunicationStatus.ERROR_SID) {
    status = labels.RECEIPT_NOT_PROCESSED + ': ' + communicationError
    color = 'red'
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
    if (isConsistencyReceipt) {
      status = (
        <>
          {labels.PHOTOGRAPH_PROCESSED} <br></br>
          {communicationError}
        </>
      )
    } else {
      status =
        labels.RECEIPT_PROCESSED +
        ': ' +
        labels.COMMUNICATION_ACCEPTED +
        ' - ' +
        labels.CLICK_ON_THE_ICON_TO_READ_THE_CONTENT
    }
  } else if (communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
    if (isConsistencyReceipt) {
      status = communicationError
      color = 'red'
    } else {
      status = labels.RECEIPT_PROCESSED + ': ' + labels.COMMUNICATION_REJECTED
    }
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR) {
    if (isConsistencyReceipt) {
      status = (
        <>
          {labels.PHOTOGRAPH_PROCESSED_WITH_DISCREPANCY}
          <br></br> {communicationError}
        </>
      )
    } else {
      status =
        labels.RECEIPT_PROCESSED_WITH_ERRORS + ': ' + labels.CLICK_ON_THE_ICON_TO_READ_THE_CONTENT
    }
  }
  return <strong style={{ color: color }}>{status}</strong>
}
