import { GET_LOCATION_BY_CITY_SUCCESS, GET_LOCATIONS_SUCCESS } from '../../actions/types'
import { GET_LOCATION_BY_CITY_FAILURE } from '../../actions/types'

const INITIAL_STATE = {
  location: {
    data: [],
    errors: []
  },
  locations: {
    data: [],
    errors: []
  }
}

export const locationReducer = (state = INITIAL_STATE.location, action) => {
  switch (action.type) {
    case GET_LOCATION_BY_CITY_SUCCESS:
      return { ...state, data: action.location }
    case GET_LOCATION_BY_CITY_FAILURE:
      return { ...state, data: action.location }
    default:
      return state
  }
}

export const locationsReducer = (state = INITIAL_STATE.locations, action) => {
  switch (action.type) {
    case GET_LOCATIONS_SUCCESS: {
      let customLocations = action.locations.map((location, index) => {
        return {
          name: location.location,
          key: index,
          province: location.province,
          postalCode: location.postalCode
        }
      })
      return { ...state, data: action.locations, customLocations: customLocations }
    }
    default:
      return state
  }
}
