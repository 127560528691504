import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import * as Constants from '../../../../config/Constants'
import { formatDateForDisplay } from '../../../shared/Utility'
import * as actions from '../../../../actions'
import getText from '../../../../components/shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// TABLE
import { ArcoRegistriesList } from './NewArcoRegistriesList'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

export function ArcoRegistryHolders(props) {
  const [showModal, setShowModal] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)

  const [holders, setHolders] = useState(props.holders || [])

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  useEffect(() => {
    setHolders(props.holders || [])
  }, [props.holders])

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  const addRegistry = (registry) => {
    if (registry) {
      if (Constants.PF === registry.personType) {
        props.addHolder(registry)
        handleShowModal()
      } else {
        PopupError({ text: labels.ERROR_ADD_HOLDER })
      }
    }
  }

  const deleteArcoArcoRegistryHoldersSubject = () => {
    if (props?.selectedItem?.id) {
      actions.deleteArcoRegistriesHolders(props.selectedItem.id, Object.keys(checkedItems)).then(
        () => {
          resetCheckBox()
          //chiudo form se contiene l'elemento cancellato
          props.removeHolder(Object.keys(checkedItems))
        },
        (errors) => {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
      )
    } else {
      props.removeHolder(Object.keys(checkedItems))
      resetCheckBox()
    }
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRows = cloneDeep(holders)
      newRows.forEach((row) => (items[row.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === holders?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const renderRows = () => {
    if (!holders?.length > 0) {
      return (
        <div className="col-12 ps-0 pe-0 empty-state-history-modal py-2">
          <td>
            <i
              className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
              style={{ width: '30px', height: '30px' }}
            />
            <h6>{labels.NORESULTS}</h6>
          </td>
        </div>
      )
    }
    return holders?.map((item, index) => {
      return (
        <div key={index} className=" text-start row-table" id={`holder-${item.id}`}>
          <div className="w-5 text-center div-td">
            <input
              type="checkbox"
              id={`checkbox_${item.id || ''}`}
              checked={checkedItems[item.id] || false}
              onChange={(e) => handleCheckChange(e, item.id)}
            />
          </div>

          <div
            className="w-20 div-td text-truncate"
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.firstName.toUpperCase()}
          </div>
          <div
            className="w-20 div-td text-truncate"
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.lastName.toUpperCase()}
          </div>
          <div
            className="w-12 div-td text-truncate"
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.fiscalCode}
          </div>
          <div
            className="w-10 div-td text-truncate"
            onClick={() => props.openChildRegistryModal(item)}>
            {formatDateForDisplay(item?.birthDate)}
          </div>
          <div
            className="w-25 div-td text-truncate"
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.location.toUpperCase()}
          </div>
          <div
            className="w-5 div-td text-truncate"
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.province.toUpperCase()}
          </div>
        </div>
      )
    })
  }

  return (
    <div className="col-12">
      <div className="row pb-2 pt-2">
        <div className="col-9">
          <div className="btn-group" role="group" aria-label="action buttons">
            <button
              type="button"
              disabled={Object.keys(checkedItems)?.length === 0 || props.disabled}
              className="btn btn-outline-primary"
              onClick={() => deleteArcoArcoRegistryHoldersSubject()}>
              <i className="thx-trash thx-icon me-2" />
              {labels.DELETE}
            </button>
            <button onClick={handleShowModal} disabled={props.disabled} className="btn btn-primary">
              <i className="thx-plus thx-icon me-2" />
              {labels.CONNECT_REGISTRY}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 w-all star-table">
        <div className="text-start header-table p-0">
          <div className="w-5 text-center div-td">
            <input
              disabled={!(holders?.length > 0)}
              name="tableCheckBox"
              type="checkbox"
              checked={allCheck}
              onClick={(e) => handleAllCheckChange(e)}
            />
          </div>
          <div className="w-20 div-td">{labels.FIRST_NAME}</div>
          <div className="w-20 div-td">{labels.LAST_NAME}</div>
          <div className="w-12 div-td">{labels.FISCAL_CODE}</div>
          <div className="w-10 div-td">{labels.BIRTH_DATE}</div>
          <div className="w-25 div-td">{labels.LOCATION}</div>
          <div className="w-5 div-td">{labels.PROVINCE}</div>
        </div>
        {renderRows()}
      </div>
      {showModal && (
        <div>
          <ArcoRegistriesList
            modalShow={false}
            arcoSubjectId={props.arcoSubjectId}
            arcoSubject={props.arcoSubject}
            showModalRegistriesList={showModal}
            onHide={handleShowModal}
            fromHolders={props.selectedItem}
            fromRegistry={props.selectedItem}
            onSelectedItem={addRegistry}
          />
        </div>
      )}
    </div>
  )
}
