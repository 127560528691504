import { useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { isEmpty } from '../../../shared/Utility'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'
import { CommunicationModal } from './CommunicationModal'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'

export function ElaborateCommunicationModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [communicationFile, setCommunicationFile] = useState('')
  const [errorCommunication, setErrorCommunication] = useState({ isValid: true, msg: '' })
  const [communication, setCommunication] = useState(undefined)
  const [sentCommunications, setSentCommunications] = useState([])

  const closeModal = () => {
    setLoading(false)
    setCommunication(undefined)
    setCommunicationFile('')
    setShowModal(false)
    setSentCommunications([])
  }

  const openModal = () => {
    setShowModal(true)
  }

  const handleUploadCommunication = (val) => {
    if (isEmpty(val)) {
      setErrorCommunication({ isValid: false, msg: labels.ERROR_NECESSARY_FILE })
    } else {
      const file = val.target.files[0]
      setCommunicationFile(file)
      if (val.target.files.length !== 1) {
        setErrorCommunication({ isValid: false, msg: labels.ERROR_SINGLE_COMMUNICATION })
      } else if (!checkFileName(file.name)) {
        setErrorCommunication({ isValid: false, msg: labels.ERROR_WRONG_FILE_EXTENSION })
      } else if (file.size > 15 * 1000 * 1000) {
        setErrorCommunication({
          isValid: false,
          msg: labels.ERROR_FILE_TOO_LARGE
        })
      } else {
        setErrorCommunication({ isValid: true, msg: '' })
      }
    }
  }

  const checkFileName = (name) => {
    const ext = name.split('.').pop().toUpperCase()
    return ['TXT', 'RAR', 'ZIP', '7Z'].includes(ext)
  }

  const handleSendCommunication = () => {
    setShowModal(false)
    setLoading(true)
    const formData = new FormData()
    formData.append('dataFile', communicationFile)

    actions.elaborateCommunicationTxt(formData, props.subjectId).then(
      (communication) => {
        if (communication && communication[0]?.id) {
          setLoading(false)
          setCommunication(communication[0])
          setCommunicationFile('')
          setShowModal(true)
          const _sentCommunications = cloneDeep(sentCommunications)
          _sentCommunications.push(communication[0])
          setSentCommunications(_sentCommunications)
        } else {
          setLoading(false)
          PopupError({
            text: labels.ERROR_PROCESSING_COMMUNICATION,
            labels
          })
        }
      },
      (errors) => {
        setLoading(false)
        setCommunication(undefined)
        setCommunicationFile('')
        setShowModal(false)
        PopupError({ text: errors || APPLICATION_GENERIC_ERROR })
      }
    )
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        className={`btn btn-outline-primary`}
        data-tip={labels.ELABORATE_COMMUNICATION_TXT}
        onClick={openModal}>
        <i className="thx-text-file thx-icon me-2" />
        {labels.ELABORATE_COMMUNICATION_TXT}
      </button>
      {communication ? (
        <CommunicationModal
          showModal={showModal}
          closeModal={closeModal}
          communications={[communication]}
          coraSubject={communication.subject}
          downloadTxt={props.downloadTxt}
          downloadPecTz={props.downloadPecTz}
          downloadDgn={props.downloadDgn}
          downloadPdf={props.downloadPdf}
          downloadErrorReport={props.downloadErrorReport}
        />
      ) : (
        <Modal
          backdrop="static"
          show={showModal}
          onHide={closeModal}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>{labels.ELABORATE_COMMUNICATION_TXT}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <div className="image-upload text-start">
                <label htmlFor={`communicationFile`}>
                  <div className="certificateFiles d-flex align-items-center">
                    <span>{labels.LOAD_COMMUNICATION_FILE}</span>{' '}
                    <i className="thx-upload thx-icon ms-2" />
                  </div>
                </label>
                <input
                  id={`communicationFile`}
                  type="file"
                  name={`communicationFile`}
                  multiple
                  onChange={(e) => handleUploadCommunication(e)}
                  accept={'.txt,.zip,.rar.7z'}
                />
                <div className="certificatiLoaded text-start d-flex align-items-center">
                  {!errorCommunication.isValid ? (
                    <div className="certificatiLoaded-error">{errorCommunication.msg}</div>
                  ) : (
                    <div>
                      {communicationFile ? (
                        <div className="d-flex align-items-center">
                          <i className="thx-check thx-icon me-2" />
                          {communicationFile.name}
                        </div>
                      ) : (
                        <div>---</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty
                text={labels.CANCEL}
                classCustom="float-start"
                handlerOnClick={closeModal}
              />
              <button
                disabled={!errorCommunication.isValid || !communicationFile}
                onClick={handleSendCommunication}
                type="button"
                className="btn btn-primary">
                {labels.CONFIRM}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
