import checkbox from '../../../styles/images/checkbox-inactive.png'
import checkboxSel from '../../../styles/images/checkbox-selected.png'

export const BwmCheckbox = (props) => {
  return (
    <div>
      <div className={`input-group ${props.formClassName || ''}`}>
        <input
          hidden={true}
          type="checkbox"
          className="form-check-input"
          name={props.name}
          id={props.name}
          checked={props.checked || ''} // Control checked state via Formik
          onChange={props.onChange} // Use onChange to toggle
          disabled={props.disabled}
        />
        <label
          className="form-check-label cursor-pointer"
          htmlFor={props.name}
          style={props.labelStyle}>
          <img
            width="20"
            className="me-1"
            src={props.checked ? checkboxSel : checkbox}
            alt="checkbox"
          />
          <span>{props.label}</span>
        </label>
      </div>
      {props.error && !props.error.isValid && <div className="text-danger">{props.error.msg}</div>}
    </div>
  )
}
