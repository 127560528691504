import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// TABLES
import { ArcoTableCollaboratorsList } from './NewArcoTableCollaboratorsList'
import { ArcoTableProfessionistsList } from './NewArcoTableProfessionistsList'

// MODALS
import Modal from 'react-bootstrap/Modal'

// COMPONENTS
import { Tabs } from '../../../shared/Tabs'

import '../../../../styles/comurei-form.css'

// COMPONENTS
import { ArcoTableRiskValues } from './NewArcoTableRiskValues'
import { ArcoRiskClasses } from './NewArcoRiskClasses'

export const ArcoTablesModal = (props) => {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const arcoTableDomains = useSelector((state) => state.arcoTableDomains)
  const arcoCollaborators = useSelector((state) => state.arcoCollaborators)
  const arcoProfessionists = useSelector((state) => state.arcoProfessionists)
  const arcoDomains = useSelector((state) => state.arcoDomains)
  const [activeTab, setActiveTab] = useState('tab-risk-values')

  useEffect(() => {
    if (props.subject?.id) {
      getAllCollaboratorBySubjectId()
      getAllProfessionistBySubjectId()
      getAllArcoDomainsBySubjectId()
      dropBoxTable()
    }
  }, [props.subject?.id])

  const getAllCollaboratorBySubjectId = () => {
    dispatch(actions.getAllCollaboratorBySubjectId(props.subject.id))
  }

  const getAllProfessionistBySubjectId = () => {
    dispatch(actions.getAllProfessionistBySubjectId(props.subject.id))
  }

  const getAllArcoDomainsBySubjectId = () => {
    dispatch(actions.getAllArcoDomains(props.subject.id))
  }

  const dropBoxTable = () => {
    dispatch(actions.getArcoTableDomains())
  }

  const pbPresent = () => {
    let filtered = props.subjectTypes.filter((subjectTypes) => {
      if (subjectTypes.code === 'PB') {
        return subjectTypes.code === props.subject?.arcoSubjectType?.code
      }
    })
    return filtered.length > 0
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={props.showTableModal}
        onHide={props.closeTableModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={'arco-modal-max overflow-hidden'}>
        <div className="bar"></div>
        <Modal.Header closeButton className="pb-0">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {props.subject?.companyName} - {labels.MANAGE_TABLES}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="d-flex">
            <div className="tab-content col-12 mt-3">
              <Tabs className="pt-3" activeTab={activeTab} setActiveTab={setActiveTab}>
                <div id="tab-risk-values" label={labels.RISK_VALUES}>
                  <ArcoTableRiskValues
                    arcoTableDomains={arcoTableDomains.data}
                    riskValues={arcoDomains.domains}
                    subject={props.subject}
                    disabled={props.disabled}
                    onClose={props.closeTableModal}
                    getAllRiskValueBySubjectId={getAllArcoDomainsBySubjectId}
                  />
                </div>

                <div id="tab-risk-classes" label={labels.RISK_CLASSES}>
                  <ArcoRiskClasses
                    crDomains={arcoDomains?.crDomains}
                    subject={props.subject}
                    getAllArcoDomainsBySubjectId={getAllArcoDomainsBySubjectId}
                  />
                </div>

                <div id="tab-collaborators" label={labels.COLLABORATORS}>
                  <ArcoTableCollaboratorsList
                    collaborators={arcoCollaborators.data}
                    subject={props.subject}
                    disabled={props.disabled}
                    onClose={props.closeTableModal}
                    getAllCollaboratorBySubjectId={getAllCollaboratorBySubjectId}
                  />
                </div>

                {pbPresent() ? (
                  <div id="tab-professionists" label={labels.PROFESSIONISTS}>
                    <ArcoTableProfessionistsList
                      professionists={arcoProfessionists.data}
                      disabled={props.disabled}
                      subject={props.subject}
                      showDisabled={true}
                      onClose={props.closeTableModal}
                      getAllProfessionistBySubjectId={getAllProfessionistBySubjectId}
                    />
                  </div>
                ) : (
                  <div
                    id="tab-professionists"
                    label={labels.PROFESSIONISTS}
                    disabled="disabled"></div>
                )}
              </Tabs>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="d-flex">
            <button className="btn btn-primary px-4" onClick={props.closeTableModal}>
              <span>{labels.CLOSE}</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
