import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../../actions'
import * as Utility from '../../shared/Utility'
import * as Constants from '../../../config/Constants'
import { APPLICATION_GENERIC_ERROR } from '../../../config/Constants'
import getText from '../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { ContactHistoryForm } from './ContactHistoryForm'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { DateField } from '../../shared/form/DateField'
import { BwmSelect } from '../../shared/form/BwmSelectB5'
import { BwmInput } from '../../shared/form/BwmInputB5'
import ErrorListAlert from '../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// BUTTONS
import { BtnEmpty } from '../../shared/BtnEmpty'
import { BtnFill } from '../../shared/BtnFill'

// COMPONENTS
import { SimpleSpinner } from '../../shared/spinner/Spinner'
import { PopupError } from '../../shared/popups/PopupError'
import { PopupConfirm, titleColors } from '../../shared/popups/PopupConfirm'

import './contactInfoModal.css'

export const baseContact = { id: null, denomination: '', phone: '' }

const defaultSize = (size = null) => ({
  denomination: Utility.getSize(size, { default: '19' }),
  login: Utility.getSize(size, { default: '22' }),
  email: Utility.getSize(size, { default: '25' }),
  password: Utility.getSize(size, { default: '20' }),
  callPriority: Utility.getSize(size, { default: '14' }),
  // second row
  note: Utility.getSize(size, { default: '20' }),
  contactList: Utility.getSize(size, { default: '45' }),
  licenseList: Utility.getSize(size, { default: '35' }),
  // contactList
  _denomination: Utility.getSize(size, { default: '55' }),
  phone: Utility.getSize(size, { default: '45' }),
  // licence list
  aplicationName: Utility.getSize(size, { default: '40' }),
  licenseType: Utility.getSize(size, { default: '32' }),
  expiration: Utility.getSize(size, { default: '18' }),
  // third row
  date: Utility.getSize(size, { default: '13' }),
  time: Utility.getSize(size, { default: '10' }),
  contact: Utility.getSize(size, { default: '14' }),
  _phone: Utility.getSize(size, { default: '17' }),
  caller: Utility.getSize(size, { default: '15' }),
  notes: Utility.getSize(size, { default: '31' })
})

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth < Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const ContactInfoModal = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [initialValues, setInitialValues] = useState({
    id: null,
    date: new Date(),
    caller: props.defaultCaller,
    notes: '',
    contact: props.selectedContactNumber?.denomination,
    phone: props.selectedContactNumber?.phone
  })

  const [contactHistories, setContactHistories] = useState([])

  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.contactInfo?.id) {
      fetchContactInfo(props.contactInfo)
    } else {
      setInitialValues({ ...props.contactInfo, contactNumbers: [baseContact] })
    }
  }, [props.contactInfo])

  const fetchContactInfo = (contactInfo) => {
    setLoading(true)
    actions
      .getContactInfo(contactInfo?.id)
      .then((res) => {
        let contacts = res.contactNumbers
        if (!res.contactNumbers?.length) {
          contacts.unshift(baseContact)
        }
        setInitialValues({ ...res, contactNumbers: contacts })
        setContactHistories([...res.contactHistories])
      })
      .then(() => setLoading(false))
  }

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsMapping)
  }

  const formErrorsMapping = [
    {
      errorKey: 'comureiSubjectType.id',
      errorLabel: labels.SUBJECT_TYPE
    }
  ]

  const validationSchema = Yup.object().shape({})

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      size={'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      className="big-modal mt-0 mb-1"
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{initialValues?.denomination || initialValues?.login || labels.NEW_CONTACT}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3">
        <>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}>
            {({ errors }) => (
              <Form>
                {showErrorAlert && (
                  <div>
                    <ErrorListAlert
                      errors={getErrors(errors)}
                      hide={() => setShowErrorAlert(false)}
                    />
                  </div>
                )}
                <FormBody
                  {...props}
                  loading={loading}
                  contactHistories={contactHistories}
                  setContactHistories={setContactHistories}
                  setShowErrorAlert={setShowErrorAlert}
                  setLoading={setLoading}
                />
              </Form>
            )}
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  )
}

function HistoryList({ elements, onDelete, sizes, loading = false, onChangeRow }) {
  const [localElements, setLocalElements] = useState([])
  useEffect(() => {
    if (elements) {
      setLocalElements(elements)
    }
  }, [elements])

  const onChange = (index, field, value) => {
    onChangeRow(index, field, value)
  }

  return (
    <div>
      {localElements.map((ch, index) => {
        return (
          <div className="row align-items-center mb-2" key={'history' + ch.id}>
            <div className={`${sizes.date} pe-2`}>
              <DateField
                className="form-control form-control-cell"
                date={ch.date}
                onChange={(date) => onChange(index, 'date', date)}
              />
            </div>

            <div className={`${sizes.time} pe-2`}>
              <BwmInput
                type="time"
                className="form-control form-control-cell"
                value={Utility.formatTimeFromDate(ch.date)}
                onChange={(e) =>
                  onChange(index, 'date', Utility.joinDateAndTime(ch.date, e.target.value))
                }
              />
            </div>

            <div className={`${sizes.contact} pe-2`}>
              <BwmInput
                className="form-control form-control-cell"
                value={ch.contact}
                onChange={(e) => onChange(index, 'contact', e.target.value)}
              />
            </div>
            <div className={`${sizes._phone} pe-2`}>
              {
                <BwmInput
                  className="form-control form-control-cell"
                  value={ch.phone}
                  Icon={() => <CallIcon phone={ch.phone} />}
                  onChange={(e) => onChange(index, 'phone', e.target.value)}
                />
              }
            </div>
            <div className={`${sizes.caller} pe-2`}>
              <BwmInput
                className="form-control form-control-cell"
                value={ch.caller}
                onChange={(e) => onChange(index, 'caller', e.target.value)}
              />
            </div>
            <div className={`${sizes.notes}`}>
              <BwmInput
                className="form-control form-control-cell"
                value={ch.notes}
                onChange={(e) => onChange(index, 'notes', e.target.value)}
                Icon={() => (
                  <button onClick={() => onDelete(ch)} disabled={loading}>
                    <i className="thx-trash thx-icon" />
                  </button>
                )}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export function CallIcon({ phone }) {
  return (
    <a
      className="d-flex align-items-center"
      target="_blank"
      rel="noopener noreferrer"
      href={`https://starinfostudio.my3cx.it/webclient/#/people?phone=${phone}`}>
      <i className="thx-phone thx-icon" />
    </a>
  )
}

function ContactList({
  contactInfo,
  onChangeRow,
  onDelete,
  onAdd,
  onSelect,
  sizes,
  loading = false
}) {
  const newContact = baseContact
  const onChange = (index, field, value) => onChangeRow(index, field, value)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <>
      <div className="d-flex align-items-center">
        <label style={{ fontSize: '18px' }} className="fw-bold">
          {labels.CONTACTS}:
        </label>

        <button className="ms-2 mb-1 btn btn-primary btn-cell" onClick={() => onAdd(newContact)}>
          {labels.ADD}
        </button>
      </div>

      {loading && <SimpleSpinner size={15} />}

      <div className="p-2 contact-info-table">
        <div className="row d-flex align-items-center">
          <div className={sizes._denomination}>
            <label>{labels.DENOMINATION}</label>
          </div>
          <div className={sizes.phone}>
            <label>{labels.PHONE}</label>
          </div>
        </div>
        {contactInfo?.contactNumbers &&
          contactInfo?.contactNumbers.map((item, index) => {
            return (
              <div key={index} className="row d-flex align-items-center mb-2">
                <div className={`${sizes._denomination} pe-2`}>
                  <BwmInput
                    className="form-control form-control-cell"
                    value={item?.denomination}
                    placeholder={labels.INSERT_DENOMINATION}
                    onChange={(e) => onChange(index, 'denomination', e.target.value)}
                  />
                </div>
                <div className={sizes.phone}>
                  <BwmInput
                    className="form-control form-control-cell"
                    value={item?.phone}
                    onChange={(e) => onChange(index, 'phone', e.target.value)}
                    placeholder={labels.INSERT_NUMBER}
                    Icon={() => (
                      <span className="d-flex align-items-center form-control-cell">
                        <button
                          className="p-0 pe-2 d-flex align-items-center"
                          onClick={() => onSelect(item)}>
                          <CallIcon phone={item.phone} />
                        </button>
                        <button
                          className="p-0"
                          onClick={() => onDelete(index, item)}
                          disabled={loading}>
                          <i className="thx-trash thx-icon text-red" />
                        </button>
                      </span>
                    )}
                  />
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues } = useFormikContext()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const PRIORITIES = [
    { id: 'NUOVO_CLIENTE', description: labels.NEW_CLIENT },
    { id: 'SOSPESO', description: labels.SUSPENDED }
  ]

  const baseHistoryObject = {
    id: null,
    date: new Date(),
    contact: '',
    phone: '',
    caller: props.defaultCaller,
    notes: ''
  }

  const [newHistory, setNewHistory] = useState(baseHistoryObject)
  const [showNewHistoryForm, setShowNewHistoryForm] = useState(false)

  const [sizesClass, setSizesClass] = useState(getSize())

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    if (props.selectedContactNumber) {
      setShowNewHistoryForm(true)
      const newHistory = {
        ...baseHistoryObject,
        contact: props.selectedContactNumber?.denomination,
        phone: props.selectedContactNumber?.phone
      }
      setNewHistory(newHistory)
    }

    return () => {}
  }, [])

  const handleChangeContactHistory = (index, field, value) => {
    let list = props.contactHistories
    list[index] = { ...list[index], [field]: value }
    props.setContactHistories([...list])
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = ![null, undefined, ''].includes(val) ? val : null
    setValues(form)
  }

  const handleDelete = () => {
    if (values?.id) {
      PopupConfirm({
        titleColor: titleColors.ALERT,
        title: labels.DELETE_CONTACT,
        text: labels.DELETE_CONTACT_TEXT,
        handleClickConfirm: () => {
          actions.deleteContactInfo(values.id).then(
            (res) => props.onSave(),
            (err) => PopupError({ text: APPLICATION_GENERIC_ERROR })
          )
        }
      })
    }
  }

  const handleChangeContactNumber = (index, field, value) => {
    let list = values.contactNumbers
    list[index] = { ...list[index], [field]: value }

    setValues({ ...values, contactNumbers: list })
  }

  const handleSave = (afterSave = (result) => {}) => {
    actions.saveContactInfo(values).then(
      (res) => {
        //salvo tutte le modifiche allo storico e contatti
        const numbersToSave = values.contactNumbers.map((c) => {
          c.contactInfo = res
          return c
        })
        let historiesToSave = props.contactHistories.map((c) => {
          c.contactInfo = res
          return c
        })
        if (newHistory.notes) {
          historiesToSave.push({ ...newHistory, contactInfo: res })
        }
        actions.saveContactNumbers(numbersToSave).then(() => {
          actions.saveContactHistories(historiesToSave).then(() => {
            afterSave(res)
          })
        })
      },
      (err) => {
        PopupError({ text: APPLICATION_GENERIC_ERROR })
      }
    )
  }

  const handleAddContactNumber = (contact) => {
    let contacts = values.contactNumbers
    contacts.unshift(contact)
    setValues({ ...values, contactNumbers: contacts })
  }

  const handleDeleteContactNumber = (index, contact) => {
    if (contact?.id) {
      PopupConfirm({
        titleColor: titleColors.ALERT,
        text: labels.DELETE_CONTACT_NUMBER_TEXT,
        handleClickConfirm: () => {
          props.setLoading(true)
          actions.deleteContactNumber(contact.id).then((res) => {
            let contacts = values.contactNumbers.filter((c) => c.id !== contact.id) //elimino i contatti in memoria invece di ricaricarli per preservare eventuali contatti non salvati
            setValues({ ...values, contactNumbers: contacts })
            props.setLoading(false)
          })
        }
      })
    } else {
      let contacts = values.contactNumbers
      contacts.splice(index, 1)
      setValues({ ...values, contactNumbers: contacts })
    }
  }

  const handleDeleteContactHistory = (contactHistory) => {
    PopupConfirm({
      titleColor: titleColors.ALERT,
      text: labels.DELETE_CONTACT_HISTORY_TEXT,
      handleClickConfirm: () => {
        props.setLoading(true)
        actions.deleteContactHistory(contactHistory.id).then((res) => {
          let histories = values.contactHistories.filter((c) => c.id !== contactHistory.id)
          setValues({ ...values, contactHistories: histories })
          props.setContactHistories(histories)
          props.setLoading(false)
        })
      }
    })
  }

  const onSelect = (contact) => {
    setShowNewHistoryForm(true)
    setNewHistory({
      ...baseHistoryObject,
      contact: contact.denomination,
      phone: contact.phone
    })
  }

  return (
    <div>
      <div className="row">
        <div className={`${sizesClass.denomination} pe-2`}>
          <BwmInput
            name="denomination"
            label={labels.DENOMINATION}
            className="form-control"
            value={values?.denomination}
            error={errors?.denomination}
            onChange={(e) => handleChangeValue(e.target.value, 'denomination')}
          />
        </div>
        <div className={`${sizesClass.login} pe-2`}>
          <BwmInput
            name="login"
            label={labels.LOGIN}
            className="form-control"
            value={values?.login}
            error={errors?.login}
            onChange={(e) => handleChangeValue(e.target.value, 'login')}
          />
        </div>
        <div className={`${sizesClass.email} pe-2`}>
          <BwmInput
            name="email"
            label="Email"
            className="form-control"
            value={values?.email}
            Icon={() => <i className="thx-copy thx-icon" />}
            onIconClick={() => navigator.clipboard.writeText(values?.email)}
            onChange={(e) => handleChangeValue(e.target.value, 'email')}
          />
        </div>
        <div className={`${sizesClass.password} pe-2`}>
          <BwmInput
            name="pwd"
            label="Password"
            type={'password'}
            className="form-control"
            value={values?.pwd}
            Icon={() => <i className="thx-copy thx-icon" />}
            onIconClick={() => navigator.clipboard.writeText(values?.pwd)}
            onChange={(e) => handleChangeValue(e.target.value, 'pwd')}
          />
        </div>
        <div className={`${sizesClass.callPriority}`}>
          <BwmSelect
            options={PRIORITIES || []}
            name="callPriority"
            label={labels.PRIORITY}
            className="form-control"
            value={values.callPriority}
            error={errors.callPriority}
            onChange={(e) => handleChangeValue(e.target.value, 'callPriority')}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className={`${sizesClass.note} pe-3`}>
          <FloatingLabel title={labels.NOTE} className="w-100 h-100" label={labels.NOTE}>
            <textarea
              name="comment"
              className={'form-control custom-text-area'}
              rows={3}
              cols={12}
              value={values.notes}
              maxLength={255}
              onChange={(e) => handleChangeValue(e.target.value, 'notes')}
            />
          </FloatingLabel>
        </div>
        <div className={`form-group ${sizesClass.contactList} pe-3`}>
          <ContactList
            loading={props.loading}
            sizes={sizesClass}
            contactInfo={values}
            onAdd={(newContact) => handleAddContactNumber(newContact)}
            onDelete={(index, contact) => handleDeleteContactNumber(index, contact)}
            onChangeRow={(index, field, value) => handleChangeContactNumber(index, field, value)}
            onSelect={(contact) => onSelect(contact)}
          />
        </div>
        <div className={`form-group ${sizesClass.licenseList}`}>
          <label className="mb-2 fw-bold" style={{ fontSize: '18px' }}>
            {labels.LICENCES}:
          </label>
          <div className="p-2 contact-info-table">
            <div className="row">
              <div className={`${sizesClass.aplicationName}`}>
                <label>{labels.APPLICATIONS_AND_SUBJECTS}</label>
              </div>
              <div className={`${sizesClass.licenseType}`}>
                <label>{labels.LICENSE_TYPE}</label>
              </div>
              <div className={`${sizesClass.expiration}`}>
                <label>{labels.EXPIRATION}</label>
              </div>
            </div>
            {values?.contactApplications &&
              values?.contactApplications.map((ca) => {
                return (
                  <div className="row" key={'applications-' + ca.id}>
                    <div
                      className={`${sizesClass.aplicationName}`}>{`${ca.application?.name} x${ca.maxSubjects}`}</div>
                    <div className={`${sizesClass.licenseType}`}>{ca.licenseType}</div>
                    <div className={`${sizesClass.expiration}`}>
                      {Utility.formatDateForDisplay(ca.expiration)}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group">
          <div className="mt-3 contact-history-call">
            <div className="row">
              <div className="d-flex align-items-center">
                <h6 className="text-primary fw-bold">{labels.CONTACT_HISTORY}: </h6>

                {props.loading && <SimpleSpinner size={16} />}
              </div>
            </div>
            <div className="row">
              <div className={sizesClass.date}>
                <label>{labels.DATE}</label>
              </div>

              <div className={sizesClass.time}>
                <label>{labels.TIME}</label>
              </div>

              <div className={sizesClass.contact}>
                <label>{labels.CONTACT}</label>
              </div>
              <div className={sizesClass._phone}>
                <label>{labels.PHONE}</label>
              </div>
              <div className={sizesClass.caller}>
                <label>{labels.CALLER}</label>
              </div>
              <div className={sizesClass.notes}>
                <label>{labels.NOTES}</label>
              </div>
            </div>
            {showNewHistoryForm && (
              <ContactHistoryForm
                key={'history-form-new'}
                sizes={sizesClass}
                historyObject={newHistory}
                onChange={(changed) => setNewHistory(changed)}
              />
            )}
            {HistoryList({
              sizes: sizesClass,
              elements: props.contactHistories,
              onChangeRow: (index, field, value) => handleChangeContactHistory(index, field, value),
              onDelete: (contactHistory) => handleDeleteContactHistory(contactHistory),
              loading: props.loading
            })}
          </div>
        </div>
      </div>
      <div className={'col-12 mt-4 d-flex justify-content-between'}>
        <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={props.onClose} />
        {values?.id && (
          <button className="btn btn-outline-primary ms-2" onClick={() => handleDelete()}>
            <i className="thx-trash thx-icon me-2" />
            {labels.DELETE_CONTACT}
          </button>
        )}

        <BtnFill
          text={labels.SAVE_AND_CLOSE}
          classCustom="float-end text-end"
          handlerOnClick={() => handleSave((result) => props.onSave(result))}
        />
      </div>
    </div>
  )
}
