import { useSelector } from 'react-redux'
import getText from '../../i18n/labels'

// FORM
import { BwmCheckbox } from '../BwmCheckboxB5'
import { BwmInput } from '../BwmInputB5'

function ContactForm(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  let email =
    props.coraSubjectSid?.email || props.coraSubjectSid?.email === ''
      ? props.coraSubjectSid?.email?.toUpperCase()
      : props.defaultEmail
        ? props.defaultEmail?.toUpperCase()
        : ''

  return (
    <div>
      <div className="row">
        <div className={`${props.sizes.email} pe-3`}>
          <div className="form-group">
            <BwmInput
              disabled={props.disabled}
              className={'form-control'}
              label={`${labels.EMAIL}`}
              name="email"
              placeholder={`${labels.EMAIL_ADDRESS_TO_RECEIVE_GENERATED_FROM} ${props.nameApplication ? props.nameApplication.toUpperCase() : 'CORA'}`}
              error={props.errors?.errorEmail?.msg}
              touched={props.errors?.errorEmail?.isValid === false}
              value={email?.toLowerCase()}
              onChange={(e) =>
                props.handleChangeSid(e, 'errorEmail', labels.INCORRECT_EMAIL_ADDRESS)
              }
            />
          </div>
        </div>
        <div className={`${props.sizes.flagPec} d-flex align-items-center mt-2 mb-1`}>
          <BwmCheckbox
            disabled={props.disabled}
            name={`flagPec_${props.coraSubjectSid?.id}`}
            label={labels.SELECT_IN_CASE_OF_PEC_EMAIL}
            className="form-control"
            checked={props.coraSubjectSid?.flagPec ? props.coraSubjectSid?.flagPec : false}
            onChange={(e) => props.handleChangeSidValues({ flagPec: e.target.checked })}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactForm
