import { useEffect, useState } from 'react'
import { DraggableList } from '../form/lists/DraggableList'
import { TableUtils } from './TableUtils'
import { SelectableElement } from '../form/lists/SelectableElement'
import { usePopper } from 'react-popper'
import getText from '../i18n/labels'

export function HeaderFilterDropdown({
  language = 'it',
  headers = [],
  hiddenColumnsProps = [],
  onHide = () => {},
  onCleanCache = () => {}
}) {
  const [hiddenColumns, setHiddenColumns] = useState(hiddenColumnsProps)
  const [headersState, setHeadersState] = useState([])
  const [showColumnFilter, setShowColumnFilter] = useState(false)
  const labels = getText(language)

  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: {} }]
  })

  useEffect(() => {
    setHeadersState(headers)
  }, [headers])

  const toggleColumn = (column) => {
    let _hiddenColumns
    if (TableUtils.isHiddenColumn(hiddenColumns, column)) {
      _hiddenColumns = hiddenColumns.filter((v) => v.fieldName !== column.fieldName)
      setHiddenColumns(_hiddenColumns)
    } else {
      _hiddenColumns = hiddenColumns.concat(column)
      setHiddenColumns(_hiddenColumns)
    }
    onHide(_hiddenColumns, headersState)
  }

  const updateHeadersOrder = (reordered = []) => {
    const _headersState = TableUtils.updateHeadersOrder(headersState, reordered)
    setHeadersState(_headersState)
    onHide(hiddenColumns, _headersState)
  }

  return (
    <div>
      <button
        type="button"
        ref={setReferenceElement}
        className="btn btn-transparent btn-xs"
        onClick={() => setShowColumnFilter(!showColumnFilter)}>
        <i className="thx-table thx-icon me-2" />
        <span>{labels.VIEW}</span>
      </button>

      {showColumnFilter && (
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, ...attributes.popper }}
          className="popper">
          <DraggableList
            elements={headersState}
            listItemComponent={(element) => {
              return (
                <SelectableHeader
                  key={`header-filter-${element}`}
                  header={element}
                  onChange={toggleColumn}
                  hiddenColumns={hiddenColumns}
                />
              )
            }}
            onReorder={(reordered) => {
              updateHeadersOrder(reordered)
            }}
          />
          <div className="d-flex mb-2 pt-2">
            <div className="col px-1 d-flex">
              <button
                type="button"
                className="btn btn-outline-primary me-2"
                onClick={() => setShowColumnFilter(!showColumnFilter)}>
                <span>{labels.CLOSE}</span>
              </button>
              <button type="button" className="btn btn-outline-primary" onClick={onCleanCache}>
                <span>{labels.RESTORE}</span>
              </button>
            </div>
            <div className="col d-flex justify-content-end me-2">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => setShowColumnFilter(!showColumnFilter)}>
                <span>{labels.SAVE}</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function SelectableHeader({ header, hiddenColumns, onChange }) {
  return (
    <SelectableElement
      value={header}
      showIcon="true"
      onChange={() => onChange(header)}
      defaultChecked={!TableUtils.isHiddenColumn(hiddenColumns, header)}
      label={header.displayedName}
    />
  )
}
