import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { inviaRispostaEmailFacciamoNoiTutto } from '../../../../actions'
import getText from '../../../shared/i18n/labels'

export function FacciamoNoiTuttoCoraThankYou() {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)

    const email = queryParameters.get('email')
    const denominazione = queryParameters.get('denominazione')
    const partitaIva = queryParameters.get('partitaIva')

    if (email && denominazione && partitaIva) {
      let dto = {
        nomeCliente: denominazione,
        codiceFiscaleCliente: partitaIva,
        emailCliente: email,
        coraRelationships: []
      }

      inviaRispostaEmailFacciamoNoiTutto(dto)
    }
  })

  return (
    <>
      <div className="mt-5">
        <div className="text-center">
          <h3>{labels.FACCIAMO_NOI_TUTTO_CORA_THANK_YOU_TITLE}</h3>
          <h4>{labels.FACCIAMO_NOI_TUTTO_CORA_THANK_YOU_SUBTITLE}</h4>
        </div>
      </div>
    </>
  )
}
