import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility'
import { cloneDeep } from 'lodash'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import { ModalCreateDelegate } from './NewModalCreateDelegate'

// BUTTONS
import { BtnDelete } from '../../../shared/BtnDelete'

// COMPONENTS
import { PopupDelete } from '../../../shared/popups/PopupDelete'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'
import RowSpinner from '../../../shared/spinner/Spinner'

import * as styles from '../../../../config/styles'
import 'react-datepicker/dist/react-datepicker.css'

export const DelegateForm = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const [modalShow, setModalShow] = useState(false)
  const [delegates, setDelegates] = useState([])
  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)
  let [delegatePerson, setDelegatePerson] = useState(null)
  let [newDelegate, setNewDelegate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    handlerRefreshDelegate()
  }, [])

  const handlerRefreshDelegate = () => {
    if (props.relationship?.id) {
      setIsLoading(true)
      actions
        .getDelegatesByRelationshipId(props.subjectId, props.relationship.id)
        .then(
          (res) => {
            setDelegates(res)
          },
          (errors) => {
            console.log(errors)
          }
        )
        .then(() => setIsLoading(false))
    }
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRows = cloneDeep(delegates)
      newRows.forEach((row) => (items[row.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === delegates?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handlerSubmitDelegate = (formDataDelegate) => {
    setIsLoading(true)
    if (formDataDelegate?.id || delegatePerson?.id) {
      actions
        .updateCoraDelegate(formDataDelegate)
        .then(
          (coraDelegate) => {
            let elementsIndex = delegates.findIndex((element) => element.id == formDataDelegate?.id)
            delegates[elementsIndex] = coraDelegate
            setDelegates(delegates)
            props.updateDelegates(delegates)
            PopupSuccess()
            closeModal()
          },
          (errors) => {
            setModalShow(false)
            PopupError({ text: labels.ERROR_UPDATING_DELEGATES + ' ' + errors })
          }
        )
        .then(() => setIsLoading(false))
    } else {
      actions
        .createCoraDelegate(formDataDelegate)
        .then(
          (coraDelegate) => {
            delegates.push(coraDelegate)
            setDelegates(delegates)
            props.updateDelegates(delegates)

            closeModal()
          },
          (errors) => {
            setModalShow(false)
            PopupError({ text: labels.ERROR_INSERTING_DELEGATES + ' ' + errors })
          }
        )
        .then(() => setIsLoading(false))
    }
    setDelegatePerson(null)
  }

  const openPopupDelete = () => {
    let array = Object.keys(checkedItems)
    let data = []
    delegates.forEach(function (entry) {
      if (array.some((el) => el == entry.id)) {
        data.push(entry)
      }
    })
    let finalArray = []
    data.forEach(function (entry) {
      finalArray.push('- ' + (entry.completeName.trim() ? entry.completeName : entry.fiscalCode))
    })

    PopupDelete({
      list: finalArray,
      operazione: labels.TITLE_AND_DELEGATE,
      handleClickConfirm: handlerDeleteDelegate
    })
  }

  const handlerDeleteDelegate = () => {
    let data = delegates
    Object.keys(checkedItems).forEach((key) => {
      actions.deleteCoraDelegate(key).then(
        () => {
          data = data.filter((entry) => entry.id?.toString() != key?.toString())
          setDelegates(data)
          resetCheckBox()
          PopupSuccess({ text: labels.REMOVED_SUCCESSFULLY })
        },
        (errors) => PopupError({ text: errors.title || labels.ERROR_DELETING_DELEGATES })
      )
    })
  }

  const handlerShowDelegateForm = (delegate) => {
    setNewDelegate(false)
    if (isNotEmpty(delegate)) {
      setDelegatePerson(delegate)
    }
    setModalShow(true)
  }

  const addNewDelegateForm = () => {
    setNewDelegate(true)
    setDelegatePerson({})
    setModalShow(true)
  }

  const setTab = () => {
    handlerRefreshDelegate()
  }

  const closeModal = () => {
    setModalShow(false)
    setTab()
  }

  const getRoleDesc = (selectedItem) => {
    let roleSelected = ''

    if (selectedItem) {
      roleSelected = props.roles.filter((role) => role.id == selectedItem)
      return roleSelected[0].code + ' - ' + roleSelected[0].description
    } else {
      return roleSelected
    }
  }

  const renderDelegateRows = (delegates, handlerShowDelegateForm, getRoleDesc) => {
    if (isLoading) {
      return <RowSpinner />
    } else if (!delegates || delegates?.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center border pt-2">
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
            style={{ width: '30px', height: '30px' }}
          />
          <h6>{labels.NO_RESULTS_TO_SHOW}</h6>
        </div>
      )
    }
    return (
      delegates?.length > 0 &&
      delegates.map((delegate, index) => (
        <div
          className={`${delegates.length - 1 === index ? 'border-radius-bottom' : ''} text-start row-table row-table-sm`}
          id={'row-' + delegate?.id}
          key={'key-delegate-' + delegate?.id}>
          <div
            className={`${props.sizes.checkbox} text-center div-td d-flex justify-content-center`}>
            <input
              type="checkbox"
              id={`checkbox_${delegate.id || ''}`}
              checked={checkedItems[delegate.id] || false}
              onChange={(e) => handleCheckChange(e, delegate.id)}
            />
          </div>
          <div
            className={`${props.sizes.role} div-td text-truncate`}
            onClick={() => handlerShowDelegateForm(delegate)}>
            {getRoleDesc(delegate?.role?.id)}
          </div>
          <div
            className={`${props.sizes.completeName} div-td text-truncate`}
            onClick={() => handlerShowDelegateForm(delegate)}>
            {delegate.completeName}
          </div>
          <div
            className={`${props.sizes.fiscalCode} div-td text-truncate`}
            onClick={() => handlerShowDelegateForm(delegate)}>
            {delegate?.fiscalCode ? delegate?.fiscalCode : ''}
          </div>
          <div
            className={`${props.sizes.birthDate} div-td text-truncate`}
            onClick={() => handlerShowDelegateForm(delegate)}>
            {delegate?.birthDate ? formatDateForDisplay(delegate?.birthDate) : ''}
          </div>
          <div
            className={`${props.sizes.location} div-td text-truncate`}
            onClick={() => handlerShowDelegateForm(delegate)}>
            {delegate?.location ? delegate?.location : ''}
          </div>
          <div
            className={`${props.sizes.province} div-td text-truncate`}
            onClick={() => handlerShowDelegateForm(delegate)}>
            {delegate?.province ? delegate?.province : ''}
          </div>
          <div
            className={`${props.sizes.startDate} div-td text-truncate`}
            onClick={() => handlerShowDelegateForm(delegate)}>
            {delegate?.startDate ? formatDateForDisplay(delegate?.startDate) : ''}
          </div>
          <div
            className={`${props.sizes.endDate} div-td text-truncate`}
            onClick={() => handlerShowDelegateForm(delegate)}>
            {delegate?.endDate ? formatDateForDisplay(delegate?.endDate) : ''}
          </div>
        </div>
      ))
    )
  }

  return (
    <div className="row p-2 pt-2">
      <div className="col-12 d-flex align-items-center pb-2">
        <h6 className="pe-2 mb-0">{labels.LIST_OF_ANAGRAFIC_RELATED_TO_THE_RELATIONSHIP}</h6>
        <BtnDelete
          className="btn-cell"
          handlerOnClick={() => openPopupDelete()}
          disabled={Object.keys(checkedItems)?.length === 0}
        />
        <div
          className="ms-auto text-end"
          data-tip={
            !props.relationship.id
              ? labels.COMPLETE_THE_COMPLETION_OF_THE_REPORT_TO_REGISTER_AN_ELEMENT
              : null
          }>
          <button
            className={`${styles.btnFillPillSm} btn-cell`}
            onClick={() => addNewDelegateForm()}
            disabled={!props.relationship.id || props.disabled}>
            <i className="thx-plus thx-icon me-2" />
            {labels.ADD}
          </button>
        </div>
      </div>
      <div id={'listDelegates_' + props.relationship.id} className="col-12 table-delegate">
        <div className="col-12 header-table header-table-sm p-0">
          <div
            className={`${props.sizes.checkbox} text-center div-td d-flex align-items-center justify-content-center`}>
            <input
              disabled={!(delegates?.length > 0)}
              name="tableCheckBox"
              type="checkbox"
              checked={allCheck}
              onClick={(e) => handleAllCheckChange(e)}
            />
          </div>
          <div className={`${props.sizes.role} div-td`}>{labels.ROLE}</div>
          <div className={`${props.sizes.completeName} div-td`}>{labels.DENOMINATION_}</div>
          <div className={`${props.sizes.fiscalCode} div-td`}>{labels.FISCAL_CODE}</div>
          <div className={`${props.sizes.birthDate} div-td`}>{labels.BIRTH_DATE}</div>
          <div className={`${props.sizes.location} div-td`}>{labels.LOCATION_}</div>
          <div className={`${props.sizes.province} div-td`}>{labels.PROVINCE}</div>
          <div className={`${props.sizes.startDate} div-td`}>{labels.START_DATE}</div>
          <div className={`${props.sizes.endDate} div-td`}>{labels.END_DATE}</div>
        </div>

        {renderDelegateRows(delegates, handlerShowDelegateForm, getRoleDesc)}
      </div>
      {(delegatePerson || newDelegate) && (
        <ModalCreateDelegate
          disabled={props.disabled}
          key={delegatePerson?.id}
          show={modalShow}
          relationship={props.relationship}
          relationshipLocations={props.relationshipLocations}
          roles={props.roles}
          delegatePerson={newDelegate ? null : delegatePerson}
          handlerSubmitDelegate={handlerSubmitDelegate}
          onHide={() => closeModal()}
        />
      )}
    </div>
  )
}
