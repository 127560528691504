import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tab from './Tab'

export const Tabs = (props) => {
  useEffect(() => {
    if (props.activeTab) props.setActiveTab(props.activeTab)
  }, [props.activeTab])

  const isPromise = (obj) => {
    return obj && typeof obj === 'object' && typeof obj.then === 'function'
  }

  const getActiveTab = () => {
    return props.children.find((child) => child.props.id === props.activeTab)
  }

  const onClickTabItem = useCallback(
    (tab, idTab) => {
      const childProps = props.children[1]?.props

      if (childProps?.handleFormSubmitHolder) {
        childProps.handleFormSubmitHolder(tab)
      } else if (childProps?.handleCreateRelationship) {
        const result = childProps.handleCreateRelationship()
        if (isPromise(result)) {
          result.then((res) => {
            if (res) props.setActiveTab(idTab, true)
          })
        } else {
          props.setActiveTab(idTab, true)
        }
      } else {
        props.setActiveTab(idTab, true)
      }
    },
    [props.children]
  )

  return (
    <div className={`tabs  ${props.className || ''}`}>
      <ul className="tab-list nav nav-tabs">
        {props.title}
        {props.children.map((child, index) => {
          const { label, icon, id, disabled } = child.props
          return (
            <Tab
              {...child.props}
              activeTab={getActiveTab()?.props?.label}
              key={label}
              last={index === props.children.length - 1}
              icon={icon}
              idTab={id}
              disabled={disabled}
              onClick={onClickTabItem}
            />
          )
        })}
      </ul>
      <div className={`tab-content ${props.className || 'pt-2'}`}>
        {props.children.map((child, index) => {
          if (props.forceRender) {
            return (
              <div key={index} className={child.props.id !== props.activeTab ? 'tab-hide' : ''}>
                {child.props.children}
              </div>
            )
          } else {
            if (child.props.id !== props.activeTab) return null
            return child.props.children
          }
        })}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  className: PropTypes.string
}
