import { isApplicationExpired } from '../../actions'
import * as Constants from '../../config/Constants'
import { formatDateForDisplay } from './Utility'
import getText from './i18n/labels'

export function SubjectTotalAndCallToAction({
  total = 0,
  used = 0,
  remaining = 0,
  licenseType = '',
  customerApplication,
  language = 'it'
}) {
  const labels = getText(language)

  /**
     * Se la licenza è in demo, indirizzo l'utente verso la pagina di carrello con prodotto base + il numero di soggetti usati
     * se DEMO
     Abbonanmento in DEMO valido fino al xxxxx  ACQUISTA
     se licenza
     Abbonamento valido fino al xxxxxxxxxxxxx
     Abbonamento SCADUTO il xxxxxxx  RINNOVA
     */

  const renderAddSubjectsButton = (customerApplication, remaining) => {
    if (remaining >= 0) {
      return (
        <a
          href={Constants.CART_LINK(
            0,
            Constants.getEcommerceSubjectProductId(customerApplication?.application.name),
            1
          )}
          target="_blank"
          rel="noopener noreferrer"
          className="text-light no-underline">
          <button type="button" className="btn btn-primary">
            <span>{labels.BUY}</span>
          </button>
        </a>
      )
    } else {
      return (
        <a
          href={Constants.CART_LINK(
            0,
            Constants.getEcommerceSubjectProductId(customerApplication?.application.name),
            remaining * -1
          )}
          target="_blank"
          rel="noopener noreferrer"
          className="text-light no-underline">
          <button type="button" className="btn btn-danger  ">
            {labels.ADD_MISSING}
          </button>
        </a>
      )
    }
  }

  const getClass = () => {
    if (isApplicationExpired(customerApplication)) return 'text-danger'
    return 'text-primary'
  }

  const renderRinnovoSection = (customerApplication, remaining, used, licenseType) => {
    if (customerApplication?.application.name !== Constants.APPLICATION_COMUREI) {
      return (
        <>
          <span className={`ms-1 me-3 font-size-md ${getClass()}`} style={{ fontWeight: 'bold' }}>
            {window.innerWidth > Constants.BREAKPOINT_LG && (
              <span className="me-1">{labels.SUBSCRIPTION}</span>
            )}
            {licenseType === 'DEMO' && (
              <>
                <strong>DEMO</strong>
              </>
            )}
            {isApplicationExpired(customerApplication) ? (
              <>
                <strong>{labels.EXPIRED}</strong> il
                <strong>{formatDateForDisplay(customerApplication?.expiration)}</strong>
              </>
            ) : (
              <span className="text-primary">
                {labels.VALID_UNTIL} {formatDateForDisplay(customerApplication?.expiration)}
              </span>
            )}
          </span>
          {(licenseType === 'DEMO' || isApplicationExpired(customerApplication)) && (
            <a
              href={Constants.CART_LINK(
                Constants.getEcommerceProductId(customerApplication?.application.name),
                Constants.getEcommerceSubjectProductId(customerApplication?.application.name),
                used
              )}
              target="_blank"
              rel="noopener noreferrer"
              className="text-light no-underline">
              <button type="button" className="btn btn-primary ">
                {(licenseType === 'DEMO' && labels.BUY) ||
                  (isApplicationExpired(customerApplication) && labels.RENEW)}
              </button>
            </a>
          )}
          {remaining < 0 && (
            <strong style={{ color: 'red' }}>
              {labels.BUY_MISSING} ({remaining * -1})
            </strong>
          )}
        </>
      )
    } else {
      return ''
    }
  }

  if (customerApplication) {
    return (
      <div className="d-flex align-items-center">
        <span className="text-black text-custom-ElencoSoggeto-p">{labels.SUBJECT_LIST}</span>
        <div className="d-flex border-custom align-items-center px-2 mt-1 mb-2 ps-2 ms-4">
          <span className="me-2 text-custom-ElencoSoggetto">{labels.PURCHASED}</span>
          <span className="me-2 primary-text-color text-custom-ElencoSoggetto-n">{total}</span>
          <span className="text-custom-ElencoSoggetto me-2">{labels.AVAILABLE}</span>
          <span className="primary-text-color text-custom-ElencoSoggetto-n me-2">{remaining}</span>
          {renderRinnovoSection(customerApplication, remaining, used, licenseType)}
          {licenseType !== 'DEMO' &&
            !isApplicationExpired(customerApplication) &&
            renderAddSubjectsButton(customerApplication, remaining)}
        </div>
      </div>
    )
  } else {
    return (
      <div className="d-flex align-items-center">
        <h3 className="text-custom-ElencoSoggeto-p mt-2 mb-2">{labels.SUBJECT_LIST}</h3>
      </div>
    )
  }
}
