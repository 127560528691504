import { FloatLabelTextBox } from '../../shared/form/input-boxes/FloatLabelTextBox'

export function AdministratorSection(props) {
  return (
    <>
      <div className={props.sectionTitleStyle}>
        CREDENZIALI Accesso Area Privata dell'AMMINISTRATORE/INCARICATO del soggetto obbligato
        (Entratel o Fiscoline)**
      </div>
      <div className="row">
        <div className="col-6">
          <FloatLabelTextBox
            value={props.amministratoreNomeCognome}
            label={'Nome e Cognome'}
            onChange={(e) => props.setAmministratoreNomeCognome(e.target.value)}
          />
        </div>
        <div className="col-3">
          <FloatLabelTextBox
            value={props.amministratoreCodiceFiscale}
            label={'Codice Fiscale'}
            onChange={(e) => props.setAmministratoreCodiceFiscale(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <FloatLabelTextBox
            value={props.amministratorePin}
            label={'PIN Fisconline/Entratel'}
            onChange={(e) => props.setAmministratorePin(e.target.value)}
          />
        </div>

        <div className="col-5">
          <FloatLabelTextBox
            value={props.amministratorePassword}
            label={'Password'}
            onChange={(e) => props.setAmministratorePassword(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <FloatLabelTextBox
            value={props.amministratoreProgressivoSede}
            label={'Progressivo sede'}
            onChange={(e) => props.setAmministratoreProgressivoSede(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}
