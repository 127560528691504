import { useState } from 'react'
import { useSelector } from 'react-redux'
import * as Constants from '../../../../config/Constants'
import { isEmpty } from '../../../shared/Utility'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel'
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker'

// FORM
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'

export function ImportDataModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)
  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: '' })
  const [loading, setLoading] = useState(false)
  const [importType, setImportType] = useState('RELATIONSHIP')
  const [updateExisting, setUpdateExisting] = useState(false)

  const closeModal = () => {
    props.onClose()
  }

  const checkFileName = (name) => {
    if (name.toLowerCase().includes('note')) {
      setImportType('NOTES')
    }
    const ext = name.split('.').pop().toLowerCase()
    return ext === 'txt' || ext === 'dat'
  }

  const handleUploadFile = (val) => {
    if (isEmpty(val)) {
      setErrorDataFile({ isValid: false, msg: labels.REQUIRED_FILE })
    }
    let files = []
    for (let i = 0; i < val.target.files.length; i++) {
      if (!checkFileName(val.target.files[i].name)) {
        setErrorDataFile({
          isValid: false,
          msg: labels.INVALID_FILE_EXTENSION + val.target.files[i].name
        })
      } else {
        files.push(val.target.files[i])
      }
    }
    if (files.length > 0) setDataFile(files)
  }

  const handleErrorMessages = (error) => {
    if (error) {
      switch (error.errorKey) {
        case Constants.MAX_SUBJECTS_EXCEEDED:
          CheckRemainingSubjects(Constants.APPLICATION_CORA, true, labels, lang)
          break
        case Constants.SUBJECT_NOT_FOUND:
          PopupError({ text: `${error.title}` })
          break
        case Constants.DUPLICATE_ENTRY:
          PopupError({ text: `${error.title}` })
          break

        default:
          PopupError({
            text: labels.ERROR_IMPORTING_DATA + labels.CHECK_FILE_AND_TRY_AGAIN,
            labels
          })
          break
      }
    }
  }

  const handleSendDataFile = () => {
    let formData = new FormData()
    if (dataFile?.length > 0) {
      for (let i = 0; i < dataFile.length; i++) {
        formData.append('dataFile', dataFile[i])
      }
      formData.append('updateExisting', updateExisting)
      props.subjectId && formData.append('subjectId', props.subjectId)
    } else {
      return
    }
    setLoading(true)
    actions
      .importCoraDataFile(formData)
      .then(
        (res) => {
          let results = []
          if (res) {
            for (let i = 0; i < res.length; i++) {
              if (res[i].type === 'RELATIONSHIP')
                results.push(
                  labels.IMPORTED +
                    ' ' +
                    res[i].totalRelationships +
                    ' ' +
                    labels.RELATIONSHIPS +
                    ' ' +
                    labels.FOR +
                    ' ' +
                    res[i].subjectName
                )
              if (res[i].type === 'NOTE')
                results.push(
                  labels.IMPORTED +
                    ' ' +
                    res[i].totalRelationships +
                    ' ' +
                    labels.NOTES +
                    ' ' +
                    labels.FOR +
                    ' ' +
                    res[i].subjectName
                )
            }
            PopupSuccess({
              dataList: results
            })
            props.onSuccess()
          } else {
            setLoading(false)
            setDataFile([])
            PopupError({ text: labels.ERROR_IMPORTING_DATA_TEXT })
          }
        },
        (errors) => {
          handleErrorMessages(errors)
        }
      )
      .then(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {loading && <PageSpinner />}

      <Modal
        backdrop="static"
        show={props.show}
        onHide={props.onClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.subjectId ? (
              <h2>{labels.IMPORT_RELATIONSHIPS_FROM_TXT}</h2>
            ) : (
              <h2>{labels.IMPORT_SUBJECT_FROM_TXT}</h2>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="image-upload text-start">
              <UploadFileLabel forLabel={'dataFile'} label={labels.LOAD_DATA_FILE} />
              <input
                id={`dataFile`}
                type="file"
                name={`dataFile`}
                multiple
                onChange={(e) => handleUploadFile(e)}
                accept=".txt,.dat"
              />
              <div className="certificatiLoaded text-start">
                {!errorDataFile.isValid ? (
                  <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
                ) : (
                  <div>
                    {Array.isArray(dataFile) &&
                      dataFile.map((file, index) => {
                        return (
                          <div className="d-flex align-items-center" key={index}>
                            <i className="thx-check thx-icon me-2" />
                            {file.name}
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row py-3">
            <BwmCheckbox
              name={`update-existing`}
              label={labels.INTEGRATE_EXISTING_DELEGATES_AND_MISSING_BALANCES}
              className="form-control"
              checked={updateExisting}
              onChange={(e) => setUpdateExisting(e.target.checked)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorDataFile.isValid || dataFile?.length === 0}
              onClick={handleSendDataFile}
              type="button"
              className="btn btn-primary">
              {labels.CONFIRM}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
