import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// FORM
import { BwmInput } from '../../../shared/form/BwmInput'
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox'

import '../../../../styles/comurei-form.css'

const UploadReceiptEmailForm = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [formData, updateFormData] = useState()
  const [emailAddress, setEmailAddress] = useState(props.emailAddress)
  const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: '' })
  const [flagPec, setFlagPec] = useState(props.flagPec || false)

  useEffect(() => {
    updateFormData({
      ...formData,
      id: props.subjectId,
      emailAddress: emailAddress,
      flagPec: flagPec
    })
  }, [])

  const confirmCreateReceiptEmail = (subjectId, communication, emailAddress, flagPec) => {
    actions.sendCrsReceiptMail(subjectId, communication.id, emailAddress, flagPec)
    props.closeModal()
  }

  const handlerChangeEmailAddress = (e) => {
    setEmailAddress(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      emailAddress: e.target.value.toUpperCase()
    })
    setFlagPec(false)
  }

  const handlerChangeFlagPec = (e) => {
    let checked = e.target.checked
    setFlagPec(checked)
    updateFormData({
      ...formData,
      flagPec: checked
    })
  }

  const resetError = () => {
    setErrorEmailAddress({ isValid: true, msg: '' })
  }

  const handleValidation = () => {
    resetError()
    let isValid = true
    if (!emailAddress) {
      setEmailAddress({ isValid: false, msg: labels.REQUIRED_FIELD })
      isValid = false
    } else if (emailAddress.toUpperCase().indexOf('AGENZIAENTRATE') != -1) {
      setErrorEmailAddress({
        isValid: false,
        msg: labels.ENTER_A_DIFFERENT_DESTINATION_FROM_THE_AGENCY_OF_ENTRIES
      })
      isValid = false
    }

    return isValid
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    if (handleValidation()) {
      confirmCreateReceiptEmail(props.subjectId, props.communication, emailAddress, flagPec)
    }
  }

  return (
    <form onSubmit={(e) => handleFormSubmit(e, false)}>
      <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 mt-5 d-flex radius">
        <div className="tab-content col-12">
          <div className="form-row">
            <div className="col-12 subDetailComurei">
              <div className="row">
                <div className="col-11">
                  <BwmInput
                    id="emailAddress"
                    type="email"
                    className="form-control"
                    label={labels.EMAIL_ADDRESS_TO_SEND_THE_RECEIPT_IN_CLEAR}
                    value={emailAddress}
                    error={errorEmailAddress.msg}
                    touched={errorEmailAddress?.isValid === false}
                    maxLength="100"
                    onChange={handlerChangeEmailAddress}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 pt-1 pb-1">
                  <BwmCheckbox
                    name="flagPec"
                    label={labels.SELECT_IN_CASE_OF_EMAIL_PEC}
                    className="form-control"
                    checked={flagPec}
                    onChange={handlerChangeFlagPec}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
        <button className="btn btn-outline-primary " onClick={props.closeModal}>
          {labels.CANCEL}
        </button>
        <button type="submit" className="btn btn-primary">
          {labels.CONFIRM}
        </button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'UploadReceiptEmailForm'
})(UploadReceiptEmailForm)
