import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'

// POPUPS
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupConfirm, titleColors } from '../../../shared/popups/PopupConfirm'

import '../../../../styles/comurei-form.css'
import { testEmail } from '../../../shared/Utility'

export const ComureiEmailForm = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [formData, updateFormData] = useState()
  const [emailAddress, setEmailAddress] = useState(props.emailAddress)
  const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: '' })

  useEffect(() => {
    updateFormData({
      ...formData,
      id: props.subjectId,
      emailAddress: emailAddress
    })
  }, [])

  const confirmComureiCommunicationsEmail = (sentCommunications, emailAddress) => {
    sentCommunications.map((communication) => {
      actions
        .comureiSendCommunicationMail(communication?.subjectId, communication.id, emailAddress)
        .then(
          (res) => {
            if (res) {
              PopupConfirm({
                titleColor: titleColors.SUCCESS,
                title: labels.COMMUNICATION_CREATED,
                component: () => SuccessMessage(),
                showCancelButton: false,
                confirm: labels.I_UNDERSTAND
              })
            } else {
              PopupError({
                text: labels.ERROR_SENDING_EMAIL
              })
            }
          },
          (errors) => {
            PopupError({ text: labels.ERROR_SENDING_EMAIL + ' ' + errors })
          }
        )
    })
    props.closeModal()
  }

  const SuccessMessage = () => {
    return (
      <>
        <div className="text-center">
          <p>{labels.GENERATED_TXT_FILE_MUST_BE_CHECKED_AND_SENT_TO_REVENUE_AGENCY}</p>
          {lang === 'it' ? (
            <p>
              Per aggiornare il Registro Indirizzi l'Agenzia impiega
              <strong className="ms-1">mediamente 24 ore</strong>: attendere quindi{' '}
              <strong className="me-1">almeno un giorno</strong>
              prima di usare questo indirizzo pec per comunicazioni all'Anagrafe Rapporti (programma
              CORA Web)
            </p>
          ) : (
            <p>
              To update the Address Register, the Revenue Agency takes
              <strong className="ms-1">24 hours</strong>: therefore wait{' '}
              <strong className="me-1">at least one day</strong> before using this PEC address for
              communications to the Anagrafe Rapporti (CORA Web program)
            </p>
          )}
        </div>
      </>
    )
  }

  const handlerChangeEmailAddress = (e) => {
    if (e.target.value && testEmail(e.target.value)) {
      setErrorEmailAddress({ isValid: true, msg: '' })
    } else {
      if (e.target.value && e.target.value !== '') {
        setErrorEmailAddress({
          isValid: false,
          msg: lang === 'it' ? 'Email non valida' : 'Email not valid'
        })
      } else {
        setErrorEmailAddress({
          isValid: false,
          msg: lang === 'it' ? 'Campo obbligatorio' : 'Required field'
        })
      }
    }
    setEmailAddress(e.target.value)
    updateFormData({
      ...formData,
      emailAddress: e.target.value
    })
  }

  const resetError = () => {
    setErrorEmailAddress({ isValid: true, msg: '' })
  }

  const handleValidation = () => {
    resetError()
    let isValid = true
    if (!emailAddress) {
      setErrorEmailAddress({ isValid: false, msg: labels.REQUIRED_FIELD })
      isValid = false
    }

    return isValid
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    if (handleValidation()) {
      confirmComureiCommunicationsEmail(props.sentCommunications, emailAddress)
    }
  }

  return (
    <form onSubmit={(e) => handleFormSubmit(e)}>
      <div className="row-detail comurei mt-4 d-flex radius">
        <div className="tab-content col-12">
          <div className="form-row">
            <div className="col-8 subDetailComurei">
              <div className="row">
                <div className="col-11">
                  <BwmInput
                    id="emailAddress"
                    type="email"
                    className="form-control"
                    label={labels.EMAIL_ADDRESS_TO_SEND_COMMUNICATIONS}
                    maxLength="100"
                    value={emailAddress}
                    error={errorEmailAddress?.msg}
                    touched={errorEmailAddress && !errorEmailAddress.isValid}
                    onChange={handlerChangeEmailAddress}
                  />
                </div>
                <span className="text-danger">{errorEmailAddress?.msg}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-4 form-relationship-button justify-content-between align-item-center p-0">
        <button className="btn btn-outline-primary " onClick={props.closeModal}>
          {labels.CANCEL}
        </button>
        <button disabled={!errorEmailAddress.isValid} type="submit" className="btn btn-primary">
          {labels.CONFIRM}
        </button>
      </div>
    </form>
  )
}
