import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import getText from '../../../shared/i18n/labels'

// COMPONENTS
import { SelectableElement } from './SelectableElement'

import './DropdownMultipleSelect.css'

export function DropdownMultipleSelect({
  options = [{ id: null, description: '' }],
  onSelectionChange = (selectedOptions) => {},
  value = []
}) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [selectedOptions, setSelectedOptions] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    setSelectedOptions(value)
  }, [value])
  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option))
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const handleConfirmClick = () => {
    onSelectionChange(selectedOptions)
    setShowDropdown(false)
  }

  return (
    <>
      <button
        title={
          selectedOptions?.length > 0
            ? selectedOptions.map((o) => o.description).join(', ')
            : undefined
        }
        className="dropdown-toggle"
        onClick={() => setShowDropdown(!showDropdown)}>
        <span className="ps-0 ms-0">
          {selectedOptions?.length > 0
            ? `${selectedOptions.length} ${selectedOptions.length === 1 ? labels.SELECTED_ELEMENT : labels.SELECTED_ELEMENTS}`
            : labels.SELECT_OPTIONS}
        </span>
      </button>
      {showDropdown && (
        <div className="dropdownMultipleSelect">
          <div className="dropdownMultipleSelect-content">
            {options.map((option, index) => (
              <SelectableElement
                key={`dropdown-multiple-select-${index}-${Math.random() * 10}`}
                value={option}
                defaultChecked={selectedOptions.includes(option)}
                onChange={() => handleOptionClick(option)}
                label={option.description}
              />
            ))}
          </div>
          <div className="dropdownMultipleSelect-footer">
            <button className="btn btn-primary mb-1 me-1 mt-2" onClick={handleConfirmClick}>
              {labels.APPLY}
            </button>
          </div>
        </div>
      )}
    </>
  )
}
