import { useState } from 'react'
import { useSelector } from 'react-redux'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import * as Utils from '../CommunicationDownloadUtils'
import getText from '../../../shared/i18n/labels'

// MODAL
import { CommunicationModal } from './CommunicationModal'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../../../styles/alertComm.css'

export function CreateCommunicationModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sentCommunications, setSentCommunications] = useState([])

  const closeModal = () => {
    setShowModal(false)
    document.getElementById('history').click()
  }

  const openModal = () => {
    setShowModal(true)
  }

  const onClickConfirm = () => {
    if (props.onClickCreateCommunicationBefore) {
      props.onClickCreateCommunicationBefore().then(() => {
        confirmCommunications()
      })
    } else {
      confirmCommunications()
    }
  }

  const confirmCommunications = () => {
    const { subjectId, delayedCommunicationDate } = props
    setLoading(true)
    if (props.type === 'MONTHLY') {
      actions.createMonthlyCommunications(subjectId, delayedCommunicationDate).then(
        (sentCommunications) => {
          if (sentCommunications?.length > 0) {
            setLoading(false)
            setSentCommunications(sentCommunications)
            openModal()
          } else {
            setLoading(false)
            PopupError({ text: labels.ERROR_PROCESSING_COMMUNICATION })
          }
        },
        (errors) => {
          setLoading(false)
          PopupError({ text: errors })
        }
      )
    } else {
      const axiosInstance = axiosService.getInstance()
      axiosInstance({
        data: props.excludedRelationships,
        url: `/api/cora/custom/cora-communications/create/yearly/${subjectId}/${props.selectedYear}`,
        method: 'POST'
      })
        .then((response) => {
          if (response) {
            let sentCommunications = response.data
            if (sentCommunications?.length > 0) {
              setLoading(false)
              setSentCommunications(sentCommunications)
              openModal()
            } else {
              setLoading(false)
              PopupError({ text: labels.ERROR_PROCESSING_COMMUNICATION })
            }
          }
        })
        .catch((errors) => {
          setLoading(false)
          PopupError({ text: labels.ERROR_PROCESSING_COMMUNICATION })
        })
    }
  }

  return (
    <>
      {loading && <PageSpinner />}
      <div className={'text-end'}>
        <button
          onClick={(e) => onClickConfirm()}
          className="btn btn-primary"
          disabled={props.disabled}>
          <i className="thx-text-file thx-icon me-2" />
          Crea comunicazione
        </button>
      </div>
      <CommunicationModal
        showModal={showModal}
        closeModal={closeModal}
        communications={sentCommunications}
        coraSubject={props.subject}
        downloadTxt={Utils.downloadTxt}
        downloadPecTz={Utils.downloadPecTz}
        downloadDgn={Utils.downloadDgn}
        downloadPdf={Utils.downloadPdf}
        downloadErrorReport={Utils.downloadErrorReport}
        delayedCommunicationDate={props.delayedCommunicationDate}
      />
    </>
  )
}
