import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import { isEmpty } from '../../../shared/Utility'
import * as Utils from '../CommunicationDownloadUtils'
import * as Constants from '../../../../config/Constants'
import getText from '../../../shared/i18n/labels'
// TABLE
import { CommunicationErrorList } from './CommunicationErrorList'
import { CommunicationHistoryList } from './NewCommunicationHistoryList'

// MODAL
import { Modal } from 'react-bootstrap'
import { CommunicationModal } from './CommunicationModal'

// FORM
import { BwmInput } from '../../../shared/form/BwmInput'
import EmailForm from './EmailForm'

// COMPONENTS
import RowSpinner from '../../../shared/spinner/Spinner'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownButton } from 'react-bootstrap'
import { PopupConfirm, titleColors } from '../../../shared/popups/PopupConfirm'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupDelete } from '../../../shared/popups/PopupDelete'

export function CommunicationsTab(props) {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const { subjectId } = props
  const [loading, setLoading] = useState(false)
  const [loadedInitialHistory, setLoadedInitialHistory] = useState(false)
  const [initialHistoryRows, setInitialHistoryRows] = useState([])
  const [communicationHistory, setCommunicationHistory] = useState([])
  const communicationErrors = useSelector((state) => state.lastErrorCommunication.data)
  const subject = useSelector((state) => state.subject.data)
  const [showCommunicationModal, setShowCommunicationModal] = useState(false)
  const [reelaboratedCommunication, setReelaboratedCommunication] = useState(undefined)
  const resetCommunicationModal = () => {
    setShowCommunicationModal(false)
    setReelaboratedCommunication(undefined)
  }
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [sentCommunication, setSentCommunication] = useState(undefined)

  const resetEmailModal = () => {
    setShowEmailModal(false)
    setSentCommunication(undefined)
  }

  const getHistory = () => {
    setLoading(true)
    actions.getCommunicationsHistoryBySubjectId(subjectId).then((res) => {
      setCommunicationHistory(res)
      setLoading(false)
    })
  }

  const getErrors = () => {
    dispatch(actions.getLastCommunicationsErrorBySubjectId(subjectId))
  }

  const getSubject = () => {
    dispatch(actions.getCoraSubject(subjectId))
  }

  const reloadAll = () => {
    getHistory()
    getErrors()
    getSubject()
  }

  useEffect(() => {
    reloadAll()
  }, [])

  //Conservo il risultato del caricamento iniziale delle righe: servirà a determinare se mostrare le tabelle o no
  useEffect(() => {
    if (!loadedInitialHistory && communicationHistory.length !== 0) {
      setInitialHistoryRows(communicationHistory)
      setLoadedInitialHistory(true)
    }
  }, [communicationHistory])

  const reelaborateCommunicationTxt = (communication) => {
    PopupConfirm({
      title: labels.CONFIRM_REQUEST,
      innerHtml: `${labels.REELABORATION_OF_TXT_COMMUNICATION}: <p class = "text-truncate">${communication.txtFilename}</p>`,
      titleColor: titleColors.SUCCESS,
      handleClickConfirm: () => {
        setLoading(true)
        actions
          .reElaborateCommunicationTxt(subjectId, communication.id)
          .then(
            (res) => {
              if (res?.length) {
                setReelaboratedCommunication(res[0])
                setShowCommunicationModal(true)
                reloadAll()
              }
            },
            (err) => {
              PopupError({ text: err || Constants.APPLICATION_GENERIC_ERROR })
            }
          )
          .then(() => setLoading(false))
      }
    })
  }

  const renderCommunicationModal = () => {
    return (
      reelaboratedCommunication && (
        <CommunicationModal
          showModal={showCommunicationModal}
          closeModal={() => resetCommunicationModal()}
          communications={[reelaboratedCommunication]}
          coraSubject={reelaboratedCommunication.subject}
          downloadTxt={Utils.downloadTxt}
          downloadPecTz={Utils.downloadPecTz}
          downloadDgn={Utils.downloadDgn}
          downloadPdf={Utils.downloadPdf}
          downloadErrorReport={Utils.downloadErrorReport}
        />
      )
    )
  }

  const renderEmailModal = () => {
    return (
      <Modal
        backdrop="static"
        show={showEmailModal}
        onHide={() => resetEmailModal()}
        dialogClassName="modal-70w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.SEND_SELECTED_COMMUNICATION}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailForm
            {...props}
            closeModal={() => resetEmailModal()}
            emailAddress={subject?.coraSubjectSid?.email}
            flagPec={subject?.coraSubjectSid?.flagPec}
            sentCommunications={[sentCommunication]}
            subject={subject}
            subjectId={subject?.id}
          />
        </Modal.Body>
      </Modal>
    )
  }

  const renderDeleteButton = (disabled = false, communications = []) => {
    return (
      <button
        type="button"
        className={`btn btn-outline-primary ms-3 ${disabled ? 'disabled' : ''}`}
        onClick={() => openPopupDelete(communications)}
        disabled={disabled}>
        <i className="thx-trash thx-icon me-2" />
        {labels.DELETE}
      </button>
    )
  }

  const confirmSearchHistory = (selectedSearchOption, query) => {
    if (selectedSearchOption?.id === 'BY_IDENTIFIER') {
      actions.getCommunicationsHistoryBySubjectIdAndIdentifier(subjectId, query).then((res) => {
        setCommunicationHistory(res)
      })
    } else if (selectedSearchOption?.id === 'BY_NAME') {
      actions.getCommunicationsHistoryBySubjectIdAndDenomination(subjectId, query).then((res) => {
        setCommunicationHistory(res)
      })
    } else if (selectedSearchOption?.id === 'BY_FILE') {
      actions.getCommunicationsHistoryBySubjectIdAndFileName(subjectId, query).then((res) => {
        setCommunicationHistory(res)
      })
    }
  }

  const openPopupDelete = (communications = []) => {
    let idsToDelete = []
    communications.forEach((comm) => idsToDelete.push(comm.id))
    PopupDelete({
      operazione: labels.COMMUNICATIONS,
      text:
        lang === 'it'
          ? `Sei proprio sicuro di voler eliminare ${idsToDelete.length} comunicazione/i dallo storico?`
          : `Are you sure you want to delete ${idsToDelete.length} communication/s from the history?`,
      handleClickConfirm: () => handlerDeleteCommunications(idsToDelete)
    })
  }

  const handlerDeleteCommunications = (idsToDelete = []) => {
    setLoading(true)
    let promises = []
    for (const id of idsToDelete) {
      promises.push(actions.deleteCommunication(id))
    }
    Promise.all(promises)
      .then(
        () => PopupSuccess(),
        (errors) => PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
      )
      .then(() => {
        reloadAll(subjectId)
        setLoading(false)
      })
  }

  if (loading) {
    return <RowSpinner />
  }

  return (
    <>
      {renderCommunicationModal()}
      {renderEmailModal()}
      <CommunicationErrorList
        communicationsInError={communicationErrors}
        renderDeleteButton={(disabled, elementsToDelete) =>
          renderDeleteButton(disabled, elementsToDelete)
        }
        downloadDgn={(communication) => Utils.downloadDgn(communication)}
        downloadTxt={(communication) => Utils.downloadTxt(communication)}
        reelaborateCommunicationTxt={(communication) => reelaborateCommunicationTxt(communication)}
      />
      <CommunicationHistoryList
        hasRowsToShow={initialHistoryRows?.length > 0}
        communications={communicationHistory}
        subjectId={subjectId}
        renderDeleteButton={(disabled, elementsToDelete) =>
          renderDeleteButton(disabled, elementsToDelete)
        }
        openEmailModal={(communication) => {
          setShowEmailModal(true)
          setSentCommunication(communication)
        }}
        downloadReceiptTxt={(communication) => Utils.downloadReceiptTxt(communication)}
        downloadPecTz={(communication) => Utils.downloadPecTz(communication)}
        downloadPdf={(communication) => Utils.downloadPdf(communication)}
        downloadTxt={(communication) => Utils.downloadTxt(communication)}
        downloadDgn={(communication) => Utils.downloadDgn(communication)}
        downloadErrorReport={(communication) => Utils.downloadErrorReport(communication)}
        reelaborateCommunicationTxt={(communication) => reelaborateCommunicationTxt(communication)}
        SearchBar={() => (
          <CoraCommunicationSearchBar
            onClickSearch={(searchOption, query) => confirmSearchHistory(searchOption, query)}
            onAbortSearch={getHistory}
          />
        )}
      />
    </>
  )
}

function CoraCommunicationSearchBar({ onClickSearch, onAbortSearch }) {
  const labels = getText(localStorage.getItem('language'))

  const searchOptions = [
    {
      id: 'BY_IDENTIFIER',
      description: labels.UNIQUE_CODE,
      placeholder: labels.INSERT_UNIQUE_CODE_PARTIAL
    },
    {
      id: 'BY_NAME',
      description: labels.DENOMINATION,
      placeholder: labels.INSERT_DENOMINATION_PARTIAL
    },
    {
      id: 'BY_FILE',
      description: labels.COMMUNICATION_NAME,
      placeholder: labels.INSERT_COMMUNICATION_NAME_PARTIAL
    }
  ]
  const [selectedSearchOption, setSelectedSearchOption] = useState(searchOptions[0])
  const [searchQuery, setSearchQuery] = useState('')
  const [isQueryActive, setIsQueryActive] = useState(false)
  return (
    <>
      <div className="row align-items-center">
        <div className="inputSelect col">
          <Dropdown
            onSelect={(value) =>
              setSelectedSearchOption(searchOptions.find((o) => o.id === value))
            }>
            <DropdownButton
              title={`${labels.SEARCH_IN_FIELD}: ${selectedSearchOption?.description}`}
              bsPrefix={'dropdown-menu-green btn-fill m-0 me-1 pe-2'}>
              {searchOptions &&
                searchOptions.map((el, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      eventKey={el.id}
                      active={el.id === selectedSearchOption?.id}>
                      {el.description}
                    </Dropdown.Item>
                  )
                })}
            </DropdownButton>
          </Dropdown>
        </div>
        <div className="col-5 pe-2">
          <BwmInput
            name="searchTerms"
            className="form-control form-control-button"
            value={searchQuery}
            maxLength={50}
            placeholder={selectedSearchOption?.placeholder}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="col-1 me-2">
          <button
            type="button"
            disabled={isEmpty(searchQuery) || searchQuery.length < 5}
            className="btn btn-primary"
            onClick={() => {
              setIsQueryActive(true)
              onClickSearch(selectedSearchOption, searchQuery)
            }}>
            {labels.SEARCH}
          </button>
        </div>
        <div className="col">
          {isQueryActive && (
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                setSearchQuery('')
                setIsQueryActive(false)
                onAbortSearch()
              }}>
              {labels.CLEAR_SEARCH}
            </button>
          )}
        </div>
      </div>
    </>
  )
}
