export default (lang) => ({
  A1: { en: 'A1 - Legal nature', it: 'A1 - Natura giuridica' }[lang],
  A2: { en: 'A2 - Prevalent activity', it: 'A2 - Attività prevalente' }[lang],
  A3: { en: 'A3 - Behavior', it: 'A3 - Comportamento' }[lang],
  A4: { en: 'A4 - Geographic area', it: 'A4 - Area geografica' }[lang],
  A5: { en: 'A5 - Custom', it: 'A5 - Personalizzata' }[lang],
  ACCEPT: { en: 'Accept', it: 'Accetta' }[lang],
  ACCEPT_CONDITIONS: { en: 'Accept the conditions', it: 'Accetta le condizioni' }[lang],
  ACCEPT_CONDITIONS_TEXT: {
    en: 'Inserting the data in this configuration form, the Customer is aware of transmitting to STAR INFOSTUDIO SRL the personal data necessary for the provision of the requested service. STAR INFOSTUDIO SRL will handle such data with diligence and in compliance with the personal data processing regulations. The Customer is aware that the management of security and confidentiality of such data cannot be limited to the company alone, therefore the Customer releases STAR INFOSTUDIO SRL from any responsibility arising from an incorrect management by the Customer or third parties.',
    it: "Inserendo i dati nel presente form di configurazione il Cliente è consapevole di trasmettere a STAR INFOSTUDIO SRL i dati personali necessari all'erogazione del servizio richiesto. STAR INFOSTUDIO SRL tratterrà detti dati con diligenza e nel rispetto della normativa sul trattamento dei dati personali. Il Cliente è consapevole che la gestione della sicurezza e la segretezza di tali dati non può essere limitata alla sola azienda, il Cliente pertanto manleva STAR INFOSTUDIO SRL da ogni responsabilità derivante da un'errata gestione da parte del Cliente o di terzi."
  }[lang],
  ACCEPT_CONDITIONS_TEXT_2: {
    en: 'The Customer is aware that the management of security and confidentiality of such data cannot be limited to the company alone, therefore the Customer releases STAR INFOSTUDIO SRL from any responsibility arising from an incorrect management by the Customer or third parties.',
    it: "Il Cliente è consapevole che la gestione della sicurezza e la segretezza di tali dati non può essere limitata alla sola azienda, il Cliente pertanto manleva STAR INFOSTUDIO SRL da ogni responsabilità derivante da un'errata gestione da parte del Cliente o di terzi."
  }[lang],
  ACCOUNTING_AVAILABILITY: {
    en: 'Accounting availability',
    it: 'Disponibilità contabile ordinaria'
  }[lang],
  ACTIVATE_THE_FIRST_SUBJECT_FOR_FREE: {
    en: 'Activate the first Subject for free',
    it: 'Attiva il primo Soggetto gratuitamente'
  }[lang],
  ACTIVE_FOR_1_SUBJECT: { en: 'Active for 1 subject', it: 'Attivo per 1 soggetto' }[lang],
  ACTUAL_HOLDERS: { en: 'Actual holders', it: 'Titolari effettivi' }[lang],
  ADD: { en: 'Add', it: 'Aggiungi' }[lang],
  ADD_SELECTED: { en: 'Add selected', it: 'Aggiungi selezionati' }[lang],
  ADDRESS: { en: 'Address (street, number)', it: 'Indirizzo (via, numero)' }[lang],
  ADDRESS_: { en: 'Address (street, number, locality)', it: 'Indirizzo (via, numero, località)' }[
    lang
  ],
  ADDRESSLIST: { en: 'Addresses', it: 'Indirizzi' }[lang],
  ADDRESS_PEC_LAST_VERSION: {
    en: 'The same to be indicated in the ACCREDITATION REQUEST to the SID',
    it: 'lo stesso da indicare nella RICHIESTA DI ACCREDITAMENTO al SID'
  }[lang],
  ADD_BALANCE: { en: 'Add balance', it: 'Aggiungi saldo' }[lang],
  ADD_APPLICATION: { en: 'Add application for the user', it: "Aggiungi Applicazione per l'utente" }[
    lang
  ],
  ADD_CLIENT: { en: 'Add client', it: 'Aggiungi cliente' }[lang],
  ADD_CLIENTS: { en: 'Add clients', it: 'Aggiungi clienti' }[lang],
  ADD_CONTRACTS: { en: 'Add contracts', it: 'Aggiungi contratti' }[lang],
  ADD_CREDENTIALS: { en: 'Add your', it: 'Inserisci le tue' }[lang],
  ADD_DOCUMENT: { en: 'Add document', it: 'Aggiungi documento' }[lang],
  ADD_MISSING: { en: 'Add missing', it: 'Aggiungi mancanti' }[lang],
  ADD_NEW_REGISTRY: { en: 'Add new registry', it: 'Aggiungi nuova anagrafica' }[lang],
  ALIASLIST: { en: 'Aliases', it: 'Alias' }[lang],
  ALIGNMENT_RELATIONSHIPS_TO_LAST_PHOTOGRAPHY: {
    en: 'Alignment relationships to last photography',
    it: "Allineamento rapporti all'ultima fotografia elaborata"
  }[lang],
  ALL: { en: 'All', it: 'Tutti' }[lang],
  ALL_AT_THE_END_OF_THE_SESSION_OR_PRESSING_F5_THE_SETTING_IS_DELETED: {
    en: 'All at the end of the session (or pressing F5) the setting is deleted',
    it: "Alla chiusura della sessione (o premendo F5) l'impostazione viene eliminata"
  }[lang],
  AMOUNT: { en: 'Amount', it: 'Importo' }[lang],
  AMOUNT_1: { en: 'Amount 1', it: 'Importo 1' }[lang],
  AMOUNT_2: { en: 'Amount 2', it: 'Importo 2' }[lang],
  AMOUNT_3: { en: 'Amount 3', it: 'Importo 3' }[lang],
  AMOUNT_4: { en: 'Amount 4', it: 'Importo 4' }[lang],
  AMOUNT_5: { en: 'Amount 5', it: 'Importo 5' }[lang],
  AMOUNT_MUST_BE_ENTERED: { en: 'Amount must be entered', it: 'Il saldo deve essere valorizzato' }[
    lang
  ],
  ANNUAL_BALANCES: { en: 'Annual balances', it: 'Saldi annuali' }[lang],
  ANTITERRORISM_CHECK: { en: 'Antiterrorism check', it: 'Controllo Antiterrorismo' }[lang],
  APPLICATION: { en: 'Application', it: 'Applicazione' }[lang],
  APPLICATIONS_AND_SUBJECTS: { en: 'Applications and Subjects', it: 'App e soggetti' }[lang],
  APPLICATION_NAME: { en: 'App name', it: 'Nome App' }[lang],
  APPLY: { en: 'Apply', it: 'Applica' }[lang],
  ARCO_CAUSAL_OPERATION_TYPE: { en: 'Causal', it: 'Causale' }[lang],
  ASSISTANCE: { en: 'Support', it: 'Assistenza' }[lang],
  ASSISTANCE_REQUEST_ERROR: {
    en: 'Error sending assistance request',
    it: "Errore nell'inoltro della richiesta di assistenza"
  }[lang],
  ASSISTANCE_REQUEST_SENT: {
    en: 'Assistance request sent. You will be contacted by our offices shortly.',
    it: 'Richiesta di assistenza inoltrata. A breve verrete contattati dai nostri uffici.'
  }[lang],
  ATTACHED_DOCUMENTS_TO_OPERATION: {
    en: 'Attached documents to operation',
    it: "Documenti allegati all'operazione"
  }[lang],
  ATTACHMENTS: { en: 'Attachments', it: 'Allegati' }[lang],
  ATTENTION: { en: 'Attention', it: 'Attenzione' }[lang],
  ATTENTION_DATA_NOT_SAVED: {
    en: 'ATTENTION: your data are not saved on our servers, for your security.',
    it: 'ATTENZIONE: i vostri dati NON vengono salvati sui nostri server, per vostra sicurezza.'
  }[lang],
  ATTENTION_IF_IN_FUTURE_IT_IS_NEEDED_TO_RESTORE_THIS_SUBJECT_AUTHORIZATION_MUST_BE_REQUESTED_TO_THE_ASSISTANCE_OFFICE:
    {
      en: "ATTENZIONE: se in futuro dovesse servire ripristinare questo/i soggetto/i, dovrà essere chiesta l'autorizzazione all'ufficio Assistenza",
      it: "ATTENZIONE: se in futuro dovesse servire ripristinare questo/i soggetto/i, dovrà essere chiesta l'autorizzazione all'ufficio Assistenza"
    }[lang],
  ATTENTION_RECEIPT_NOT_RELATED_TO_A_COMMUNICATION: {
    en: 'Attention: the receipt is NOT related to a communication in the history',
    it: 'Attenzione: la ricevuta NON si riferisce ad una comunicazione nello storico'
  }[lang],
  AUI: { en: 'AUI', it: 'AUI' }[lang],
  AUTHORITY_OF_ISSUANCE: { en: 'Authority of issuance', it: 'Autorità di rilascio' }[lang],
  AVAILABLE: { en: 'Available', it: 'Disponibili' }[lang],
  AVERAGE_GAZ_MEDIUM: { en: 'Average gaz. medium', it: 'Giaz. Media' }[lang],
  AVERAGE_STOCK: { en: 'Average stock', it: 'Giaz. media' }[lang],
  A_PHYSICAL_PERSON_CANNOT_HAVE_AN_ACTUAL_TITLE: {
    en: 'A physical person cannot have an actual title',
    it: 'Una persona fisica non può avere un titolare effettivo'
  }[lang],
  B1: { en: 'B1 - Type', it: 'B1 - Tipologia' }[lang],
  B2: { en: 'B2 - Mode of execution', it: 'B2 - Modalità di svolgimento' }[lang],
  B3: { en: 'B3 - Amount', it: 'B3 - Ammontare' }[lang],
  B4: { en: 'B4 - Frequency and duration', it: 'B4 - Frequenza e durata' }[lang],
  B5: { en: 'B5 - Type of relation', it: 'B5 - Tipo di relazione' }[lang],
  B6: { en: 'B6 - Geographic area of the client', it: 'B6 - Area geografica del cliente' }[lang],
  B7: { en: 'B7 - Geographic area of counterpart', it: 'B7 - Area geografica di controparte' }[
    lang
  ],
  B8: { en: 'B8 - Custom', it: 'B8 - Personalizzata' }[lang],
  BALANCE: { en: 'Balance', it: 'Saldo' }[lang],
  BALANCES_INSTRUCTIONS: { en: 'Instructions for balances', it: 'Istruzioni per i saldi' }[lang],
  BALANCES_LIST_OF_THE_REPORT: {
    en: 'Balances list of the report',
    it: 'Elenco saldi del rapporto'
  }[lang],
  BALANCE_AND_PAYMENTS: { en: 'Balance and payments', it: 'Saldo e pagamenti' }[lang],
  BEHAVIOUR: { en: 'Behaviour', it: 'Comportamento' }[lang],
  BIRTHDATE: { en: 'Birth Date', it: 'Data di nascita' }[lang],
  BIRTHLIST: { en: 'Birth List', it: 'Date e luoghi di nascita' }[lang],
  BIRTHPLACE: { en: 'Birth Place', it: 'Luogo di nascita' }[lang],
  BIRTHPLACE_: { en: 'Birth Place', it: 'Luogo di nascita' }[lang],
  BIRTH_DATE: { en: 'Birth date', it: 'Data di nascita' }[lang],
  BIRTH_LOCATION: { en: 'Birth location', it: 'Luogo di nascita' }[lang],
  BIRTH_PLACE: { en: 'Birth place', it: 'Luogo di nascita' }[lang],
  BIRTH_PROVINCE: { en: 'Prov.', it: 'Prov.' }[lang],
  BUY: { en: 'Buy', it: 'Compra' }[lang],
  BUY_MISSING: { en: 'Buy missing', it: 'Acquista mancanti' }[lang],
  BUY_ONLINE: { en: 'Buy online', it: 'Acquista online' }[lang],
  CAB: { en: 'CAB', it: 'CAB' }[lang],
  CALLED_FROM: { en: 'Called from', it: 'Chiamato da' }[lang],
  CALLER: { en: 'Caller', it: 'Chiamante' }[lang],
  CALLER_DEFAULT: { en: 'Caller default', it: 'Chiamante di default' }[lang],
  CANCEL: { en: 'Cancel', it: 'Annulla' }[lang],
  CANCELED_RELATIONSHIPS: { en: 'Canceled relationships', it: 'Rapporti cancellati' }[lang],
  CANCELLATION: { en: 'Cancellation', it: 'Cancellazione' }[lang],
  CANCELLATION_1: { en: 'Cancellation', it: 'Annullamento' }[lang],
  CANCELLATION_DATE: { en: 'Cancellation date', it: 'Data cancellazione' }[lang],
  CANCELLATION_REASON: { en: 'Cancellation reason', it: 'Motivo della Cancellazione' }[lang],
  CANCELLATION_REASON_1: {
    en: 'Termination with consolidation of financial activity in a single financial operator',
    it: "Cessazione con confluenza dell'attività finanziaria in un solo operatore finanziario"
  }[lang],
  CANCELLATION_REASON_2: {
    en: 'Loss of subject requirements',
    it: 'Perdita dei requisiti soggettivi'
  }[lang],
  CANCELLATION_REASON_3: {
    en: 'Termination without consolidation of financial activity in a single financial operator',
    it: 'Cessazione senza confluenza in altro soggetto'
  }[lang],
  CANCELLATION_REASON_4: {
    en: 'Termination with consolidation of financial activity in a single financial operator',
    it: "Cessazione con confluenza dell'attività finanziaria in un solo operatore finanziario"
  }[lang],
  CANCELLATION_REASON_5: {
    en: 'Termination with consolidation of financial activity in multiple financial operators',
    it: "Cessazione con confluenza dell'attività finanziaria in più operatori finanziari"
  }[lang],
  CAN_BE_COMMUNICATED_FROM_THE_FIRST_DAY_OF_THE_FOLLOWING_MONTH: {
    en: 'can be communicated from the first day of the following month',
    it: 'può essere comunicato dal primo giorno del mese successivo'
  }[lang],
  CASH: { en: 'Cash', it: 'Contanti' }[lang],
  CASH_GREATER_THAN_AMOUNT: { en: 'Cash greater than amount', it: 'Contanti maggiore di importo' }[
    lang
  ],
  CAUSAL_OPERATION_TYPE: { en: 'Causal', it: 'Causale' }[lang],
  CENTRALIZED_STRUCTURE: { en: 'Centralized structure', it: 'Struttura accentrata' }[lang],
  CENTRALIZED_STRUCTURE_DESCRIPTION: {
    en: 'For centralized structures, it is not possible to load the commitment to the transmission of an intermediary',
    it: "Per le strutture accentrate non sarà possibile caricare l'impegno alla trasmissione di un intermediario"
  }[lang],
  CERTIFICATES_DOWNLOADED: {
    en: 'The file has been downloaded correctly',
    it: 'Il file è stato scaricato correttamente'
  }[lang],
  CERTIFICATES_EXPIRED: { en: 'Certificati scaduti', it: 'Certificati scaduti' }[lang],
  CHECK_CERTIFICATES_AND_SID_INSERTED_IN_SUBJECT: {
    en: 'Check the correctness of the certificates and SID inserted in the subject',
    it: 'Verificare la correttezza dei certificati e dati SID inseriti nel soggetto'
  }[lang],
  CHECK_CONNECTION: { en: 'Check connection', it: 'Controlla connessione' }[lang],
  CHECK_FILE_AND_TRY_AGAIN: {
    en: 'Check the file and try again',
    it: 'Controllare il file e riprovare'
  }[lang],
  CHECK_LOG_FILE: { en: 'Check the LOG file', it: 'Controlla il file LOG' }[lang],
  CHECK_LOG_FILE_DESCRIPTION: {
    en: 'Check the LOG file to see the errors',
    it: 'Controlla il file LOG per vedere gli errori'
  }[lang],
  CITIZENCOUNTRY: { en: 'Citizen Country', it: 'Cittadinanza' }[lang],
  CITIZENLIST: { en: 'Citizen List', it: 'Cittadinanze' }[lang],
  CLEAR: { en: 'Clear form', it: 'Svuota campi' }[lang],
  CLEAR_SEARCH: { en: 'Clear', it: 'Pulisci' }[lang],
  CLICK_ON_THE_ICON_TO_READ_THE_CONTENT: {
    en: 'Click on the icon to read the content',
    it: "Cliccare sull'icona per leggere il contenuto"
  }[lang],
  CLICK_TO_ENABLE_CHANGES: {
    en: 'Click to enable changes',
    it: 'Clicca per abilitare le modifiche'
  }[lang],
  CLIENTS: { en: 'Clients', it: 'Clienti' }[lang],
  CLONE_RELATIONSHIP: { en: 'Clone relationship', it: 'Clona rapporto' }[lang],
  CLOSE: { en: 'Close', it: 'Chiudi' }[lang],
  CLOSED_RELATIONSHIPS: { en: 'Closed relationships', it: 'Rapporti chiusi' }[lang],
  CLOSE_AND_SAVE: { en: 'Close and save', it: 'Chiudi e salva' }[lang],
  CLOSE_WITHOUT_SAVING: { en: 'Close without saving', it: 'Chiudi senza salvare' }[lang],
  CODE: { en: 'Code', it: 'Codice' }[lang],
  COLLABORATOR: { en: 'Collaborator', it: 'Collaboratore' }[lang],
  COLLABORATORS: { en: 'Collaborators', it: 'Collaboratori' }[lang],
  COMMITMENT_TO_TRANSMISSION: { en: 'Commitment to transmission', it: 'Impegno alla trasmissione' }[
    lang
  ],
  COMMUNICANT_FISCAL_CODE: { en: 'Communicant fiscal code', it: 'C. fiscale del comunicante' }[
    lang
  ],
  COMMUNICATION_NAME: { en: 'Communication name', it: 'Nome comunicazione' }[lang],
  COMMUNICATION: { en: 'Communication', it: 'Comunicazione' }[lang],
  COMMUNICATIONS: { en: 'communication/s', it: 'comunicazione/i' }[lang],
  COMMUNICATIONS_HISTORY: { en: 'Communications history', it: 'Storico comunicazioni' }[lang],
  COMMUNICATIONS_IN_ERROR: { en: 'Communications in error', it: 'Comunicazioni in errore' }[lang],
  COMMUNICATIONS_WITH_NO_ERRORS_WILL_BE_SENT_VIA_PEC_ON_DATE: {
    en: 'Communications with no errors will be sent via PEC on date',
    it: 'Comunicazioni senza errori saranno spedite tramite PEC in data'
  }[lang],
  COMMUNICATION_ACCEPTED: { en: 'Communication accepted', it: 'Comunicazione accolta' }[lang],
  COMMUNICATION_ACCEPTED_PARTIALLY: {
    en: 'Communication accepted partially (excluding errors indicated)',
    it: 'Comunicazione accolta parzialmente (al netto degli errori indicati)'
  }[lang],
  COMMUNICATION_ACCEPTED_PARTIALLY_WITH_ERRORS: {
    en: 'Communication accepted partially (excluding errors indicated)',
    it: 'Comunicazione accolta parzialmente (al netto degli errori indicati)'
  }[lang],
  COMMUNICATION_ACCEPTED_TOTAL: {
    en: 'Communication accepted totally',
    it: 'Comunicazione accolta totalmente'
  }[lang],
  COMMUNICATION_ACCEPTED_TOTALLY: {
    en: 'Communication accepted totally',
    it: 'Comunicazione accolta totalmente'
  }[lang],
  COMMUNICATION_CREATED: {
    en: 'The communication to the R.E.I. has been created',
    it: 'La comunicazione al R.E.I. è stata creata'
  }[lang],
  COMMUNICATION_HISTORY: { en: 'Communication history', it: 'Storico comunicazioni' }[lang],
  COMMUNICATION_NOT_ACCEPTED: { en: 'Communication not accepted', it: 'Comunicazione non accolta' }[
    lang
  ],
  COMMUNICATION_REASONS: { en: 'Communication reasons', it: 'Motivi della comunicazione' }[lang],
  COMMUNICATION_REASONS_REI_SECTIONS: {
    en: 'Communication reasons (REI sections)',
    it: 'Motivi della Comunicazione (sezioni REI)'
  }[lang],
  COMMUNICATION_REJECTED: { en: 'Communication rejected', it: 'Comunicazione scartata' }[lang],
  COMMUNICATION_SCARTED_TOTAL: {
    en: 'Communication scarted totally',
    it: 'Comunicazione scartata totalmente'
  }[lang],
  COMMUNICATION_SCARTED_TOTALLY: {
    en: 'Communication scarted totally',
    it: 'Comunicazione scartata totalmente'
  }[lang],
  COMMUNICATION_TO_R_E_I_FOR: {
    en: 'Communication to R.E.I. for',
    it: 'Comunicazione al R.E.I. per'
  }[lang],
  COMMUNICATION_TYPE: { en: 'Communication type', it: 'Tipo comunicazione' }[lang],
  COMPANY_DATA: { en: 'Company data', it: 'Dati societari' }[lang],
  COMPANY_NAME: { en: 'Company name', it: 'Ragione Sociale' }[lang],
  COMPLETE_ADDRESS: {
    en: 'Address (street, number, postal code, and city)',
    it: 'Indirizzo (via, numero, cap e città)'
  }[lang],
  COMPLETE_THE_COMPLETION_OF_THE_REPORT_TO_REGISTER_AN_ELEMENT: {
    en: 'Complete the completion of the report to register an element',
    it: 'Completa la compilazione del rapporto per registrare un elemento'
  }[lang],
  COMPLETE_THE_CONFIGURATION_OR_DELETE_THE_DELAYED_SENDING_DATA_TO_CONTINUE: {
    en: 'Complete the configuration or delete the delayed sending data to continue',
    it: 'Completa la configurazione o elimina i dati di invio differito per continuare'
  }[lang],
  CONFIGURATION_PEC_PARAMETERS: {
    en: 'Configuration parameters PEC',
    it: 'Configurazione parametri PEC'
  }[lang],
  CONFIRM: { en: 'Confirm', it: 'Conferma' }[lang],
  CONFIRM_REQUEST: { en: 'Confirm request', it: 'Conferma richiesta' }[lang],
  CONFIRM_SEND_DATA: { en: 'Confirm send data', it: 'Conferma invio dati' }[lang],
  CONFIRM_SEND_DATA_DESCRIPTION: {
    en: 'If the data are correct, press the SEND button and the data will be sent securely to our assistance office for processing.',
    it: "Se i dati sono corretti, premere il bottone INVIA e i dati saranno inviati in modo sicuro al nostro ufficio assistenza per l'elaborazione."
  }[lang],
  CONNECT_REGISTRY: { en: 'Connect registry', it: 'Collega anagrafica' }[lang],
  CONTACT: { en: 'Contact', it: 'Contatto' }[lang],
  CONTACT_HISTORY: { en: 'Contact history', it: 'Storico contatti' }[lang],
  CONTACTS: { en: 'Contacts', it: 'Contatti' }[lang],
  CONTACTSUPPORT: { en: 'CONTACT SUPPORT', it: 'RICHIEDI ASSISTENZA' }[lang],
  CONTACT_EMAIL: { en: 'Contact email', it: 'Indirizzo di posta ordinario per contatti' }[lang],
  CONTACT_EMAIL_TEXT: {
    en: 'The email address of the contact',
    it: "L'indirizzo email del contatto"
  }[lang],
  CONTINUE: { en: 'Continue', it: 'Continua' }[lang],
  CONTRACTS: { en: 'Contracts', it: 'Contratti' }[lang],
  CONTRACT_DATE: { en: 'Contract date', it: 'Data contratto' }[lang],
  CONTRIBUTOR: { en: 'Contributor', it: 'Contribuente' }[lang],
  CONTROLLING: { en: 'controlling', it: 'controllanti' }[lang],
  CONTROLLING_PERSONS: { en: 'Controlling persons', it: 'Persone controllanti' }[lang],
  COPY: { en: 'Copy', it: 'Copia' }[lang],
  COPY_EMAIL: { en: 'Copy email', it: 'Copia email' }[lang],
  COPY_PASSWORD: { en: 'Copy password', it: 'Copia password' }[lang],
  CORRECTLY_READ: { en: 'Correctly read', it: 'Lette correttamente' }[lang],
  CORRECTLY_SAVED: { en: 'Subject saved correctly', it: 'Soggetto salvato correttamente' }[lang],
  COUNTERPARTS: { en: 'counterparts', it: 'controparti' }[lang],
  COUNTER_PARTS: { en: 'Counterparts', it: 'Controparti' }[lang],
  COUNTRY: { en: 'Country', it: 'Paese' }[lang],
  COUNTRY_OF_RESIDENTIAL_ADDRESS: {
    en: 'Country of residential address',
    it: 'Paese di residenza fiscale'
  }[lang],
  CREATED_DELAYED_SENDING_ON: {
    en: 'Created - Delayed sending on',
    it: 'Creato - Invio ritardato in data'
  }[lang],
  CREATED_PEC_SENT_ON: { en: 'Created - Pec sent on', it: 'Creato - PEC inviato in data' }[lang],
  CREATED_WAITING_FOR_RECEIPT: {
    en: 'Created - Waiting for receipt',
    it: 'Creato - In attesa di ricevuta'
  }[lang],
  CREATED_WAITING_RECEIPT: {
    en: 'Created - Waiting for receipt',
    it: 'Creata - In attesa di ricevuta'
  }[lang],
  CREATED_WITH_ERROR: { en: 'Created with error', it: 'Creato con errore' }[lang],
  CREATED_WITH_ERRORS: { en: 'Created with errors', it: 'Creata con errori' }[lang],
  CREATE_ACCOUNT: { en: 'Create your', it: 'Crea il tuo' }[lang],
  CREATE_AND_CONTINUE: { en: 'Create and continue', it: 'Crea e continua' }[lang],
  CREATE_COMMUNICATION: { en: 'Create communication', it: 'Crea comunicazione' }[lang],
  CREATE_EMPTY_COMMUNICATION: { en: 'Create empty communication', it: 'Crea comunicazione vuota' }[
    lang
  ],
  CREATE_EMPTY_COMMUNICATIONS_FOR_SELECTED_SUBJECTS: {
    en: 'Create empty communications for selected subjects',
    it: 'Crea comunicazioni vuote per i soggetti selezionati'
  }[lang],
  CREATE_NEW: { en: 'Create new', it: 'Crea nuovo' }[lang],
  CREATION_DATE: { en: 'Creation date', it: 'Data creazione' }[lang],
  CREDENTIALS: { en: 'Credentials', it: 'Credenziali' }[lang],
  CURRENCY: { en: 'Currency', it: 'Divisa' }[lang],
  DATA_SEPARATOR: { en: 'Data separator', it: 'Separatore di dati' }[lang],
  DATA_SAVED_SUCCESSFULLY: {
    en: 'Data saved successfully',
    it: 'Dati salvati correttamente'
  }[lang],
  DATE: { en: 'Date', it: 'Data' }[lang],
  DATE_OF_SENDING: { en: 'Date of sending', it: 'Data di invio' }[lang],
  DATE_VARIATION: { en: 'Date variation', it: 'Data variazione' }[lang],
  DBPOPUPTEXT: [
    {
      en: 'Our goal is to simplify your daily tasks, for this reason we keep our databases updated with ',
      it: 'Il nostro obiettivo è di semplificare le tue operazioni quotidiane, per questo teniamo sempre aggiornati i nostri database con i dati '
    }[lang],
    { en: 'official ', it: 'ufficiali' }[lang],
    {
      en: 'data from the most complete antiterrorism lists.',
      it: ' delle più complete liste antiterrorismo.'
    }[lang]
  ],
  DBPOPUPTEXT2: {
    en: 'Below you will find the links to the official Websites and those to directly download the antiterrorism lists that we use to make your queries here in BLUE.',
    it: 'Qui sotto trovi i collegamenti ai siti Web ufficiali e quelli diretti alle liste antiterrorismo che usiamo per eseguire le tue ricerche qui su BLUE.'
  }[lang],
  DBPOPUPTEXT3: [
    { en: 'These lists comply with the law ', it: 'Liste conformi al ' }[lang],
    { en: ' More information on the ', it: ' Maggiori dettagli sul ' }[lang],
    {
      en: `official website of the Bank of Italy's UIF.`,
      it: `sito ufficiale dell'UIF della Banca d'Italia.`
    }[lang]
  ],
  DBPOPUPBUTTONTEXT: [
    { en: 'Official Website', it: 'Sito Web Ufficiale' }[lang],
    { en: 'Download file', it: 'Scarica il file' }[lang]
  ],
  DBPOPUPTITLE: { en: 'How does BLUE work?', it: 'Come funziona BLUE?' }[lang],
  DEFAULT: { en: 'Default', it: 'Default' }[lang],
  DEFAULT_AUTHORITY: { en: 'Authority', it: 'Comune' }[lang],
  DELAYED_COMMUNICATION_IMPOSED_AT: {
    en: 'Delayed communication imposed at',
    it: 'Comunicazione ritardata imposta al'
  }[lang],
  DELETE: { en: 'Delete', it: 'Elimina' }[lang],
  DELETESUBJECTSPOPUPTITLE: {
    en: 'Delete Selected Subjects?',
    it: 'Elimina Soggetti Selezionati?'
  }[lang],
  DELETE_SELECTED_ELEMENTS: {
    en: 'Delete Selected elements?',
    it: 'Elimina elementi selezionati?'
  }[lang],
  DELETE_CONFIGURATION: { en: 'Delete configuration', it: 'Elimina configurazione' }[lang],
  DELETE_CONTACT: { en: 'Delete contact', it: 'Elimina contatto' }[lang],
  DELETE_CONTACT_HISTORY_TEXT: {
    en: 'Do you want to delete the selected contact?',
    it: 'Si desidera eliminare il contatto selezionato?'
  }[lang],
  DELETE_CONTACT_NUMBER_TEXT: {
    en: 'Are you sure you want to delete the contact number? The operation is irreversible',
    it: "Sei sicuro di voler eliminare il numero di contatto? L'operazione è irreversibile"
  }[lang],
  DELETE_CONTACT_TEXT: {
    en: 'Are you sure you want to delete the contact? The operation is irreversible',
    it: "Sei sicuro di voler eliminare il contatto? L'operazione è irreversibile"
  }[lang],
  DELETE_FROM_REGISTRY: { en: 'Delete from registry', it: 'Cancellazione dal/dai Registro/i' }[
    lang
  ],
  DELETE_SELECTED: { en: 'Delete selected', it: 'Elimina selezionati' }[lang],
  DEMO: { en: 'DEMO', it: 'DEMO' }[lang],
  DEMO_ACTIVATION: { en: 'Demo activation', it: 'Attivazione demo' }[lang],
  DEMO_AVAILABLE_FOR_30_DAYS: {
    en: 'Demo available for 30 days',
    it: 'Demo disponibile per 30 giorni'
  }[lang],
  DEMO_LICENSE_ACTIVATED: { en: 'DEMO License activated', it: 'Licenza DEMO attivata' }[lang],
  DENOMINATION: { en: 'Denomination', it: 'Ragione Sociale' }[lang],
  DENOMINATION_: { en: 'Denomination', it: 'Denominazione' }[lang],
  DENOMINATION_OBLIGATED_SUBJECT: { en: 'Subject obliged', it: 'Soggetto obbligato' }[lang],
  DESCRIPTION: { en: 'Description', it: 'Descrizione' }[lang],
  DESCRIPTION_OPERATION: { en: 'Description of the operation', it: 'Descrizione operazione' }[lang],
  DETAIL: { en: 'Detail', it: 'Dettaglio' }[lang],
  DETECTED_SAME_SID_NUMBER_ON_MULTIPLE_CORA_SUBJECTS_VERIFY_THAT_THE_SID_NUMBER_IS_ASSOCIATED_WITH_A_SINGLE_SUBJECT:
    {
      en: 'Detected same SID number on multiple CORA subjects. Verify that the SID number is associated with a single subject.',
      it: 'Rilevato stesso numero SID su più soggetti CORA. Verificare che il numero SID sia associato ad un solo soggetto.'
    }[lang],
  DISCREPANCY_BETWEEN_THE_ARCHIVES_TO_VERIFY: {
    en: 'Discrepancy between the archives to verify',
    it: 'Discordanza tra gli archivi da verificare'
  }[lang],
  DISPATCH_TYPE: { en: 'Dispatch type', it: 'Tipologia di invio' }[lang],
  DISPONIBILITY_ACCOUNTING_ORDINARY: {
    en: 'Disponibility of accounting ordinary',
    it: 'Disponibilità contabilità ordinaria'
  }[lang],
  DOCUMENT: { en: 'Document', it: 'Documento' }[lang],
  DOCUMENTLIST: { en: 'Document List', it: 'Documenti' }[lang],
  DOCUMENTS: { en: 'Documents', it: 'Documenti' }[lang],
  DOCUMENT_AUTHORITY: { en: 'Document authority', it: 'Autorità rilascio documento' }[lang],
  DOCUMENT_AUTHORITY_DETAIL: {
    en: 'Document authority detail',
    it: 'Autorità rilascio documento dettaglio'
  }[lang],
  DOCUMENT_EXPIRATION_DATE: { en: 'Document expiration date', it: 'Data scadenza documento' }[lang],
  DOCUMENT_ISSUE_DATE: { en: 'Document issue date', it: 'Data rilascio documento' }[lang],
  DOCUMENT_NUMBER: { en: 'Document number', it: 'Numero documento' }[lang],
  DOCUMENT_TYPE: { en: 'Document type', it: 'Tipo documento' }[lang],
  DOWLOADOK: {
    en: 'File has been successfully downloaded',
    it: 'Il file è stato scaricato correttamente'
  }[lang],
  DOWNLOAD: { en: 'Download', it: 'Scarica' }[lang],
  DOWNLOAD_ALL: { en: 'Download all', it: 'Scarica tutti' }[lang],
  DOWNLOAD_COMMUNICATION_TXT: { en: 'Download communication txt', it: 'Scarica comunicazione txt' }[
    lang
  ],
  DOWNLOAD_COUPLE_FILE: { en: 'Download couple file PECAT', it: 'Scarica coppia file PECAT' }[lang],
  DOWNLOAD_CSV_TEMPLATE: { en: 'Download csv template', it: 'Scarica template csv' }[lang],
  DOWNLOAD_DGN: { en: 'Download DGN', it: 'Scarica DGN' }[lang],
  DOWNLOAD_DIAGNOSTIC: { en: 'Download diagnostic', it: 'Scarica diagnostico' }[lang],
  DOWNLOAD_ERRORS: { en: 'Download errors', it: 'Scarica errori' }[lang],
  DOWNLOAD_ERROR_FILE: {
    en: 'Download here the file to see the error lines',
    it: 'Scarica qui il file per vedere le righe in errore'
  }[lang],
  DOWNLOAD_ERROR_REPORT: { en: 'Download error report', it: 'Scarica dettaglio errori' }[lang],
  DOWNLOAD_ORIGINAL_RECEIPT: {
    en: 'Download original receipt',
    it: 'Scarica file esito originale'
  }[lang],
  DOWNLOAD_PDF: { en: 'Download PDF', it: 'Scarica PDF' }[lang],
  DOWNLOAD_PDF_WITH_DATA_INDICATED: {
    en: 'Download PDF with data indicated',
    it: 'Scarica PDF con dati indicati'
  }[lang],
  DOWNLOAD_PEC_TXT: { en: 'Download PEC txt', it: 'Scarica PEC txt' }[lang],
  DOWNLOAD_PHOTOGRAPH_TXT: {
    en: 'Download photograph TXT',
    it: 'Scarica file fotografia in chiaro'
  }[lang],
  DOWNLOAD_PRINT_COMMUNICATION_PDF: {
    en: 'Download print communication PDF',
    it: 'Scarica stampa comunicazione PDF'
  }[lang],
  DOWNLOAD_PROCESSED_FILE: {
    en: 'Download processed file',
    it: 'Scarica file elaborato da SID (RUN)'
  }[lang],
  DOWNLOAD_RECEIPT: { en: 'Download receipt', it: 'Scarica ricevuta' }[lang],
  DOWNLOAD_RECEIPT_TXT: { en: 'Download receipt TXT', it: 'Scarica file esito in chiaro' }[lang],
  DOWNLOAD_RECEIVED_FILE: {
    en: 'Download received file (P7M)',
    it: 'Scarica file ricevuto se presente (P7M)'
  }[lang],
  DOWNLOAD_RUN_FILE: {
    en: 'Download RUN file from SID (RUN)',
    it: 'Scarica file elaborato da SID (RUN)'
  }[lang],
  DOWNLOAD_TEMPLATE_CSV: { en: 'Download template CSV', it: 'Scarica template CSV' }[lang],
  DOWNLOAD_XML: { en: 'Download xml', it: 'Scarica xml' }[lang],
  DO_YOU_WANT_TO_CREATE_A_COMMUNICATION_WITH_ONLY_THE_SELECTED_RELATIONSHIPS: {
    en: 'Do you want to create a communication with ONLY THE SELECTED RELATIONSHIPS?',
    it: 'Si desidera creare una comunicazione con SOLO I RAPPORTI SELEZIONATI?'
  }[lang],
  DO_YOU_WANT_TO_DELETE_THE_PEC_CONFIGURATION: {
    en: 'Do you want to delete the PEC configuration?',
    it: 'Vuoi eliminare la configurazione PEC?'
  }[lang],
  EDIT: { en: 'Edit', it: 'Modifica' }[lang],
  EDIT_SUBJECT: { en: 'Edit subject', it: 'Modifica soggetto' }[lang],
  EE: { en: 'EE', it: 'EE' }[lang],
  EE_FOR_FOREIGN_COUNTRIES: { en: 'EE for foreign countries', it: 'EE per paesi esteri' }[lang],
  EE_FOR_FOREIGN_COUNTRY: { en: 'EE for foreign country', it: 'EE per paesi esteri' }[lang],
  EFFECTIVE_DATE: { en: 'Effective date', it: 'Data decorrenza' }[lang],
  ELABORATED_IN_DATE: { en: 'Elaborated in date', it: 'Elaborato in data' }[lang],
  ELABORATE_AUTOMATICALLY_RECEIPTS: {
    en: 'Elaborate automatically receipts',
    it: 'Elabora automaticamente le ricevute'
  }[lang],
  ELABORATE_AUTOMATICALLY_RECEIPTS_TEXT: {
    en: 'Leave the seen for automatic receipt processing',
    it: 'La scia il visto per attivare la gestione automatica delle ricevute'
  }[lang],
  ELABORATE_COMMUNICATION_TXT: {
    en: 'Elaborate communication TXT',
    it: 'Rielabora comunicazione TXT'
  }[lang],
  ELABORATE_CONSISTENCY_PHOTOGRAPHY: {
    en: 'Elaborate consistency photography',
    it: 'Elaborare fotografia di consistenza'
  }[lang],
  ELABORATE_RECEIVED_FILES: { en: 'Elaborate received files', it: 'Elaborare file ricevuti' }[lang],
  ELABORATE_RECEIVED_FILES_AND_RESULTS: {
    en: 'Elaborate received files and results',
    it: 'Elaborare file ricevuti e esiti'
  }[lang],
  EMAIL: { en: 'Email', it: 'Indirizzo email' }[lang],
  EMAILADDRESS: { en: 'Email Address', it: 'Indirizzo email' }[lang],
  EMAIL_ADDRESS: { en: 'Email Address', it: 'Indirizzo email' }[lang],
  EMAIL_: { en: 'Email', it: 'Posta Elettronica' }[lang],
  EMAIL_ADDRESS_TO_RECEIVE_GENERATED_FROM: {
    en: 'Email address to receive generated from',
    it: 'Indirizzo email a cui ricevere quanto generato da'
  }[lang],
  EMAIL_ADDRESS_TO_RECEIVE_GENERATED_FROM_MIT: {
    en: 'Email address to receive generated from MIT',
    it: 'Indirizzo email a cui ricevere quanto generato da MIT'
  }[lang],
  EMAIL_ADDRESS_TO_SEND_COMMUNICATIONS: {
    en: 'Email address to send communications',
    it: 'Indirizzo email a cui spedire le comunicazioni'
  }[lang],
  EMAIL_ADDRESS_TO_SEND_COMPLETED_COMMUNICATIONS: {
    en: 'Email address to send completed communications',
    it: 'Indirizzo email a cui spedire le comunicazioni completate'
  }[lang],
  EMAIL_ADDRESS_TO_SEND_EXPORT_FILE: {
    en: 'Email address to send the export file to',
    it: 'Indirizzo email a cui inviare il file di export'
  }[lang],
  EMAIL_ADDRESS_TO_SEND_THE_EXPORT: {
    en: 'Email address to send the export',
    it: "Indirizzo email al quale inviare l'esportazione"
  }[lang],
  EMAIL_ADDRESS_TO_SEND_THE_RECEIPT_IN_CLEAR: {
    en: 'Email address to send the receipt in clear',
    it: 'Indirizzo email a cui spedire la ricevuta in chiaro'
  }[lang],
  EMAIL_CLIENT: { en: 'Email client', it: 'Email cliente' }[lang],
  EMAIL_NOT_FORMALLY_CORRECT: {
    en: 'Email not formally correct',
    it: 'Email non formalmente corretta'
  }[lang],
  EMAIL_OR_PASSWORD_WRONG: { en: 'Email or password wrong', it: 'Email o password errati' }[lang],
  EMAIL_SENDING_STARTED: { en: 'Email sending started', it: 'Invio email avviato' }[lang],
  EMAIL_SENT: { en: 'Email sent', it: 'Email inviata' }[lang],
  EMPTY: { en: 'Empty', it: 'Vuota' }[lang],
  EN: { en: 'English', it: 'Inglese' }[lang],
  ENABLED: { en: 'Enabled', it: 'Abilitato' }[lang],
  END_DATE: { en: 'End date', it: 'Data fine' }[lang],
  END_DATE_: { en: 'End date', it: 'Data cessazione' }[lang],
  END_DATE_MUST_BE_AFTER_START_DATE: {
    en: 'The end date must be after the start date',
    it: 'La data fine deve essere successiva alla data inizio'
  }[lang],
  END_DATE_NOT_BEFORE_START_DATE: {
    en: 'The end date cannot be before the start date',
    it: 'La data fine non può essere minore della data inizio'
  }[lang],
  END_DATE_RELATIONSHIP: { en: 'End date relationship', it: 'Data fine rapporto' }[lang],
  ENTER_A_DIFFERENT_DESTINATION_FROM_THE_AGENCY_OF_ENTRIES: {
    en: 'Enter a different destination from the agency of entries',
    it: "Inserire un destinatario diverso dall'agenzia delle entrate"
  }[lang],
  ENTER_A_DIFFERENT_DESTINATION_FROM_THE_AGENCY_OF_INCOMES: {
    en: 'Enter a different destination from the agency of incomes',
    it: "Inserire un destinatario diverso dall'agenzia delle entrate"
  }[lang],
  ENTITYDETAILS: { en: 'Entity Details', it: 'Dettaglio Soggetto' }[lang],
  ERROR: { en: 'Error', it: 'Errore' }[lang],
  ERRORS: { en: 'Errors', it: 'Errori' }[lang],
  ERROR_CODE: { en: 'Error code', it: 'Codice esito' }[lang],
  ERRORS_IN_COMMUNICATION: { en: 'Errors in communication', it: 'Errori nella comunicazione' }[
    lang
  ],
  ERRORS_IN_COMMUNICATION_DESCRIPTION: {
    en: 'Click on the yellow button to download the errors in clear',
    it: 'Clicca sul pulsante giallo per scaricare gli errori in chiaro'
  }[lang],
  ERRORS_IN_FILE: { en: 'Errors in file', it: 'Errori nel file' }[lang],
  ERRORS_PRESENT_IN_REPORTS_INCLUDED_IN_THE_COMMUNICATION: {
    en: 'Errors present in reports included in the communication',
    it: 'Errori presenti nei rapporti inclusi nella comunicazione'
  }[lang],
  ERROR_ADD_DOCUMENT: {
    en: 'Impossible to add document, save the operation first',
    it: "Impossibile aggiungere documento, salvare prima l'operazione"
  }[lang],
  ERROR_ADD_HOLDER: {
    en: 'It is not allowed to add legal persons in this list',
    it: 'Non è consentito inserire persone giuridiche in questa lista'
  }[lang],
  ERROR_CREATE_ZIP_FOLDER: {
    en: 'Error creating zip folder',
    it: 'Errore creazione cartella .zip'
  }[lang],
  ERROR_CREATING_FILE: {
    en: 'An error occurred while creating the file',
    it: 'Si è verificato un errore durante la creazione del file'
  }[lang],
  ERROR_CREATING_REPORT: { en: 'Error creating report', it: 'Errore creazione report' }[lang],
  ERROR_DELETING_DELEGATES: {
    en: 'An error occurred during the deletion of the data',
    it: "Si è verificato un errore durante l'eliminazione dei dati anagrafici"
  }[lang],
  ERROR_DELETING_SUBJECT: {
    en: 'Error deleting subject',
    it: "Errore durante l'eliminazione del soggetto"
  }[lang],
  ERROR_DOWNLOADING_CONTRACTS: {
    en: 'Error downloading contracts',
    it: 'Errore download contratti'
  }[lang],
  ERROR_DOWNLOADING_DOCUMENT: { en: 'Error downloading document', it: 'Errore download documento' }[
    lang
  ],
  ERROR_DOWNLOADING_OPERATION_NOT_SAVED: {
    en: 'Impossible to download: Operation not saved',
    it: 'Impossibile scaricare: Operazione non salvata'
  }[lang],
  ERROR_DOWNLOAD_DOCUMENT: { en: 'Error downloading document', it: 'Errore download documento' }[
    lang
  ],
  ERROR_DOWNLOAD_PDF: {
    en: 'An error occurred in the download of the PDF',
    it: 'Si è verificato un errore nello scaricamento del file'
  }[lang],
  ERROR_DURING_THE_VERIFICATION_OF_THE_RECEIPT: {
    en: 'Error during the verification of the receipt',
    it: 'Errore durante la verifica della ricevuta'
  }[lang],
  ERROR_EXPORTING_DATA: {
    en: 'An error occurred during the export of the data.',
    it: "Si è verificato un errore durante l'esportazione dei dati."
  }[lang],
  ERROR_EXPORTING_DATA_SUBJECTS: {
    en: 'An error occurred during the export of the subjects.',
    it: "Si è verificato un errore durante l'esportazione dei soggetti."
  }[lang],
  ERROR_EXPORTING_SUBJECTS: {
    en: 'Error exporting subjects',
    it: "Errore nell'esportazione dei soggetti"
  }[lang],
  ERROR_FILE_EXTENSION: { en: 'Extension or file name error', it: 'Estensione o nome file errati' }[
    lang
  ],
  ERROR_FILE_SIZE: { en: 'File size greater than 20MB', it: 'Dimensione file maggiore di 20MB' }[
    lang
  ],
  ERROR_FILE_TOO_LARGE: {
    en: 'File too large for processing (max 15MB). Please compact it in ZIP or RAR format and try again',
    it: "File troppo grande per l'elaborazione (max 15MB). Si prega di compattarlo in formato ZIP o RAR e riprovare"
  }[lang],
  ERROR_GENERATION_COMMUNICATION: {
    en: 'Error in the generation of the communication',
    it: 'Errore nella generazione della comunicazione'
  }[lang],
  ERROR_IMPORTING_DATA: {
    en: 'Error during the import of CORA data',
    it: "Errore durante l'importazione dei dati di CORA"
  }[lang],
  ERROR_IMPORTING_DATA_TEXT: {
    en: 'Error during the import of CORA data. <br>Check the file and try again.',
    it: "Errore durante l'importazione dei dati di CORA. <br>Controllare il file e riprovare."
  }[lang],
  ERROR_IMPORTING_SUBJECT: {
    en: 'You have reached the maximum number of purchased subjects: to import additional subjects you can purchase additional subjects',
    it: 'Hai raggiunto il numero massimo di soggetti acquistati: per importare ulteriori soggetti puoi acquistare soggetti aggiuntivi'
  }[lang],
  ERROR_IMPORTING_SUBJECT_2: {
    en: "Errore durante l'importazione del soggetto. <br>Controllare il file e riprovare.",
    it: "Errore durante l'importazione del soggetto. <br>Controllare il file e riprovare."
  }[lang],
  ERROR_IMPORTING_THE_SUBJECT: {
    en: 'Error importing the subject',
    it: "Errore nell'import del soggetto"
  }[lang],
  ERROR_IMPORTING_YEARLY_BALANCES: {
    en: 'Error importing yearly balances',
    it: "Errore durante l'importazione dei Saldi Annuali"
  }[lang],
  ERROR_INSERTING_BALANCES: {
    en: 'Error inserting balances',
    it: "Errore nell'inserimento dei saldi"
  }[lang],
  ERROR_INSERTING_DELEGATES: {
    en: 'Error inserting delegates',
    it: "Errore nell'inserimento dei delegati"
  }[lang],
  ERROR_NECESSARY_FILE: {
    en: "It's necessary to insert at least one file",
    it: "E' necessario inserire almeno un file"
  }[lang],
  ERROR_NECESSARY_SID_SUBJECT: {
    en: "It's necessary to insert the SID subject credentials to continue",
    it: "E' necessario inserire le credenziali SID del soggetto per poter continuare"
  }[lang],
  ERROR_OCCURRED_DURING_EXPORT_SUBJECTS: {
    en: 'Error occurred during export subjects',
    it: "Errore nell'esportazione dei soggetti"
  }[lang],
  ERROR_OCCURRED_DURING_IMPORT_DOCUMENTS: {
    en: 'Error occurred during the import of the documents',
    it: "Errore nell'importazione dei documenti"
  }[lang],
  ERROR_OCCURRED_DURING_OPERATION: {
    en: 'An error occurred during the operation',
    it: "Si è verificato un errore durante l'operazione"
  }[lang],
  ERROR_OCCURRED_DURING_UPDATE_SUBJECT: {
    en: 'An error occurred during the update of the subject',
    it: "Si è verificato un errore durante l'aggiornamento del soggetto"
  }[lang],
  ERROR_PROCESSING_COMMUNICATION: {
    en: 'Error in the processing of the communication',
    it: "Errore nell'elaborazione della comunicazione"
  }[lang],
  ERROR_PROCESSING_COMMUNICATION_TITLE: {
    en: 'Error in the processing of the communication',
    it: "Errore nell'elaborazione della comunicazione"
  }[lang],
  ERROR_PROCESSING_FILE: { en: 'Error processing file', it: 'Errore elaborazione file' }[lang],
  ERROR_PROCESSING_RECEIPT: {
    en: 'An error occurred in the processing of the receipt.',
    it: "Si è verificato un errore nell'elaborazione della ricevuta.."
  }[lang],
  ERROR_READING_SUBJECTS: {
    en: 'An error occurred while reading the subjects',
    it: 'Si è verificato un errore nella lettura dei soggetti'
  }[lang],
  ERROR_REQUIRED_FILE: { en: 'The file is required', it: 'Il file è obbligatorio' }[lang],
  ERROR_SAVE_OPERATION: { en: 'Error saving', it: 'Errore nel salvataggio' }[lang],
  ERROR_SAVING_CERTIFICATES: {
    en: 'An error occurred while saving the certificates',
    it: 'Si è verificato un errore durante il salvataggio dei certificati'
  }[lang],
  ERROR_SAVING_REGISTRY: { en: 'Error saving registry', it: 'Errore salvataggio anagrafica' }[lang],
  ERROR_SENDING_EMAIL: { en: 'Error sending email', it: "Errore nell'invio della email" }[lang],
  ERROR_SINGLE_COMMUNICATION: {
    en: 'It is necessary to insert only one file',
    it: "E' necessario inserire un solo file"
  }[lang],
  ERROR_UPDATING_BALANCES: {
    en: 'Error updating balances',
    it: "Errore nell'aggiornamento dei saldi"
  }[lang],
  ERROR_UPDATING_DELEGATES: {
    en: 'Error updating delegates',
    it: "Errore nell'aggiornamento dei delegati"
  }[lang],
  ERROR_UPDATING_SUBJECT: {
    en: 'Error updating subject',
    it: "Errore nell'aggiornamento del soggetto"
  }[lang],
  ERROR_WRONG_FILE_EXTENSION: { en: 'Wrong file extension', it: 'Estensione file errato' }[lang],
  EURO_UNIONE_ECONOMICA_MONETARIA: {
    en: 'EURO UNIONE ECONOMICA MONETARIA',
    it: 'EURO UNIONE ECONOMICA MONETARIA'
  }[lang],
  EXCLUDE_FROM_COMMUNICATION: {
    en: 'Exclude from communication',
    it: 'Escludi dalla comunicazione'
  }[lang],
  EXPIRATION: { en: 'Expiration', it: 'Scadenza' }[lang],
  EXPIRATION_DATE: { en: 'Expiration date', it: 'Data scadenza' }[lang],
  EXPIRATION_DATE_TEXT: { en: 'fino al', it: 'fino al' }[lang],
  EXPIRATION_DATE_TEXT_2: { en: 'scaduto il', it: 'scaduto il' }[lang],
  EXPIRED: { en: 'Expired', it: 'Scaduto' }[lang],
  EXPIRED_ON: { en: 'expired on', it: 'scaduto il' }[lang],
  EXPIREDLICENCE: (link) =>
    ({
      en: `Your licence has expired, you may only export or delete your subjects <br> Buy or renew your license
    </br><button class="btn btn-danger mt-5" onclick="location.href='${link}'" type="button">BUY OR RENEW YOUR LICENSE</button>`,
      it: `Abbonamento scaduto, è consentito solo esportare o eliminare i soggetti 
    </br> Acquista o rinnova la licenza
    </br><button class="btn btn-danger mt-5" onclick="location.href='${link}'" type="button">AQUISTA O RINNOVA</button>`
    })[lang],
  EXPLANATION_OF_THE_THEME: { en: 'Explanation of the theme', it: 'Spiegazione accurata sul tema' }[
    lang
  ],
  EXPORT: { en: 'Export', it: 'Esporta' }[lang],
  EXPORT_CERTIFICATES: { en: 'Export certificates', it: 'Esporta certificati' }[lang],
  EXPORT_SELECTED_SUBJECTS: { en: 'Export selected subjects', it: 'Esporta soggetti selezionati' }[
    lang
  ],
  EXPORT_SUBJECTS: { en: 'Export subjects', it: 'Esporta soggetti' }[lang],
  EXPORT_SUBJECTS_DESCRIPTION: {
    en: 'Through this function you can download all the data related to the selected subjects.',
    it: 'Attraverso questa funzione è possibile scaricare tutti i dati inerenti ai soggetti selezionati.'
  }[lang],
  EXPORT_SUBJECTS_STARTED: { en: 'Export subjects started', it: 'Esportazione soggetti avviata' }[
    lang
  ],
  EXPORT_YEARLY_BALANCES: { en: 'Export yearly balances', it: 'Esporta saldi annuali' }[lang],
  EXPORT_YEARLY_BALANCES_DESCRIPTION: {
    en: 'Export the balances for the selected year',
    it: "Esporta i saldi per l'anno selezionato"
  }[lang],
  EXTRAORDINARY: { en: 'Extraordinary', it: 'Straordinario' }[lang],
  FACCIAMO_NOI: { en: 'We do it', it: 'Facciamo noi' }[lang],
  FACCIAMO_NOI_TUTTO_CORA_THANK_YOU_SUBTITLE: {
    en: 'We will update you next month, good work.',
    it: 'Ci aggiorniamo il mese prossimo, buon lavoro.'
  }[lang],
  FACCIAMO_NOI_TUTTO_CORA_THANK_YOU_TITLE: {
    en: 'Perfect! Your collaboration is necessary for the good functioning of the service.',
    it: 'Perfetto! La tua collaborazione è necessaria al buon funzionamento del servizio.'
  }[lang],
  FAMILY: {
    en: 'FAMILY (Related to the relationship)',
    it: 'FAMIGLIA (Anagrafiche collegate al rapporto)'
  }[lang],
  FILE: { en: 'File', it: 'Fascicolo' }[lang],
  FILEERROR: {
    en: 'An error occurred while processing your file',
    it: "Errore durante l'elaborazione del file"
  }[lang],
  FILEREQUIRED: { en: 'A file is required', it: 'Il file è obbligatorio' }[lang],
  FILEWRONGFORMAT: { en: 'Wrong file format', it: 'Estensione file errata' }[lang],
  FILE_DOWNLOADED: { en: 'File downloaded', it: 'File scaricato' }[lang],
  FILE_EXTENSION_ERROR: { en: 'File extension or name error', it: 'Estensione o nome file errati' }[
    lang
  ],
  FILE_NAME: { en: 'File name', it: 'Nome file' }[lang],
  FILE_NOT_FOUND: { en: 'File not found', it: 'File non trovato' }[lang],
  FILE_PROCESSED_CORRECTLY: { en: 'File processed correctly', it: 'File elaborato correttamente' }[
    lang
  ],
  FILE_PROCESSED_CORRECTLY_DESCRIPTION: {
    en: 'The file has been processed correctly',
    it: 'Il file è stato elaborato correttamente'
  }[lang],
  FILE_TOO_LARGE: {
    en: 'The file cannot weigh more than 20MB',
    it: 'Il file non può pesare più di 20MB'
  }[lang],
  FILE_UPLOADED_SUCCESSFULLY: {
    en: 'File uploaded successfully',
    it: 'File caricato con successo'
  }[lang],
  FILL_ONLY_IN_CASE_OF_COMMUNICATION_SENT_VIA_ENTRATEL_OF_AN_INTERMEDIARY_DIFFERENT_FROM_THE_OBLIGED_SUBJECT:
    {
      en: 'Fill only in case of communication sent via Entratel of an intermediary (commercialist) different from the obliged subject.',
      it: 'Compilare solo nel caso di comunicazione spedita tramite Entratel di un intermediario (commercialista) diverso dal soggetto obbligato.'
    }[lang],
  FINANCIAL_INVESTIGATIONS: { en: 'Financial investigations', it: 'Indagini Finanziarie' }[lang],
  FINANCIAL_INVESTIGATIONS_ANAGRAFE_RAPPORTI: {
    en: 'Financial investigations (Anagrafe Rapporti)',
    it: 'Indagini Finanziarie (Anagrafe Rapporti)'
  }[lang],
  FIRST100: {
    en: 'Only the first 100 are displayed',
    it: 'Sono visualizzati solo i primi 100 risultati'
  }[lang],
  FIRSTLASTNAME: { en: 'Last and First Name', it: 'Cognome e Nome' }[lang],
  FIRSTLASTNAME_OR_DENOMINATION: {
    en: 'Last and first nme or denomination',
    it: 'Cognome e nome o ragione sociale'
  }[lang],
  FIRSTNAME: { en: 'First Name', it: 'Nome' }[lang],
  FIRST_NAME: { en: 'First name', it: 'Nome' }[lang],
  FIRST_REGISTRATION: { en: 'First registration', it: 'Prima iscrizione' }[lang],
  FISCALCODE: { en: 'Fiscal Code', it: 'Codice Fiscale' }[lang],
  FISCAL_CODE: { en: 'Fiscal code', it: 'Codice fiscale' }[lang],
  FISCAL_IDENTIFIER: { en: 'Fiscal identifier', it: 'ID fiscale' }[lang],
  FISCAL_CODE_SUBJECT: { en: 'Fiscal code subject', it: 'Codice fiscale soggetto' }[lang],
  FOR: { en: 'for', it: 'per' }[lang],
  FOR_30_DAYS: { en: 'for 30 days', it: 'per 30 giorni' }[lang],
  FORM_INCOMPLETE: { en: 'Form incomplete', it: 'Form incompleto' }[lang],
  FOR_FOREIGN_COUNTRIES: { en: 'for foreign countries', it: 'per paesi esteri' }[lang],
  FOR_THE_SELECTION_THE_START_DATE_OF_THE_REPORTS_WILL_BE_READ: {
    en: 'For the selection, the start date of the reports will be read',
    it: 'Per la selezione sarà letta la DATA DI INIZIO dei rapporti'
  }[lang],
  FROM_WHO_PERFORMS_THE_SENDING: {
    en: 'From who performs the SENDING',
    it: "Da chi effettua l'INVIO"
  }[lang],
  GENDER: { en: 'Gender', it: 'Sesso' }[lang],
  GENERATED_TXT_FILE_MUST_BE_CHECKED_AND_SENT_TO_REVENUE_AGENCY: {
    en: 'The generated TXT file must be checked and sent to the Revenue Agency through Entratel/Fisconline',
    it: "Il file TXT generato va controllato e spedito all'Agenzia delle Entrate tramite Entratel/Fisconline"
  }[lang],
  GENERICERROR: {
    en: 'An error occurred while saving your data',
    it: 'Si è verificato un errore durante il salvataggio dei dati'
  }[lang],
  GENERICSEARCH: { en: 'Generic Search', it: 'Ricerca Generica' }[lang],
  GENERIC_ERROR: {
    en: 'An error occurred while saving the data',
    it: 'Si è verificato un errore durante il salvataggio dei dati'
  }[lang],
  GENERIC_ERROR_IN_DATA_SAVING: {
    en: 'Generic error in data saving',
    it: 'Errore generico nel salvataggio dei dati'
  }[lang],
  GOLD_PRICE: { en: 'Gold price €/g', it: 'Prezzo Oro Puro €/g' }[lang],
  GENERICTEXT: [
    { en: 'Generic search looks for the value provided in', it: 'La ricerca generica cerca in' }[
      lang
    ],
    { en: 'every', it: 'tutti' }[lang],
    { en: ' field with the lowest ', it: ' i campi, con precisione ' }[lang],
    { en: 'precision', it: 'MINIMA' }[lang]
  ],
  HELP: [
    {
      name: { en: 'High', it: 'Alta' }[lang],
      text: [
        {
          en: 'has among their names and aliases, both the firstname and the lastname specified or the wholename specified',
          it: 'Ha tra i nomi e alias sia il nome, che il cognome, oppure il nome completo specificati'
        }[lang],
        {
          en: 'has as birth date the one specified or none',
          it: 'ha come data di nascita quella specificata o nessuna'
        }[lang],
        {
          en: 'has as birth place the one specified or none',
          it: 'ha come luogo di nascita quello specificato o nessuno'
        }[lang],
        {
          en: 'has as passport number the one specified or none',
          it: 'ha come numero di passaporto/ID quello specificato o nessuno'
        }[lang],
        {
          en: 'has as citizen country the one specified or none',
          it: 'ha come paese di cittadinanza quello specificato o nessuno'
        }[lang]
      ]
    },
    {
      name: { en: 'Medium', it: 'Media' }[lang],
      text: [
        {
          en: 'have among their names and aliases, both the firstName and the lastName specified or the wholeName specified',
          it: 'ha tra i nomi e alias sia il nome, che il cognome, oppure il nome completo specificati'
        }[lang]
      ]
    },
    {
      name: { en: 'Low', it: 'Bassa' }[lang],
      text: [
        {
          en: 'have among their names and aliases, either the firstName or the lastName or the wholeName specified',
          it: 'ha tra i nomi e alias o il nome, o il cognome o il nome completo specificati'
        }[lang]
      ]
    },
    {
      name: { en: 'Lowest', it: 'Minima' }[lang],
      text: [
        {
          en: 'have among their names and aliases, either the firstName or the lastName or the wholeName specified even if a portion of the string is provided. (e.g. you search Moham and get Mohammad, Mohammud, Moham Baqi etc.)',
          it: 'ha tra i nomi e alias o il nome o il cognome o il nome completo specificati, anche se viene fornita solo una porzione della stringa. (es. cerchi Moham e trovi Mohammad, Mohammud, Moham Baqi ecc.)'
        }[lang]
      ]
    }
  ],
  HELPTITLE: { en: 'Precision Guide', it: 'Guida Precisione' }[lang],
  HIGH: { en: 'High', it: 'Alta' }[lang],
  HIGH_RISK: { en: 'High risk', it: 'Rischio Alto' }[lang],
  HIGH_RISK_BEHAVIOUR: { en: 'High risk behaviour', it: 'Comportamento Rischio Alto' }[lang],
  HIGH_RISK_MAX: { en: 'High risk max', it: 'Rischio Alto Massimo' }[lang],
  HIGH_RISK_MIN: { en: 'High risk min', it: 'Rischio Alto Minimo' }[lang],
  HISTORIC_SUBJECTS_NOT_COUNTED: {
    en: 'Historic subjects (not counted)',
    it: 'Soggetti storici (non conteggiati)'
  }[lang],
  HISTORY: { en: 'History', it: 'Storico' }[lang],
  HISTORYTITLE: { en: 'Search History', it: 'Storico ricerche' }[lang],
  HISTORY_OF_CONSISTENCY_PHOTOGRAPHS_OR_RESULTS: {
    en: 'History of consistency photographs/results',
    it: 'Storico fotografie di consistenza/esiti'
  }[lang],
  HOLDER: { en: 'Holder', it: 'Titolare' }[lang],
  HOLDER_OF: { en: 'Holder of', it: 'Titolare di' }[lang],
  HOLDER_OF_THE_FINANCIAL_RELATIONSHIP: {
    en: 'Holder of the financial relationship',
    it: 'Titolare del rapporto finanziario'
  }[lang],
  HOLDER_TYPE: { en: 'Holder type', it: 'Tipo titolare' }[lang],
  ID_FISCAL_OICR_TRUST: { en: 'Id Fiscal OICR/TRUST', it: 'Id Fiscal OICR/TRUST' }[lang],
  ID_RESULT: { en: 'ID result', it: 'ID esito' }[lang],
  IF_NOTHING_IS_INDICATED_ALL_THE_REPORTS_PRESENT_IN_THE_PHOTOGRAPHY_WILL_BE_ELABORATED: {
    en: 'If nothing is indicated, all the reports present in the photography will be elaborated',
    it: 'Se non è indicato nulla, tutti i rapporti presenti nella fotografia verranno elaborati'
  }[lang],
  IF_RELATIONSHIP_NOT_DUPLICATED_PRESS_CONFIRM: {
    en: 'If the relationship is NOT duplicated, press CONFIRM, otherwise CLOSE WITHOUT SAVING and the relationship will NOT be recorded.',
    it: 'Se il rapporto non è duplicato, premere CONFIRM, altrimenti CHIUDI SENZA SALVARE e il rapporto non sarà registrato.'
  }[lang],
  IF_YOU_WANT_TO_INSERT_RELATIONSHIPS_WITHOUT_UNIQUE_CODE_FILL_THE_POSITION_FIELD_WITH_VALUE_1_IN_CORRESPONDENCE_WITH_THE_FIRST_OWNER:
    {
      en: 'If you want to insert relationships without unique code, fill the "Position" field with value 1 in correspondence with the first owner',
      it: 'Se si desidera inserire rapporti senza codice univoco, compilare il campo "Posizione" con valore 1 in corrispondenza del primo titolare'
    }[lang],
  IMPORT: { en: 'Import', it: 'Importa' }[lang],
  IMPORTED: { en: 'Imported', it: 'Importato' }[lang],
  IMPORT_A_ZIP_7Z_RAR_FILE_WITH_ARCO_DESKTOP_DOCUMENTS: {
    en: 'Import a zip/7z/rar file with ARCO desktop documents',
    it: 'Importa un file zip/7z/rar con i documenti di ARCO desktop'
  }[lang],
  IMPORT_BALANCES: { en: 'Import balances', it: 'Importa saldi' }[lang],
  IMPORT_DATA_COMPLETED_CORRECTLY: {
    en: 'Import data completed correctly',
    it: 'Importazione dati completata correttamente'
  }[lang],
  IMPORT_DATA_FROM_THE_LATEST_CONSISTENCY: {
    en: 'Through this screen, you can import the data from the reports found in the last processed photograph that are not already present in Cora Web. Upon startup, an export of the subject will be sent to the specified email address.',
    it: "Tramite questa schermata, è possibile importare i dati dei rapporti presenti sull'ultima fotografia elaborata, non presenti su cora web. All'avvio, verrà recapitata una esportazione del soggetto alla mail specificata."
  }[lang],
  IMPORT_DOCUMENTS: { en: 'Import documents', it: 'Importa documenti' }[lang],
  IMPORT_DOCUMENTS_SUCCESSFULLY: {
    en: 'Successfully imported',
    it: 'Importazione effettuata con successo'
  }[lang],
  IMPORT_FILE: { en: 'Import file', it: 'Importa file' }[lang],
  IMPORT_RELATIONSHIPS: { en: 'Import relationships', it: 'Importa rapporti' }[lang],
  IMPORT_RELATIONSHIPS_COMPLETED: {
    en: 'Import relationships completed',
    it: 'Importazione rapporti terminata'
  }[lang],
  IMPORT_RELATIONSHIPS_FROM_CSV_FILE_ON_DATE: {
    en: 'Import relationships from CSV file on date',
    it: 'Importazione rapporti da file CSV del'
  }[lang],
  IMPORT_RELATIONSHIPS_FROM_TXT: {
    en: 'Import relationships from communication TXT',
    it: 'Importa rapporti da comunicazione TXT'
  }[lang],
  IMPORT_REPORTS_FROM_THE_LATEST_CONSISTENCY_TITLE: {
    en: 'Import reports from the latest consistency',
    it: 'Importa rapporti da ultima fotografia'
  }[lang],
  IMPORT_SUBJECT: { en: 'Imported subject:', it: 'Soggetto importato:' }[lang],
  IMPORT_SUBJECTS_FROM_CRS_DESKTOP: {
    en: 'Import subjects from CRS DESKTOP',
    it: 'Importa soggetti da CRS DESKTOP'
  }[lang],
  IMPORT_SUBJECTS_TO_YOUR_LIST: {
    en: 'Import subjects to your list',
    it: 'Importa soggetti alla tua lista'
  }[lang],
  IMPORT_SUBJECT_FROM_TXT: {
    en: 'Import subject from communication TXT',
    it: 'Importa soggetto da comunicazione TXT'
  }[lang],
  IMPORT_YEARLY_BALANCES: { en: 'Import yearly balances', it: 'Importa saldi annuali' }[lang],
  IMPORT_YEARLY_BALANCES_DESCRIPTION: {
    en: 'Import the balances for the selected year',
    it: "Importa i saldi per l'anno selezionato"
  }[lang],
  IMPOSSIBLE_TO_GENERATE_FILE: {
    en: 'Impossible to generate the file',
    it: 'Impossibile generare il file'
  }[lang],
  INCLUDE_IN_NEXT_COMMUNICATION: {
    en: 'Include in next communication',
    it: 'Includi nella prossima comunicazione'
  }[lang],
  INCOMING_MAIL_FOLDER: { en: 'Incoming mail folder', it: 'Casella di posta in entrata' }[lang],
  INCOMING_MAIL_FOLDER_TEXT: {
    en: 'The name of the folder configured by your manager for the reception of messages',
    it: 'Il nome della casella configurata dal vostro gestore per la ricezione dei messaggi'
  }[lang],
  INCOMING_MAIL_HOST: { en: 'Incoming mail host', it: 'Server posta in entrata' }[lang],
  INCOMING_MAIL_HOST_TEXT: {
    en: 'The parameters of the configuration of your PEC manager',
    it: 'I parametri di configurazione del vostro gestore della PEC'
  }[lang],
  INCOMING_MAIL_PORT: { en: 'Incoming mail port', it: 'Porta posta in entrata' }[lang],
  INCOMPLETEDATA: { en: 'Incomplete form', it: 'Form incompleto' }[lang],
  INCONGRUENT_RANGE: { en: 'Incongruent range', it: 'Range incongruente' }[lang],
  INCORPORATING_FISCAL_CODE_FOUR: {
    en: 'Incorporating fiscal code 4',
    it: 'Codice fiscale operatore incorporante 4'
  }[lang],
  INCORPORATING_FISCAL_CODE_ONE: {
    en: 'Incorporating fiscal code 1',
    it: 'Codice fiscale operatore incorporante 1'
  }[lang],
  INCORPORATING_FISCAL_CODE_THREE: {
    en: 'Incorporating fiscal code 3',
    it: 'Codice fiscale operatore incorporante 3'
  }[lang],
  INCORPORATING_FISCAL_CODE_TWO: {
    en: 'Incorporating fiscal code 2',
    it: 'Codice fiscale operatore incorporante 2'
  }[lang],
  INCORRECT_EMAIL_ADDRESS: { en: 'Incorrect email address', it: 'Indirizzo email non corretto' }[
    lang
  ],
  INCORRECT_FISCAL_CODE: {
    en: 'Incorrect fiscal code',
    it: 'Codice fiscale inserito non corretto'
  }[lang],
  INDIVIDUAL: { en: 'Individual', it: 'Persona fisica' }[lang],
  INFORMATION: { en: 'Information', it: 'Informazioni' }[lang],
  INFO: { en: 'Info', it: 'Info' }[lang],
  INFORM_A_DIFFERENT_DESTINATION_FROM_AGENCY_OF_INCOME: {
    en: 'Inform a different destination from the agency of income',
    it: "Inserire un destinatario diverso dall'agenzia delle entrate"
  }[lang],
  INSERT_A_DIFFERENT_DESTINATION_FROM_THE_AGENCY_OF_INCOMES: {
    en: 'Insert a different destination from the agency of incomes',
    it: "Inserire un destinatario diverso dall'agenzia delle entrate"
  }[lang],
  INSERT_A_VALID_EMAIL_FOR_THE_EXPORT: {
    en: 'Insert a valid email for the export',
    it: "Inserire un indirizzo email valido per l'esportazione"
  }[lang],
  INSERT_CALLER: { en: 'Insert caller', it: 'Inserisci chiamante' }[lang],
  INSERT_COMMUNICATION_NAME_PARTIAL: {
    en: 'Insert communication name, even partial (min. 5 characters)',
    it: 'Inserire nome della comunicazione, anche parziale (min. 5 caratteri)'
  }[lang],
  INSERT_CONTACT: { en: 'Insert contact', it: 'Inserisci contatto' }[lang],
  INSERT_DENOMINATION: { en: 'Insert denomination', it: 'Inserisci ragione sociale' }[lang],
  INSERT_DENOMINATION_PARTIAL: {
    en: 'Insert denomination of the owner of the report, even partial (min. 5 characters)',
    it: 'Inserire denominazione del titolare del rapporto, anche parziale (min. 5 caratteri)'
  }[lang],
  INSERT_EMAIL_ADDRESS_DIFFERENT_FROM_AGENCY: {
    en: 'Insert an email address different from the agency',
    it: "Inserire un indirizzo email diverso dall'agenzia delle entrate"
  }[lang],
  INSERT_NOTES: { en: 'Insert notes', it: 'Inserisci note' }[lang],
  INSERT_NUMBER: { en: 'Insert number', it: 'Inserisci numero' }[lang],
  INSERT_UNIQUE_CODE_PARTIAL: {
    en: 'Insert unique code, even partial (min. 5 characters)',
    it: 'Inserire codice univoco, anche parziale (min. 5 caratteri)'
  }[lang],
  INSERT_VALID_DATE: { en: 'Insert valid date', it: 'Inserire data valida' }[lang],
  INSTRUCTIONS: { en: 'Instructions', it: 'Istruzioni' }[lang],
  INSTRUCTIONS_FOR_COMPLETING_ANNUAL_BALANCES: {
    en: 'Instructions for completing annual balances',
    it: 'Istruzioni per la compilazione saldi annuali'
  }[lang],
  INSTRUCTIONS_FOR_RENEWAL: {
    en: 'Instructions for renewal in',
    it: 'Istruzioni per il rinnovo in'
  }[lang],
  INTEGRATE_EXISTING_DELEGATES_AND_MISSING_BALANCES: {
    en: 'Integrate existing delegates and missing balances',
    it: 'Integra delegati e saldi mancanti nei rapporti esistenti'
  }[lang],
  INVALID: { en: 'not valid', it: 'non valido' }[lang],
  INVALID_DATE: { en: 'Insert a valid date', it: 'Inserire una data valida' }[lang],
  INVALID_EMAIL: { en: 'not valid', it: 'non valido' }[lang],
  INVALID_END_DATE_FOR_NEW_RELATIONSHIP: {
    en: 'not valid for new relationship',
    it: 'non valida per comunicazione nuovo rapporto'
  }[lang],
  INVALID_FILE_EXTENSION: { en: 'Invalid file extension', it: 'Estensione o nome file errati' }[
    lang
  ],
  INVALID_FILE_EXTENSION_OR_NAME: {
    en: 'Invalid file extension or name',
    it: 'Estensione o nome file errati'
  }[lang],
  INVALIDEMAILFORMAT: { en: 'Invalid email format', it: 'Indirizzo email non valido' }[lang],
  INVALID_FISCAL_CODE: { en: 'invalid', it: 'non valido' }[lang],
  ISSUANCE_DATE: { en: 'Issuance date', it: 'Data emissione' }[lang],
  IS_MANDATORY_TO_INSERT_AT_LEAST_ONE_CONTROLLING_PERSON_FOR_THE_SELECTED_TYPE_OF_OWNER: {
    en: 'It is mandatory to insert at least one controlling person for the selected type of owner',
    it: 'È obbligatorio inserire almeno una persona controllante per il tipo titolare selezionato'
  }[lang],
  IS_NOT_VALID_VERIFY_EXTENSION: {
    en: "is not valid. Verify extension (.run, .p7m, .enc), name (without parentheses), size (max 5MB) and the presence of special characters in the file name: { } ( ) [ ] | ~ ` $ % & * + - = < > @ # ^ _  / ; : '.",
    it: "non è valido. Verificare estensione (.run, .p7m, .enc), nome (senza parentesi), dimensioni (max 5MB) e l'eventuale presenza di caratteri speciali nel nome del file: { } ( ) [ ] | ~ ` $ % & * + - = < > @ # ^ _  / ; : '."
  }[lang],
  IT: { en: 'Italiano', it: 'Italiano' }[lang],
  IT_MAY_BE_NECESSARY_TO_REINSERT_REPORTS_AND_CERTIFICATES: {
    en: 'It may be necessary to reinsert reports and certificates',
    it: 'Potrebbe essere necessario reinserire rapporti e certificati'
  }[lang],
  I_UNDERSTAND: { en: 'I understand', it: 'Ho capito' }[lang],
  LANGUAGE: { en: 'Language', it: 'Lingua' }[lang],
  LASTNAME: { en: 'Last Name', it: 'Cognome' }[lang],
  LAST_CALL: { en: 'Last call', it: 'Ultima telefonata' }[lang],
  LAST_COMMUNICATION: { en: 'Last communication', it: 'Ultima comunicazione' }[lang],
  LAST_COMMUNICATION_CANCELLATION: {
    en: 'Last communication cancellation',
    it: 'Ultima comunicazione cancellazione'
  }[lang],
  LAST_COMMUNICATION_IN_ERROR: {
    en: 'Last communication in error',
    it: 'Ultima comunicazione in errore'
  }[lang],
  LAST_MODIFICATION_DATE: { en: 'Last modification date', it: 'Data ultima modifica' }[lang],
  LAST_NAME: { en: 'Last name', it: 'Cognome' }[lang],
  LAST_ORDER: { en: 'Last order', it: 'Ultimo ordine' }[lang],
  LEGALPERSON: { en: 'Legal Person', it: 'Persona Giuridica' }[lang],
  LEGAL_ADDRESS: { en: 'Legal address', it: 'Sede legale' }[lang],
  LEGAL_NAME: { en: 'Legal name', it: 'Ragione sociale' }[lang],
  LEGAL_NAME_OICR_TRUST: { en: 'Legal name OICR or TRUST', it: 'Ragione sociale OICR o TRUST' }[
    lang
  ],
  LEGAL_PERSON: { en: 'Legal person', it: 'Persona giuridica' }[lang],
  LEGAL_PERSON_DECLARATION: {
    en: 'Legal person declaration',
    it: 'Dichiarazione persona giuridica'
  }[lang],
  LEGAL_SEAT: { en: 'Legal seat (Locality)', it: 'Sede legale (Località)' }[lang],
  LEGAL_SEAT_ADDRESS: { en: 'Legal seat address', it: 'Indirizzo sede legale' }[lang],
  LEGAL_SEAT_COMMUNITY: { en: 'Legal seat community', it: 'Comune sede legale' }[lang],
  LEGAL_SEAT_LOCATION: { en: 'Legal seat location', it: 'Località sede legale' }[lang],
  LICENSE_EXPIRATION_BEFORE_SELECTED_DATE: {
    en: 'The license purchased expires before the selected date',
    it: 'La licenza acquistata scade precedentemente alla data selezionata'
  }[lang],
  LICENSE_EXPIRED_PURCHASE_OR_RENEW_LICENSE: {
    en: 'License expired, purchase or renew license',
    it: 'Licenza scaduta, acquistare o rinnovare licenza'
  }[lang],
  LICENCES: { en: 'Licences', it: 'Licenze' }[lang],
  LICENSE_TYPE: { en: 'License Type', it: 'Tipo Licenza' }[lang],
  LINE: { en: 'Line', it: 'Riga' }[lang],
  LINES: { en: 'lines', it: 'righe' }[lang],
  LIST_INITIAL: { en: 'List initial', it: 'Elenco iniziale' }[lang],
  LIST_OF_ANAGRAFIC_RELATED_TO_THE_RELATIONSHIP: {
    en: 'List of anagrafic related to the relationship',
    it: 'Elenco anagrafiche collegate al rapporto'
  }[lang],
  LIST_OF_PHYSICAL_PERSONS: { en: 'List of physical persons', it: 'Elenco persone fisiche' }[lang],
  LIST_OF_RELATIONSHIPS: { en: 'List of relationships', it: 'Elenco rapporti' }[lang],
  LIST_OF_RELATIONSHIPS_CANCELED_AND_COMMUNICATED: {
    en: 'List of relationships canceled and communicated',
    it: 'Elenco rapporti cancellati e comunicati'
  }[lang],
  LIST_OF_RELATIONSHIPS_CLOSED: {
    en: 'List of relationships closed',
    it: 'Elenco rapporti chiusi'
  }[lang],
  LIST_OF_RELATIONSHIPS_ONLY_OPEN: {
    en: 'List of relationships only open',
    it: 'Elenco rapporti solo aperti'
  }[lang],
  LOAD: { en: 'Load', it: 'Carica' }[lang],
  LOADING: { en: 'Loading...', it: 'Caricamento...' }[lang],
  LOADING_TEXT: { en: 'We are processing your request', it: 'Stiamo elaborando la tua richiesta' }[
    lang
  ],
  LOAD_COMMUNICATION_FILE: { en: 'Load communication file', it: 'Carica file comunicazione' }[lang],
  LOAD_CSV_FILE: { en: 'Load CSV file', it: 'Carica file CSV' }[lang],
  LOAD_DATA_FILE: { en: 'Load data file', it: 'Carica file dati' }[lang],
  LOAD_DATA_FILES: { en: 'Load data files', it: 'Carica file dati' }[lang],
  LOAD_FILE: { en: 'Load file', it: 'Carica file' }[lang],
  LOAD_RECEIPT_FILE: { en: 'Load receipt file', it: 'Carica file ricevuto' }[lang],
  LOAD_RECEIPT_FILE_TIP: {
    en: 'Caricare file RICEVUTA DI TRASMISSIONE (ATPECRQ) o ESITI (ATPECXX)',
    it: 'Caricare file RICEVUTA DI TRASMISSIONE (ATPECRQ) o ESITI (ATPECXX)'
  }[lang],
  LOCALITY: { en: 'Locality', it: 'Località' }[lang],
  LOCATION: { en: 'Location', it: 'Località' }[lang],
  LOCATION_: { en: 'Location', it: 'Comune' }[lang],
  LOCATION_LEGAL: { en: 'Location legal', it: 'Località sede legale' }[lang],
  LOGGED_IN_AS: { en: 'LOGGED IN AS', it: 'LOGGATO COME' }[lang],
  LOGIN: { en: 'Login', it: 'Accedi' }[lang],
  LOGOUT: { en: 'Logout', it: 'Esci' }[lang],
  LOST_PASSWORD: { en: 'Lost password?', it: 'Hai dimenticato la password?' }[lang],
  LOW: { en: 'Low', it: 'Bassa' }[lang],
  LOWEST: { en: 'Lowest', it: 'Minima' }[lang],
  LOW_RISK: { en: 'Low risk', it: 'Rischio Basso' }[lang],
  LOW_RISK_BEHAVIOUR: { en: 'Low risk behaviour', it: 'Comportamento Rischio Basso' }[lang],
  LOW_RISK_MAX: { en: 'Low risk max', it: 'Rischio Basso Massimo' }[lang],
  LOW_RISK_MIN: { en: 'Low risk min', it: 'Rischio Basso Minimo' }[lang],
  MAIL_PARAMETERS_VERIFIED_SUCCESSFULLY: {
    en: 'Mail parameters verified successfully',
    it: 'Parametri verificati con successo'
  }[lang],
  MANAGE_TABLES: { en: 'Manage tables', it: 'Gestione tabelle' }[lang],
  MANUALS_AND_REGULATIONS: { en: 'Manuals and regulations', it: 'Manuali e regolamenti' }[lang],
  MASSIVE: { en: 'Massive Search from CSV file', it: 'Ricerca massiva da file CSV' }[lang],
  MASSIVE100LIMIT: {
    en: 'The maximum number of subjects allowed is 100',
    it: 'La ricerca è limitata a 100 soggetti'
  }[lang],
  MASSIVEINSTRUCTION: {
    en: 'Download massive Search Instructions',
    it: 'Scarica Istruzioni Ricerca massiva'
  }[lang],
  MASSIVEPOPUPDROPFILE: [
    { en: 'Drop the CSV file here', it: 'Rilascia il file CSV qui' }[lang],
    { en: 'or click to select it.', it: 'o fai click per selezionarlo.' }[lang],
    { en: 'Confirm', it: 'Conferma' }[lang],
    {
      en: `You'll receive an email with the results when ready.`,
      it: 'Ti verrà inviata una email riepilogativa quando i risultati saranno pronti.'
    }[lang]
  ],
  MASSIVEPOPUPEMAIL: {
    en: 'Insert the email where you want to receive the results',
    it: `Inserisci l'email in cui vuoi ricevere i risultati`
  }[lang],
  MASSIVEPOPUPTEXT: [
    {
      en: 'To make a massive search it is necessary to upload a ',
      it: 'Per effettuare la ricerca massiva è necessario caricare un file '
    }[lang],
    {
      en: ' file containing, in each row, a subject to be searched ',
      it: ' contenente in ogni riga un soggetto da ricercare'
    }[lang],
    { en: 'Download the example file.', it: 'Scarica qui il file di esempio.' }[lang]
  ],
  MASSIVEPOPUPTITLE: { en: 'Massive Search', it: 'Ricerca Massiva' }[lang],
  MASSIVESEARCHNOTAUTHORIZED: {
    en: 'Massive search is not enabled for this user',
    it: 'Utente non autorizzato alla ricerca massiva'
  }[lang],
  MAX: { en: 'Max', it: 'Massimo' }[lang],
  MAX_20_CHARACTERS: {
    en: 'Password protection (Max 20 characters)',
    it: 'Password di protezione (Massimo 20 caratteri)'
  }[lang],
  MAXSUBJECTSREACHED: {
    en: 'Your license has reached the maximum amount of subjects.',
    it: 'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
  }[lang],
  MAX_SUBJECTS_REACHED: {
    en: 'You have reached the maximum number of subjects manageable with this license.',
    it: 'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
  }[lang],
  MEDIUM: { en: 'Medium', it: 'Media' }[lang],
  MEDIUM_RISK: { en: 'Medium risk', it: 'Rischio Medio' }[lang],
  MEDIUM_RISK_BEHAVIOUR: { en: 'Medium risk behaviour', it: 'Comportamento Rischio Medio' }[lang],
  MEDIUM_RISK_MAX: { en: 'Medium risk max', it: 'Rischio Medio Massimo' }[lang],
  MEDIUM_RISK_MIN: { en: 'Medium risk min', it: 'Rischio Medio Minimo' }[lang],
  MIN: { en: 'Min', it: 'Min' }[lang],
  MIN_8_CHARACTERS: { en: 'Insert at least 8 characters', it: 'Inserire almeno 8 caratteri' }[lang],
  MONITORING_REASON: { en: 'Monitoring reason', it: 'Motivo di monitoraggio' }[lang],
  MONITORING_TAXES: { en: 'Monitoring taxes', it: 'Monitoraggio fiscale' }[lang],
  NAME: { en: 'Name', it: 'Nome' }[lang],
  NAMELIST: { en: 'Names', it: 'Nomi' }[lang],
  NAME_AND_SURNAME: { en: 'Name and surname', it: 'Nome e cognome' }[lang],
  NAME_SURNAME_OR_DENOMINATION: {
    en: 'Name, Surname or Denomination',
    it: 'Nome, cognome o ragione sociale'
  }[lang],
  NATION: { en: 'Nation', it: 'Nazione' }[lang],
  NATURE: { en: 'Nature', it: 'Natura' }[lang],
  NATURE_ASSIGNMENT: { en: 'Assignment', it: 'Incarico' }[lang],
  NATURE_MANDATE: { en: 'Mandate', it: 'Mandato' }[lang],
  NATURE_PROFESSIONAL_PERFORMANCE: {
    en: 'Professional performance',
    it: 'Presazione Professionale'
  }[lang],
  NATURE_REAL_ESTATE: { en: 'Real estate', it: 'Compravendita' }[lang],
  NEW_CLIENT: { en: 'New client', it: 'Nuovo cliente' }[lang],
  NEW_CONTACT: { en: 'New contact', it: 'Nuovo contatto' }[lang],
  NEW_CREDENTIALS_CERTIFICATES: {
    en: 'New credentials certificates',
    it: 'Nuove credenziali certificati'
  }[lang],
  NEW: { en: 'New', it: 'Nuovo' }[lang],
  NEW_DATA: { en: 'New data', it: 'Nuovi dati' }[lang],
  NEW_OPERATION: { en: 'New operation', it: 'Nuova operazione' }[lang],
  NEW_REGISTRY: { en: 'New registry', it: 'Nuova anagrafica' }[lang],
  NEW_RELATIONSHIP: { en: 'New relationship', it: 'Nuovo rapporto' }[lang],
  NEW_REPORT: { en: 'New report', it: 'Nuovo rapporto' }[lang],
  NEW_SUBJECT: { en: 'New subject', it: 'Nuovo soggetto' }[lang],
  NO: { en: 'No', it: 'No' }[lang],
  NOENTITY: { en: 'No entity found', it: 'Nessun soggetto trovato' }[lang],
  NON_PROCESSED_LINES: { en: 'Non processed lines', it: 'Righe non elaborate' }[lang],
  NON_PROCESSED_LINES_DESCRIPTION: { en: 'Non processed lines', it: 'Righe non elaborate' }[lang],
  NORESULTS: { en: 'No results to show', it: 'Non ci sono risultati da mostrare' }[lang],
  NOTE: { en: 'Notes', it: 'Note' }[lang],
  NOTES: { en: 'Notes', it: 'Note' }[lang],
  NOTES_PLACEHOLDER: { en: 'not for AUI purposes', it: 'non ai fini AUI' }[lang],
  NOTVALID: { en: 'not valid', it: 'non valido' }[lang],
  NOT_ALLOWED_TO_INSERT_LEGAL_PERSONS: {
    en: 'It is not allowed to insert legal persons in this list',
    it: 'Non è consentito inserire persone giuridiche in questa lista'
  }[lang],
  NOT_FACTA_REASON_SELECTABLE: {
    en: 'The facta reason cannot be selected if the subject type is 29',
    it: 'Il motivo di fattura non può essere selezionato se il tipo di soggetto è 29'
  }[lang],
  NOT_MANDATORY_RENEWAL_NO_NEW_COMMUNICATION: {
    en: 'Not mandatory. If indicated, in case of renewal, NO new communication must be made',
    it: 'non obbligatoria. Se indicata in caso di rinnovo NON deve essere fatta una nuova comunicazione'
  }[lang],
  NOT_MONITORING_REASON_SELECTABLE: {
    en: 'The monitoring reason cannot be selected if the subject type is 05',
    it: 'Il motivo di monitoraggio non può essere selezionato se il tipo di soggetto è 05'
  }[lang],
  NOT_REGISTRY_REASON_SELECTABLE: {
    en: 'The registry reason cannot be selected if the subject type is 29',
    it: 'Il motivo di anagrafica non può essere selezionato se il tipo di soggetto è 29'
  }[lang],
  NOT_REQUIRED_DO_NOT_INDICATE: {
    en: 'It is no longer required, therefore it must not be indicated',
    it: 'non è più prevista quindi NON va indicata'
  }[lang],
  NO_ACCOUNT: { en: 'No account?', it: 'Non hai un account?' }[lang],
  NO_BALANCES_ALLOWED_MESSAGE: {
    en: 'Saldi non ammessi per questa tipologia di rapporto',
    it: 'Saldi non ammessi per questa tipologia di rapporto'
  }[lang],
  NO_BALANCES_FOUND: { en: 'No balances found', it: 'Nessun saldo trovato' }[lang],
  NO_COMMUNICATION_HISTORY_FOUND: {
    en: 'No communication history found',
    it: 'Nessun storico comunicazioni trovato'
  }[lang],
  NO_LATEST_CONSISTENCY_FOUND: {
    en: 'No latest consistency found',
    it: 'Nessuna fotografia elaborata con questo soggetto'
  }[lang],
  NO_PHOTOGRAPH_OR_RESULTS_FOUND: {
    en: 'No photograph/results found',
    it: 'Nessuna fotografia/esiti trovata'
  }[lang],
  NO_RELATIONSHIPS_TO_SEND: { en: 'No relationships to send', it: 'Nessun rapporto da spedire' }[
    lang
  ],
  NO_RESULTS: { en: 'No results to show', it: 'Non ci sono risultati da mostrare' }[lang],
  NO_RESULTS_TO_SHOW: { en: 'No results to show', it: 'Nessun risultato da mostrare' }[lang],
  NUMBER: { en: 'Number', it: 'Numero' }[lang],
  NUMBER_OF_SUBJECTS: { en: 'Number of subjects', it: 'Numero di soggetti' }[lang],
  NUMBER_REQUEST_REGISTRATION_SERVICE_FISCONLINE: {
    en: 'Number request registration service Fisconline',
    it: 'Numero richiesta registrazione servizio Fisconline'
  }[lang],
  NUMBER_TYPE: { en: 'Number type', it: 'Tipo numero' }[lang],
  NUMERATED_CV_RECEIVED: { en: 'Numbered CV received', it: 'CV numerato ricevuto' }[lang],
  N_SUBJECTS: { en: 'N. Subjects', it: 'N. Soggetti' }[lang],
  OBLIGATED_SUBJECT: { en: 'Obligated subject', it: 'Soggetto obbligato' }[lang],
  OICR_OR_TRUST: { en: 'OICR or TRUST', it: 'OICR o TRUST' }[lang],
  OICR_TRUST_ID_WITHOUT_LEGAL_PERSON: {
    en: "OICR/TRUST ID without legal person issued by Banca d'Italia (5 characters) or ID Fiscale TRUST (16 characters)",
    it: "ID OICR senza p. giuridica rilasciato da Banca d'Italia (5 caratteri) oppure ID Fiscale TRUST (16 caratteri)"
  }[lang],
  OK: { en: 'Ok, Capito', it: 'Ok, Capito' }[lang],
  OK_CLOSE: { en: 'Ok, Close', it: 'Ok, Chiudi' }[lang],
  OK_SEND: { en: 'OK, INVIA', it: 'OK, INVIA' }[lang],
  ONLY_ONE_CSV_FILE: { en: 'Only one CSV file is allowed', it: 'È consentito un solo file CSV' }[
    lang
  ],
  ONLY_FOR_THIS_PRINT: { en: 'only for this print', it: 'solo per questa stampa' }[lang],
  OPERATION: { en: 'Operation', it: 'Operazione' }[lang],
  OPERATION_COMPLETED_SUCCESSFULLY: {
    en: 'Operation completed successfully',
    it: 'Operazione completata con successo'
  }[lang],
  OPERATION_DATE: { en: 'Operation date', it: 'Data operazione' }[lang],
  OPERATION_DESCRIPTION: { en: 'Operation description', it: 'Descrizione operazione' }[lang],
  OPERATION_NATURE: { en: 'Operation nature', it: 'Natura operazione' }[lang],
  OPERATION_PURPOSE: { en: 'Operation purpose', it: 'Scopo operazione' }[lang],
  OPERATOR: { en: 'Operator', it: 'Operatore' }[lang],
  OR: { en: 'Or', it: 'Oppure' }[lang],
  ORDINARY: { en: 'Ordinary', it: 'Ordinario' }[lang],
  ORGANIZATION: { en: 'Organization', it: 'Organizzazione' }[lang],
  ORIGINDB: { en: 'Origin DB', it: 'DB di origine' }[lang],
  ORIGINLIST: { en: 'List of origin Databases', it: 'Lista Database di origine' }[lang],
  OTHER_INFO_AMOUNT_5: { en: 'Other info/Amount 5', it: 'Altre info/Importo 5' }[lang],
  OUTCOME_DOCUMENT_COMMUNICATION_ACCEPTED: {
    en: 'Outcome Document: Communication ACCEPTED',
    it: 'Esito Elaborato: comunicazione ACCOLTA'
  }[lang],
  OUTCOME_DOCUMENT_COMMUNICATION_ACCEPTED_WITH_ERRORS: {
    en: 'Outcome Document: Communication ACCEPTED WITH ERRORS',
    it: 'Esito Elaborato: comunicazione ACCOLTA CON ERRORI'
  }[lang],
  OUTCOME_DOCUMENT_COMMUNICATION_REJECTED: {
    en: 'Outcome Document: Communication REJECTED',
    it: 'Esito Elaborato: comunicazione SCARTATA'
  }[lang],
  OUTGOING_MAIL_HOST: { en: 'Outgoing mail host', it: 'Server posta in uscita' }[lang],
  OUTGOING_MAIL_PORT: { en: 'Outgoing mail port', it: 'Porta posta in uscita' }[lang],
  OUTSIDEITALY: { en: 'EE if foreign', it: 'EE per Estero' }[lang],
  PASSPORTNUMBER: { en: 'Passport/ID Number', it: 'Numero di Passaporto/ID' }[lang],
  PASSWORD: { en: 'Password', it: 'Password' }[lang],
  PASSWORD_FOR_USE_CERTIFICATES: {
    en: 'Password for use certificates',
    it: 'Password per uso certificati'
  }[lang],
  PASSWORD_MUST_BE_BETWEEN_15_AND_20_CHARACTERS: {
    en: 'The password must be between 15 and 20 characters',
    it: 'La password deve essere lunga tra i 15 ed i 20 caratteri'
  }[lang],
  PASSWORD_MUST_BE_BETWEEN_8_AND_20_CHARACTERS: {
    en: 'The password must be between 8 and 20 characters',
    it: 'La password deve essere lunga tra gli 8 ed i 20 caratteri'
  }[lang],
  PASSWORD_MUST_BE_COMPOSED_OF_AT_LEAST_ONE_NUMBER_AND_ONE_LETTER: {
    en: 'The password must be composed of at least one number and one letter',
    it: 'La password deve essere composta da almeno un numero ed una lettera'
  }[lang],
  PASSWORD_PROTECTION: { en: 'Password of protection', it: 'Password di protezione' }[lang],
  PAYMENT_TYPE: { en: 'Payment type', it: 'Tipo di pagamento' }[lang],
  PEC_ALREADY_COMMUNICATED_TO_CHANGE: {
    en: 'PEC already communicated to REI to change',
    it: 'Indirizzo PEC già comunicato al REI da variare'
  }[lang],
  PEC_ALREADY_COMMUNICATED_TO_REI_TO_CANCEL: {
    en: 'PEC already communicated to REI to cancel',
    it: 'Indirizzo PEC già comunicato al REI da cancellare'
  }[lang],
  PEC_EMAIL: { en: 'PEC email', it: 'Email PEC' }[lang],
  PEC_EMAIL_ADDRESS: { en: 'Address PEC last version', it: 'Indirizzo PEC ultima versione' }[lang],
  PEC_EMAIL_ADDRESS_: { en: 'PEC email address', it: 'Indirizzo email PEC' }[lang],
  PEC_EMAIL_ADDRESS_LAST_VERSION: {
    en: 'PEC email address last version',
    it: 'Indirizzo email PEC ultima versione'
  }[lang],
  PEC_MANAGER: { en: 'PEC manager', it: 'Gestore PEC' }[lang],
  PEC_NOT_CONFIGURED_CORRECTLY: {
    en: 'PEC not configured correctly',
    it: 'PEC non configurato correttamente'
  }[lang],
  PEC_NOT_CONFIGURED_FOR_THE_SUBJECT: {
    en: 'PEC parameters not configured for the subject',
    it: 'Parametri PEC non configurati per il soggetto'
  }[lang],
  PEC_RECIPIENT: { en: 'PEC recipient', it: 'Indirizzo Pec DESTINATARIO' }[lang],
  PEC_RECIPIENT_TEXT: {
    en: 'The address of the Agenzia delle Entrate (SID1, SID2-5)',
    it: "L'indirizzo dell'Agenzia delle Entrate (SID1, SID2-5)"
  }[lang],
  PEC_SENDER_ADDRESS: { en: 'PEC sender address', it: 'Indirizzo PEC Mittente' }[lang],
  PEC_SENDER_ADDRESS_TEXT: {
    en: 'The pec indicated in the SID request for accreditation',
    it: 'La pec indicata nella richiesta di accreditamento SID'
  }[lang],
  PEC_SERVICE_PROVIDER: {
    en: 'The service provider of the PEC (Infocamere, Aruba, PEC.it etc.)',
    it: 'Il gestore del servizio PEC (Infocamere, Aruba, PEC.it etc.)'
  }[lang],
  PEC_TO_REWRITE: { en: 'PEC to rewrite at REI', it: 'Indirizzo PEC da reiscrivere al REI' }[lang],
  PEC_TO_WRITE: { en: 'PEC to write at REI', it: 'Indirizzo PEC da scrivere al REI' }[lang],
  PERIOD: { en: 'Period', it: 'Periodo' }[lang],
  PERPAGE: { en: 'Per page', it: 'Per pagina' }[lang],
  PERSONAL_DATA: { en: 'Personal data', it: 'Dati anagrafici' }[lang],
  PERSONAL_EMAIL_OF_THE_RESPONSIBLE: {
    en: 'Personal email of the responsible',
    it: 'Email personale del responsabile'
  }[lang],
  PERSON_TYPE: { en: 'Person type', it: 'Tipo di persona' }[lang],
  PERSON_TYPE_ERROR: { en: 'Select the person type', it: 'Selezionare il tipo di persona' }[lang],
  PHONE: { en: 'Phone', it: 'Telefono' }[lang],
  PHOTOGRAPHY_RESULTS: { en: 'Photography/results', it: 'Fotografia/esiti' }[lang],
  PHOTOGRAPH_NOT_PROCESSED: { en: 'Photograph not processed', it: 'Fotografia non processata' }[
    lang
  ],
  PHOTOGRAPH_PROCESSED: {
    en: 'Photograph processed: click on the icon to read the content',
    it: "Fotografia elaborata: cliccare sull'icona per leggere il contenuto"
  }[lang],
  PHOTOGRAPH_PROCESSED_WITH_DISCREPANCY: {
    en: 'Photograph processed: discrepancy between the archives to verify',
    it: 'Fotografia elaborata: discordanza tra gli archivi da verificare'
  }[lang],
  PHOTOGRAPH_PROCESSED_WITH_DISCREPANCY_TEXT: {
    en: 'Photograph processed: discrepancy between the archives to verify',
    it: 'Fotografia Elaborata: nessuna discordanza tra gli archivi'
  }[lang],
  PHOTOGRAPH_PROCESSED_WITH_DISCREPANCY_TEXT_WITH_ERRORS: {
    en: 'Photograph processed: discrepancy between the archives to verify',
    it: 'Fotografia Elaborata: presenti discordanze tra gli archivi'
  }[lang],
  PHYSICALPERSON: { en: 'Physical Person', it: 'Persona Fisica' }[lang],
  PHYSICAL_PERSON: { en: 'Physical person', it: 'Persona fisica' }[lang],
  PHYSICAL_PERSON_AND_ATTORNEY_DECLARATION: {
    en: 'Physical person and attorney declaration',
    it: 'Dichiarazione persona fisica e procuratore'
  }[lang],
  PHYSICAL_PERSON_DECLARATION: {
    en: 'Physical person declaration',
    it: 'Dichiarazione persona fisica'
  }[lang],
  PIN_FISCONLINE_ENTRATEL: { en: 'PIN Fisconline/Entratel', it: 'PIN Fisconline/Entratel' }[lang],
  PIN_OF_REVOCA: { en: 'PIN of revocation', it: 'PIN di revoca' }[lang],
  PIN_REVOCA_AMBIENTE_DI_SICUREZZA_ATTUALE: {
    en: 'PIN of revocation ambiente di sicurezza attuale',
    it: 'PIN di revoca ambiente di sicurezza attuale'
  }[lang],
  PLEASE_SELECT_AN_ARI_FILE: {
    en: 'Please select an ARI file',
    it: 'Si prega di selezionare un file ARI'
  }[lang],
  PLEASE_TRY_AGAIN_IN_FIVE_MINUTES: {
    en: 'Please try again in five minutes',
    it: 'Si prega di riprovare fra cinque minuti'
  }[lang],
  POLITICALLY_EXPOSED: { en: 'Politically exposed', it: 'Persona politicamente esposta' }[lang],
  POLITICALLY_EXPOSED_DETAIL: {
    en: 'Politically exposed detail',
    it: 'Persona politicamente esposta dettaglio'
  }[lang],
  PORT: { en: 'Port', it: 'Porta' }[lang],
  POSITION: { en: 'Position', it: 'Posizione' }[lang],
  POSTALCODE: { en: 'Postalcode', it: 'CAP' }[lang],
  POSTAL_CODE: { en: 'Postal code', it: 'CAP' }[lang],
  PRECISION: { en: 'Precision', it: 'Precisione' }[lang],
  PRESET_VALUES: { en: 'Preset values', it: 'Valori preimpostati' }[lang],
  PRINT_COLLABORATOR: { en: 'Print collaborator', it: 'Stampa collaboratore' }[lang],
  PRINT_COMPLETE_LIST: { en: 'Print complete list', it: 'Stampa lista completa' }[lang],
  PRINT_CONTRACTS: { en: 'Print contracts', it: 'Stampa contratti' }[lang],
  PRINT_DATE: { en: 'Print date', it: 'Data stampa' }[lang],
  PRINT_DOCUMENT: { en: 'Print document', it: 'Stampa documento' }[lang],
  PRINT_DOCUMENTS_OF_THE_SUBJECT: {
    en: 'Print documents of the subject',
    it: 'Stampa documenti del soggetto'
  }[lang],
  PRINT_OPERATION: { en: 'Print operation', it: 'Stampa operazione' }[lang],
  PRINT_PERSONAL_DATA: { en: 'Print personal data', it: 'Stampa anagrafiche' }[lang],
  PRINT_RELATIONSHIPS: { en: 'Print relationships', it: 'Stampa rapporti' }[lang],
  PRIORITY: { en: 'Priority', it: 'Priorità' }[lang],
  PRIVACY_INFORMATION: { en: 'Privacy information', it: 'Informazioni privacy' }[lang],
  PROCESSED_LINES: { en: 'Processed lines', it: 'Righe elaborate' }[lang],
  PROCESSED_LINES_DESCRIPTION: { en: 'Processed lines', it: 'Righe elaborate' }[lang],
  PROCESSING_RESULT_OF_SID_RECEIPT: {
    en: 'Processing result of SID receipt',
    it: 'Esito elaborazione ricevuta SID'
  }[lang],
  PROCESS_RECEIPTS: { en: 'Process receipts', it: 'Elabora ricevute' }[lang],
  PROCESS_XML_COMMUNICATION: { en: 'Process XML communication', it: 'Elabora comunicazione XML' }[
    lang
  ],
  PROCURATORS: { en: 'Procurators', it: 'Procuratori' }[lang],
  PROCURATOR_OF: { en: 'Procurator of', it: 'Procuratore di' }[lang],
  PROFESSIONISTS: { en: 'Professionists', it: 'Professionisti' }[lang],
  PROFESSIONISTS_LIST: { en: 'Professionists list', it: 'Elenco professionisti' }[lang],
  PROFILING_DATE: { en: 'Profiling date', it: 'Data profilatura' }[lang],
  PROGRESSIVE: { en: 'Progressive', it: 'Progressivo' }[lang],
  PROGRESSIVE_SEDE: { en: 'Progressive sede', it: 'Progressivo sede' }[lang],
  PROTECTION_PASSWORD: { en: 'Protection password', it: 'Password di protezione' }[lang],
  PROVINCE: { en: 'Prov.', it: 'Prov.' }[lang],
  PROVINCE_PLACEHOLDER: { en: 'EE for abroad', it: 'EE per estero' }[lang],
  PUBLICATIONURL: { en: 'Publication URL', it: 'URL di pubblicazione' }[lang],
  PUBLIC_KEY: { en: 'Public key', it: 'Chiave pubblica' }[lang],
  PURCHASED: { en: 'Purchased', it: 'Acquistati' }[lang],
  PURCHASE_LICENSE: { en: 'Purchase license', it: 'Acquista licenza' }[lang],
  PURCHASE_LICENSE_TO_CREATE_COMMUNICATIONS: {
    en: 'Purchase license to create communications',
    it: 'Acquistare licenza per creare comunicazioni'
  }[lang],
  PURPOSE_ASSIGNMENT: { en: 'Assignment', it: 'Incarico' }[lang],
  PURPOSE_BUSINESS_NEEDS: { en: 'Business needs', it: 'Esigenze aziendali' }[lang],
  PURPOSE_DISINVESTMENT: { en: 'Disinvestment', it: 'Disinvestimento' }[lang],
  PURPOSE_FAMILY_NEEDS: { en: 'Family needs', it: 'Esigenze di famiglia' }[lang],
  PURPOSE_INVESTMENT: { en: 'Investment', it: 'Investimento' }[lang],
  PURPOSE_MANDATE: { en: 'Mandate', it: 'Mandato' }[lang],
  PURPOSE_PERSONAL_NEEDS: { en: 'Personal needs', it: 'Esigenze personali' }[lang],
  PURPOSE_PROFESSIONAL_PERFORMANCE: {
    en: 'Professional performance',
    it: 'Presazione Professionale'
  }[lang],
  PURPOSE_REAL_ESTATE_TRANSACTION: {
    en: 'Real estate transaction',
    it: 'Compravendita immobiliare'
  }[lang],
  RECEIPT_ACCEPTED: { en: 'Receipt accepted', it: 'Ricevuta accolta' }[lang],
  RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FOUND_ON_CRS_WEB: {
    en: 'Receipt accepted, communication not found on CRS WEB',
    it: 'Ricevuta accolta, comunicazione non trovata su CRS WEB'
  }[lang],
  RECEIPT_ACCEPTED_WITH_ERRORS: {
    en: 'Receipt accepted with errors, communication not found on CRS WEB',
    it: 'Ricevuta accolta totalmente, comunicazione non trovata su CRS WEB'
  }[lang],
  RECEIPT_ACCEPTED_WITH_ERRORS_AND_COMMUNICATION_NOT_FOUND_ON_CRS_WEB: {
    en: 'Receipt accepted with errors, communication not found on CRS WEB',
    it: 'Ricevuta accolta con errori formali, comunicazione non trovata su CRS WEB'
  }[lang],
  RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FOUND: {
    en: 'Receipt accepted with errors, communication not found on CRS WEB',
    it: 'Ricevuta accolta con errori formali, comunicazione non trovata su CRS WEB'
  }[lang],
  RECEIPT_NOT_ACCEPTED: { en: 'Receipt not accepted', it: 'Ricevuta non accolta' }[lang],
  RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FOUND: {
    en: 'Receipt scarted totally, communication not found on CRS WEB',
    it: 'Ricevuta scartata totalmente, comunicazione non trovata su CRS WEB'
  }[lang],
  RECEIPT_NOT_PROCESSED: { en: 'Receipt not processed', it: 'Ricevuta non elaborata' }[lang],
  RECEIPT_PROCESSED: { en: 'Receipt processed', it: 'Ricevuta elaborata' }[lang],
  RECEIPT_PROCESSED_ACCEPTED: {
    en: 'Receipt processed - Accepted',
    it: 'Ricevuta elaborata - Ricevuta accettata'
  }[lang],
  RECEIPT_PROCESSED_ACCEPTED_WITH_ERRORS: {
    en: 'Receipt processed - Accepted with errors',
    it: 'Ricevuta elaborata - Ricevuta accettata con errori'
  }[lang],
  RECEIPT_PROCESSED_REJECTED: {
    en: 'Receipt processed - Rejected',
    it: 'Ricevuta elaborata - Ricevuta rifiutata'
  }[lang],
  RECEIPT_PROCESSED_WITH_ERRORS: {
    en: 'Receipt processed with errors',
    it: 'Ricevuta elaborata con errori'
  }[lang],
  RECEIPT_SCARTED_AND_COMMUNICATION_NOT_FOUND_ON_CRS_WEB: {
    en: 'Receipt scarted totally, communication not found on CRS WEB',
    it: 'Ricevuta scartata totalmente, comunicazione non trovata su CRS WEB'
  }[lang],
  RECIPIENT: { en: 'Recipient', it: 'Destinatario' }[lang],
  RECOVER_PASSWORD: { en: 'Recover password', it: 'Recupera password' }[lang],
  REELABORATE_FILE_TXT: { en: 'Reelaborate file txt', it: 'Rielabora file txt' }[lang],
  REELABORATE_TXT_FILE: { en: 'Rielaborate txt file', it: 'Rielabora file txt' }[lang],
  REELABORATION_OF_TXT_COMMUNICATION: {
    en: 'Rielaboration of txt communication',
    it: 'Rielaborazione comunicazione txt'
  }[lang],
  REFERENCE_PERIOD: { en: 'Reference period', it: 'Periodo di riferimento' }[lang],
  REFERENCE_YEAR: { en: 'Reference year', it: 'Anno di riferimento' }[lang],
  REGISTER: { en: 'Register', it: 'Registrati' }[lang],
  REGISTRATION_DATE: { en: 'Registration date', it: 'Data registrazione' }[lang],
  REGISTRIES_AND_CONTRACTS: {
    en: 'Registries and contracts (Ex AUI)',
    it: 'Anagrafiche e contratti (Ex AUI)'
  }[lang],
  REGISTRIES_LIST: { en: 'Registries list', it: 'Lista anagrafiche' }[lang],
  REGISTRY: { en: 'Registry', it: 'Anagrafica' }[lang],
  REGISTRY_RISK: { en: 'Registry Risk', it: 'Rischio Anagrafica' }[lang],
  REI_HISTORY: { en: 'R.E.I history', it: 'Storico R.E.I' }[lang],
  RELATIONSHIP: { en: 'Relationship', it: 'Rapporto' }[lang],
  RELATIONSHIPS: { en: 'relationships', it: 'rapporti' }[lang],
  RELATIONSHIPS_FROM_COMMUNICATION: {
    en: 'Relationships from communication TXT',
    it: 'Rapporti da comunicazione TXT'
  }[lang],
  RELATIONSHIPS_FROM_CSV: { en: 'Relationships from CSV', it: 'Rapporti da file CSV' }[lang],
  RELATIONSHIPS_ONLY_OPEN: { en: 'Relationships only open', it: 'Rapporti solo aperti' }[lang],
  RELATIONSHIPS_TO_SEND: { en: 'Relationships to send', it: 'Rapporti da spedire' }[lang],
  RELATIONSHIP_BALANCE: { en: 'Relationship balance', it: 'Saldo rapporto' }[lang],
  RELATIONSHIP_CLONED_SUCCESSFULLY: {
    en: 'Relationship cloned successfully, the cloned relationship is open in the detail',
    it: 'Rapporto clonato con successo, il rapporto clonato è aperto nel dettaglio'
  }[lang],
  RELATIONSHIP_CLOSED: { en: 'Relationship closed', it: 'Rapporto chiuso' }[lang],
  RELATIONSHIP_CLOSED_NOT_ALLOWED_FOR_TYPE_98: {
    en: 'Relationship closed not allowed for type 98',
    it: 'Chiusura non ammessa per rapporti di tipo 98'
  }[lang],
  RELATIONSHIP_IDENTIFIER: { en: 'Relationship identifier', it: 'Identificativo rapporto' }[lang],
  RELATIONSHIP_NOT_DOCUMENTED: {
    en: 'Relationship not documented',
    it: 'Rapporto non documentato'
  }[lang],
  RELATIONSHIP_NOT_DOCUMENTED_TIP: {
    en: 'The relationship is not documented if the subject has not provided any information',
    it: 'Il rapporto è non documentato se il soggetto non ha fornito alcuna informazione'
  }[lang],
  RELATIONSHIP_NOT_SAVED_MESSAGE: {
    en: 'Complete the report to register a balance',
    it: 'Completa la compilazione del rapporto per registrare un saldo'
  }[lang],
  RELATIONSHIP_NUMBER: { en: 'Relationship number', it: 'Numero rapporto (conto)' }[lang],
  RELATIONSHIP_REGISTRY: { en: 'Relationship registry', it: 'Anagrafica rapporto' }[lang],
  RELATIONSHIP_SAVED_CORRECTLY: {
    en: 'Relationship saved correctly',
    it: 'Rapporto salvato correttamente'
  }[lang],
  RELATIONSHIP_SLEEPY: { en: 'Relationship sleepy', it: 'Rapporto dormiente' }[lang],
  RELATIONSHIP_TYPE: { en: 'Relationship type', it: 'Tipo rapporto' }[lang],
  REMEMBER_ME: { en: 'Stay connected', it: 'Rimani connesso' }[lang],
  REMEMBER_TO_CLOSE_OR_UPDATE_RELATIONSHIPS: {
    en: 'Remember that to close (or update) already communicated relationships, just select the relationship from the list and indicate ONLY the closing date (a new relationship should not be created) and then set the communication type to 5=closure on time or 2=late closure or update.',
    it: "Ricordare che per chiudere (o aggiornare) rapporti già comunicati, selezionare il rapporto dall'elenco e indicare solo la data di chiusura (non creare un nuovo rapporto) e poi impostare il tipo di comunicazione a 5=chiusura in tempo o 2=chiusura in ritardo o aggiornamento."
  }[lang],
  REMOVE: { en: 'Remove', it: 'Elimina' }[lang],
  REMOVED_SUCCESSFULLY: { en: 'Removed successfully', it: 'Eliminati con successo' }[lang],
  REMOVE_CLIENTS: { en: 'Remove clients', it: 'Elimina clienti' }[lang],
  REMOVE_CLIENTS_SUCCESS: {
    en: 'The selected clients have been removed successfully',
    it: 'I clienti selezionati sono stati eliminati con successo'
  }[lang],
  REMOVE_CONTRACTS: { en: 'Remove contracts', it: 'Elimina contratti' }[lang],
  REMOVE_CONTRACTS_SUCCESS: {
    en: 'The selected contracts are removed successfully',
    it: 'I contratti selezionati sono stati eliminati con successo'
  }[lang],
  REMOVE_COUNTERPARTS: {
    en: 'The selected counterparts are removed successfully',
    it: 'Le controparti selezionate sono state eliminate con successo'
  }[lang],
  REMOVE_PROFESSIONALS: {
    en: 'The selected professionals are removed successfully',
    it: 'I professionisti selezionati sono stati eliminati con successo'
  }[lang],
  RENEW: { en: 'Renew', it: 'Rinnova' }[lang],
  REPORT_EXCLUDED_FROM_INTEGRATIVE_ANNUAL_BALANCES_COMMUNICATION: {
    en: 'This report is EXCLUDED from the INTEGRATIVE ANNUAL BALANCES COMMUNICATION',
    it: "Questo rapporto E' ESCLUSO dalla comunicazione INTEGRATIVA SALDI ANNUALI"
  }[lang],
  REPORT_INFORMATION: { en: 'Report information', it: 'Anagrafica rapporto' }[lang],
  REQUEST_ASSISTANCE: { en: 'Request assistance', it: 'Richiedi assistenza' }[lang],
  REQUIREDFIELD: { en: 'Required field', it: 'Campo obbligatorio' }[lang],
  REQUIRED_FIELD: { en: 'Required field', it: 'Campo obbligatorio' }[lang],
  REQUIRED_FIELD_FOR_FORM_COMPLETION: {
    en: 'indicates that the field is required for the completion of the form',
    it: 'indica che il campo è obbligatorio per la compilazione del form'
  }[lang],
  REQUIRED_FILE: { en: 'Required file', it: 'File obbligatorio' }[lang],
  RESET_ALL_FILTERS: { en: 'Reset all filters', it: 'Resetta tutti i filtri' }[lang],
  RESIDENCE_ADDRESS: { en: 'Residence address', it: 'Indirizzo di residenza' }[lang],
  RESIDENCE_LOCATION: { en: 'Location', it: 'Località' }[lang],
  RESPONSIBLE: { en: 'Responsible', it: 'Responsabile' }[lang],
  RESTORE: { en: 'Restore', it: 'Ripristina' }[lang],
  RESTORED: { en: 'restored', it: 'ripristinato' }[lang],
  RESTORED_SUBJECT: { en: 'Restored subject', it: 'Soggetto ripristinato' }[lang],
  RESTORE_CORA_SUBJECT: { en: 'Restore Cora subject', it: 'Ripristina soggetto CORA' }[lang],
  RESULT: { en: 'Result', it: 'Esito' }[lang],
  RETURN_TO_MAIN_PAGE: { en: 'Return to main page', it: 'Torna alla pagina principale' }[lang],
  RETURN_TO_SUBJECTS_LIST: { en: 'Return to subjects list', it: 'Torna a elenco soggetti' }[lang],
  RETURN_TO_SUBJECT_LIST: { en: 'Return to subject list', it: 'Torna a elenco soggetti' }[lang],
  RE_REGISTRATION_TO_REGISTRI: {
    en: 'Re-registration to the registries',
    it: 'Reiscrizione al/ai Registri'
  }[lang],
  RISK_ANALYSIS_SCHEDULE: { en: 'Risk analysis schedule', it: 'Scheda analisi rischio' }[lang],
  RISK_CLASSES: { en: 'Risk classes', it: 'Classi di rischio' }[lang],
  RISK_OPERATION: { en: 'Risk operation', it: 'Rischio operazione' }[lang],
  RISK_VALUES: { en: 'Risk values', it: 'Valori di rischio' }[lang],
  ROLE: { en: 'Role', it: 'Ruolo' }[lang],
  SAME_START_DATE_SAME_SUBJECT_SAME_RELATIONSHIP_TYPE: {
    en: 'Same start date, same subject, same relationship type',
    it: 'Stessa data inizio, stesso soggetto, stesso tipo rapporto'
  }[lang],
  SAVE: { en: 'Save', it: 'Salva' }[lang],
  SAVED_SUCCESSFULLY: { en: 'Saved successfully', it: 'Salvato con successo' }[lang],
  SAVESUCCESS: { en: 'Save successful', it: 'Salvataggio effettuato' }[lang],
  SAVE_AND_CLOSE: { en: 'Save and close', it: 'Salva e chiudi' }[lang],
  SAVE_AND_CLOSE_CONTACT: { en: 'Save and close contact', it: 'Salva e chiudi contatto' }[lang],
  SAVE_DATA_CREATE_COMMUNICATION: {
    en: 'Saved data, do you want to create the communication?',
    it: 'Dati salvati, si desidera creare la comunicazione?'
  }[lang],
  SCHEDULED_DELAYED_SENDING: { en: 'Scheduled delayed sending', it: 'Invio differito' }[lang],
  SCHEDULED_DELAYED_SENDING_SET_TO: {
    en: 'Scheduled delayed sending set to',
    it: 'Invio differito impostato per la data'
  }[lang],
  SCOPE: { en: 'Scope', it: 'Ambito' }[lang],
  SCREENSIZEWARNING: {
    title: {
      en: 'Screen Size Warning',
      it: 'Avviso Dimensione Schermo'
    }[lang],
    description: {
      en: 'Attention: Your screen is too small to properly display this content. For an optimal experience, use a larger screen.',
      it: "Attenzione: il tuo schermo è troppo piccolo per visualizzare correttamente questo contenuto. Per un'esperienza ottimale, utilizza uno schermo più grande"
    }[lang]
  },
  SEARCH: { en: 'Search', it: 'Cerca' }[lang],
  SEARCH_BY_NAME_AND_SURNAME: {
    en: 'Search by name and last name',
    it: 'Cerca per nome e cognome'
  }[lang],
  SEARCHTERM: { en: 'Search term', it: 'Termini ricerca' }[lang],
  SEARCHDATE: { en: 'Search date', it: 'Data ricerca' }[lang],
  SEARCHEMPTYFORM: {
    en: 'Warning: please insert at least one search term',
    it: 'Attenzione: inserire almeno un termine di ricerca'
  }[lang],
  SEARCHERROR: {
    en: 'An error occurred while processing your request',
    it: 'Si è verificato un errore durante il controllo delle liste'
  }[lang],
  SEARCHRESULT1: { en: 'Found ', it: 'Trovati ' }[lang],
  SEARCHRESULT2: { en: ' results.', it: ' risultati.' }[lang],
  SEARCHRESULTTITLE: { en: 'Search Completed', it: 'Ricerca Completata' }[lang],
  SEARCHTABTITLE: { en: 'SEARCH', it: 'RICERCA' }[lang],
  SEARCH_IN_FIELD: { en: 'Search in field', it: 'Ricerca nel campo' }[lang],
  SEARCH_SID_NUMBER: { en: 'Search SID number', it: 'Cerca numero SID' }[lang],
  SEARCH_SUBJECT: { en: 'Search subject', it: 'Cerca soggetto' }[lang],
  SEARCH_TERMS: { en: 'Search terms', it: 'Termini di ricerca' }[lang],
  SECTION: { en: 'Section', it: 'Sezione' }[lang],
  SELECTED: { en: 'selected', it: 'selezionate' }[lang],
  SELECTED_ELEMENT: { en: 'selected', it: 'selezionato' }[lang],
  SELECTED_ELEMENTS: { en: 'selected', it: 'selezionati' }[lang],
  SELECTED_SUBJECTS: { en: 'Selected subjects', it: 'Soggetti selezionati' }[lang],
  SELECT_AT_LEAST_ONE_OPTION: {
    en: 'Select at least one option',
    it: 'Selezionare almeno una opzione'
  }[lang],
  SELECT_A_FILE_TYPE_AT_A_TIME: {
    en: 'Select a file type at a time',
    it: 'Selezionare un tipo file per volta'
  }[lang],
  SELECT_CERTIFICATES_UTEC_UTEF_UTENTE_KS: {
    en: 'Select certificates UTEC, UTEF and UTENTE.KS',
    it: 'Selezionare contemporaneamente i certificati UTEC, UTEF e UTENTE.KS'
  }[lang],
  SELECT_DATE_AFTER_TODAY: {
    en: 'Select date after today',
    it: 'Selezionare data successiva a quella odierna'
  }[lang],
  SELECT_IF_YOU_WANT_TO_INCLUDE_THE_RELATIONSHIPS_IN_THE_NEXT_COMMUNICATION: {
    en: 'Select if you want to include the relationships in the next communication',
    it: 'Selezionare se si desidera includere i rapporti nella prossima comunicazione'
  }[lang],
  SELECT_IN_CASE_OF_EMAIL_PEC: {
    en: 'Select in case of email PEC',
    it: 'Selezionare in caso di email PEC'
  }[lang],
  SELECT_IN_CASE_OF_PEC_EMAIL: {
    en: 'Select in case of PEC email',
    it: 'Selezionare in caso di email PEC'
  }[lang],
  SELECT_OPTIONS: { en: 'Select options', it: 'Scegli opzioni' }[lang],
  SELECT_PERSON_TYPE: { en: 'Select person type', it: 'Selezionare il tipo di persona' }[lang],
  SELECT_SUBJECTS_TO_CREATE_COMMUNICATION: {
    en: 'Select subjects to create communication',
    it: 'Seleziona soggetti per creare comunicazione'
  }[lang],
  SELECT_THE_MANAGER_OF_YOUR_PEC: {
    en: 'Select the manager of your PEC',
    it: 'Seleziona il manager della vostra PEC'
  }[lang],
  SELECT_THE_STARTING_YEAR_OF_ELABORATION: {
    en: 'Select the starting year of elaboration',
    it: "Seleziona l'anno di inizio elaborazione"
  }[lang],
  SELECT_XML_FILE_TO_PROCESS: {
    en: 'Select XML file to process',
    it: 'Seleziona file XML da elaborare'
  }[lang],
  SEND: { en: 'Send', it: 'Invia' }[lang],
  SENDER: { en: 'Sender', it: 'Mittente' }[lang],
  SEND_COMMUNICATION: { en: 'Send communication', it: 'Invia comunicazione' }[lang],
  SEND_CREDENTIALS_TO_ASSISTANCE: {
    en: 'Send your credentials to the assistance center',
    it: 'Invia le tue credenziali al centro assistenza'
  }[lang],
  SEND_CREDENTIALS_TO_ASSISTANCE_INFO: {
    en: 'Through this form you can send, in a secure way, your login data to our assistance center.',
    it: 'Tramite questa form è possibile inviare, in maniera sicura, i dati di login al nostro centro assistenza.'
  }[lang],
  SEND_DIRECTLY_FROM_PEC: { en: 'Send directly from PEC', it: 'Invia direttamente da PEC' }[lang],
  SEND_PEC_MAIL: { en: 'Send PEC mail', it: 'Invia PEC mail' }[lang],
  SEND_SELECTED_COMMUNICATION: {
    en: 'Send selected communication',
    it: 'Invia comunicazione selezionata'
  }[lang],
  SEND_TYPE: { en: 'Send type', it: 'Tipo invio' }[lang],
  SERVER_MAINTENANCE: {
    en: 'The server is temporarily in maintenance',
    it: 'Il server è momentaneamente in manutenzione'
  }[lang],
  SET_CLOSING_DATE: { en: 'Set closing date', it: 'Imposta data chiusura' }[lang],
  SET_DELAYED_SENDING: { en: 'Set delayed sending', it: 'Imposta invio ritardato' }[lang],
  SET_DELAYED_SENDING_DESCRIPTION: {
    en: 'Set the communication creation date.',
    it: 'Imposta la data di creazione della comunicazione.'
  }[lang],
  SET_DELAYED_SENDING_DESCRIPTION_2: {
    en: 'This will be sent via certified email (PEC) on the specified date',
    it: 'Questa verrà spedita tramite PEC alla data indicata'
  }[lang],
  SET_PEC_PARAMETERS: { en: 'Set PEC parameters', it: 'Imposta parametri PEC' }[lang],
  SET_PEC_PARAMETERS_DESCRIPTION: {
    en: 'Set the parameters for the automatic PEC sending',
    it: "Imposta i parametri per l'invio automatico della PEC"
  }[lang],
  SET_PEC_PARAMETERS_DESCRIPTION_2: {
    en: 'To configure the automatic PEC sending, the certificates, the related password and the SID number must be correctly uploaded',
    it: "Per configurare l'invio automatico della PEC, i certificati, la relativa password e il numero SID devono essere caricati correttamente"
  }[lang],
  SET_PEC_PARAMETERS_DESCRIPTION_3: {
    en: 'Before uploading the certificates, insert the fiscal code/VAT number, the SID number and the password of the certificates',
    it: 'Prima di caricare i certificati, inserire Codice fiscale/P.IVA, numero SID e password certificati'
  }[lang],
  SET_PEC_PARAMETERS_DESCRIPTION_4: {
    en: 'Insert the fiscal code/VAT number, the SID number, the password of the certificates and upload the certificates',
    it: 'Inserire Codice fiscale/P.IVA, numero SID, password certificati e caricare file'
  }[lang],
  SET_UP_AUTOMATIC_PEC_MANAGEMENT: {
    en: 'Set up automatic PEC management',
    it: 'Configura gestione automatica PEC'
  }[lang],
  SHOWHISTORY: { en: 'Show history', it: 'Mostra storico' }[lang],
  SHOW_ALL: { en: 'Show all', it: 'Mostra tutti' }[lang],
  SHOW_FIRST: { en: 'Show first', it: 'Mostra primi' }[lang],
  SID_CODE: { en: 'SID code', it: 'Codice SID' }[lang],
  SID_EMAIL: { en: 'Email from starsoluzioni', it: 'Email da starsoluzioni' }[lang],
  SID_EXPIRATION: { en: 'Certificate exp.', it: 'Scad. certificati' }[lang],
  SID_MUST_BE_8_CHARACTERS: {
    en: 'The SID must be 8 characters long',
    it: 'Il numero SID deve essere lungo 8 caratteri'
  }[lang],
  SID_MUST_START_WITH_S: {
    en: 'The SID must start with S',
    it: 'Il numero SID deve iniziare con S'
  }[lang],
  SID_NUMBER: { en: 'SID num.', it: 'Num. SID' }[lang],
  SID_NUMBER_ALREADY_USED: {
    en: 'Detected same SID number on multiple CORA subjects. Verify that the SID number is associated with a single subject.',
    it: 'Rilevato stesso numero SID su più soggetti CORA. Verificare che il numero SID sia associato ad un solo soggetto.'
  }[lang],
  SID_PROCESSING_RESULT: { en: 'SID processing result', it: 'Esito elaborazione SID' }[lang],
  SIGNATORIES: { en: 'Signatories', it: 'Firmatari' }[lang],
  SIGNATORY_OF: { en: 'Signatory of', it: 'Firmatario di' }[lang],
  SILVER_PRICE: { en: 'Silver price €/g', it: 'Prezzo Argento Puro €/g' }[lang],
  SINGLE_CSV_FILE_ALLOWED: {
    en: 'Only one CSV file is allowed',
    it: 'È consentito un solo file CSV'
  }[lang],
  SOURCE_OF_FUNDS: { en: 'Source of funds', it: 'Origine fondi' }[lang],
  SOURCE_OF_FUNDS_DETAIL: { en: 'Source of funds detail', it: 'Origine fondi dettaglio' }[lang],
  SPINNERTITLE: { en: 'Processing your request', it: 'Stiamo elaborando la tua richiesta' }[lang],
  START_DATE: { en: 'Start date', it: 'Data inizio' }[lang],
  START_DATE_DELEGATE_MUST_BE_EQUAL_OR_AFTER_START_DATE_RELATIONSHIP: {
    en: 'Start date delegate must be equal or after start date relationship',
    it: 'Data di inizio delegato deve essere uguale o successiva a data inizio rapporto'
  }[lang],
  START_DATE_RELATIONSHIP: { en: 'Start date relationship', it: 'Data inizio rapporto' }[lang],
  START_EXPORT_SUBJECTS_PROCEDURE: {
    en: 'Start export subjects procedure',
    it: 'Inizia procedura esportazione soggetti'
  }[lang],
  START_EXPORT_SUBJECTS_PROCEDURE_DESCRIPTION: {
    en: 'Export of subjects started: you will receive the files at your email address.',
    it: 'Esportazione dei soggetti avviata: riceverai i file al tuo indirizzo email.'
  }[lang],
  START_OF_CONTRACT_WITH_PEC_PROVIDER: {
    en: 'It is the start of the contract with the PEC service provider',
    it: "è l'inizio del contratto con il gestore del servizio PEC"
  }[lang],
  STATUS: { en: 'Status', it: 'Stato' }[lang],
  STRAORDINARY: { en: 'Straordinary', it: 'Straordinario' }[lang],
  SUBJECT: { en: 'Subject', it: 'Soggetto' }[lang],
  SUBJECT_CODE: { en: 'Subject code', it: 'Codice soggetto' }[lang],
  SUBSTITUTIVE: { en: 'Substitutive', it: 'Sostitutiva' }[lang],
  SUBJECTDELETE: { en: 'Delete', it: 'Elimina' }[lang],
  SUBJECTNEW: { en: 'New subject', it: 'Nuovo soggetto' }[lang],
  SUBJECTS: { en: 'subjects', it: 'soggetti' }[lang],
  SUBJECTS_PURCHASED: { en: 'purchased subjects', it: 'soggetti acquistati' }[lang],
  SUBJECTS_REMAINING: { en: 'remaining subjects on', it: 'soggetti rimanenti su' }[lang],
  SUBJECT_CREDENTIALS: { en: 'Subject credentials', it: 'Credenziali del soggetto obbligato' }[
    lang
  ],
  SUBJECT_FROM_CORATU: {
    en: 'Subject from CORAtu (Export for CoraWeb)',
    it: 'Soggetto da CORAtu (Esportazione per CoraWeb)'
  }[lang],
  SUBJECT_LIST: { en: 'Subjects', it: 'Elenco soggetti' }[lang],
  SUBJECT_SAVED_CORRECTLY: { en: 'Subject saved correctly', it: 'Soggetto salvato correttamente' }[
    lang
  ],
  SUBJECT_TYPE: { en: 'Subject type', it: 'Tipo soggetto' }[lang],
  SUBSCRIPTION: { en: 'Subscription', it: 'Abbonamento' }[lang],
  SUBSCRIPTION_WITHOUT_EXPIRATION_FOR: {
    en: 'Subscription WITHOUT EXPIRATION for',
    it: 'Abbonamento SENZA SCADENZA per'
  }[lang],
  SUCCESSOK: { en: 'Dismiss', it: 'OK, Chiudi' }[lang],
  SUCCESSTITLE: {
    en: 'Operation was completed successfully',
    it: 'Operazione completata con successo'
  }[lang],
  SUCCESS_TITLE: {
    en: 'Operation completed successfully',
    it: 'Operazione completata con successo'
  }[lang],
  SUSPENDED: { en: 'Suspended', it: 'Sospeso' }[lang],
  TABLES: { en: 'Tables', it: 'Tabelle' }[lang],
  TELEMATIC_COMMITMENT: { en: 'Telematic commitment', it: 'Impegno alla trasmissione telematica' }[
    lang
  ],
  TERMINATION_WITHOUT_MERGER_INTO_ANOTHER_ENTITY: {
    en: 'Termination without merger into another entity',
    it: 'Cessazione senza confluenza in altro soggetto'
  }[lang],
  TERMS_AND_CONDITIONS: { en: 'Terms and conditions', it: 'Termini e condizioni' }[lang],
  THE_ACTIVE_SETTING_REMAINS_VALID_FOR_ANY_COMMUNICATIONS_CREATED_WITH_IT: {
    en: 'The active setting remains valid for any communications created with it',
    it: "L'impostazione attiva rimane valida per qualsiasi comunicazione creata con essa"
  }[lang],
  THE_COMPLETION_OF_THE_OPERATION_WILL_BE_NOTIFIED_VIA_MAIL_WITH_EVENTUAL_LOGS: {
    en: 'The completion of the operation will be notified via mail with eventual logs',
    it: "La completamento dell'operazione verrà notificato via mail con eventuali log"
  }[lang],
  THE_CORA_WEB_DEMO: {
    en: 'The CORA Web demo has no limitations and can be used for 30 days.',
    it: 'La demo di CORA Web non ha limiti e può essere usata per 30 giorni.'
  }[lang],
  THE_PERIOD: { en: 'The period', it: 'Il periodo' }[lang],
  THE_RELATIONSHIP: { en: 'the relationship', it: 'il rapporto' }[lang],
  THIS_PAGE: { en: 'this page', it: 'questa pagina' }[lang],
  THIS_USER_HAS_NO_RESTORABLE_SUBJECTS: {
    en: 'This user has no restorable subjects',
    it: 'Questo utente non ha soggetti ripristinabili'
  }[lang],
  TIME: { en: 'Time', it: 'Ora' }[lang],
  TIN: { en: 'TIN (Fiscal ID)', it: 'TIN (ID Fiscale)' }[lang],
  TITLELIST: { en: 'Titles', it: 'Titoli' }[lang],
  TITLE_AND_DELEGATE: { en: 'Title and delegate', it: 'Titolare effettivo e delegato' }[lang],
  TO_BE_COMMUNICATED_AS: { en: 'To be communicated as', it: 'Da comunicare come' }[lang],
  TO_CANCEL_THESE_SENDINGS_THE_ROW_MUST_BE_DELETED_FROM_THE_COMMUNICATION_HISTORY: {
    en: 'To cancel these sendings the row must be deleted from the communication history',
    it: 'Per annullare questi invii la riga deve essere eliminata dallo STORICO comunicazioni'
  }[lang],
  TO_SEND: { en: 'To send', it: 'Da spedire' }[lang],
  TYPE: { en: 'Type', it: 'Tipo' }[lang],
  TYPE_OF_SENDING: { en: 'Type of sending', it: 'Tipologia invio' }[lang],
  TYPOLOGY: { en: 'Typology', it: 'Tipologia' }[lang],
  UNEXPECTED_ERROR_PROCESSING_THE_CONSISTENCY_PHOTOGRAPHY: {
    en: 'An unexpected error occurred in the processing of the consistency photography',
    it: "Si è verificato un errore inatteso nell'elaborazione della fotografia"
  }[lang],
  UNEXPECTED_ERROR_PROCESSING_THE_RECEIPT: {
    en: 'An unexpected error occurred in the processing of the receipt',
    it: "Si è verificato un errore non gestito nell'elaborazione della ricevuta"
  }[lang],
  UNIQUE_CODE: { en: 'Unique code', it: 'Codice univoco' }[lang],
  UP_TO: { en: 'Up to', it: 'fino al' }[lang],
  UPDATE_EXISTING_RELATIONSHIPS: {
    en: 'Update existing relationships',
    it: 'Aggiorna rapporti esistenti'
  }[lang],
  UPDATE_EXISTING_RELATIONSHIPS_TEXT: {
    en: 'If selected, the reports on Cora Web will be updated to those of the photograph, based on the unique code.',
    it: 'Se selezionata, i rapporti su cora web verranno aggiornati a quelli della fotografia, in base al codice univoco.'
  }[lang],
  UPDATE_REPORT_DELAYED: {
    en: 'UPDATE REPORT DELAYED',
    it: 'AGGIORNAMENTO/CHIUSURA RAPPORTO TARDIVA'
  }[lang],
  UPDATE_REQUIRED_BY_31_01_2023: {
    en: 'update required by 31/01/2023',
    it: 'aggiornamento richiesto entro 31/01/2023'
  }[lang],
  UPLOADCSV: { en: 'Upload CSV', it: 'Carica CSV' }[lang],
  UPLOAD_FILES_TRANSMISSION_RECEIPT_TEXT: {
    en: 'Upload files TRANSMISSION RECEIPT (ATPEZC), PHOTOGRAPHY (ATPEC05) and RESULTS (ATPECXX)',
    it: 'Caricare file RICEVUTA DI TRASMISSIONE (ATPEZC), FOTOGRAFIA (ATPEC05) e ESITI (ATPECXX)'
  }[lang],
  UPLOAD_FILE_RECEIVED: { en: 'Upload file received', it: 'Carica file ricevuto' }[lang],
  USER: { en: 'User', it: 'Utente' }[lang],
  USER_LOGIN: { en: 'User login', it: 'Login utente' }[lang],
  VALIDITY_CERTIFICATES: { en: 'Validity of certificates', it: 'Validità certificati' }[lang],
  VALID_UNTIL: { en: 'VALID UNTIL', it: 'VALIDO FINO AL' }[lang],
  UNTIL: { en: 'UNTIL', it: 'FINO AL' }[lang],
  VALUE: { en: 'Value', it: 'Valore' }[lang],
  VALUE_MUST_BE_0: { en: 'The value must be 0', it: 'Il valore deve essere pari a 0' }[lang],
  VALUE_MUST_BE_99: { en: 'The value must be 99', it: 'Il valore deve essere pari a 99' }[lang],
  VARIATION_OF_DATA_ALREADY_COMMUNICATED: {
    en: 'Variation of data already communicated',
    it: 'Variazione di dati già comunicati'
  }[lang],
  VAT_NUMBER: { en: 'VAT number', it: 'Partita IVA' }[lang],
  VERIFY: { en: 'Verify', it: 'Verifica' }[lang],
  VERIFY_CERTIFICATES: { en: 'Verify certificates', it: 'Verifica certificati' }[lang],
  VIDEO_TUTORIAL: { en: 'Video tutorial', it: 'Video tutorial' }[lang],
  VIEW: { en: 'View', it: 'Vista' }[lang],
  VIEWPDF: { en: 'Print PDF', it: 'Stampa PDF' }[lang],
  WAITING_FOR_RECEIPT: { en: 'Waiting for receipt', it: 'In attesa di ricevuta' }[lang],
  WARNING: { en: 'Warning', it: 'Attenzione' }[lang],
  WARNING_IF_IN_THE_FUTURE_IT_IS_NEEDED_TO_RESTORE_THIS_SUBJECT_AUTHORIZATION_MUST_BE_REQUESTED_TO_THE_ASSISTANCE_OFFICE:
    {
      en: 'WARNING: If in the future it is needed to restore this/these subject/s, the authorization must be requested to the Assistance Office',
      it: "ATTENZIONE: se in futuro dovesse servire ripristinare questo/i soggetto/i, dovrà essere chiesta l'autorizzazione all'ufficio Assistenza"
    }[lang],
  WHOLENAME: { en: 'Whole Name', it: 'Nome Completo' }[lang],
  WITH_REPORTS: { en: 'With reports', it: 'Con rapporti' }[lang],
  WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION: {
    en: 'Would you like to activate the demo version of',
    it: 'Si desidera attivare la versione demo di'
  }[lang],
  WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION_OF_CORA: {
    en: 'Would you like to activate the demo version of CORA for 30 days?',
    it: 'Si desidera attivare la versione demo di CORA per 30 giorni?'
  }[lang],
  WOULD_YOU_LIKE_TO_ACTIVATE_DEMO_VERSION_OF_CRS: {
    en: 'Would you like to activate the demo version of CRS for 30 days?',
    it: 'Si desidera attivare la versione demo di CRS per 30 giorni?'
  }[lang],
  WOULD_YOU_LIKE_TO_ACTIVATE_COMUREI: {
    en: 'Would you like to activate COMUREI for the FIRST SUBJECT (free)?',
    it: 'Si desidera attivare COMUREI per il PRIMO SOGGETTO (gratuito)?'
  }[lang],
  YEAR: { en: 'Year', it: 'Anno' }[lang],
  YEAR_OF_REFERENCE: {
    en: 'Year of reference (subject detail)',
    it: 'Anno di riferimento (dettaglio soggetto)'
  }[lang],
  YES: { en: 'Yes', it: 'Si' }[lang],
  YOU_CAN_DOWNLOAD_ALL_THE_DATA_RELATED_TO_THE_SELECTED_SUBJECTS: {
    en: 'You can download all the data related to the selected subjects',
    it: 'Puoi scaricare tutti i dati inerenti ai soggetti selezionati'
  }[lang],
  YOU_MUST_ACCEPT_THE_CONDITIONS: {
    en: 'You must accept the conditions',
    it: 'Devi accettare le condizioni'
  }[lang],
  YOU_MUST_CHECK_THE_CONNECTION_BEFORE_SAVING: {
    en: 'You must check the connection before saving',
    it: 'Devi controllare la connessione prima di salvare'
  }[lang],
  YOU_WILL_RECEIVE_THE_FILES_AT_YOUR_EMAIL_ADDRESS: {
    en: 'You will receive the files at your email address',
    it: 'Riceverai i file al tuo indirizzo email'
  }[lang]
})
