import { Component } from 'react'
import PropTypes from 'prop-types'

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }

  onClick = () => {
    const { label, onClick, idTab } = this.props
    onClick(label, idTab)
  }

  render() {
    const {
      onClick,
      props: { activeTab, label, icon, disabled, idTab }
    } = this

    let className = 'tab-list-item nav-link'

    if (activeTab === label) {
      className += ' tab-list-active active'
    }
    if (disabled) {
      className += ' disabled'
    }

    return (
      <li className={className} onClick={onClick} id={idTab}>
        <div
          className="col-12 text-center font-size-big text-custom-tab"
          style={activeTab !== label ? { color: '#6E6E6E' } : {}}>
          {icon && <img src={icon} width="18" />}
          {label}
        </div>
      </li>
    )
  }
}

export default Tab
