import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../../actions'
import * as Utils from '../CommunicationDownloadUtils'
import getText from '../../../shared/i18n/labels'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'

// COMPONENTS
import RowSpinner from '../../../shared/spinner/Spinner'
import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { formatDateForDisplay } from '../../../shared/Utility'

const defaultSize = (size = null) => ({
  creationDate: Utility.getSize(size, { default: '15' }),
  fileName: Utility.getSize(size, { default: '30' }),
  status: Utility.getSize(size, { default: '42' }),
  download: Utility.getSize(size, { default: '13' })
})

const getTableContainerWidth = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return '110%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '100%'
  return '100%'
}

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export function ConsistencyHistoryList(props) {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [loading, setLoading] = useState(false)
  const [communicationsHistory, setCommunicationsHistory] = useState([])

  const [sizesClass, setSizesClass] = useState(getSize())

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    getHistory(props.subjectId)
    getSubject(props.subjectId)
  }, [])

  const getHistory = (subjectId) => {
    setLoading(true)
    actions.getConsistencyReceiptHistoryBySubjectId(subjectId).then((res) => {
      setCommunicationsHistory(res)
      setLoading(false)
    })
  }

  const getSubject = (subjectId) => {
    dispatch(actions.getCoraSubject(subjectId))
  }

  const renderRowHeader = () => {
    return (
      <>
        <div className={`${sizesClass.creationDate} div-td`}>{labels.CREATION_DATE}</div>
        <div className={`${sizesClass.fileName} div-td`}>{labels.FILE_NAME}</div>
        <div className={`${sizesClass.status} div-td`}>{labels.STATUS}</div>
        <div className={`${sizesClass.download} div-td`}>{labels.DOWNLOAD}</div>
      </>
    )
  }

  const isATPEC03or04 = (communication) => {
    return (
      communication?.txtFilename.startsWith('ATPEC03') ||
      communication?.txtFilename.startsWith('ATPEC04')
    )
  }

  const computeStatusText = (communication) => {
    let status = ''
    if (communication?.communicationStatus === CommunicationStatus.ERROR_SID) {
      status = labels.ATTENTION + '!! ' + communication?.communicationError + '.'
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
      if (isATPEC03or04(communication)) {
        status = labels.OUTCOME_DOCUMENT_COMMUNICATION_ACCEPTED
      } else {
        status = labels.PHOTOGRAPH_PROCESSED_WITH_DISCREPANCY_TEXT
      }
    } else if (
      communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR
    ) {
      if (isATPEC03or04(communication)) {
        status = labels.OUTCOME_DOCUMENT_COMMUNICATION_ACCEPTED_WITH_ERRORS
      } else {
        status = labels.PHOTOGRAPH_PROCESSED_WITH_DISCREPANCY_TEXT_WITH_ERRORS
      }
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
      if (communication.communicationError) {
        status = communication.communicationError
      } else {
        if (isATPEC03or04(communication)) {
          status = labels.OUTCOME_DOCUMENT_COMMUNICATION_REJECTED
        } else {
          status = labels.PHOTOGRAPH_NOT_PROCESSED
        }
      }
    }
    return status
  }

  const renderCommunicationRow = (communication, index) => {
    let isReceipt = isATPEC03or04(communication)
    let status = computeStatusText(communication)
    return (
      <div
        key={index}
        className={`${index % 2 !== 0 ? 'bg-color' : ''} text-start row-table row-table-without-click`}>
        <div className={`${sizesClass.creationDate} div-td text-truncate`}>
          {formatDateForDisplay(communication.creationDate)}
        </div>
        <div className={`${sizesClass.fileName} div-td text-truncate`}>
          {communication?.txtFilename}
        </div>
        <div className={`${sizesClass.status} div-td text-truncate`}>{status}</div>
        <div
          className={`${sizesClass.download} div-td text-truncate d-flex justify-content-around align-items-center containerPopOver`}>
          {isReceipt ? (
            <>
              <button
                data-tip={labels.DOWNLOAD_RECEIPT_TXT}
                className="me-2"
                onClick={() => Utils.downloadReceiptTxt(communication)}>
                <i className="thx-text-file thx-icon bg-primary" />
              </button>
              <button
                data-tip={labels.DOWNLOAD_ORIGINAL_RECEIPT}
                onClick={() => Utils.downloadOriginalReceipt(communication)}>
                <i className="thx-text-file-solid thx-icon bg-primary" />
              </button>
            </>
          ) : (
            <>
              <button
                data-tip={labels.DOWNLOAD_PHOTOGRAPH_TXT}
                className="me-2"
                onClick={() => Utils.downloadConsistencyReceiptTxt(communication)}>
                <i className="thx-text-file thx-icon bg-primary" />
              </button>
              <button
                data-tip={labels.DOWNLOAD_RECEIVED_FILE}
                className="me-2"
                onClick={() => Utils.downloadConsistencyFile(communication)}>
                <i className="thx-text-file-solid thx-icon bg-primary" />
              </button>
              <button
                data-tip={labels.DOWNLOAD_PROCESSED_FILE}
                className="me-2"
                onClick={() => Utils.downloadOriginalReceipt(communication)}>
                <i className="thx-text-file-solid thx-icon bg-primary" />
              </button>
            </>
          )}
        </div>
      </div>
    )
  }

  const renderRows = (communications) => {
    if (loading) return <RowSpinner />

    if (communications?.length === 0) {
      return (
        <div className={'empty-state'} style={{ height: 'unset', width: '99%' }}>
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
            style={{ width: '56px', height: '56px' }}
          />
          <h3 className="mt-3">{labels.NO_PHOTOGRAPH_OR_RESULTS_FOUND}</h3>
        </div>
      )
    }
    return communications.map((communication, index) => {
      return renderCommunicationRow(communication, index)
    })
  }

  return (
    <div>
      <div className="row header-btn-v2 mb-2 align-items-left">
        {labels.HISTORY_OF_CONSISTENCY_PHOTOGRAPHS_OR_RESULTS}
      </div>
      <div className="star-table-recipient">
        <div className="star-table" style={{ width: getTableContainerWidth(), height: '68vh' }}>
          <div className="text-start header-table">{renderRowHeader()}</div>
          {renderRows(communicationsHistory)}
        </div>
      </div>
    </div>
  )
}
