import { useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../../shared/Utility'
import * as actions from '../../../../actions'
import getText from '../../../../components/shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import Modal from 'react-bootstrap/Modal'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

export function ArcoRegistryImportDocumentModal(props) {
  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: '' })

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const closeModal = () => {
    props.onHide()
  }

  const checkFileName = (name) => {
    let allowed = ['pdf', 'jpg', 'png', 'jpeg', 'docx', 'txt']
    return allowed.indexOf(name.split('.').pop().toLowerCase()) !== -1
  }

  const checkFileDimension = (file) => {
    let sumDimensionFiles = 0
    if (file.length === 1) {
      sumDimensionFiles = file[0].size
    } else {
      for (let i = 0; i < file.length; i++) {
        sumDimensionFiles += file[i].size
      }
    }
    if (sumDimensionFiles > 20e6) {
      return false
    } else {
      return true
    }
  }

  const handleUploadFile = (val) => {
    if (isEmpty(val)) {
      setErrorDataFile({ isValid: false, msg: labels.ERROR_REQUIRED_FILE })
    }
    let files = []
    if (checkFileDimension(val.target.files)) {
      setErrorDataFile({ isValid: true, msg: '' })
      for (let i = 0; i < val.target.files.length; i++) {
        if (!checkFileName(val.target.files[i].name)) {
          setErrorDataFile({ isValid: false, msg: labels.ERROR_FILE_EXTENSION })
        } else {
          setErrorDataFile({ isValid: true, msg: '' })
          files.push(val.target.files[i])
        }
      }
    } else {
      setErrorDataFile({ isValid: false, msg: labels.ERROR_FILE_SIZE })
    }
    if (files.length > 0) setDataFile(files)
  }

  const handleSendDataFile = () => {
    let formData = new FormData()
    if (dataFile?.length > 0) {
      for (let i = 0; i < dataFile.length; i++) {
        formData.append('files', dataFile[i])
      }
    } else {
      return
    }

    actions.importDocumentFile(props.id, formData).then(
      (res) => {
        let results = []
        if (!res.error) {
          PopupSuccess({
            dataList: results
          })
          closeModal()
          props.uncheckAll()
          props.getAllArcoDocumentsByOperationId()
        } else {
          setDataFile([])
        }
      },
      (err) => {
        if (err.errorKey) PopupError({ text: err.title })
        else PopupError({ text: labels.ERROR_IMPORT_FILE })
      }
    )
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.IMPORT_FILE}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="image-upload text-start">
            <label htmlFor={`dataFile`}>
              <div className="certificateFiles d-flex align-items-center ">
                {labels.LOAD_FILE} <i className="thx-upload thx-icon ms-2" />
              </div>
            </label>
            <input
              id={`dataFile`}
              type="file"
              name={`dataFile`}
              multiple
              onChange={(e) => handleUploadFile(e)}
            />
            <div className="certificatiLoaded text-start">
              {!errorDataFile.isValid ? (
                <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
              ) : (
                <div>
                  {dataFile.length > 0 &&
                    dataFile.map((file, index) => {
                      return (
                        <div key={index}>
                          <i className="thx-check thx-icon me-2" />
                          {file.name}
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-0">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorDataFile.isValid || !dataFile}
              type="button"
              className="btn btn-primary"
              onClick={handleSendDataFile}>
              {labels.CONFIRM}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
