import { useEffect, useState } from 'react'

// COMPONENTS
import Autosuggest from 'react-autosuggest'

const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const getSuggestions = (value, filter = []) => {
  const escapedValue = escapeRegexCharacters(value.trim())

  if (escapedValue === '') {
    return []
  }
  const regex = new RegExp('^.*' + escapedValue + '.*$', 'i')
  const suggestions = filter.filter((datas) => regex.test(datas.name))

  return suggestions
}

export const AutoCompileCustom = (props) => {
  const [value, setValue] = useState(props.value || '')
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value || '')
    }
  }, [props.value])

  const onChange = (event, { newValue, method }) => {
    setValue(newValue?.toUpperCase() || '')
    props.handleInputAutocompile(newValue, props.id)
  }

  const onSuggestionSelected = (event, { suggestionValue, method }) => {
    if (method === 'click') {
      setValue(suggestionValue)
      props.handleInputAutocompile(suggestionValue)
    } else {
      event.preventDefault()
    }
  }

  const getSuggestionValue = (suggestion) => {
    return suggestion.name
  }

  const renderSuggestion = (suggestion) => {
    return suggestion.name
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, props.filter))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const inputProps = { placeholder: props.placeholder, value, onChange }
  const inputWrapperClass = `input-wrapper w-100 ${props.touched && !props.error?.isValid ? 'has-error' : ''}`

  return (
    <div className={inputWrapperClass}>
      {props.label && <label>{props.label}</label>}
      <Autosuggest
        shouldRenderSuggestions={() => true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        disabled={true}
      />
      {!props.error?.isValid ? <div className="text-danger">{props.error.msg}</div> : ''}
    </div>
  )
}
