import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../actions'
import * as Constants from '../../config/Constants'
import authService from '../../services/auth-service'
import getText from '../shared/i18n/labels'

// TABLE
import { StarTable } from '../shared/tables/StarTable'
import { TableUtils, baseSearchObject, fieldTypes } from '../shared/tables/TableUtils'

// MODALS
import { AdminNewApplicationModal } from './AdminNewApplicationModal'

// FORM
import { BwmInput } from '../shared/form/BwmInputB5'

// BUTTONS
import { BtnFill } from '../shared/BtnFill'

// COMPONENTS
import { PopupError } from '../shared/popups/PopupError'
import { PopupSuccess } from '../shared/popups/PopupSuccess'
import { Link } from 'react-router-dom'

const tableName = 'admin-customer-applications'
const startelPath = '/admin/star-tel'

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '170%'
  if (window.innerWidth < Constants.BREAKPOINT_LG) return '160%'
  return '100%'
}

export function AdminCustomers() {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  var editedIds = []

  const [loading, setLoading] = useState(false)
  const [sidNumber, setSidNumber] = useState('')
  const [subjectDenomination, setSubjectDenomination] = useState('')
  const [rows, setRows] = useState([])
  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    denomination: '',
    email: '',
    applicationId: [],
    maxSubjects: '',
    licenseType: [],
    expiration: '',
    lastModified: '',
    pageSize: 100,
    sortField: 'lastModified',
    sidNumber: '',
    subjectDenomination: '',
    version: '3'
  })

  useEffect(() => {
    dispatch(actions.getAccount())
  }, [])

  const onEdit = async (newList = [], id) => {
    editedIds = [...editedIds, id]
    setRows(prepareRows(newList))
  }

  const getCustomerApplications = (searchObject) => {
    setLoading(true)
    actions
      .searchAdminCustomerApplications(searchObject)
      .then((res) => {
        setRows(prepareRows(res.content))
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const onUpdate = (customerApplication) => {
    actions.updateCustomerApplication(customerApplication.id, customerApplication).then(
      () => {
        getCustomerApplications(searchObject)
        editedIds = editedIds.filter((id) => id !== customerApplication.id)
      },
      (err) => {
        PopupError({ text: err?.title || Constants.APPLICATION_GENERIC_ERROR })
      }
    )
  }

  const onSave = (customerApplication) => {
    setLoading(true)
    actions
      .addCustomerApplication(customerApplication)
      .then(
        () => {
          PopupSuccess({ text: labels.LICENSE_INSERTED })
          getCustomerApplications(searchObject)
        },
        (err) => {
          PopupError({
            text: err?.title || Constants.APPLICATION_GENERIC_ERROR
          })
        }
      )
      .then(() => setLoading(false))
  }

  const header = [
    TableUtils.composeHeader({
      fieldName: 'denomination',
      displayedName: labels.DENOMINATION,
      maxWidth: '12rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'email',
      displayedName: labels.EMAIL
    }),
    TableUtils.composeHeader({
      fieldName: 'applicationId',
      displayedName: labels.APPLICATION_NAME,
      type: fieldTypes.SELECTION,
      options: Constants.APPLICATIONS,
      minWidth: '9rem',
      maxWidth: '9rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'maxSubjects',
      displayedName: labels.N_SUBJECTS,
      type: fieldTypes.NUMBER,
      minWidth: '10rem',
      maxWidth: '10rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'licenseType',
      displayedName: labels.LICENSE_TYPE,
      type: fieldTypes.SELECTION,
      options: Constants.LICENSE_TYPES,
      minWidth: '10rem',
      maxWidth: '10rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'expiration',
      displayedName: labels.EXPIRATION,
      type: fieldTypes.DATE,
      minWidth: '10rem',
      maxWidth: '10rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'lastModified',
      displayedName: labels.LAST_ORDER,
      type: fieldTypes.DATE,
      minWidth: '11rem',
      maxWidth: '11rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'save',
      displayedName: labels.EDIT,
      additionalClass: 'd-flex justify-contnet-center',
      sortable: false,
      searchable: false,
      minWidth: '6rem',
      maxWidth: '6rem'
    })
  ]

  const prepareRows = (customerApplications = []) => {
    return customerApplications.map((app) => {
      const application = Constants.APPLICATIONS.find((a) => a.id === app.applicationId)
      return TableUtils.composeRow({
        id: app.id,
        cellData: {
          denomination: TableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: app.denomination
          }),
          email: TableUtils.composeCell({
            fieldName: 'email',
            fieldValue: app.email,
            component: () => (
              <div className=" text-truncate d-flex align-items-center">
                <AdminNewApplicationModal
                  onSave={(customerApplication) => onSave(customerApplication)}
                  key={'admin-new-application' + app.id}
                  customer={app.customer}
                />
                <Link
                  onClick={() => authService.setHeader(Constants.FAKE_USER, app.email)}
                  to={'/dashboard'}>
                  <button type="button" className="btn btn-primary btn-cell me-2">
                    {labels.LOGIN}
                  </button>
                </Link>
                <span className="text-truncate" style={{ maxWidth: '75%' }}>
                  {app.email}
                </span>
                <Link
                  to={{
                    pathname: '/app/cora/admin',
                    state: { loginParam: app.email }
                  }}
                  rel="noopener noreferrer"
                  className="ms-auto">
                  <button type="button" className="ms-2 btn btn-primary btn-cell">
                    C
                  </button>
                </Link>
                <Link
                  to={{
                    pathname: '/app/facciamo-noi/admin',
                    state: { loginParam: app.email }
                  }}
                  rel="noopener noreferrer">
                  <button type="button" className="ms-2 btn btn-primary btn-cell me-2">
                    FNT
                  </button>
                </Link>
                <Link
                  to={`${startelPath}?login=${app.email}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="thx-phone thx-icon me-2" />
                </Link>
              </div>
            )
          }),
          applicationId: TableUtils.composeCell({
            fieldName: 'applicationId',
            fieldValue: application ? application.description : ''
          }),
          maxSubjects: TableUtils.composeCell({
            fieldName: 'maxSubjects',
            fieldValue: app.maxSubjects,
            editable: true,
            onEdit: (rowId, fieldName, value) =>
              onEdit(
                TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }),
                app.id
              )
          }),
          licenseType: TableUtils.composeCell({
            fieldName: 'licenseType',
            fieldValue: app.licenseType,
            editable: true,
            onEdit: (rowId, fieldName, value) =>
              onEdit(
                TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }),
                app.id
              )
          }),
          expiration: TableUtils.composeCell({
            fieldName: 'expiration',
            fieldValue: app.expiration,
            editable: true,
            onEdit: (rowId, fieldName, value) =>
              onEdit(
                TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }),
                app.id
              )
          }),
          lastModified: TableUtils.composeCell({
            fieldName: 'lastModified',
            fieldValue: app.lastModified
          }),
          save: TableUtils.composeCell({
            fieldName: 'save',
            component: () => {
              return (
                <div className="w-100 d-flex justify-content-center">
                  <button
                    disabled={!editedIds.includes(app.id)}
                    className="btn btn-primary btn-cell"
                    onClick={() => onUpdate(app)}>
                    {labels.SAVE}
                  </button>
                </div>
              )
            }
          })
        }
      })
    })
  }

  const renderTable = () => {
    return (
      <StarTable
        loading={loading}
        headerColumns={header}
        rows={rows}
        tableContainerWidth={getTableContainerWidth()}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={(newSearchObject) => setSearchObject(newSearchObject)}
        searchCallBack={actions.searchAdminCustomerApplications}
        onExecutedSearch={(content) => {
          setRows(prepareRows(content))
        }}
      />
    )
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <div className={'d-flex align-items-center'}>
          <div className="me-2 w-100">
            <BwmInput
              label={labels.SEARCH_SUBJECT}
              className="form-control"
              value={subjectDenomination}
              onChange={(e) => {
                setSubjectDenomination(e.target.value)
              }}
            />
          </div>
          <BwmInput
            label={labels.SEARCH_SID_NUMBER}
            className="form-control"
            value={sidNumber}
            onChange={(e) => {
              setSidNumber(e.target.value)
            }}
          />
          <BtnFill
            classCustom={'ms-3 d-flex btn-search'}
            text={labels.SEARCH}
            handlerOnClick={() => {
              setSearchObject({
                ...searchObject,
                sidNumber,
                subjectDenomination
              })
              getCustomerApplications({
                ...searchObject,
                sidNumber,
                subjectDenomination
              })
            }}
          />
        </div>
        <div className={'ms-auto d-flex align-items-center justify-content-end'}>
          <Link to={'/app/facciamo-noi/admin'}>
            <BtnFill text={labels.FACCIAMO_NOI} />
          </Link>
          <Link className="ms-2 me-2" to={'/app/cora/admin'}>
            <BtnFill text={'CORA'} />
          </Link>
          <Link to={startelPath}>
            <BtnFill text={'STAR-TEL'} />
          </Link>
        </div>
      </div>
      {renderTable()}
    </>
  )
}
