import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { downloadErrorReport } from '../CommunicationDownloadUtils'
import { formatDateForDisplay } from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

export function CommunicationErrorList(props) {
  const {
    communicationsInError,
    renderDeleteButton,
    downloadTxt,
    downloadDgn,
    reelaborateCommunicationTxt
  } = props

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [allChecked, setAllChecked] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(true)
  const [checkedCommunications, setCheckedCommunications] = useState(new Map())

  //rivaluto la flag del bottone di eliminazione, e la flag di check delle righe
  useEffect(() => {
    if (checkedCommunications.size > 0) {
      setDisableDeleteButton(false)
    } else {
      setDisableDeleteButton(true)
    }
    setAllChecked(
      communicationsInError?.length !== 0 &&
        checkedCommunications.size === communicationsInError.length
    )
  }, [checkedCommunications])

  //resetto la lista di comunicazioni selezionate al ricaricamento della lista
  useEffect(() => {
    setCheckedCommunications(new Map())
  }, [communicationsInError])

  const handleAllChecked = (e) => {
    let checkedComms = new Map()
    if (e.target.checked) {
      setAllChecked(true)
      communicationsInError.forEach((element) => {
        checkedComms.set(element.id, element)
      })
    } else {
      setAllChecked(false)
    }
    setCheckedCommunications(checkedComms)
  }

  const handleCheckChange = (e, communication) => {
    let checkedComms = new Map(checkedCommunications)
    if (e.target.checked) {
      checkedComms.set(communication.id, communication)
    } else {
      checkedComms.delete(communication.id)
    }
    setAllChecked(checkedComms.size === communicationsInError.length)
    setCheckedCommunications(checkedComms)
  }

  const renderErrorRowHeader = () => {
    return (
      <>
        <div className="w-5 text-center div-td d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            checked={allChecked}
            onChange={(e) => {
              handleAllChecked(e)
            }}
          />
        </div>
        <div className="w-10 div-td">{labels.CREATION_DATE}</div>
        <div className="w-8 div-td">{labels.REFERENCE_PERIOD}</div>
        <div className="w-15 div-td">{labels.COMMUNICATION}</div>
        <div className="w-10 div-td">{labels.DISPATCH_TYPE}</div>
        <div className="w-50 div-td">{labels.STATUS}</div>
        <div className="w-10 div-td">{labels.DOWNLOAD}</div>
      </>
    )
  }

  const renderErrorRow = (communication) => {
    let status = labels.ERRORS_IN_COMMUNICATION
    const createdWithError =
      communication?.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
    if (createdWithError) {
      status = status + ': ' + labels.ERRORS_IN_COMMUNICATION_DESCRIPTION
    }
    const isChecked = checkedCommunications.has(communication.id)
    return (
      <div key={`key-comm-history-error${communication.id}`}>
        <div
          className={`text-start row-table ${isChecked && 'bg-cobalt bold'}`}
          id={`row-error-check-${communication.id}`}>
          <div className="w-5 text-center div-td d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              aria-label="Checkbox for following text input"
              id={`row-error-check-${communication.id}`}
              name="communicationErrorCheck"
              value={communication.id}
              checked={isChecked}
              onChange={(e) => handleCheckChange(e, communication)}
            />
          </div>
          <div className="w-10 div-td text-truncate">
            {formatDateForDisplay(communication?.creationDate)}
          </div>
          <div className="w-8 div-td text-truncate">{communication?.referencePeriod}</div>
          <div className="w-15 div-td text-truncate">
            {communication?.communicationType?.code} -
            {communication?.communicationType?.description}
          </div>
          <div className="w-10 div-td text-truncate">
            {communication?.dispatchType === 'ORDINARY' ? 'Ordinario' : 'Straordinario'}
          </div>
          <div className="w-50 div-td text-truncate">{status}</div>
          <div className="w-10 div-td d-flex justify-content-around pe-4 ps-4 containerPopOver">
            {createdWithError && (
              <span
                className="cursor-pointer me-2"
                data-tip={labels.DOWNLOAD_ERROR_REPORT}
                onClick={() => downloadErrorReport(communication)}>
                <i className="thx-triangle thx-icon" />
              </span>
            )}
            <span
              className="cursor-pointer me-2"
              data-tip={labels.DOWNLOAD_COMMUNICATION_TXT}
              onClick={() => downloadTxt(communication)}>
              <i className="thx-text-file thx-icon" />
            </span>
            <div
              className="cursor-pointer me-2"
              data-tip={labels.DOWNLOAD_DIAGNOSTIC}
              onClick={() => downloadDgn(communication)}>
              <i className="thx-list-file thx-icon me-2" />
            </div>
            <span
              className="cursor-pointer me-2"
              data-tip={labels.REELABORATE_FILE_TXT}
              onClick={() => reelaborateCommunicationTxt(communication)}>
              <i className="thx-rotate-right thx-icon me-2" />
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {communicationsInError?.length > 0 && (
        <>
          <div className="row header-btn-history mb-2">
            <div className={'col-3'}>
              {labels.COMMUNICATIONS_IN_ERROR} &nbsp; &nbsp; &nbsp;
              {renderDeleteButton(disableDeleteButton, checkedCommunications)}
            </div>
          </div>
          <div className="text-start header-table error">{renderErrorRowHeader()}</div>

          {communicationsInError.map((e) => {
            return renderErrorRow(e)
          })}
        </>
      )}
    </>
  )
}
