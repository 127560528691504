import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import { IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import { APPLICATION_ARCO } from '../../../../config/Constants'

// MODAL
import { ArcoRegistryClipModal } from './NewArcoRegistryClipModal'

// COMPONENTS
import { Link } from 'react-router-dom'
import { ArcoRegistrationsList } from './NewArcoRegistrationsList'
import { ArcoRegistriesList } from './NewArcoRegistriesList'
import { LicenseExpiredPopup } from '../../../shared/auth/license/LicenseExpiredPopup'

import arrow_l from '../../../../styles/images/arrow_l.png'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

export const ArcoRegistrations = (props) => {
  const dispatch = useDispatch()

  const [arcoSubject, setArcoSubject] = useState({})
  const [selectedArcoOperation, setSelectedArcoOperation] = useState()
  const [showRegistryList, setShowRegistryList] = useState(false)
  const [expired, setExpired] = useState(false)

  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    getSubject()
    getProfessionals()
    getArcoDomains()
    getAllCollaborator()
    getDocumentType()
    getLocations()
    getCountries()
    getAllCurrency()
  }, [])

  useEffect(() => {
    if (expired) LicenseExpiredPopup({})
  }, [expired])

  useEffect(() => isExpired(), [auth])

  const isExpired = () => {
    if (auth?.isAuth) {
      let valid = IsLicenseValid(auth.customer?.customerApplications, APPLICATION_ARCO)
      setExpired(!valid)
    }
  }

  const getLocations = () => {
    if (!props.locations || props.locations.length == 0) dispatch(actions.getAllLocations())
  }

  const getCountries = () => {
    if (!props.countries || props.countries.length == 0) dispatch(actions.getAllCountries())
  }

  const getDocumentType = () => {
    dispatch(actions.getAllDocumentType())
  }

  const getAllCurrency = () => {
    if (!props.currencies || props.currencies.length == 0) {
      dispatch(actions.getAllCurrency())
    }
  }

  const getAllCollaborator = () => {
    dispatch(actions.getAllCollaboratorBySubjectId(props.match.params.id))
  }

  const getArcoDomains = () => {
    dispatch(actions.getAllArcoDomains(props.match.params.id))
  }

  const getProfessionals = () => {
    dispatch(actions.getAllProfessionistBySubjectId(props.match.params.id))
  }

  const getSubject = () => {
    actions.getArcoSubjectDetail(props.match.params.id).then((res) => {
      setArcoSubject(res)
    })
  }

  const handleOpenClipModal = (val) => {
    setSelectedArcoOperation(val)
  }

  return (
    <>
      <div className="row title-page p-0">
        <Link to="/app/arco/dashboard">
          <img src={arrow_l} className="pe-3 pb-1" alt="Torna a elenco soggetti" />
          {arcoSubject.companyName}
        </Link>
      </div>
      <div className="table-custom">
        <div className="table-custom mt-1 mb-1">
          <div className="row">
            <ArcoRegistrationsList
              disabled={expired}
              arcoSubjectId={props.match.params.id}
              arcoSubject={arcoSubject}
              setShowRegistryList={setShowRegistryList}
              showClipModal={(response) => handleOpenClipModal(response)}
            />
          </div>
        </div>
      </div>
      <div>
        {selectedArcoOperation && (
          <div>
            <ArcoRegistryClipModal
              disabled={expired}
              arcoSubjectId={props.match.params.id}
              arcoSubject={arcoSubject}
              show={!!selectedArcoOperation}
              arcoRegistriesOperation={selectedArcoOperation}
              onHide={() => handleOpenClipModal()}
            />
          </div>
        )}

        {showRegistryList && (
          <ArcoRegistriesList
            disabled={expired}
            arcoSubjectId={props.match.params.id}
            arcoSubject={arcoSubject}
            showModalRegistriesList={showRegistryList}
            onHide={() => {
              setShowRegistryList(false)
              handleOpenClipModal()
            }}
          />
        )}
      </div>
    </>
  )
}
