import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// FORM
import { BwmInput } from '../../../shared/form/BwmInput'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'

import '../../../../styles/comurei-form.css'

export const ArcoRiskClasses = (props) => {
  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  useEffect(() => {
    setNewInitialValues()
  }, [props.crDomains])

  const setNewInitialValues = () => {
    setInitialValues({
      crDomainMinLow: props.crDomains?.crDomainMinLow?.value,
      crDomainMinHigh: props.crDomains?.crDomainMinHigh?.value,
      crBehaviourMin: props.crDomains?.crBehaviourMin?.value,
      crDomainMediumLow: props.crDomains?.crDomainMediumLow?.value,
      crDomainMediumHigh: props.crDomains?.crDomainMediumHigh?.value,
      crBehaviourMed: props.crDomains?.crBehaviourMed?.value,
      crDomainMaxLow: props.crDomains?.crDomainMaxLow?.value,
      crDomainMaxHigh: props.crDomains?.crDomainMaxHigh?.value,
      crBehaviourMax: props.crDomains?.crBehaviourMax?.value
    })
  }

  const formErrorsMapping = [
    // MIN
    {
      errorKey: 'crDomainMinLow',
      errorLabel: labels.LOW_RISK_MIN
    },
    {
      errorKey: 'crDomainMinHigh',
      errorLabel: labels.LOW_RISK_MAX
    },
    {
      errorKey: 'crBehaviourMin',
      errorLabel: labels.LOW_RISK_BEHAVIOUR
    },
    // MEDIUM
    {
      errorKey: 'crDomainMediumLow',
      errorLabel: labels.MEDIUM_RISK_MIN
    },
    {
      errorKey: 'crDomainMediumHigh',
      errorLabel: labels.MEDIUM_RISK_MAX
    },
    {
      errorKey: 'crBehaviourMed',
      errorLabel: labels.MEDIUM_RISK_BEHAVIOUR
    },
    // MAX
    {
      errorKey: 'crDomainMaxLow',
      errorLabel: labels.HIGH_RISK_MIN
    },
    {
      errorKey: 'crDomainMaxHigh',
      errorLabel: labels.HIGH_RISK_MAX
    },
    {
      errorKey: 'crBehaviourMax',
      errorLabel: labels.HIGH_RISK_BEHAVIOUR
    }
  ]

  const validationSchema = Yup.object({
    // MIN
    crDomainMinLow: Yup.number().required(labels.REQUIRED_FIELD).oneOf([0], labels.VALUE_MUST_BE_0),
    crDomainMinHigh: Yup.number()
      .required(labels.REQUIRED_FIELD)
      .moreThan(Yup.ref('crDomainMinLow'), labels.INCONGRUENT_RANGE),
    crBehaviourMin: Yup.string().required(labels.REQUIRED_FIELD),
    // MEDIUM
    crDomainMediumLow: Yup.number()
      .required(labels.REQUIRED_FIELD)
      .moreThan(Yup.ref('crDomainMinHigh'), labels.INCONGRUENT_RANGE)
      .lessThan(Yup.ref('crDomainMediumHigh'), labels.INCONGRUENT_RANGE),
    crDomainMediumHigh: Yup.number()
      .required(labels.REQUIRED_FIELD)
      .moreThan(Yup.ref('crDomainMediumLow'), labels.INCONGRUENT_RANGE),
    crBehaviourMed: Yup.string().required(labels.REQUIRED_FIELD),
    // MAX
    crDomainMaxLow: Yup.number()
      .required(labels.REQUIRED_FIELD)
      .moreThan(Yup.ref('crDomainMediumHigh'), labels.INCONGRUENT_RANGE)
      .lessThan(Yup.ref('crDomainMaxHigh'), labels.INCONGRUENT_RANGE),
    crDomainMaxHigh: Yup.number()
      .required(labels.REQUIRED_FIELD)
      .oneOf([99], labels.VALUE_MUST_BE_99)
      .moreThan(Yup.ref('crDomainMaxLow'), labels.INCONGRUENT_RANGE),
    crBehaviourMax: Yup.string().required(labels.REQUIRED_FIELD)
  })

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsMapping)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form>
            {showErrorAlert && (
              <div className="me-4">
                <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
              </div>
            )}
            <FormBody
              {...props}
              initialValues={initialValues}
              setNewInitialValues={setNewInitialValues}
              setShowErrorAlert={setShowErrorAlert}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [touched, setTouched] = useState(false)

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  const handleChangeValue = (val, name, upperCase = false) => {
    const form = Object.assign({}, values)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    form[name] = ![null, undefined, ''].includes(val) ? val : null
    setValues(form)
  }

  const cancel = () => {
    props.setNewInitialValues()
  }

  const updateRiskClass = () => {
    const arrayToSave = [
      { ...props.crDomains.crDomainMinLow, value: values.crDomainMinLow },
      { ...props.crDomains.crDomainMinHigh, value: values.crDomainMinHigh },
      { ...props.crDomains.crBehaviourMin, value: values.crBehaviourMin },
      { ...props.crDomains.crDomainMediumLow, value: values.crDomainMediumLow },
      { ...props.crDomains.crDomainMediumHigh, value: values.crDomainMediumHigh },
      { ...props.crDomains.crBehaviourMed, value: values.crBehaviourMed },
      { ...props.crDomains.crDomainMaxLow, value: values.crDomainMaxLow },
      { ...props.crDomains.crDomainMaxHigh, value: values.crDomainMaxHigh },
      { ...props.crDomains.crBehaviourMax, value: values.crBehaviourMax }
    ]
    actions
      .updateCrArcoDomains(props.subject.id, arrayToSave)
      .then((res) => {
        props.getAllArcoDomainsBySubjectId()
        PopupSuccess({ text: labels.SAVED_SUCCESSFULLY })
      })
      .catch((errors) => {
        PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
      })
  }

  const handleFormSubmit = async () => {
    setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false) // Nascondi l'alert
      updateRiskClass()
    }
  }

  return (
    <div className="mt-4">
      <div className="d-flex flex-column justify-content-center">
        <div className="row">
          <div className="col-2 me-2 d-flex align-items-center justify-content-center text-center">
            <div className="lowRisk ps-2 d-flex align-items-center justify-content-center text-center">
              <b>{labels.LOW_RISK}</b>
            </div>
          </div>
          <div className="col-1 me-2 mb-2">
            <BwmInput
              name="minLowRisk"
              label={labels.MIN}
              className="form-control form-control-normal text-end"
              maxLength="2"
              type="number"
              value={values.crDomainMinLow}
              error={touched && errors.crDomainMinLow}
              onChange={(e) => handleChangeValue(parseInt(e.target.value || '0'), 'crDomainMinLow')}
            />
          </div>
          <div className="col-1 me-2 mb-2">
            <BwmInput
              name="maxLowRisk"
              label={labels.MAX}
              className="form-control form-control-normal text-end"
              maxLength="2"
              type="number"
              value={values.crDomainMinHigh}
              error={touched && errors.crDomainMinHigh}
              onChange={(e) =>
                handleChangeValue(parseInt(e.target.value || '0'), 'crDomainMinHigh')
              }
            />
          </div>
          <div className="col pe-4">
            <BwmInput
              name="behaviourLowRisk"
              label={labels.BEHAVIOUR}
              className="form-control form-control-normal"
              value={values.crBehaviourMin}
              error={touched && errors.crBehaviourMin}
              onChange={(e) => handleChangeValue(e.target.value, 'crBehaviourMin')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-2 me-2 d-flex align-items-center justify-content-center text-center">
            <div className="mediumRisk ps-2 d-flex align-items-center justify-content-center text-center">
              <b>{labels.MEDIUM_RISK}</b>
            </div>
          </div>
          <div className="col-1 me-2 mb-2">
            <BwmInput
              name="minMediumRisk"
              className="form-control form-control-normal text-end"
              maxLength="2"
              type="number"
              value={values.crDomainMediumLow}
              error={touched && errors.crDomainMediumLow}
              onChange={(e) =>
                handleChangeValue(parseInt(e.target.value || '0'), 'crDomainMediumLow')
              }
            />
          </div>
          <div className="col-1 me-2 mb-2">
            <BwmInput
              name="maxMediumRisk"
              className="form-control form-control-normal text-end"
              maxLength="2"
              type="number"
              value={values.crDomainMediumHigh}
              error={touched && errors.crDomainMediumHigh}
              onChange={(e) =>
                handleChangeValue(parseInt(e.target.value || '0'), 'crDomainMediumHigh')
              }
            />
          </div>
          <div className="col pe-4">
            <BwmInput
              name="behaviourMedRisk"
              className="form-control form-control-normal"
              value={values.crBehaviourMed}
              error={touched && errors.crBehaviourMed}
              onChange={(e) => handleChangeValue(e.target.value, 'crBehaviourMed')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-2 me-2 d-flex align-items-center justify-content-center text-center ">
            <div className="highRisk ps-2 d-flex align-items-center justify-content-center text-center">
              <b>{labels.HIGH_RISK}</b>
            </div>
          </div>
          <div className="col-1 me-2 mb-2">
            <BwmInput
              name="minHighRisk"
              className="form-control form-control-normal text-end"
              maxLength="2"
              type="number"
              value={values.crDomainMaxLow}
              error={touched && errors.crDomainMaxLow}
              onChange={(e) => handleChangeValue(parseInt(e.target.value || '0'), 'crDomainMaxLow')}
            />
          </div>
          <div className="col-1 me-2 mb-2">
            <BwmInput
              name="maxHighRisk"
              className="form-control form-control-normal text-end"
              maxLength="2"
              type="number"
              value={values.crDomainMaxHigh}
              error={touched && errors.crDomainMaxHigh}
              onChange={(e) =>
                handleChangeValue(parseInt(e.target.value || '0'), 'crDomainMaxHigh')
              }
            />
          </div>
          <div className="col pe-4">
            <BwmInput
              name="behaviourMaxRisk"
              className="form-control form-control-normal"
              value={values.crBehaviourMax}
              error={touched && errors.crBehaviourMax}
              onChange={(e) => handleChangeValue(e.target.value, 'crBehaviourMax')}
            />
          </div>
        </div>
      </div>

      <div className="d-flex mt-4 align-item-center justify-content-between">
        <button type="button" className="btn btn-outline-primary" onClick={cancel}>
          <i className="thx-cancel thx-icon me-2" />
          <span>{labels.CANCEL}</span>
        </button>
        <button type="submit" onClick={handleFormSubmit} className="btn btn-primary me-4">
          <i className="thx-floppy-disk thx-icon me-2" />
          {labels.SAVE}
        </button>
      </div>
    </div>
  )
}
