import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as Utility from '../../../shared/Utility'
import * as actions from '../../../../actions'
import * as Constants from '../../../../config/Constants'
import getText from '../../../shared/i18n/labels'
// MODAL
import { ImportModal } from '../../../shared/popups/ImportModal'

// FORM
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// POPUPS
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

const defaultSize = (size = null) => ({
  fiscalCode: Utility.getSize(size, { default: '10', lg: '14', md: '19' }),
  arcoSubjectType: Utility.getSize(size, { default: '27', lg: '40' }),
  companyName: Utility.getSize(size, { default: '36', lg: '46', md: '41' }),
  vatNumber: Utility.getSize(size, { default: '10', lg: '14', md: '19' }),
  address: Utility.getSize(size, { default: '17', lg: '15', md: '35' }),
  location: Utility.getSize(size, { default: '16', lg: '14', md: '25' }),
  province: Utility.getSize(size, { default: '4', lg: '5', md: '8' }),
  postalCode: Utility.getSize(size, { default: '6', lg: '6', md: '10' }),
  presetValues: Utility.getSize(size, { default: '11', lg: '9', md: '14' }),
  operationDescription: Utility.getSize(size, { default: '32', lg: '18', md: '38' }),
  operationNature: Utility.getSize(size, { default: '10', lg: '14', md: '24' }),
  operationPurpose: Utility.getSize(size, { default: '21', lg: '14', md: '24' })
})

const getSize = () => {
  if (window.innerWidth <= Constants.ARCO_BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const ArcoSubjectForm = (props) => {
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    if (props.setLoading) props.setLoading(loading)
  }, [loading])

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  const retrieveSubject = (id) => {
    setLoading(true)
    if (id) {
      actions.getArcoSubjectDetail(id).then((res) => {
        setNewInitialValues(res)
        setLoading(false)
      })
    } else {
      setNewInitialValues()
      setLoading(false)
    }
  }

  const setNewInitialValues = (data = {}) => {
    setTouched(false)
    setInitialValues({
      id: data?.id || null,
      fiscalCode: data?.fiscalCode || '',
      companyName: data?.companyName || '',
      address: data?.address || '',
      location: data?.location || '',
      province: data?.province || '',
      postalCode: data?.postalCode || '',
      vatNumber: data?.vatNumber || '',
      operationDescription: data?.operationDescription || '',
      operationNature: data?.operationNature || '',
      operationPurpose: data?.operationPurpose || '',
      arcoSubjectType: data?.arcoSubjectType || (props?.subjectTypes ? props?.subjectTypes[0] : {}),
      errorFiscalCode: false
    })
  }

  const formErrorsMapping = [
    {
      errorKey: 'companyName',
      errorLabel: labels.COMPANY_NAME
    },
    {
      errorKey: 'fiscalCode',
      errorLabel: labels.FISCAL_CODE
    },
    {
      errorKey: 'address',
      errorLabel: labels.ADDRESS
    },
    {
      errorKey: 'location',
      errorLabel: labels.LOCATION
    },
    {
      errorKey: 'province',
      errorLabel: labels.PROVINCE
    },
    {
      errorKey: 'postalCode',
      errorLabel: labels.POSTAL_CODE
    },
    {
      errorKey: 'vatNumber',
      errorLabel: labels.VAT_NUMBER
    }
  ]

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(labels.REQUIRED_FIELD),
    fiscalCode: Yup.string().required(labels.REQUIRED_FIELD),
    address: Yup.string().required(labels.REQUIRED_FIELD),
    location: Yup.string().required(labels.REQUIRED_FIELD),
    province: Yup.string().required(labels.REQUIRED_FIELD),
    postalCode: Yup.string().required(labels.REQUIRED_FIELD),
    vatNumber: Yup.string().required(labels.REQUIRED_FIELD)
  })

  const getError = (errors) => {
    return Utility.extractErrors(errors, formErrorsMapping)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form className={props.formOnBottom ? 'form-on-bottom border-top' : ''}>
            {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
            {showErrorAlert &&
              touched &&
              errors &&
              typeof errors === 'object' &&
              Object.keys(errors).length > 0 && (
                <div className={props.formOnBottom ? 'arco-form-on-bottom-error' : ''}>
                  <ErrorListAlert errors={getError(errors)} hide={() => setShowErrorAlert(false)} />
                </div>
              )}
            <FormBody
              {...props}
              loading={loading}
              touched={touched}
              initialValues={initialValues}
              retrieveSubject={retrieveSubject}
              setLoading={setLoading}
              setTouched={setTouched}
              setShowErrorAlert={setShowErrorAlert}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showImportDocumentsModal, setShowImportDocumentsModal] = useState(false)

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = ![null, undefined, ''].includes(val) ? val : null
    setValues(form)
  }

  const handleFiscalCode = (val) => {
    const form = Object.assign({}, values)
    actions.getBaseRegistryByFiscalCode(val).then(
      (baseRegistry) => {
        if (baseRegistry) {
          form.companyName = baseRegistry?.companyName || ''
          form.location = baseRegistry?.location?.location || ''
          form.province = baseRegistry?.location?.province || ''
          form.postalCode = baseRegistry?.location?.postalCode || ''
        }
        form.errorFiscalCode = false
        setValues(form)
      },
      (errors) => {
        form.errorFiscalCode = true
        setValues(form)
      }
    )
  }

  const handlerChangeSubjectType = (e) => {
    const form = Object.assign({}, values)
    const cod = e.target[e.target.selectedIndex].dataset.cod
    form.arcoSubjectType = { id: parseInt(e.target.value), code: cod }
    setValues(form)
  }

  const handleInputAutocompile = (newLocation) => {
    const form = Object.assign({}, values)
    form.location = newLocation ? newLocation.toUpperCase() : ''

    actions
      .getLocation(form.location)
      .then((loc) => {
        if (loc) {
          form.location = loc.location
          form.province = loc.province
          form.postalCode = loc.postalCode
        }
        setValues(form)
      })
      .catch(() => {
        setValues(form)
      })
  }

  const close = (retrieve = false, id = null) => {
    if (retrieve) props.retrieveSubject(props.subjectId)
    props.setTouched(false)
    if (props.close) props.close(props.subjectId ? null : id)
  }

  const saveArcoSubject = (subject, showManageTable) => {
    actions.saveArcoSubject(subject).then(
      (response) => {
        setValues(response)
        close(false, response.id)
        props.getSubjects()
        if (showManageTable) props.showPopupManageTable(response)
        PopupSuccess({ text: labels.SAVED_SUCCESSFULLY })
      },
      (errors) => {
        close()
        PopupError({
          text:
            errors.status === 511
              ? labels.MAX_SUBJECTS_REACHED
              : labels.ERROR_OCCURRED_DURING_UPDATE_SUBJECT + errors,
          labels
        })
      }
    )
  }

  const manageTable = async () => {
    const errors = await validateForm(values) // Validazione dei valori
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false) // Nascondi l'alert
      actions.saveArcoSubject(values).then((response) => {
        props.showPopupManageTable(response)
      })
    }
  }

  const uploadZipFile = (dataFile) => {
    let formData = new FormData()
    if (dataFile?.length > 0) {
      props.setLoading(true)
      for (let file of dataFile) {
        formData.append('file', file)
      }
      actions
        .importArcoDocumentsZip(formData, values.id)
        .then(
          (res) => PopupSuccess({ title: `${labels.IMPORT_DOCUMENTS_SUCCESSFULLY} ${res}` }),
          (err) => PopupError({ text: labels.ERROR_OCCURRED_DURING_IMPORT_DOCUMENTS })
        )
        .finally(() => {
          props.setLoading(false)
          setShowImportDocumentsModal(false)
        })
    }
  }

  const handleFormSubmit = async (showManageTable) => {
    const errors = await validateForm(values) // Validazione dei valori
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false) // Nascondi l'alert
      saveArcoSubject(values, showManageTable)
    }
  }

  const isBreakPointLg = () => {
    return !(
      window.innerWidth < Constants.ARCO_BREAKPOINT_MD ||
      window.innerWidth > Constants.BREAKPOINT_LG
    )
  }

  return (
    <>
      <div className="row-detail d-flex row-form big">
        <div>
          <div className="row mb-1 p-2">
            <div className={`${sizesClass.fiscalCode} pe-2`}>
              <BwmInput
                disabled={props.disabled}
                name="fiscalCode"
                maxLength="16"
                label={`${labels.FISCAL_CODE}*`}
                className="form-control"
                value={values.fiscalCode}
                error={errors.fiscalCode}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'fiscalCode', true)}
                onBlur={(e) => handleFiscalCode(e.target.value)}
              />
            </div>
            <div className={`${sizesClass.arcoSubjectType} pe-2`}>
              <BwmSelect
                disabled={props.disabled}
                options={props.subjectTypes}
                name="subjectType"
                label={`${labels.SUBJECT_TYPE}`}
                className="form-control"
                showCode={true}
                value={values.arcoSubjectType?.id}
                error={errors.arcoSubjectType}
                touched={props.touched}
                onChange={(e) => handlerChangeSubjectType(e)}
              />
            </div>
            <div
              className={`${sizesClass.companyName} mb-2 ${window.innerWidth < Constants.BREAKPOINT_LG ? '' : 'pe-2'}`}>
              <BwmInput
                disabled={props.disabled}
                name="companyName"
                label={`${labels.COMPANY_NAME}*`}
                type="companyName"
                className="form-control font-size-big font-weight-bold"
                maxLength="60"
                value={values.companyName}
                error={errors.companyName}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'companyName', true)}
              />
            </div>
            <div className={`${sizesClass.vatNumber} pe-2 mb-2`}>
              <BwmInput
                disabled={props.disabled}
                label={`${labels.VAT_NUMBER}*`}
                className="form-control"
                maxLength="16"
                value={values.vatNumber}
                error={errors.vatNumber}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'vatNumber', true)}
              />
            </div>
            <div
              className={`${sizesClass.address} ${window.innerWidth <= Constants.BREAKPOINT_LG ? 'pe-2' : ''} mb-2`}>
              <BwmInput
                disabled={props.disabled}
                name="address"
                label={`${labels.ADDRESS}*`}
                className="form-control"
                maxLength="100"
                value={values.address}
                error={errors.address}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'address', true)}
              />
            </div>
            <div className={`${sizesClass.location} mb-2 pe-2`}>
              <AutoCompileCustom
                disabled={props.disabled}
                label={`${labels.LOCATION}*`}
                id="location"
                filter={props.locations}
                value={values.location || ''}
                error={errors.location}
                touched={props.touched}
                handleInputAutocompile={(e) => handleInputAutocompile(e)}
              />
            </div>
            <div className={`${sizesClass.province} pe-2`}>
              <ProvinceField
                disabled={props.disabled}
                maxLength="2"
                label={`${labels.PROVINCE}*`}
                placeholder={`${labels.EE_FOR_FOREIGN_COUNTRY}`}
                province={values.province || ''}
                error={errors.province}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'province', true)}
              />
            </div>
            <div
              className={`${sizesClass.postalCode} ${window.innerWidth <= Constants.BREAKPOINT_LG ? 'pe-2' : 'pe-4'}`}>
              <BwmInput
                disabled={props.disabled}
                name={labels.POSTAL_CODE}
                label={`${labels.POSTAL_CODE}*`}
                type="postalCode"
                className="form-control"
                maxLength="5"
                value={values.postalCode}
                error={errors.postalCode}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'postalCode', true)}
              />
            </div>
            {!isBreakPointLg() && (
              <div className={`${sizesClass.presetValues} align-self-center`}>
                <h6 className="p-0 m-0">
                  <strong>{labels.PRESET_VALUES}</strong>
                </h6>
              </div>
            )}
            <div className={`${sizesClass.operationDescription} pe-2`}>
              <BwmInput
                disabled={props.disabled}
                name="operationDescription"
                label={`${labels.OPERATION_DESCRIPTION}`}
                className="form-control"
                placeholder={`${isBreakPointLg() ? labels.PRESET_VALUES : ''}`}
                value={values.operationDescription}
                errors={errors.operationDescription}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'operationDescription', true)}
              />
            </div>
            <div className={`${sizesClass.operationNature} pe-2`}>
              <BwmInput
                name="operationNature"
                label={`${labels.OPERATION_NATURE}`}
                className="form-control"
                placeholder={`${isBreakPointLg() ? labels.PRESET_VALUES : ''}`}
                value={values.operationNature}
                errors={errors.operationNature}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'operationNature', true)}
              />
            </div>
            <div className={`${sizesClass.operationPurpose}`}>
              <BwmInput
                name="operationPurpose"
                label={`${labels.OPERATION_PURPOSE}`}
                className="form-control"
                placeholder={`${isBreakPointLg() ? labels.PRESET_VALUES : ''}`}
                value={values.operationPurpose}
                error={errors.errorOperationPurpose}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'operationPurpose', true)}
              />
            </div>
          </div>
          <div className="form-row row align-item-center">
            <div className="d-flex justify-content-between p-2 pt-1">
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-outline-primary me-4"
                  onClick={() => close(true)}>
                  <i className="thx-cancel thx-icon me-2" />
                  <span>{labels.CANCEL}</span>
                </button>
                {props.paginationComponent && (
                  <div className="d-flex justify-content-end">{props.paginationComponent}</div>
                )}
                {window.innerWidth > Constants.BREAKPOINT_LG && (
                  <div className="ms-4">
                    <b className="me-2">*</b>
                    <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  disabled={props.disabled}
                  className="btn btn-outline-primary me-2"
                  onClick={() =>
                    props?.showModal ? handleFormSubmit(props?.showModal) : manageTable()
                  }>
                  <i className="thx-document thx-icon me-2" />
                  {window.innerWidth > Constants.BREAKPOINT_MD
                    ? labels.MANAGE_TABLES
                    : labels.TABLES}
                </button>
                {values.id && (
                  <button
                    type="button"
                    disabled={props.disabled}
                    className="btn btn-outline-primary me-2"
                    onClick={() => setShowImportDocumentsModal(true)}>
                    <i className="thx-upload thx-icon me-2" />
                    {window.innerWidth > Constants.BREAKPOINT_MD
                      ? labels.IMPORT_DOCUMENTS
                      : labels.DOCUMENTS}
                  </button>
                )}
                <button
                  type="submit"
                  onClick={() => handleFormSubmit(false)}
                  className="btn btn-primary">
                  <i className="thx-floppy-disk thx-icon me-2" />
                  {labels.SAVE}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showImportDocumentsModal && (
        <ImportModal
          key={'import-zip-' + values?.id}
          show={showImportDocumentsModal}
          allowedFileExtensions={['zip', '7z', 'rar']}
          onHide={() => setShowImportDocumentsModal(!showImportDocumentsModal)}
          onSend={(dataFile) => uploadZipFile(dataFile)}
          secondaryTitle={() => (
            <div className="font-size-md">
              {labels.IMPORT_A_ZIP_7Z_RAR_FILE_WITH_ARCO_DESKTOP_DOCUMENTS}
            </div>
          )}
        />
      )}
    </>
  )
}
