import { ModalProcessingOutComeReceipt } from './ModalProcessingOutComeReceipt'
/**
 * Questa funzione serve a visualizzare piu popup insieme quando è ritornata la risposta
 * @param {*} props l'oggetto communication, con fileName e tutte le props, del padre
 * @returns piu popup insieme
 */
export const ShowManyMofalTogheter = (props) => {
  let communications = props.communication

  if (communications?.length === 0) {
    return null
  }

  return (
    <div>
      {communications.map((communication, index) => {
        return (
          <ModalProcessingOutComeReceipt
            key={communication.filename + ' ' + index}
            nameSubject={communication.crsCommunication?.subject?.companyName}
            communication={communication.crsCommunication}
            fileName={communication.filename}
          />
        )
      })}
    </div>
  )
}
