import { assistanceRequest, getCurrentApplicationName } from '../Utility'

export function ButtonAssistance({ buttonTitle }) {
  const onClick = () => {
    const link = document.createElement('a')
    const applicationName = getCurrentApplicationName()
    link.href = `mailto:assistenza@starinfostudio.it?
        subject=${applicationName.toUpperCase()} - Richiesta assistenza`
    document.body.appendChild(link)
    link.click()
    assistanceRequest(applicationName)
  }

  return (
    <button className="btn btn-primary" onClick={onClick}>
      <i className="thx-question thx-icon me-2" />
      {buttonTitle || 'Richiedi assistenza'}
    </button>
  )
}
