import { useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../../shared/Utility'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

export const ImportCrsSubjectDataModal = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: '' })

  const closeModal = () => {
    setShowModal(false)
    setDataFile('')
  }

  const openModal = () => {
    setShowModal(true)
  }

  const checkFileName = (name) => {
    return name.split('.').pop().toLowerCase() === 'txt'
  }

  const handleUploadFile = (val) => {
    if (isEmpty(val)) {
      setErrorDataFile({ isValid: false, msg: labels.REQUIRED_FILE })
    }
    let files = []
    for (let file of val.target.files) {
      if (!checkFileName(file.name)) {
        setErrorDataFile({ isValid: false, msg: labels.INVALID_FILE_EXTENSION })
      } else {
        files.push(file)
      }
    }
    if (files.length > 0) setDataFile(files)
  }

  const handleSendDataFile = () => {
    setShowModal(false)
    setLoading(true)
    let formData = new FormData()
    if (dataFile?.length > 0) {
      for (let datafile of dataFile) {
        formData.append('dataFile', datafile)
      }
    } else {
      return
    }
    actions
      .importCrsDataFile(formData)
      .then(
        (res) => {
          props.getSubjects()
          let results = []
          if (res) {
            for (let result of res) {
              results.push(labels.IMPORT_SUBJECT + result.denomination)
            }
            PopupSuccess({
              dataList: results
            })
          } else {
            PopupError({
              text: labels.ERROR_IMPORTING_SUBJECT,
              labels
            })
          }
        },
        (errors) => {
          if (errors?.errorKey === 'licenseCheck') {
            PopupError({
              text: labels.ERROR_IMPORTING_SUBJECT,
              labels
            })
          } else {
            PopupError({
              text: labels.ERROR_IMPORTING_SUBJECT_2,
              labels
            })
          }
        }
      )
      .then(() => {
        setDataFile('')
        setLoading(false)
      })
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        data-tip={labels.IMPORT_SUBJECTS_TO_YOUR_LIST}
        className={`btn btn-outline-primary  `}
        disabled={props.disabled}
        onClick={openModal}>
        <i className="thx-file-import thx-icon me-2" />
        {labels.IMPORT}
      </button>

      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.IMPORT_SUBJECTS_FROM_CRS_DESKTOP}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="image-upload text-start">
              <label htmlFor={`dataFile`}>
                <div className={props.altDivClass || 'btn btn-primary'}>
                  <i className="thx-upload thx-icon me-2" />
                  {labels.LOAD_DATA_FILES}
                </div>
              </label>
              <input
                id={`dataFile`}
                type="file"
                name={`dataFile`}
                multiple
                onChange={(e) => handleUploadFile(e)}
              />
              <div className="certificatiLoaded text-start">
                {!errorDataFile.isValid ? (
                  <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
                ) : (
                  <div>
                    {dataFile &&
                      dataFile.map((file, index) => {
                        return (
                          <div className="d-flex align-items-center" key={index}>
                            <i className="thx-check thx-icon me-2" />
                            {file.name}
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorDataFile.isValid || !dataFile}
              onClick={handleSendDataFile}
              type="button"
              className="btn btn-primary">
              {labels.CONFIRM}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
