import { useSelector } from 'react-redux'
import getText from './i18n/labels'

export function BtnDelete(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const { handlerOnClick, disabled } = props
  return (
    <button
      type="button"
      className={`btn btn-outline-primary ${props.className}`}
      onClick={handlerOnClick}
      disabled={disabled}>
      <i className="thx-trash thx-icon me-2" />
      {labels.DELETE}
    </button>
  )
}
