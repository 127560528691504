import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { APPLICATION_CRS, BREAKPOINT_LG } from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import * as Constants from '../../../../config/Constants'
import getText from '../../../shared/i18n/labels'

import { searchCrsSubjects } from '../../../../actions'

// TABLE
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'

// MODAL
// import { CrsSubjectModal } from './NewCrsSubjectModal'

// FORM
import { CrsForm } from './NewCrsSubjectForm'

// COMPONENTS
import { PopupSubjectLicense } from '../../../shared/popups/PopupSubjectLicense'

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '130%'
  return '100%'
}

const getOffset = () => {
  if (window.innerWidth <= BREAKPOINT_LG) return 610
  return window.innerWidth <= BREAKPOINT_LG ? 600 : 550
}

export function CrsSubjectList(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)

  const [newSubjectCode, setNewSubjectCode] = useState(0)
  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    subjectCode: null,
    fiscalCode: null,
    companyName: null,
    idFiscalOicrTrust: null,
    referenceYear: null,
    id: null,
    version: '1'
  })

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => ({
      name: location.location,
      key: index
    }))
  )

  useEffect(() => {
    const subjectSummary = props.subjectSummary
    if (subjectSummary && subjectSummary?.available > 0) {
      const axiosInstance = axiosService.getInstance()
      axiosInstance.get('/api/crs/custom/crs-subjects/lastProgressive').then((res) => {
        let newCode = parseInt(res.data) + 1
        setNewSubjectCode(newCode)
        setSubject(subject)
      })
    } else if (subjectSummary?.available <= 0) {
      PopupSubjectLicense(
        {
          total: subjectSummary?.total,
          used: subjectSummary?.used,
          available: subjectSummary?.available,
          name: APPLICATION_CRS
        },
        lang
      )
    }
  }, [props.subjectSummary])

  useEffect(() => {
    props.setSearchObject(searchObject)
  }, [searchObject])

  const countries = useSelector((state) =>
    state.countries?.data.map((country) => ({
      name: country.code,
      key: country.description
    }))
  )

  const [subject, setSubject] = useState(null)
  const tableUtils = TableUtils
  const tableName = 'crs-subject'
  const history = useHistory()

  const header = [
    tableUtils.composeHeader({
      fieldName: 'subjectCode',
      displayedName: labels.CODE,
      maxWidth: '8rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: labels.FISCAL_CODE,
      maxWidth: '12rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'companyName',
      displayedName: labels.OBLIGATED_SUBJECT
    }),
    tableUtils.composeHeader({
      fieldName: 'idFiscalOicrTrust',
      displayedName: 'OICR',
      maxWidth: '12rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'referenceYear',
      displayedName: labels.REFERENCE_YEAR,
      maxWidth: '16rem'
    }),
    tableUtils.composeHeader({
      fieldName: 'expiration',
      type: fieldTypes.DATE,
      displayedName: labels.SID_EXPIRATION,
      maxWidth: '12rem'
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          subjectCode: tableUtils.composeCell({
            fieldName: 'subjectCode',
            fieldValue: c.subjectCode
          }),
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          companyName: tableUtils.composeCell({
            fieldName: 'companyName',
            fieldValue: c.companyName
          }),
          idFiscalOicrTrust: tableUtils.composeCell({
            fieldName: 'idFiscalOicrTrust',
            fieldValue: c.idFiscalOicrTrust
          }),
          referenceYear: tableUtils.composeCell({
            fieldName: 'referenceYear',
            fieldValue: c.referenceYear
          }),
          expiration: tableUtils.composeCell({
            fieldName: 'expiration',
            fieldValue: c.expiration
          })
        }
      })
    })
  }

  const onClickRow = (subject) => {
    setSubject(subject)
  }

  const onDoubleClickRow = (subject) => {
    setSubject(subject)
    const path = props.licenseExpired ? '#' : `/app/crs/relationship/${subject.id}`
    history.push(path)
  }

  const setNewSubjects = (subjects) => {
    props.setData(subjects, searchObject)
  }

  useEffect(() => {
    setRows(prepareRows(props.subjects))
    setSubject(subject ? subject : props.subjects?.[0])
  }, [props.subjects])

  useEffect(() => {
    setSubject()
  }, [props.resetForm])

  const renderTable = () => {
    return (
      <StarTable
        id={subject?.id}
        headerColumns={header}
        checkedItems={props.checkedItems}
        rows={rows}
        loading={show}
        getOffset={getOffset}
        tableContainerWidth={getTableContainerWidth()}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => {
          setSubject()
          return searchCrsSubjects(searchObject)
        }}
        onExecutedSearch={(content) => setNewSubjects(content)}
        usePagination={true}
        withCheckBoxes={true}
        onClickRow={onClickRow}
        onDoubleClickRow={onDoubleClickRow}
        onClickCheck={props.onClickCheck}
        actionsComponent={props.actionsComponent}
        formComponent={CrsForm}
        formComponentProps={{
          disabled: props.disabled || loading,
          formOnBottom: true,
          subjectId: subject?.id,
          locations: locations,
          countries: countries,
          newSubjectCode: newSubjectCode,
          setLoading: setLoading,
          close: (newSubject) =>
            setSubject(newSubject ? newSubject : subject ? subject : undefined),
          getSubjects: props.getSubjects
        }}
        // dialogComponent={
        //   subject?.id !== null &&
        //   subject?.id !== undefined && (
        //     <CrsSubjectModal
        //       id={subject?.id}
        //       hideButton={true}
        //       subject={subject}
        //       showModal={!!subject.id}
        //       licenseExpired={props.disabled}
        //       subjectSummary={props.subjectSummary}
        //       locations={locations || []}
        //       countries={countries || []}
        //       getSubjects={props.getSubjects}
        //       closeModal={() => setSubject(null)}
        //     />
        //   )
        // }
      />
    )
  }

  return <>{renderTable()}</>
}

export default CrsSubjectList
