import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../actions'

import Select from 'react-select'

export default function LanguageSelector({ login }) {
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.language.language)

  const options = [
    { value: 'it', label: 'Italiano', flag: '/languages/lang-it-it.svg' },
    { value: 'en', label: 'English', flag: '/languages/lang-en-us.svg' }
  ]

  const [selectedLanguage, setSelectedLanguage] = useState(
    options.find((option) => option.value === lang) || options[0]
  )

  useEffect(() => {
    localStorage.setItem('language', lang)
  }, [lang])

  const handleLanguageClick = (language) => {
    setSelectedLanguage(language)
    dispatch(actions.changeLanguage(language.value))
  }

  const formatOptionLabel = ({ label, flag }) => {
    return (
      <div className="d-flex align-items-center">
        <img className={login ? '' : 'me-2'} src={flag} alt={label} />
        {!login && <span>{label}</span>}
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center">
      <Select
        className="language-select"
        classNamePrefix="language-select"
        defaultValue={selectedLanguage}
        isSearchable={false}
        formatOptionLabel={formatOptionLabel}
        options={options}
        onChange={handleLanguageClick}
      />
    </div>
  )
}
