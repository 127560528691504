import { confirmAlert } from 'react-confirm-alert'
import getText from '../../shared/i18n/labels'

// BUTTONS
import { BtnFill } from '../BtnFill'

import 'react-confirm-alert/src/react-confirm-alert.css'

export function PopupAlert(props) {
  const labels = getText(localStorage.getItem('language'))

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup">
            <div className="w-100 d-flex justify-content-end">
              <button type="button" className="close btn-close" onClick={onClose}>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <div className={'row show-grid popup-title-alert text-center'}>
              <div className="col-12 pb-3">
                <h5>{props?.title || labels.WARNING}</h5>
              </div>
            </div>
            <div className={'row show-grid text-center'}>
              <div className="col-12" dangerouslySetInnerHTML={{ __html: props?.innerHtml }}></div>
              <div className="col-12">{props.text}</div>
            </div>
            <div className="pt-4 d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  if (props.onClickCancel) props.onClickCancel()
                  onClose()
                }}>
                {props.cancel || labels.CANCEL}
              </button>
              <BtnFill
                text={props.confirm || labels.CONTINUE}
                handlerOnClick={() => {
                  if (props.handleClickConfirm) props.handleClickConfirm()
                  onClose()
                }}
              />
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
