import { jwtDecode } from 'jwt-decode'
import * as moment from 'moment'

class AuthService {
  tokenKey = 'jwt-token'

  getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  setCookie(name, value, days) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = `${name}=${value}; expires=${expires}; path=/`
  }

  deleteCookie() {
    this.setCookie(this.tokenKey, '', -1)
  }

  getToken() {
    //return localStorage.getItem(this.tokenKey);
    return this.getCookie(this.tokenKey)
  }

  decode(token) {
    return jwtDecode(token)
  }

  saveToken(token) {
    //localStorage.setItem(this.tokenKey, token);
    this.setCookie(this.tokenKey, token, 1) // Set cookie expiration to 1 days or adjust as needed
  }

  invalidateUser() {
    localStorage.removeItem(this.tokenKey)
    this.deleteCookie(this.tokenKey)
  }

  getExpiration(token) {
    const exp = this.decode(token).exp

    return moment.unix(exp)
  }

  getUsername() {
    //il nostro token ha solo il ruolo
    return this.decode(this.getToken()).auth
  }

  isValid(token) {
    return moment().isBefore(this.getExpiration(token))
  }

  isAuthenticated() {
    const token = this.getToken()
    return token && this.isValid(token) ? true : false
  }

  setHeader(header, value) {
    this.setCookie(header, value, 7) // Set cookie expiration to 7 days or adjust as needed
    //localStorage.setItem(header, value);
  }

  getHeader(header) {
    return this.getCookie(header) || ''
    //return localStorage.getItem(header);
  }
}

export default new AuthService()
