import thunk from 'redux-thunk'
import { languageReducer } from '../components/shared/i18n/language-reducer'
import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './auth-reducer'
import { customerApplicationReducer, customersReducer } from './customer-reducer'
import { applicationReducer, selectedApplicationReducer } from './application-reducer'
import {
  relationshipsReducer,
  relationshipTypesReducer,
  selectedRelationshipReducer,
  rankedRelationshipTypesReducer
} from './cora/relationship-reducer'
import { locationReducer, locationsReducer } from './common/location-reducer'
import { delegateReducer } from './cora/delegate-reducer'
import { selectedSubjectReducer, subjectError, subjectReducer } from './cora/subject-reducer'

import { reducer as formReducer } from 'redux-form'
import { currencyReducer, currencyTypesReducer } from './common/currency-reducer'
import { roleReducer } from './cora/role-reducer'
import {
  communicationsBalanceReducer,
  communicationsHistoryReducer,
  communicationsLastErrorReducer,
  communicationsToSendReducer,
  communicationTypesReducer
} from './cora/communication-reducer'

import { delayedCommunicationDateReducer } from './cora/delayed-communication-date-reducer'

import { balanceReducer } from './cora/balances-reducer'
import {
  comureiCommunicationTypesReducer,
  comureiSubjectsReducer,
  comureiSubjectTypesReducer
} from './comurei/comurei-subject-reducer'
import { comureiCommunicationsReducer } from './comurei/comurei-communication-reducer'
import { crsSubjectReducer, crsSubjectsReducer } from './crs/crs-subject-reducer'
import { countriesReducer } from './common/country-reducer'
import { crsRelationshipReducer } from './crs/crs-relationship-reducer'
import {
  arcoSubjectsReducer,
  arcoSubjectReducer,
  arcoSubjectTypesReducer,
  arcoCollaboratorsReducer,
  arcoProfessionistsReducer,
  arcoDomainsReducer,
  arcoRiskValueReducer,
  arcoTableDomainReducer,
  arcoRelationshipDocumentReducer
} from './arco/arco-subject-reducer'
import {
  arcoRegistriesReducer,
  arcoRegistriesOperationsReducer,
  arcoRegistriesContractsReducer,
  arcoRegistriesCausalOperationsReducer,
  arcoRegistryDocumentReducer,
  arcoOperationSearchReducer,
  arcoRegistrySearchReducer
} from './arco/arco-registrations-reducer'
import {
  corproSubjectsReducer,
  corproSubjectTypesReducer,
  corproTransmissionTypesReducer,
  corproSupplierTypesReducer
} from './corpro/corpro-subject-reducer'
import { corproCommunicationsReducer } from './corpro/corpro-communication-reducer'
import {
  blueSubjectReducer,
  blueSubjectsReducer,
  blueSearchEventsReducer
} from './blue/blue-subject-reducer'

export const init = () => {
  return configureStore({
    reducer: {
      language: languageReducer,
      form: formReducer,
      auth: authReducer,
      relationships: relationshipsReducer,
      relationship: selectedRelationshipReducer,
      communicationsToSend: communicationsToSendReducer,
      communicationsHistory: communicationsHistoryReducer,
      yearlyBalanceRelationships: communicationsBalanceReducer,
      lastErrorCommunication: communicationsLastErrorReducer,
      delayedCommunicationDate: delayedCommunicationDateReducer,
      customers: customersReducer,
      customerApplications: customerApplicationReducer,
      applications: applicationReducer,
      application: selectedApplicationReducer,
      location: locationReducer,
      locations: locationsReducer,
      countries: countriesReducer,
      relationshipTypes: relationshipTypesReducer,
      rankedRelationshipTypes: rankedRelationshipTypesReducer,
      delegates: delegateReducer,
      balances: balanceReducer,
      subjects: subjectReducer,
      subject: selectedSubjectReducer,
      subjectError: subjectError,
      currencies: currencyReducer,
      currencyTypes: currencyTypesReducer,
      roles: roleReducer,
      communicationTypes: communicationTypesReducer,
      comureiCommunicationTypes: comureiCommunicationTypesReducer,
      comureiSubjectTypes: comureiSubjectTypesReducer,
      comureiSubjects: comureiSubjectsReducer,
      comureiCommunications: comureiCommunicationsReducer,
      corproSubjectTypes: corproSubjectTypesReducer,
      corproSubjects: corproSubjectsReducer,
      corproCommunications: corproCommunicationsReducer,
      corproTransmissionTypes: corproTransmissionTypesReducer,
      corproSupplierTypes: corproSupplierTypesReducer,
      crsSubjects: crsSubjectsReducer,
      crsSubject: crsSubjectReducer,
      crsRelationships: crsRelationshipReducer,
      arcoSubjects: arcoSubjectsReducer,
      arcoSubject: arcoSubjectReducer,
      arcoSubjectTypes: arcoSubjectTypesReducer,
      arcoCollaborators: arcoCollaboratorsReducer,
      arcoProfessionists: arcoProfessionistsReducer,
      arcoDomains: arcoDomainsReducer,
      arcoRiskValues: arcoRiskValueReducer,
      arcoTableDomains: arcoTableDomainReducer,
      arcoRelationshipDocuments: arcoRelationshipDocumentReducer,
      arcoRegistries: arcoRegistriesReducer,
      arcoRegistriesOperations: arcoRegistriesOperationsReducer,
      operationSearchData: arcoOperationSearchReducer,
      registrySearchData: arcoRegistrySearchReducer,
      arcoContracts: arcoRegistriesContractsReducer,
      arcoCausalOperations: arcoRegistriesCausalOperationsReducer,
      arcoRegistryDocument: arcoRegistryDocumentReducer,
      blueSubjects: blueSubjectsReducer,
      blueSubject: blueSubjectReducer,
      blueSearchEvents: blueSearchEventsReducer
    },
    middleware: [thunk]
  })
}
