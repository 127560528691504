import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import axiosService from '../../../../services/axios-service'
import { isEmpty, testEmail } from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'
import { BtnEmpty } from '../../../shared/BtnEmpty'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

export function CrsSubjectsExportModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseModal, setResponseModal] = useState('')
  const [responseKo, setResponseKo] = useState('')
  const [responseOk, setResponseOk] = useState('')
  const [emailAddress, setEmailAddress] = useState(props.email)
  const [emailModified, setEmailModified] = useState(false)
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: '' })

  const prevEmailAddressRef = useRef(emailAddress)

  let email = isEmpty(emailAddress) && !emailModified ? props.email : emailAddress

  useEffect(() => {
    const newEmailAddress = email

    if (newEmailAddress && newEmailAddress !== prevEmailAddressRef.current) {
      setEmailAddress(newEmailAddress)
      prevEmailAddressRef.current = newEmailAddress // Update the ref to reflect the current state
    }
  }, [props.checkedItems])

  const closeModal = () => {
    setShowModal(false)
    setResponseModal(false)
    setEmailAddress(email)
  }

  const openModal = () => {
    setShowModal(true)
    setResponseModal(false)
  }

  const handlerEmail = (e) => {
    handlerValidation(e.target.value)
    setEmailAddress(e.target.value)
    setEmailModified(true)
  }

  const handlerValidation = (_emailAddress) => {
    if (isEmpty(_emailAddress)) {
      setErrorEmail({ isValid: false, msg: labels.REQUIRED_FIELD })
      return false
    }
    if (!testEmail(_emailAddress)) {
      setErrorEmail({ isValid: false, msg: labels.EMAIL_NOT_CORRECT })
      return false
    }
    setErrorEmail({ isValid: true, msg: '' })
    return true
  }

  const exportCrsSubjects = () => {
    if (handlerValidation(emailAddress)) {
      setLoading(true)
      const axiosInstance = axiosService.getInstance()
      axiosInstance({
        data: Object.values(props.checkedItems),
        url: `/api/crs/custom/crs-subjects/export/${emailAddress}`,
        method: 'POST'
      })
        .then(
          (response) => {
            if (response) {
              if (response?.status === 200) {
                closeModal()
                setResponseOk('OK')
                setResponseModal(true)
              } else {
                closeModal()
                setResponseKo(labels.ERROR_EXPORTING_DATA)
              }
            }
          },
          (err) => {
            closeModal()
            PopupError({ text: labels.ERROR_EXPORTING_DATA })
          }
        )
        .then(() => setLoading(false))
    }
  }

  return (
    <>
      {loading ? <PageSpinner /> : ''}
      <button
        type="button"
        disabled={props.disabled}
        className={`btn btn-outline-primary    ${props.disabled ? 'disabled' : ''}`}
        data-tip={labels.EXPORT_SELECTED_SUBJECTS}
        onClick={openModal}>
        <i className="thx-export thx-icon me-2 mt-1" />
        {labels.EXPORT}
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.EXPORT_SUBJECTS}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p>{labels.EXPORT_SUBJECTS_DESCRIPTION}</p>
          </div>
          <div className="row-detail comurei mt-2 d-flex radius">
            <div className="tab-content col-12">
              <div className="form-row">
                <div className="col-11">
                  <BwmInput
                    id="emailAddress"
                    type="email"
                    className="form-control form-control"
                    maxLength="100"
                    label={labels.EMAIL_ADDRESS_TO_SEND_EXPORT_FILE}
                    value={email}
                    error={errorEmail}
                    onChange={(e) => handlerEmail(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={errorEmail?.isValid === false}
              type="button"
              className="btn btn-primary"
              onClick={exportCrsSubjects}>
              {labels.EXPORT}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {responseModal && (
        <Modal
          backdrop="static"
          show={responseModal}
          onHide={closeModal}
          dialogClassName="modal-70w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>{labels.START_EXPORT_SUBJECTS_PROCEDURE}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {responseOk ? (
              <>{labels.START_EXPORT_SUBJECTS_PROCEDURE_DESCRIPTION}</>
            ) : (
              <div className="popup communication">
                <div className="row">
                  <>
                    <p className={'color-red'}>
                      {labels.ERROR_EXPORTING_DATA_SUBJECTS} {responseKo}
                    </p>
                  </>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty
                text={labels.CANCEL}
                classCustom="float-start"
                handlerOnClick={closeModal}
              />
              <button onClick={closeModal} type="button" className="btn btn-primary">
                {labels.OK}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
