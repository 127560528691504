import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axiosService from '../../../../services/axios-service'
import { cloneDeep } from 'lodash'
import * as actions from '../../../../actions'
import getText from '../../../../components/shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import { ArcoRegistryImportDocumentModal } from './NewArcoRegistryImportDocumentModal'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import RowSpinner from '../../../shared/spinner/Spinner'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

export function ArcoRegistryClipForm(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const [showImportModal, setShowImportModal] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [arcoDocument, setArcoDocument] = useState([])

  const openShowImportModal = () => {
    if (props?.arcoRegistriesOperation?.id) {
      setShowImportModal(!showImportModal)
    } else {
      PopupError({ text: labels.ERROR_ADD_DOCUMENT })
    }
  }

  useEffect(() => {
    getAllArcoDocumentsByOperationId()
  }, [])

  const getAllArcoDocumentsByOperationId = () => {
    if (props.arcoRegistriesOperation) {
      setLoading(true)
      actions
        .getArcoRegistryDocuments(props.arcoRegistriesOperation?.id)
        .then((response) => {
          setArcoDocument(response)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const downloadDocument = (value) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: '/api/arco/custom/arco-operations-document',
      data: value,
      method: 'POST',
      responseType: 'blob'
    }).then(
      (response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', value.documentName)
          document.body.appendChild(link)
          link.click()
        }
      },
      (err) => {
        if (err.errorKey === 'arcoOperationCheck') PopupError({ text: err.title })
        else PopupError({ text: labels.ERROR_DOWNLOAD_DOCUMENT })
      }
    )
  }

  const getBlobFromBase64 = (base64) => {
    let byteString = atob(base64)
    // Convert that text into a byte array.
    let ab = new ArrayBuffer(byteString.length)
    let ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], {
      type: 'application/zip'
    })
  }

  const zipAllDocuments = () => {
    const list = []
    Object.keys(checkedItems).forEach((val) => {
      const el = arcoDocument.find((el) => val?.toString() === el?.arcoRegistryId?.toString())
      list.push(el)
    })
    actions.downloadArcoOperationZip(list).then(
      (response) => {
        if (response) {
          const url = window.URL.createObjectURL(getBlobFromBase64(response))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'DOCUMENTI - ' + props?.arcoRegistriesOperation?.description?.substring(0, 30) + '.zip'
          )
          document.body.appendChild(link)
          link.click()
          resetCheckBox()
        }
      },
      (err) => {
        if (err.errorKey === 'arcoOperationCheck') PopupError({ text: err.title })
        else PopupError({ text: labels.ERROR_CREATE_ZIP_FOLDER })
      }
    )
  }

  const deleteClipDocumentsSubject = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: '/api/arco/custom/arco-operations-document',
      data: Object.keys(),
      method: 'DELETE'
    }).then(
      (response) => {
        setCheckedItems({})
        getAllArcoDocumentsByOperationId()
        //chiudo form se contiene l'elemento cancellato
      },
      (err) => {
        PopupError({ text: err.title })
      }
    )
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRows = cloneDeep(arcoDocument)
      newRows.forEach((row) => (items[row.arcoRegistryId] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === arcoDocument?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const renderRows = () => {
    if (loading) {
      return <RowSpinner />
    } else if (!arcoDocument || arcoDocument?.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center border pt-2">
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
            style={{ width: '30px', height: '30px' }}
          />
          <h6>{labels.NO_RESULTS_TO_SHOW}</h6>
        </div>
      )
    }
    return arcoDocument.map((document, index) => {
      return (
        <div key={index} className={'text-start row-table'} id={`clip-${document.documentName}`}>
          <div className="w-5 div-td text-center">
            <input
              type="checkbox"
              className="mt-1"
              id={`checkbox_${document.arcoRegistryId || ''}`}
              checked={checkedItems[document.arcoRegistryId] || false}
              onChange={(e) => handleCheckChange(e, document.arcoRegistryId)}
            />
          </div>
          <div
            className="w-85 div-td text-truncate d-flex align-items-center"
            onClick={() => downloadDocument(document)}>
            <strong>{document.documentName}</strong>
          </div>
          <div
            className="w-10 div-td text-center justify-content-center d-flex align-items-center"
            onClick={() => downloadDocument(document)}>
            <i className="thx-pdf thx-icon text-primary" />
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="col-12">
        <div className="mb-1">
          <div id={'searchButtonFatherDiv'} className="tab-content p-0">
            <div className="d-flex justify-content-between header-btn-relationship mb-2">
              <div className="p-0">
                <button
                  type="button"
                  disabled={`${Object.keys(checkedItems)?.length > 0 ? '' : 'disabled'}`}
                  className={`btn btn-outline-primary  ${Object.keys(checkedItems)?.length === 0 ? 'disabled' : ''}`}
                  onClick={() => deleteClipDocumentsSubject()}>
                  <i className="thx-trash thx-icon me-2" />
                  {labels.DELETE}
                </button>
              </div>
              <div className="p-0">
                <h4 className="fw-bolder">{labels.ATTACHED_DOCUMENTS_TO_OPERATION}</h4>
              </div>
              <div className="p-0 ps-5 ms-4">
                <button
                  type="button"
                  disabled={props.disabled}
                  className="btn btn-primary"
                  onClick={openShowImportModal}>
                  <i className="thx-plus thx-icon me-2" />
                  {labels.ADD_DOCUMENT}
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="star-table row me-0 ms-0 arco-registry-clip-form">
              <div className="header-table">
                <div className="w-5 text-center div-td">
                  <input
                    disabled={!(arcoDocument?.length > 0)}
                    name="tableCheckBox"
                    type="checkbox"
                    checked={allCheck}
                    onClick={(e) => handleAllCheckChange(e)}
                  />
                </div>
                <div className="w-85 div-td text-uppercase">{labels.DOCUMENT}</div>
                <div className="w-10 div-td text-center text-uppercase">{labels.DOWNLOAD}</div>
              </div>
              {renderRows()}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div>
            <button className="btn btn-outline-primary " onClick={props.onHide}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CANCEL}
            </button>
          </div>
          <div>
            <button
              className={`btn btn-primary  ${Object.keys(checkedItems)?.length === 0 && 'disabled'}`}
              disabled={`${Object.keys(checkedItems)?.length > 0 ? '' : 'disabled'}`}
              onClick={zipAllDocuments}>
              <i className="thx-zip thx-icon me-2" />
              {labels.DOWNLOAD_ALL}
            </button>
          </div>
        </div>
        {showImportModal && (
          <ArcoRegistryImportDocumentModal
            show={showImportModal}
            onHide={openShowImportModal}
            id={props.arcoRegistriesOperation?.id}
            getAllArcoDocumentsByOperationId={getAllArcoDocumentsByOperationId}
            uncheckAll={resetCheckBox}
          />
        )}
      </div>
    </>
  )
}
