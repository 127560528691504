import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

// FORM
import NumberFormat from 'react-number-format'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { PaymentList } from './NewPaymentList'
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

export const PaymentForm = forwardRef((props, ref) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [initialValues, setInitialValues] = useState({})

  const balanceFormBodyRefData = useRef()

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      return await balanceFormBodyRefData.current.handleValidation()
    },
    getData() {
      return balanceFormBodyRefData.current.getData()
    },
    savePayments(id) {
      return balanceFormBodyRefData.current.savePayments(id)
    }
  }))

  const setNewInitialValues = () => {
    setInitialValues({
      id: props.relationship.id,
      yearRel: props.relationship?.yearRel,
      amountRel: props.relationship?.amountRel || 0.0,
      currencyRel: props.relationship?.currencyRel || {
        id: '58',
        isoCode: 'EUR',
        description: ''
      },
      closed: props.relationship?.closed || false,
      sleepy: props.relationship?.sleepy || false,
      notDocumented: props.relationship?.notDocumented || false
    })
  }

  useEffect(() => {
    setNewInitialValues()
  }, [])

  useEffect(() => {
    setNewInitialValues()
  }, [props.relationship.id])

  const validationSchema = Yup.object().shape({
    amountRel: Yup.string().required(labels.AMOUNT_MUST_BE_ENTERED)
  })

  return (
    <div className="mt-3">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        <div>
          <FormBody {...props} ref={balanceFormBodyRefData} />
        </div>
      </Formik>
    </div>
  )
})

const FormBody = forwardRef((props, ref) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [touched, setTouched] = useState(false)
  const paymentListRefData = useRef()

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      const errors = await validateForm(values) // Validazione dei valori
      setTouched(true)
      if (Object.keys(errors).length > 0) {
        return false
      } else {
        return true
      }
    },
    getData() {
      return Object.assign({}, values)
    },
    savePayments(id) {
      paymentListRefData.current.getCrsPaymentList(id)
    }
  }))

  const handlerAmountRel = (val) => {
    const form = Object.assign({}, values)
    val = val ? val.floatValue : val
    if (isEmpty(val)) val = 0
    form.amountRel = val
    props.setRelationship(form)
    setValues(form)
  }

  const handleInputAutocompileCurrency = (newCurrency) => {
    const form = Object.assign({}, values)
    form.currencyRel = {
      id: '',
      isoCode: newCurrency,
      description: ''
    }

    let currencyFilter = props.currencies.filter((currency) => currency.name === newCurrency)

    if (currencyFilter.length === 1) {
      form.currencyRel = {
        id: currencyFilter[0].id,
        isoCode: currencyFilter[0].name.split(' - ')[0],
        description: currencyFilter[0].name.split(' - ')[1]
      }
    }

    if (currencyFilter.length === 0) {
      form.currencyRel = {
        id: '',
        isoCode: newCurrency,
        description: ''
      }
    }
    props.setRelationship(form)
    setValues(form)
  }

  const handleChangeCheck = (e, name) => {
    const form = Object.assign({}, values)
    form[name] = e.target.checked
    props.setRelationship(form)
    setValues(form)
  }

  const inputWrapperClass = `input-wrapper d-flex w-100 align-items-center text-end ${touched && errors.amountRel !== undefined ? 'has-error' : ''}`

  return (
    <div className="row m-2 my-0">
      <div className={`${props.sizes.leftSide}`}>
        <div className="payment_relationship">
          <div className="align-items-start">
            <div className={`${props.sizes.yearRel} form-group pe-4 mb-2`}>
              <div className="input-group">
                <BwmInput
                  name="yearRel"
                  label={labels.YEAR_OF_REFERENCE}
                  className="form-control"
                  disabled={true}
                  maxLength="4"
                  value={values.yearRel}
                  error={errors.yearRel}
                  touched={touched}
                />
              </div>
            </div>
            <div className="d-flex pe-3">
              <div className={`${props.sizes.amountRel} form-group mb-2 pe-2`}>
                <div className="input-group">
                  <NumberFormat
                    id="filled-multiline-flexible"
                    label={labels.RELATIONSHIP_BALANCE}
                    multiline={true}
                    variant="filled"
                    name="amountRel_"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    className={inputWrapperClass}
                    inputMode="numeric"
                    customInput={BwmInput}
                    value={values.amountRel || 0}
                    onValueChange={(val) => handlerAmountRel(val)}
                  />
                </div>
              </div>

              <div className={`${props.sizes.currencyRel} form-group pe-2`}>
                <div className="input-group">
                  <AutoCompileCustom
                    id="currencyRel_"
                    label={labels.CURRENCY}
                    filter={props.currencies}
                    value={values.currencyRel?.isoCode || ''}
                    handleInputAutocompile={(e) => handleInputAutocompileCurrency(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row form-check mt-2 col-auto ms-2">
            <div className="me-3">
              <BwmCheckbox
                name="closed_"
                label={labels.RELATIONSHIP_CLOSED}
                checked={values.closed}
                onChange={(e) => handleChangeCheck(e, 'closed')}
              />
            </div>
            <div className="me-3">
              <BwmCheckbox
                name="sleepy_"
                label={labels.RELATIONSHIP_SLEEPY}
                checked={values.sleepy}
                onChange={(e) => handleChangeCheck(e, 'sleepy')}
              />
            </div>
            <div className="me-3">
              <BwmCheckbox
                name="notDocumented_"
                label={labels.RELATIONSHIP_NOT_DOCUMENTED}
                checked={values.notDocumented}
                onChange={(e) => handleChangeCheck(e, 'notDocumented')}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`${props.sizes.rightSide} mb-2`}>
        <PaymentList
          ref={paymentListRefData}
          sizes={props.sizes}
          activeTab={props.activeTab}
          currencies={props.currencies}
          relationship={props.relationship}
          subject={props.subject}
        />
      </div>
    </div>
  )
})
