import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../../shared/Utility'
import CodiceFiscale from 'codice-fiscale-js'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { BwmSelect } from '../../../shared/form/BwmSelect'
import { BwmInput } from '../../../shared/form/BwmInput'
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustom'
import { DateField } from '../../../shared/form/DateField'
import { ProvinceField } from '../../../shared/form/ProvinceField'
import { PersonTypeField } from '../../../shared/PersonTypeField'

import Container from 'react-bootstrap/Container'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { BtnFill } from '../../../shared/BtnFill'

import 'react-datepicker/dist/react-datepicker.css'

export const ModaleAggiungiDelegati = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const [formDataDelegate, updateFormDataDelegate] = useState({
    personType: Constants.PF,
    coraRelationship: {
      id: props.relationship?.id ? props.relationship?.id : null
    }
  })

  let startDate = props.delegatePerson?.startDate
    ? new Date(props.delegatePerson.startDate)
    : props.relationship?.startDate
      ? new Date(props.relationship.startDate)
      : null

  const [startDateDelegate, setStartDateDelegate] = useState(startDate)
  const [errorStartDateDelegate, setErrorStartDateDelegate] = useState({ isValid: true, msg: '' })
  const [endDateDelegate, setEndDateDelegate] = useState(
    props.delegatePerson?.endDate ? new Date(props.delegatePerson.endDate) : null
  )
  const [errorEndDateDelegate, setErrorEndDateDelegate] = useState({ isValid: true, msg: '' })
  const [roleDelegate, setRoleDelegate] = useState(
    props.delegatePerson?.role?.id ? props.delegatePerson.role.id : 5
  )
  const [errorRoleDelegate, setErrorRoleDelegate] = useState({ isValid: true, msg: '' })
  const [fiscalCodeDelegate, setFiscalCodeDelegate] = useState(
    props.delegatePerson?.fiscalCode ? props.delegatePerson.fiscalCode : ''
  )
  const [errorFiscalCodeDelegate, setErrorFiscalCodeDelegate] = useState({ isValid: true, msg: '' })
  const [lastNameDelegate, setLastNameDelegate] = useState(
    props.delegatePerson?.lastName ? props.delegatePerson.lastName : ''
  )
  const [errorLastNameDelegate, setErrorLastNameDelegate] = useState({ isValid: true, msg: '' })
  const [firstNameDelegate, setFirstNameDelegate] = useState(
    props.delegatePerson?.firstName ? props.delegatePerson.firstName : ''
  )
  const [errorFirstNameDelegate, setErrorFirstNameDelegate] = useState({ isValid: true, msg: '' })
  const [genderDelegate, setGenderDelegate] = useState(
    props.delegatePerson?.gender || Constants.genderList[0].id
  )
  const [errorGenderDelegate, setErrorGenderDelegate] = useState({ isValid: true, msg: '' })

  const [birthDateDelegate, setBirthDateDelegate] = useState(
    props.delegatePerson?.birthDate ? new Date(props.delegatePerson.birthDate) : null
  )
  const [errorBirthDateDelegate, setErrorBirthDateDelegate] = useState({ isValid: true, msg: '' })
  const [birthLocationDelegate, setBirthLocationDelegate] = useState(
    props.delegatePerson?.location ? props.delegatePerson.location : ''
  )
  const [errorBirthLocationDelegate, setErrorBirthLocationDelegate] = useState({
    isValid: true,
    msg: ''
  })
  const [birthProvDelegate, setBirthProvDelegate] = useState(
    props.delegatePerson?.province ? props.delegatePerson.province : ''
  )
  const [errorBirthProvDelegate, setErrorBirthProvDelegate] = useState({ isValid: true, msg: '' })
  const [personType, setPersonType] = useState(props.delegatePerson?.personType || Constants.PF)
  const [companyName, setCompanyName] = useState(props.delegatePerson?.companyName || '')
  const [errorCompanyName, setErrorCompanyName] = useState({ isValid: true, msg: '' })
  const [errorPersonType, setErrorPersonType] = useState({ isValid: true, msg: '' })
  const [displayPf, setDisplayPf] = useState(true)
  const [displayPnf, setDisplayPnf] = useState(false)

  useEffect(() => {
    setRoleDelegate(props.delegatePerson?.role?.id ? props.delegatePerson.role.id : 5)
    if (personType == Constants.PF) {
      setDisplayPf(true)
      setDisplayPnf(false)
    } else if (personType == Constants.PNF) {
      setDisplayPf(false)
      setDisplayPnf(true)
    }
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      personType: personType,
      startDate: startDateDelegate,
      companyName: companyName,
      fiscalCode: fiscalCodeDelegate,
      endDate: endDateDelegate,
      role: { id: roleDelegate },
      firstName: firstNameDelegate,
      lastName: lastNameDelegate,
      gender: genderDelegate,
      birthDate: birthDateDelegate,
      location: birthLocationDelegate,
      province: birthProvDelegate,
      id: props.delegatePerson?.id || props.index + 1
    })
  }, [])

  const handleInputBirthLocationDelegateAutocompile = (newLocation) => {
    if (birthProvDelegate?.toUpperCase() === 'EE') {
      setBirthLocationDelegate(newLocation?.toUpperCase() || '')
      updateFormDataDelegate({
        ...formDataDelegate,
        location: newLocation.toUpperCase(),
        province: birthProvDelegate?.toUpperCase() || ''
      })
      return
    }
    actions.getLocation(newLocation?.toUpperCase()).then(
      (loc) => {
        if (loc) {
          setBirthProvDelegate(loc.province)
          updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
            location: newLocation.toUpperCase(),
            province: loc.province
          })
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
    setBirthLocationDelegate(newLocation?.toUpperCase())
    updateFormDataDelegate({
      ...formDataDelegate,
      location: newLocation.toUpperCase()
    })
  }

  const handlerStartDate = (date) => {
    setStartDateDelegate(date)
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      startDate: date
    })
  }

  const handlerEndDate = (date) => {
    setEndDateDelegate(date)
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      endDate: date
    })
  }

  const handlerBirthDate = (date) => {
    setBirthDateDelegate(date)
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      birthDate: date
    })
  }

  const handlerRole = (e) => {
    setRoleDelegate(e.target.value.trim())
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      role: {
        id: e.target.value.trim(),
        code: e.target.getAttribute('data-cod'),
        description: e.target[e.target.value].text
      }
    })
  }

  const handlerChangeFiscalCode = (e) => {
    setFiscalCodeDelegate(e.target.value?.trim().toUpperCase())
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      fiscalCode: e.target.value?.trim().toUpperCase()
    })
  }

  const handlerLastName = (e) => {
    setLastNameDelegate(e.target.value.toUpperCase())
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      lastName: e.target.value.toUpperCase()
    })
  }

  const handlerFirstName = (e) => {
    setFirstNameDelegate(e.target.value.toUpperCase())
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      firstName: e.target.value.toUpperCase()
    })
  }

  const handlerGender = (e) => {
    setGenderDelegate(e.target.value)
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      gender: e.target.value
    })
  }

  const handleKeyPress = (val) => {
    if (personType === Constants.PF) {
      try {
        const cf = new CodiceFiscale(val).toJSON()
        if (cf) {
          getFiscalCodeData(cf)
        }
      } catch (e) {
        console.log('codice fiscale errato')
      }
    }
  }

  const getFiscalCodeData = (cf) => {
    const formatLoc = cf?.birthplace.toUpperCase()
    birthDateDelegate || setBirthDateDelegate(cf?.birthday ? new Date(cf?.birthday) : null)
    setGenderDelegate(cf?.gender === 'M' ? 'MALE' : 'FEMALE')
    if (cf.birthplaceProvincia !== 'EE') {
      birthLocationDelegate || setBirthLocationDelegate(formatLoc || '')
    }
    birthProvDelegate || setBirthProvDelegate(cf?.birthplaceProvincia || '')
    updateFormDataDelegate({
      ...formDataDelegate,
      coraRelationship: { id: props.relationship?.id ? props.relationship?.id : null },
      location: birthLocationDelegate || cf.birthplaceProvincia !== 'EE' ? formatLoc : '',
      province: birthProvDelegate || cf?.birthplaceProvincia || '',
      birthDate: birthDateDelegate || (cf?.birthday ? new Date(cf?.birthday) : null),
      gender: cf?.gender === 'M' ? 'MALE' : 'FEMALE'
    })
  }

  const handleBirthProvChange = (e) => {
    setBirthProvDelegate(e.target.value.toUpperCase())
    updateFormDataDelegate({
      ...formDataDelegate,
      province: e.target.value.toUpperCase(),
      location: birthLocationDelegate
    })
  }

  const handleValidation = () => {
    resetError()

    let isValid = true
    if (startDateDelegate === undefined || isEmpty(startDateDelegate)) {
      setErrorStartDateDelegate({ isValid: false, msg: labels.REQUIRED_FIELD })
      isValid = false
    } else if (new Date(startDateDelegate) < new Date(props.relationship.startDate)) {
      setErrorStartDateDelegate({
        isValid: false,
        msg: labels.START_DATE_DELEGATE_MUST_BE_EQUAL_OR_AFTER_START_DATE_RELATIONSHIP
      })
      isValid = false
    }
    if (roleDelegate === undefined || isEmpty(roleDelegate)) {
      setErrorRoleDelegate({ isValid: false, msg: labels.REQUIRED_FIELD })
      isValid = false
    }

    if (personType === Constants.PF) {
      if (lastNameDelegate === undefined || isEmpty(lastNameDelegate)) {
        setErrorLastNameDelegate({ isValid: false, msg: labels.REQUIRED_FIELD })
        isValid = false
      } else {
        formDataDelegate.lastName = lastNameDelegate?.trim()
      }
      if (firstNameDelegate === undefined || isEmpty(firstNameDelegate)) {
        setErrorFirstNameDelegate({ isValid: false, msg: labels.REQUIRED_FIELD })
        isValid = false
      } else {
        formDataDelegate.firstName = firstNameDelegate?.trim()
      }
      if (genderDelegate === undefined || isEmpty(genderDelegate)) {
        setErrorGenderDelegate({ isValid: false, msg: labels.REQUIRED_FIELD })
        isValid = false
      }
      if (birthDateDelegate === undefined || isEmpty(birthDateDelegate)) {
        setErrorBirthDateDelegate({ isValid: false, msg: labels.REQUIRED_FIELD })
        isValid = false
      }
    } else {
      if (companyName === undefined || isEmpty(companyName)) {
        setErrorCompanyName({ isValid: false, msg: labels.REQUIRED_FIELD })
        isValid = false
      } else {
        formDataDelegate.companyName = companyName?.trim()
      }
    }

    if (birthLocationDelegate === undefined || isEmpty(birthLocationDelegate)) {
      setErrorBirthLocationDelegate({ isValid: false, msg: labels.REQUIRED_FIELD })
      isValid = false
    } else {
      formDataDelegate.location = birthLocationDelegate?.trim()
    }
    if (birthProvDelegate === undefined || isEmpty(birthProvDelegate)) {
      setErrorBirthProvDelegate({ isValid: false, msg: labels.REQUIRED_FIELD })
      isValid = false
    } else {
      formDataDelegate.province = birthProvDelegate?.trim()
    }
    return isValid
  }

  const resetError = () => {
    setErrorStartDateDelegate({ isValid: true, msg: '' })
    setErrorEndDateDelegate({ isValid: true, msg: '' })
    setErrorRoleDelegate({ isValid: true, msg: '' })
    setErrorFiscalCodeDelegate({ isValid: true, msg: '' })
    setErrorLastNameDelegate({ isValid: true, msg: '' })
    setErrorFirstNameDelegate({ isValid: true, msg: '' })
    setErrorBirthLocationDelegate({ isValid: true, msg: '' })
    setErrorGenderDelegate({ isValid: true, msg: '' })
    setErrorBirthDateDelegate({ isValid: true, msg: '' })
    setErrorBirthProvDelegate({ isValid: true, msg: '' })
    setErrorCompanyName({ isValid: true, msg: '' })
  }

  const handlerSubmit = () => {
    if (handleValidation()) {
      props.handlerSubmitDelegate(formDataDelegate)
      handlerClose()
    }
  }

  const handlerClose = () => {
    resetError()
    props.onHide()
  }

  const handlePersonTypeClick = (event) => {
    setPersonType(event.target.value)
    updateFormDataDelegate({
      ...formDataDelegate,
      personType: event.target.value
    })
    if (event.target.value == Constants.PF) {
      updateFormDataDelegate({
        ...formDataDelegate,
        personType: event.target.value,
        companyName: '',
        gender: null
      })
      setDisplayPf(true)
      setDisplayPnf(false)
    } else if (event.target.value == Constants.PNF) {
      updateFormDataDelegate({
        ...formDataDelegate,
        personType: event.target.value,
        birthDate: null,
        firstName: '',
        lastName: '',
        gender: null
      })
      setDisplayPf(false)
      setDisplayPnf(true)
    }
  }

  const handlerChangeCompanyName = (e) => {
    setCompanyName(e.target.value.toUpperCase())
    updateFormDataDelegate({
      ...formDataDelegate,
      companyName: e.target.value.toUpperCase()
    })
  }

  return (
    <Modal
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      size="xl"
      centered
      onHide={handlerClose}
      bsPrefix="modal-create modal">
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {labels.FAMILY}
          <h4>
            {props.relationship?.companyName
              ? props.relationship.companyName
              : props.relationship.lastName + ' ' + props.relationship.firstName}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="row show-grid">
            <div className="col-2 me-4">
              <DateField
                label={labels.START_DATE}
                date={startDateDelegate}
                onChange={(date) => handlerStartDate(date)}
                error={errorStartDateDelegate}
              />
            </div>
            <div className="col-2 me-4">
              <DateField
                label={labels.END_DATE}
                date={endDateDelegate}
                onChange={(date) => handlerEndDate(date)}
                error={errorEndDateDelegate}
              />
            </div>
            <div className="col-6 me-6">
              <BwmSelect
                options={props.roles}
                name="role"
                label={labels.ROLE}
                className="form-control"
                onChange={(e) => handlerRole(e)}
                error={errorRoleDelegate}
                value={roleDelegate}
                showCode={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <PersonTypeField
                personType={personType}
                onClick={handlePersonTypeClick}
                error={errorPersonType}
              />
            </div>
            <div id="infoPnf" className={'col-10' + (!displayPnf ? ' d-none' : '')}>
              <div className="form-row col-12">
                <div className="col-3 me-5 offset-1">
                  <BwmInput
                    name="fiscalCode"
                    label={labels.FISCAL_CODE}
                    className="form-control"
                    value={fiscalCodeDelegate}
                    error={errorFiscalCodeDelegate}
                    maxLength="16"
                    onChange={(e) => handlerChangeFiscalCode(e)}
                    onBlur={(e) => handleKeyPress(e.target.value)}
                  />
                </div>
                <div className="col-5">
                  <BwmInput
                    name="companyName"
                    label={labels.COMPANY_NAME}
                    className="form-control"
                    value={companyName}
                    onChange={(e) => handlerChangeCompanyName(e)}
                    error={errorCompanyName}
                    maxLength="60"
                  />
                </div>
                <div className="col-5 me-5 offset-1">
                  <AutoCompileCustom
                    label={labels.LEGAL_SEAT}
                    id="location"
                    handleInputAutocompile={handleInputBirthLocationDelegateAutocompile}
                    filter={props.relationshipLocations}
                    value={birthLocationDelegate}
                    error={errorBirthLocationDelegate}
                  />
                </div>
                <div className="col-3">
                  <ProvinceField
                    province={birthProvDelegate}
                    onChange={(e) => handleBirthProvChange(e)}
                    error={errorBirthProvDelegate}
                  />
                </div>
              </div>
            </div>
            <div id="infoPf" className={'col-10' + (!displayPf ? ' d-none' : '')}>
              <div className="form-row col-12">
                <div className="col-3">
                  <BwmInput
                    name="fiscalCode"
                    label={labels.FISCAL_CODE}
                    className="form-control"
                    value={fiscalCodeDelegate}
                    error={errorFiscalCodeDelegate}
                    maxLength="16"
                    onChange={(e) => handlerChangeFiscalCode(e)}
                    onBlur={(e) => handleKeyPress(e.target.value)}
                  />
                </div>

                <div className="col-3 offset-1">
                  <BwmInput
                    name="lastName"
                    label={labels.LAST_NAME}
                    className="form-control"
                    value={lastNameDelegate}
                    onChange={(e) => handlerLastName(e)}
                    error={errorLastNameDelegate}
                    maxLength="26"
                  />
                </div>
                <div className="col-3 offset-1">
                  <BwmInput
                    name="firstName"
                    label={labels.FIRST_NAME}
                    className="form-control"
                    value={firstNameDelegate}
                    onChange={(e) => handlerFirstName(e)}
                    error={errorFirstNameDelegate}
                    maxLength="25"
                  />
                </div>
              </div>
              <div className="form-row col-12">
                <div className="col-1">
                  <BwmSelect
                    options={Constants.genderList}
                    name="gender"
                    label={labels.GENDER}
                    className="form-control"
                    onChange={handlerGender}
                    error={errorGenderDelegate}
                    value={genderDelegate}
                  />
                </div>
                <div className="col-3 offset-1">
                  <DateField
                    label={labels.BIRTH_DATE}
                    date={birthDateDelegate}
                    customOptions={{ maxDate: new Date() }}
                    onChange={(date) => handlerBirthDate(date)}
                    error={errorBirthDateDelegate}
                    name={'birthDate'}
                  />
                </div>
                <div className={'col-3 offset-1'}>
                  <AutoCompileCustom
                    label={labels.BIRTH_LOCATION}
                    id="birthLocation"
                    handleInputAutocompile={handleInputBirthLocationDelegateAutocompile}
                    filter={props.relationshipLocations}
                    value={birthLocationDelegate}
                    error={errorBirthLocationDelegate}
                  />
                </div>
                <div className="col-2 offset-1">
                  <ProvinceField
                    province={birthProvDelegate}
                    onChange={(e) => handleBirthProvChange(e)}
                    error={errorBirthProvDelegate}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={handlerClose} />
        <BtnFill
          text={labels.CONFIRM}
          classCustom="float-end text-end"
          handlerOnClick={handlerSubmit}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModaleAggiungiDelegati
