import { useSelector } from 'react-redux'
import { saveSid } from '../../SidCertsHandler'
import getText from '../../../shared/i18n/labels'

// MODAL
import { Modal } from 'react-bootstrap'

// FORM
import { FormPecParameters } from './FormPecParameters'

// COMPONENTS
import { PopupSuccess } from '../../popups/PopupSuccess'
import { PopupError } from '../../popups/PopupError'

export function ModalPecParameters({
  coraSubjectSid,
  show = false,
  onClose = () => {},
  afterSave = (savedSid) => {}
}) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const save = (subjectSid) => {
    saveSid(
      [],
      subjectSid,
      subjectSid.fiscalCode,
      subjectSid.application,
      (savedSid) => {
        if (afterSave) {
          afterSave(savedSid)
        } else {
          PopupSuccess()
        }
      },
      (errors) => {
        PopupError({
          text: errors.detail ? errors.detail : labels.GENERIC_ERROR
        })
      }
    )
  }

  const getWidth = () => {
    return window.innerWidth > 1800 ? 'w-60 max-w-60' : 'w-90 max-w-90'
  }

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onClose}
      dialogClassName={`${getWidth()} p-2`}
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{labels.SET_PEC_PARAMETERS}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormPecParameters
          coraSubjectSid={coraSubjectSid}
          onSave={(subjectSid) => save(subjectSid)}
          onCancel={onClose}
        />
      </Modal.Body>
    </Modal>
  )
}
