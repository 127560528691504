import { useSelector } from 'react-redux'
import { formatDateForDisplay } from '../../../shared/Utility'
import getText from '../../../../components/shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { ArcoRegistryClipForm } from './NewArcoRegistryClipForm'

import '../../../../styles/autocomplete.css'
import 'react-datepicker/dist/react-datepicker.css'

export function ArcoRegistryClipModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const getSubjectName = () => {
    return props.arcoSubject?.companyName
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{'< ' + getSubjectName()}</h2>
            <h3>
              {labels.OPERATION + ': ' + props?.arcoRegistriesOperation?.description}, &nbsp;
              {labels.OPERATION_DATE +
                ': ' +
                formatDateForDisplay(props?.arcoRegistriesOperation?.createDate)}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ArcoRegistryClipForm
              arcoRegistriesOperation={props.arcoRegistriesOperation}
              disabled={props.disabled}
              onHide={props.onHide}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
