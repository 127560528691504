import {
  ARCO_GET_SUBJECTS_SUCCESS,
  ARCO_GET_SUBJECT_SUCCESS,
  ARCO_GET_SUBJECT_TYPES_SUCCESS,
  GET_ALL_COLLABORATORS_SUCCESS,
  GET_ALL_ARCO_DOMAIN_SUCCESS,
  GET_ALL_PROFESSIONISTS_SUCCESS,
  GET_ALL_RISK_VALUE_SUCCESS,
  GET_ARCO_TABLE_DOMAIN_SUCCESS,
  GET_ARCO_RELATIONSHIP_DOCUMENT_SUCCESS
} from '../../actions/types'

const INITIAL_STATE = {
  arcoSubjects: {
    data: [],
    errors: []
  },

  arcoSubject: {
    data: [],
    error: []
  },

  arcoSubjectTypes: {
    data: [],
    errors: []
  },

  arcoCollaborators: {
    data: [],
    errors: []
  },

  arcoProfessionists: {
    data: [],
    errors: []
  },

  arcoDomains: {
    crDomains: {},
    domains: [],
    errors: []
  },

  arcoRiskValues: {
    data: [],
    errors: []
  },

  arcoTableDomains: {
    data: [],
    errors: []
  },

  arcoRelationshipDocuments: {
    data: [],
    errors: []
  }
}

export const arcoSubjectsReducer = (state = INITIAL_STATE.arcoSubjects, action) => {
  switch (action.type) {
    case ARCO_GET_SUBJECTS_SUCCESS:
      return { ...state, data: action.arcoSubjects }
    default:
      return state
  }
}

export const arcoSubjectReducer = (state = INITIAL_STATE.arcoSubject, action) => {
  switch (action.type) {
    case ARCO_GET_SUBJECT_SUCCESS:
      return { ...state, data: action.arcoSubject }
    default:
      return state
  }
}

export const arcoSubjectTypesReducer = (state = INITIAL_STATE.arcoSubjectTypes, action) => {
  switch (action.type) {
    case ARCO_GET_SUBJECT_TYPES_SUCCESS:
      return { ...state, data: action.arcoSubjectTypes }
    default:
      return state
  }
}

export const arcoCollaboratorsReducer = (state = INITIAL_STATE.arcoCollaborators, action) => {
  switch (action.type) {
    case GET_ALL_COLLABORATORS_SUCCESS:
      return { ...state, data: action.arcoCollaborators }
    default:
      return state
  }
}

export const arcoProfessionistsReducer = (state = INITIAL_STATE.arcoProfessionists, action) => {
  switch (action.type) {
    case GET_ALL_PROFESSIONISTS_SUCCESS:
      return { ...state, data: action.arcoProfessionists }
    default:
      return state
  }
}

export const arcoRiskValueReducer = (state = INITIAL_STATE.arcoRiskValues, action) => {
  switch (action.type) {
    case GET_ALL_RISK_VALUE_SUCCESS:
      return { ...state, data: action.arcoRiskValues }
    default:
      return state
  }
}

export const arcoTableDomainReducer = (state = INITIAL_STATE.arcoTableDomains, action) => {
  switch (action.type) {
    case GET_ARCO_TABLE_DOMAIN_SUCCESS:
      return { ...state, data: action.arcoTableDomains }
    default:
      return state
  }
}

export const arcoRelationshipDocumentReducer = (
  state = INITIAL_STATE.arcoRelationshipDocuments,
  action
) => {
  switch (action.type) {
    case GET_ARCO_RELATIONSHIP_DOCUMENT_SUCCESS:
      return { ...state, data: action.arcoRelationshipDocuments }
    default:
      return state
  }
}

export const arcoDomainsReducer = (state = INITIAL_STATE.arcoDomains, action) => {
  switch (action.type) {
    case GET_ALL_ARCO_DOMAIN_SUCCESS: {
      let crDomainMinLow = null
      let crDomainMinHigh = null
      let crDomainMediumLow = null
      let crDomainMediumHigh = null
      let crDomainMaxLow = null
      let crDomainMaxHigh = null
      let crBehaviourMin = null
      let crBehaviourMed = null
      let crBehaviourMax = null
      let domainFiltered = []
      action.arcoDomains.forEach((item) => {
        if (item.type === 'CR') {
          if (item.code === 'C0') {
            crDomainMinLow = item
          } else if (item.code === 'C1') {
            crDomainMinHigh = item
          } else if (item.code === 'C2') {
            crDomainMediumLow = item
          } else if (item.code === 'C3') {
            crDomainMediumHigh = item
          } else if (item.code === 'C4') {
            crDomainMaxLow = item
          } else if (item.code === 'C5') {
            crDomainMaxHigh = item
          } else if (item.code === 'CL') {
            crBehaviourMin = item
          } else if (item.code === 'CM') {
            crBehaviourMed = item
          } else if (item.code === 'CH') {
            crBehaviourMax = item
          }
        } else {
          domainFiltered.push(item)
        }
      })
      return {
        ...state,
        crDomains: {
          crDomainMinLow,
          crDomainMinHigh,
          crDomainMediumLow,
          crDomainMediumHigh,
          crDomainMaxLow,
          crDomainMaxHigh,
          crBehaviourMin,
          crBehaviourMed,
          crBehaviourMax
        },
        domains: domainFiltered
      }
    }
    default:
      return state
  }
}
