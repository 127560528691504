import { GET_DELEGATE_BY_RELATIONSHIP_SUCCESS } from '../../actions/types'

const INITIAL_STATE = {
  delegates: {
    data: [],
    errors: []
  }
}

export const delegateReducer = (state = INITIAL_STATE.delegates, action) => {
  switch (action.type) {
    case GET_DELEGATE_BY_RELATIONSHIP_SUCCESS:
      return { ...state, data: action.delegates }
    default:
      return state
  }
}
