import { useSelector } from 'react-redux'
import getText from '../../shared/i18n/labels'

// COMPONENTS
import RowSpinner from './Spinner'

import '../../../styles/spinner.css'

export function PageSpinner(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <div className="resultLoader">
      <div className="loader">
        <RowSpinner />
        <div className="text">{props?.text || labels.LOADING_TEXT}</div>
      </div>
    </div>
  )
}
