import { useSelector } from 'react-redux'
import getText from '../shared/i18n/labels'

/**
 *
 * @param {*} simplePointsArr array composto da oggetti di tipo {title:"",text:""}
 * @returns
 */
export function InfoBox({ simplePointsArr = [], additionalClasses }) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const renderSimpleInfoPoints = (arr) => {
    if (arr) {
      return arr.map((el, index) => {
        return (
          <div key={'infobox-' + index} className="infoPoint" style={{ fontSize: '13px' }}>
            <strong>{el.title ? `${el.title}: ` : ``}</strong>
            <span className="ms-1">{el.text}</span>
          </div>
        )
      })
    }
  }

  return (
    <div className={`infoPostaEle px-2 ${additionalClasses ? additionalClasses : ''}`}>
      <div className={`infoPoint-title d-flex align-items-center`}>
        <i className="thx-info-circle thx-icon me-2" />
        {labels.INFORMATION}
      </div>
      {renderSimpleInfoPoints(simplePointsArr)}
    </div>
  )
}
