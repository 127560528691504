import { CORPRO_COMMUNICATIONS_HISTORY_SUCCESS } from '../../actions/types'

const INITIAL_STATE = {
  corproCommunications: {
    data: [],
    errors: []
  }
}

export const corproCommunicationsReducer = (state = INITIAL_STATE.corproCommunications, action) => {
  switch (action.type) {
    case CORPRO_COMMUNICATIONS_HISTORY_SUCCESS:
      return { ...state, data: action.corproCommunications }
    default:
      return state
  }
}
