import { APPLICATION_SUCCESS, GET_APPLICATION_SUCCESS } from '../actions/types'

const INITIAL_STATE = {
  applications: {
    data: [],
    errors: []
  },
  application: {
    data: [],
    errors: []
  }
}

export const applicationReducer = (state = INITIAL_STATE.applications, action) => {
  switch (action.type) {
    case APPLICATION_SUCCESS:
      return { ...state, data: action.applications }
    default:
      return state
  }
}

export const selectedApplicationReducer = (state = INITIAL_STATE.application, action) => {
  switch (action.type) {
    case GET_APPLICATION_SUCCESS:
      return { ...state, data: action.application }
    default:
      return state
  }
}
