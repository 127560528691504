import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../../actions'
import getText from '../../../../components/shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import Modal from 'react-bootstrap/Modal'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

// COMPONENTS
import { ArcoTableProfessionistsList } from '../subject-arco/NewArcoTableProfessionistsList'
import { BtnEmpty } from '../../../shared/BtnEmpty'

export function ArcoRegistrationProfessionalsModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const dispatch = useDispatch()

  const getAllProfessionistBySubjectId = () => {
    dispatch(actions.getAllProfessionistBySubjectId(props.subject?.id))
  }

  const confirmButton = (selectedItems = []) => {
    return (
      <button
        className="btn btn-primary mt-2"
        disabled={selectedItems.length === 0}
        onClick={() => props.onConfirm(selectedItems)}>
        {labels.ADD_SELECTED}
      </button>
    )
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={'arco-modal-max'}>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.PROFESSIONISTS_LIST}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'me-1 ms-1'}>
            <ArcoTableProfessionistsList
              professionists={props.professionistsData}
              subject={props.subject}
              disabled={props.disabled}
              onClose={props.onHide}
              getAllProfessionistBySubjectId={getAllProfessionistBySubjectId}
              confirmButton={(selectedItems = []) => confirmButton(selectedItems)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={props.onHide} />
        </Modal.Footer>
      </Modal>
    </>
  )
}
