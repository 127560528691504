import { useSelector } from 'react-redux'
import getText from '../../../../shared/i18n/labels'

import UploadReceiptEmailForm from '../UploadReceiptEmailiForm'
import GenerateStatus from './GenerateStatus'
import { CommunicationStatus } from '../../../../../config/CommunicationStatus'

import { downloadConsistencyReceiptTxt, downloadReceiptTxt } from '../../CommunicationDownloadUtils'
/**
 * Corpo da visualizzare su Cora se il messaggio è corretto
 * @param {*} props
 * @returns corpo del messaggio del popup
 */
export default function MessageCorrectFile(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const noCommunicationFoundMsg = `(${labels.ATTENTION_RECEIPT_NOT_RELATED_TO_A_COMMUNICATION})`

  const dispatchType = props.dispatchType
  const communication = props.communication
  const isConsistencyReceipt = dispatchType === 'CONSISTENCY_RECEIPT'

  return (
    <div className="popup communication">
      <div className="row">
        <div className="col-9 ">
          <GenerateStatus
            dispatchType={dispatchType}
            communicationStatus={communication.communicationStatus}
            communicationError={communication.communicationError}
          />
        </div>
        {communication.communicationType || isConsistencyReceipt ? (
          ''
        ) : (
          <div className="col-7 ">
            <strong>{noCommunicationFoundMsg}</strong>
          </div>
        )}
        <div className="col-3 div-td text-truncate justify-content-around align-items-center pe-4 ps-4 containerPopOver">
          {props.receiptType === 'CONSISTENCY' ? (
            <button onClick={() => downloadConsistencyReceiptTxt(communication)}>
              <div className="photo">
                <i className="thx-text-file thx-icon" />
              </div>
            </button>
          ) : communication.communicationStatus !== CommunicationStatus.ERROR_SID ? (
            <button onClick={() => downloadReceiptTxt(communication, props.fileName)}>
              <div className="recepit">
                <i className="thx-text-file thx-icon" />
              </div>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
      <UploadReceiptEmailForm
        closeModal={props.closeReceiptModal}
        subjectId={communication?.subject?.id}
        communication={communication}
        emailAddress={communication?.subject?.coraSubjectSid?.email}
        flagPec={communication.subject?.coraSubjectSid?.flagPec}
        fileName={props.fileName}
      />
    </div>
  )
}
