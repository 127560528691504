export function Tips() {
  const pinTooltip_EN = `* If the Company is registered with the Fisconline service, indicate the Pincode consisting of 10 numeric characters obtained during registration.
If the Company is registered with the Entratel service, indicate the Pincode consisting of 16 alphanumeric characters found in section 3 of the virtual envelope obtained during registration with the Entratel service;
also indicate the progressive office number obtainable from the certificate provided by the office: set to "000" if you intend to generate the security environment for the main office.
Instead, it is "001", "002", etc. if the environment refers to a secondary office;
`
  const credenzialiToolTip_EN = `**If the administrator/appointed person accesses with Spid and therefore does not have Fisconline credentials, it is necessary to arrange an appointment for a remote assistance session by writing to: Assistenza@starsoluzioni.it`

  const nuoveCredenzialiToolTip_EN = `***Password of your choice, minimum 8 maximum 20 characters without accented letters, at least one letter and at least one number`
  const PinToolTip_EN = `***Revocation pin of your choice, enter a code chosen by the user consisting of a minimum of 15 and a maximum of 20 characters (only numbers and/or non-accented letters).`

  const pinTooltip = `* Se la Società è iscritta al servizio Fisconline, indicare il Pincode composto da 10 caratteri numerici e ottenuto in fase   di registrazione.
  Se la Società è iscritta al servizio Entratel, indicare il Pincode composto da 16 caratteri alfanumerici e presente nella sezione 3 della busta virtuale ottenuta in fase di registrazione al servizio Entratel;
  indicare anche il progressivo sede rilevabile dall'attestazione fornita dall'ufficio: da impostare a "000" se si intende generare l'ambiente di sicurezza per la sede principale.
  Vale, invece, "001", "002", etc. nel caso in cui l'ambiente si riferisce ad una sede secondaria;
  `
  const credenzialiToolTip = `**Se l'amministratore/incaricato accede con Spid e quindi non possiede le credenziali di Fisconline, è necessario concordare un appuntamento per una sessione di assistenza remota scrivendo a:  Assistenza@starsoluzioni.it`

  const nuoveCredenzialiToolTip = `***Password a vostra scelta, minimo 8 massimo 20 caratteri senza lettere accentate, almeno una lettera e almeno un numero`
  const PinToolTip = `***Pin di revoca a scelta, inserire un codice scelto a piacere dall’utente costituito da un minimo di 15 e da un massimo di 20 caratteri (di cui solo numeri e/o lettere non accentate).`

  return (
    <section>
      <div className="row">
        <div className="col">
          <small>{localStorage.getItem('language') === 'en' ? pinTooltip_EN : pinTooltip}</small>
        </div>
      </div>
      <div className="row mt-2 mb-2">
        <div className="col">
          <small>
            {localStorage.getItem('language') === 'en' ? credenzialiToolTip_EN : credenzialiToolTip}
          </small>
        </div>
      </div>
      <div className="row mt-2 mb-2">
        <div className="col">
          <small>
            {localStorage.getItem('language') === 'en'
              ? nuoveCredenzialiToolTip_EN
              : nuoveCredenzialiToolTip}
          </small>
        </div>
      </div>
      <div className="row mt-2 mb-2">
        <div className="col">
          <small>{localStorage.getItem('language') === 'en' ? PinToolTip_EN : PinToolTip}</small>
        </div>
      </div>
    </section>
  )
}
