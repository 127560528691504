import { useState } from 'react'
import { useSelector } from 'react-redux'
import getText from '../../shared/i18n/labels'

import { FloatLabelTextBox } from '../../shared/form/input-boxes/FloatLabelTextBox'

export function PasswordSection(props) {
  const [errorPassword, setErrorPassword] = useState('')
  const [errorPasswordRevoca, setErrorPasswordRevoca] = useState('')
  const passwordRegex = /([a-zA-Z]+)(\d+)/

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const validatePassword = (value = '') => {
    let error = ''
    if (value.length < 8 || value.length > 20) {
      error = labels.PASSWORD_MUST_BE_BETWEEN_8_AND_20_CHARACTERS
    } else if (!value.match(passwordRegex)) {
      error = labels.PASSWORD_MUST_BE_COMPOSED_OF_AT_LEAST_ONE_NUMBER_AND_ONE_LETTER
    }
    setErrorPassword(error)
  }

  const validatePasswordRevoca = (value = '') => {
    let error = ''
    if (value.length < 15 || value.length > 20) {
      error = labels.PASSWORD_MUST_BE_BETWEEN_15_AND_20_CHARACTERS
    }
    setErrorPasswordRevoca(error)
  }

  return (
    <>
      <div className={props.sectionTitleStyle}>{labels.NEW_CREDENTIALS_CERTIFICATES}</div>
      <Row>
        <Col lg={5}>
          <FloatLabelTextBox
            value={props.password}
            label={labels.PASSWORD_FOR_USE_CERTIFICATES}
            onChange={(e) => props.setPassword(e.target.value)}
            error={errorPassword}
            onBlur={(e) => validatePassword(e.target.value)}
          />
        </Col>
        <Col lg={5}>
          <FloatLabelTextBox
            value={props.passwordRevoca}
            label={labels.PIN_OF_REVOCA}
            onChange={(e) => props.setPasswordRevoca(e.target.value.toUpperCase())}
            error={errorPasswordRevoca}
            onBlur={(e) => validatePasswordRevoca(e.target.value)}
          />
        </Col>
      </Row>
    </>
  )
}
