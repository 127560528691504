import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

/**
 * Permette di costruire un dropdown con determinati elementi in cima alla lista
 * @param {*} props
 * @param props.list lista di oggetti {id, code, description}
 * @param props.rankedElements come la lista di oggetti, verranno inseriti in cima
 * @param props.onChange callback su selezione, con parametro evento
 * @param props.error oggetto errore {isValid, msg}
 * @param selectedValue item selezionato, per id
 * @returns
 */
export function RankedDropdownList(props) {
  const list = props.list || []
  const rankedElements = props.rankedElements || []
  const label = props.label || ''
  const error = props.error
  const selectedValue = props.selectedValue || '' // Default to an empty string instead of null
  const disabled = props.disabled

  useEffect(() => {
    setRenderOptions(getRenderOptions())
  }, [props.rankedElements, selectedValue])

  const getRenderOptions = () => {
    const completeList = [...new Set([...rankedElements, ...list])]
    return completeList.map((el, index) => {
      if (rankedElements.length && index === rankedElements.length - 1) {
        return (
          <React.Fragment key={index}>
            <option key={`option-${index}`} value={el.id || ''}>
              {`${el.code && `${el.code} - `}${el.description}`}
            </option>
            <option value={''} disabled>
              ________________________________________________________________________
            </option>
          </React.Fragment>
        )
      } else {
        return (
          <option key={`ranked-option-${index}`} value={el.id}>
            {`${el.code} - ${el.description}`}
          </option>
        )
      }
    })
  }

  const [renderOptions, setRenderOptions] = useState(getRenderOptions())

  const handleChange = (e) => {
    props.onChange(e)
  }

  const hasError = props.touched && props.error !== undefined ? 'has-error' : ''

  return (
    <div className={`input-wrapper w-100 ${hasError}`}>
      <div className="input-group">
        <FloatingLabel title={label} id="floatingLabel" label={label}>
          <Form.Control
            disabled={disabled}
            className={`form-control bg-white custom-select-arrow ${hasError}`}
            value={selectedValue} // Controlled value
            onChange={handleChange}
            as="select">
            <option value={''}></option> {/* Use empty string instead of null */}
            {renderOptions}
          </Form.Control>
        </FloatingLabel>
      </div>
      {error && !error.isValid && <div className="text-danger">{error.msg}</div>}
    </div>
  )
}
