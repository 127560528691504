import { useEffect, useRef, useState } from 'react'
import { StarTooltip } from '../tooltips/Tooltip'

export const BwmInput = ({
  label,
  type,
  Icon,
  onIconClick = () => undefined,
  clickableIcon = true,
  iconTip,
  placeholder,
  formClassName,
  className,
  onChange,
  name,
  value,
  onKeyPress,
  onFocus,
  maxLength,
  error,
  pattern,
  step,
  onBlur = () => {},
  readOnly,
  id,
  onInput,
  disabled,
  autoComplete,
  dataTip,
  autofocus = false
}) => {
  //in certi casi, il cursore può saltare alla fine. questo stato riporta il cursore al posto giusto
  const [cursor, setCursor] = useState(null)
  const ref = useRef(null)

  //Focus su primo render, se richiesto
  useEffect(() => {
    if (autofocus) {
      ref.current.focus()
    }
  }, [])

  useEffect(() => {
    const input = ref.current
    if (input && ['text', 'search', 'URL', 'tel', 'password'].includes(input.type))
      input.setSelectionRange(cursor || 0, cursor || 0)
  }, [ref, cursor, value])

  const handleChange = (e) => {
    setCursor(e.target.selectionStart)
    onChange && onChange(e)
  }

  return (
    <div className={`form-group ${formClassName || ''}`}>
      {label && <label>{label}</label>}
      <div className="input-group" data-tip={dataTip || null}>
        <input
          ref={ref}
          id={id}
          type={type}
          className={className}
          name={name}
          value={![null, undefined, ''].includes(value) ? value : ''}
          placeholder={placeholder}
          maxLength={maxLength}
          pattern={pattern}
          step={step}
          readOnly={readOnly}
          onInput={onInput}
          disabled={disabled}
          autoComplete={autoComplete}
          onKeyDown={onKeyPress}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange}
        />

        {Icon && Icon() && (
          <div className="input-group-append">
            <StarTooltip text={iconTip}>
              <div
                className={`${className} input-group-text py-0}`}
                onClick={onIconClick}
                style={{
                  cursor: clickableIcon && 'pointer',
                  border: 0,
                  background: 'inherit',
                  color: 'inherit'
                }}>
                <Icon />
              </div>
            </StarTooltip>
          </div>
        )}
      </div>
      {error !== undefined && !error.isValid && <div className="text-danger">{error.msg}</div>}
    </div>
  )
}
