import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// FORM
import { BwmInput } from '../../../shared/form/BwmInput'
import { BwmInput as BwmInputB5 } from '../../../shared/form/BwmInputB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { DateField } from '../../../shared/form/DateField'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'

// BUTTONS
import { DeleteButton } from './table-components/DeleteButton'

import '../../../../styles/comurei-form.css'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

export const ArcoTableProfessionistsList = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)
  const [newRow, setNewRow] = useState({})
  const [rows, setRows] = useState([])
  const [renderRows, setRenderRows] = useState([])
  const [editedIds, setEditedIds] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    setNewRows()
  }, [props.professionists, query])

  useEffect(() => {
    setRenderRows(_renderRows())
  }, [rows, newRow, checkedItems])

  const getPropsProfessionists = () => {
    let professionists = []
    if (!props.showDisabled && props.professionists) {
      professionists = props.professionists.filter((p) => !p.disabled)
    } else {
      professionists = props.professionists
    }
    return professionists
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const setNewRows = () => {
    let professionistsData = getPropsProfessionists()
    if (query) {
      professionistsData = professionistsData.filter((e) => e.name.toLowerCase().includes(query))
    }
    setRows(professionistsData)
    setTimeout(() => {
      const element = document.getElementById('arco-professionists-list')
      if (element) element.scrollTop += 3000
    }, 400)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRows = cloneDeep(rows)
      newRows.forEach((el) => (items[el.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === rows?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handleValidation = (row) => {
    let isValid = true
    if ([undefined, null, ''].includes(row.code)) isValid = false
    if ([undefined, null, ''].includes(row.name)) isValid = false
    return isValid
  }

  const addArcoProfessionist = (professionist, index) => {
    const formData = Object.assign({}, professionist)
    formData.createDate = new Date()
    formData.updateDate = new Date()
    formData.code = formData.code || ''
    formData.name = formData.name || ''
    formData.disableDate = formData.disableDate || null
    formData.disabled = formData.disabled || false
    formData.arcoSubject = props.subject
    actions.saveArcoProfessionist(formData).then(
      (response) => {
        setEditedIds(editedIds.filter((_, _index) => index?.toString() !== index?.toString()))
        setCheckedItems({})
        setNewRow({})
        PopupSuccess({ text: labels.REMOVED_SUCCESSFULLY })
        props.getAllProfessionistBySubjectId()
      },
      (errors) => {
        PopupError({ text: errors?.title || APPLICATION_GENERIC_ERROR })
      }
    )
  }

  const onUpdate = (professionist, index) => {
    if (!handleValidation(professionist)) return
    addArcoProfessionist(professionist, index)
  }

  const deleteArcoProfessionistSubject = () => {
    const promises = []
    Object.keys(checkedItems).map((professionist) => {
      promises.push(actions.deleteArcoProfessionist(professionist))
    })
    Promise.all(promises)
      .then(() => {
        setNewRow({})
        setCheckedItems([])
        props.getAllProfessionistBySubjectId()
      })
      .catch((errors) => PopupError({ text: errors }))
  }

  const handleChangeValue = (val, index, name, upperCase = false) => {
    const newEditedIds = Object.assign([], editedIds)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    if (index !== -1) {
      const newRows = Object.assign([], rows)
      newRows[index][name] = val
      setRows(newRows)
      newEditedIds[index] = handleValidation(newRows[index]) ? true : false
    } else {
      const _newRow = Object.assign({}, newRow)
      _newRow[name] = val
      setNewRow(_newRow)
      newEditedIds[index] = handleValidation(_newRow) ? true : false
    }
    setEditedIds(newEditedIds)
  }

  const row = (professionist, index = -1) => {
    return (
      <div key={index} className="text-start d-flex row-table">
        <div className="w-5 text-center div-td d-flex align-items-center justify-content-center">
          {professionist?.id && (
            <input
              key={`check_${index}}`}
              name={`check_${index}}`}
              type="checkbox"
              id={`checkbox_${professionist.id || ''}`}
              checked={checkedItems[professionist.id] || false}
              onChange={(e) => handleCheckChange(e, professionist.id)}
            />
          )}
        </div>
        <div className="w-7 div-td text-truncate">
          <BwmInput
            name="code"
            className="form-control form-control-cell"
            formClassName={`${[undefined, null, ''].includes(professionist.code) && Object.keys(professionist).length > 0 ? 'has-error' : ''}`}
            value={professionist.code}
            onChange={(e) => handleChangeValue(e.target.value, index, 'code')}
          />
        </div>
        <div className="w-59 div-td text-truncate">
          <BwmInput
            name="name"
            className="form-control form-control-cell"
            formClassName={`${[undefined, null, ''].includes(professionist.name) && Object.keys(professionist).length > 0 ? 'has-error' : ''}`}
            value={professionist.name}
            onChange={(e) => handleChangeValue(e.target.value, index, 'name', true)}
          />
        </div>
        <div className="w-15 div-td text-truncate">
          <DateField
            name={`disabledDate_${professionist?.id}`}
            disabled={props.disabled}
            className="form-control form-control-cell"
            date={professionist.disabledDate}
            error={professionist.disabledDate}
            onChange={(value) => handleChangeValue(value, index, 'disabledDate')}
          />
        </div>
        <div className="w-7 div-td text-truncate d-flex justify-content-center align-items-center">
          <BwmCheckbox
            name={`disabled_${professionist?.id}`}
            className="form-control form-control-cell"
            checked={professionist.disabled}
            onChange={(e) => handleChangeValue(e.target.checked, index, 'disabled')}
          />
        </div>
        <div className="w-7 div-td d-flex justify-content-center align-items-center">
          <button
            disabled={!editedIds[index]}
            className="btn btn-primary btn-cell"
            onClick={() => onUpdate(professionist, index)}>
            {labels.SAVE}
          </button>
        </div>
      </div>
    )
  }

  const rowList = () => {
    const list = []
    try {
      Object.keys(checkedItems).forEach((val) => {
        const el = props.professionists.find((el) => val?.toString() === el?.id?.toString())
        list.push(el)
      })
    } catch {
      return []
    }
    return list
  }

  const _renderRows = () => {
    const newRows = []
    rows.forEach((professionist, index) => newRows.push(row(professionist, index)))
    if (!props.disabled) newRows.push(row(newRow))
    return newRows
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-end">
          <div className="mb-1 me-4">
            <DeleteButton
              disabled={Object.keys(checkedItems)?.length === 0}
              onClick={deleteArcoProfessionistSubject}
            />
          </div>
          <div className="d-flex align-items-center mb-2 mt-3 w-100">
            <span style={{ width: '290px' }}>{labels.SEARCH_BY_NAME_AND_SURNAME}:</span>
            <BwmInputB5
              className="form-control form-control-cell"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
        <div>{props.confirmButton && props.confirmButton(rowList())}</div>
      </div>

      <div id="arco-professionists-list" className="star-table arco-professionists-list">
        <div className="text-start header-table">
          <div className="w-5 text-center div-td d-flex align-items-center justify-content-center">
            <input
              disabled={!props.professionists?.length > 0}
              type="checkbox"
              checked={allCheck}
              onChange={(e) => handleAllCheckChange(e)}
            />
          </div>
          <div className="w-7 div-td">{labels.CODE}</div>
          <div className="w-59 div-td">{labels.NAME_AND_SURNAME}</div>
          <div className="w-15 div-td">{labels.END_DATE}</div>
          <div className="w-7 div-td text-center">{labels.ENABLED}</div>
          <div className="w-7 div-td text-center">{labels.EDIT}</div>
        </div>
        {renderRows}
      </div>
    </>
  )
}
