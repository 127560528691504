import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import getText from '../i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

import 'react-datepicker/dist/react-datepicker.css'

export function ProvinceField(props) {
  const [error, setError] = useState({ isValid: true, msg: '' })
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)
  
  registerLocale(lang, lang === 'it' ? it : en)

  useEffect(() => {
    if (props.error !== undefined && props.error !== error) {
      setError(props.error)
    } else {
      setError({ isValid: true, msg: '' })
    }
  }, [props.error])

  return (
    <>
      <div className="form-group">
        <div className="input-group col-6 ps-0">
          <input
            type={'text'}
            className={'form-control'}
            name={props.name}
            onFocus={props.onFocus}
            onChange={props.onChange}
            value={props.province}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
        </div>
        {!error.isValid ? <div className="text-danger">{error.msg}</div> : ''}
      </div>
    </>
  )
}
