import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'
import getText from '../shared/i18n/labels'

// FORM
import { BwmInput } from '../shared/form/BwmInputB5'
import { BwmCheckbox } from '../shared/form/BwmCheckboxB5'

const LoginForm = (props) => {
  const { submitCb, errors } = props

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [type, setType] = useState('password')
  const [stayConn, setStayConn] = useState(true)
  const [formData, updateFormData] = useState()
  const [errorUserName, setErrorUserName] = useState({ isValid: true, msg: '' })
  const [errorPassword, setErrorPassword] = useState({ isValid: true, msg: '' })

  useEffect(() => {
    updateFormData({
      ...formData,
      rememberMe: true
    })
  }, [])

  const onClickIconPsw = (e, id) => {
    e.preventDefault()
    let x = document.getElementById(id)
    if (x !== null) {
      if (x.type === 'password') {
        x.type = 'text'
        setType('text')
      } else {
        x.type = 'password'
        setType('password')
      }
    }
  }

  const handlerEmail = (val) => {
    setUsername(val.toLowerCase())
    updateFormData({ ...formData, username: val.toLowerCase() })
  }

  const handlerPassword = (val) => {
    setPassword(val)
    updateFormData({ ...formData, password: val })
  }

  const handlerStayConn = (e) => {
    setStayConn(e.target.checked)
    updateFormData({ ...formData, rememberMe: e.target.checked })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validation()) submitCb(formData)
  }

  const resetError = () => {
    setErrorUserName({ isValid: true, msg: '' })
    setErrorPassword({ isValid: true, msg: '' })
  }

  const validation = () => {
    resetError()
    let isValid = true
    if (!username) {
      setErrorUserName({ isValid: false, msg: labels.REQUIRED_FIELD })
      isValid = false
    }
    if (!password) {
      setErrorPassword({ isValid: false, msg: labels.REQUIRED_FIELD })
      isValid = false
    } else if (password.length < 8) {
      setErrorPassword({ isValid: false, msg: labels.MIN_8_CHARACTERS })
      isValid = false
    }
    return isValid
  }

  return (
    <div className="col-10 mt-3">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div>
          <BwmInput
            name="username"
            type="text"
            label="Email"
            className="form-control"
            showErrorMessage={true}
            value={username}
            error={errorUserName}
            onChange={(e) => handlerEmail(e.target.value)}
          />
        </div>
        <div className="mt-3">
          <BwmInput
            id="loginPsw"
            name="password"
            type="password"
            label="Password"
            className="form-control"
            value={password}
            showErrorMessage={true}
            error={errorPassword}
            Icon={() => (
              <div
                className="d-flex align-self-center"
                onClick={(e) => onClickIconPsw(e, 'loginPsw')}>
                <i
                  className={`thx-icon bg-primary ${type === 'text' ? 'thx-eye' : 'thx-eye-off'}`}
                />
              </div>
            )}
            onChange={(e) => handlerPassword(e.target.value)}
          />
        </div>
        <div className="row-form loginStayConn mt-3">
          <span>{labels.REMEMBER_ME}</span>
          <div className="input-group cursor-pointer">
            <BwmCheckbox
              name="stayConnected"
              className="form-control"
              checked={stayConn}
              onChange={(e) => handlerStayConn(e)}
            />
          </div>
        </div>
        {errors?.status === 404 && (
          <div className="text-danger ">{labels.EMAIL_OR_PASSWORD_WRONG}</div>
        )}
        <div className="row mt-3 mb-4">
          <div className="col-12">
            <div className="notAccount">
              <span>
                {labels.NO_ACCOUNT}
                <a
                  target={'_blank'}
                  className="ms-1"
                  rel="noopener noreferrer"
                  href="https://www.starsoluzioni.it/my-account/">
                  <strong>{labels.REGISTER}</strong>
                </a>
              </span>
            </div>
            <div className="lostPassword">
              <span>
                {labels.LOST_PASSWORD}
                <a
                  target={'_blank'}
                  className="ms-1"
                  rel="noopener noreferrer"
                  href="https://www.starsoluzioni.it/my-account/lost-password/">
                  <strong>{labels.RECOVER_PASSWORD}</strong>
                </a>
              </span>
            </div>
          </div>
          <div className="col-12 mt-4">
            <button className="btn btn-primary w-100" type="submit">
              {labels.LOGIN}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'loginForm'
})(LoginForm)
