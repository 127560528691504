import { useState, useCallback, useEffect } from 'react'
import debounce from 'lodash/debounce'

export function PageSelector(props) {
  const [pageNumber, setPageNumber] = useState(props.pageNumber || 0)
  const [totalPages, setTotalPages] = useState(props.totalPages || 0)
  const onClickBack = props.onClickBack
  const onClickForward = props.onClickForward

  const [value, setValue] = useState('')

  useEffect(() => {
    setPageNumber(props.pageNumber || 0)
  }, [props.pageNumber])

  useEffect(() => {
    setTotalPages(props.totalPages || 0)
  }, [props.totalPages])

  const debouncedOnChangeValue = useCallback(
    debounce((newValue) => {
      const page = parseInt(newValue)
      setValue('')
      if (page <= 0) return
      if (newValue !== undefined && newValue !== '' && !isNaN(newValue) && page <= totalPages) {
        props.onChangeValue(page - 1)
      }
    }, 500),
    [props, totalPages, pageNumber]
  )

  const onChange = (newValue) => {
    setValue(newValue)
    debouncedOnChangeValue(newValue)
  }

  return (
    <div className="d-flex justify-content-end align-items-center" style={{ maxWidth: '145px' }}>
      <button
        disabled={pageNumber <= 0 || props.loading}
        type="button"
        className={`p-0 btn-change-page ${pageNumber <= 0 && 'disabled'} `}
        onClick={() => onClickBack()}>
        <i className="thx-nav-arrow-left thx-icon" />
      </button>
      <input
        value={value}
        disabled={totalPages === 0 || props.loading}
        type="number"
        placeholder={`${totalPages !== 0 ? pageNumber + 1 : 0} / ${totalPages}`}
        className="star-table-page-selector"
        onChange={(e) => onChange(e.target.value)}
      />
      <button
        disabled={totalPages === pageNumber + 1 || !(totalPages !== 0) || props.loading}
        type="button"
        className={`p-0 btn-change-page ${totalPages === pageNumber + 1 && 'disabled'}`}
        onClick={() => onClickForward()}>
        <i className="thx-nav-arrow-right thx-icon" />
      </button>
    </div>
  )
}
