import {
  GET_ALL_COMMUNICATION_TYPES_SUCCESS,
  COMMUNICATIONS_TO_SEND_SUCCESS,
  COMMUNICATIONS_HISTORY_SUCCESS,
  COMMUNICATIONS_BALANCE_SUCCESS,
  COMMUNICATIONS_LAST_ERROR_SUCCESS
} from '../../actions/types'

const INITIAL_STATE = {
  communicationTypes: {
    data: [],
    errors: []
  },
  communicationsToSend: {
    data: {},
    errors: []
  },
  communicationsHistory: {
    data: [],
    errors: []
  },
  yearlyBalanceRelationships: {
    data: [],
    errors: []
  },
  lastErrorCommunication: {
    data: [],
    errors: []
  }
}

export const communicationTypesReducer = (state = INITIAL_STATE.communicationTypes, action) => {
  switch (action.type) {
    case GET_ALL_COMMUNICATION_TYPES_SUCCESS:
      return { ...state, data: action.communicationTypes }
    default:
      return state
  }
}

export const communicationsToSendReducer = (state = INITIAL_STATE.communicationsToSend, action) => {
  switch (action.type) {
    case COMMUNICATIONS_TO_SEND_SUCCESS:
      return { ...state, data: action.communicationsToSend }
    default:
      return state
  }
}

export const communicationsHistoryReducer = (
  state = INITIAL_STATE.communicationsHistory,
  action
) => {
  switch (action.type) {
    case COMMUNICATIONS_HISTORY_SUCCESS:
      return { ...state, data: action.communicationsHistory }
    default:
      return state
  }
}

export const communicationsBalanceReducer = (
  state = INITIAL_STATE.yearlyBalanceRelationships,
  action
) => {
  switch (action.type) {
    case COMMUNICATIONS_BALANCE_SUCCESS:
      return { ...state, data: action.yearlyBalanceRelationships }
    default:
      return state
  }
}

export const communicationsLastErrorReducer = (
  state = INITIAL_STATE.lastErrorCommunication,
  action
) => {
  switch (action.type) {
    case COMMUNICATIONS_LAST_ERROR_SUCCESS:
      return { ...state, data: action.lastErrorCommunication }
    default:
      return state
  }
}
