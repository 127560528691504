import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../actions'
import { ApplicationList } from './ApplicationList'

import '../../styles/dashboard.css'

export const Dashboard = () => {
  const dispatch = useDispatch()
  const applications = useSelector((state) => state.applications.data)

  useEffect(() => {
    getApplication()
  }, [])

  const getApplication = () => {
    dispatch(actions.getApplicationsByCustomer())
    dispatch(actions.getAccount())
  }

  const enabledApplications = applications.filter((app) => app?.application?.enabled)
  const applicationsOther = enabledApplications.filter(
    (app) => app?.application?.category !== 'ALTRE_COMUNICAZIONI'
  )
  const applicationsOtherCommunications = enabledApplications.filter(
    (app) => app?.application?.category === 'ALTRE_COMUNICAZIONI'
  )

  return (
    <div className="dashboard">
      <ApplicationList getApplication={getApplication} applications={applicationsOther} />
      <ApplicationList
        getApplication={getApplication}
        applications={applicationsOtherCommunications}
      />
    </div>
  )
}
