import { useSelector } from 'react-redux'
import getText from '../../shared/i18n/labels'

import { Modal } from 'react-bootstrap'
import { BtnFill } from '../../shared/BtnFill'

export function ConfirmModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <Modal
      show={props.showSendModal}
      onHide={() => props.setShowSendModal(false)}
      backdrop="static"
      dialogClassName="w-50 p-2"
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100 popup-title-success">
          {labels.CONFIRM_SEND_DATA}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="row text-center d-flex flex-column justify-content-between mt-2">
        <div className="col">
          <h4>{labels.DOWNLOAD_PDF_WITH_DATA_INDICATED}</h4>
          <BtnFill text={labels.DOWNLOAD} handlerOnClick={props.riepilogo}></BtnFill>
        </div>

        <div className="col">
          <h4>{labels.CONFIRM_SEND_DATA_DESCRIPTION}</h4>
          <BtnFill text={labels.SEND} handlerOnClick={props.invia}></BtnFill>
        </div>
        <div className="col mt-2">
          <h4>{labels.ATTENTION_DATA_NOT_SAVED}</h4>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={() => props.setShowSendModal(false)}>
          <i className="thx-cancel thx-icon me-2" />
          {labels.CANCEL}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
