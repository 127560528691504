import { useSelector } from 'react-redux'
import { formatTimeFromDate, joinDateAndTime } from '../../shared/Utility'
import getText from '../../shared/i18n/labels'

// COMPONENTS
import { DateField } from '../../shared/form/DateField'
import { BwmInput } from '../../shared/form/BwmInput'
import { CallIcon } from './NewContactInfoModal'

export function ContactHistoryForm({ historyObject, sizes, onChange }) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <>
      <div className="row mb-2">
        <div className={`${sizes.date} pe-2`}>
          <DateField
            className="form-control form-control-cell"
            date={historyObject.date}
            onChange={(date) => {
              onChange({ ...historyObject, date: date })
            }}
          />
        </div>

        <div className={`${sizes.time} pe-2`}>
          <BwmInput
            className="form-control form-control-cell"
            type={'time'}
            value={formatTimeFromDate(historyObject.date)}
            placeholder={labels.INSERT_CONTACT}
            onChange={(e) =>
              onChange({
                ...historyObject,
                date: joinDateAndTime(historyObject.date, e.target.value)
              })
            }
          />
        </div>

        <div className={`${sizes.contact} pe-2`}>
          <BwmInput
            className="form-control form-control-cell"
            value={historyObject.contact}
            placeholder={labels.INSERT_CONTACT}
            onChange={(e) => onChange({ ...historyObject, contact: e.target.value })}
          />
        </div>
        <div className={`${sizes._phone} pe-2`}>
          <BwmInput
            className="form-control form-control-cell"
            value={historyObject.phone}
            placeholder={labels.INSERT_NUMBER}
            Icon={() => <CallIcon phone={historyObject.phone} />}
            onChange={(e) => onChange({ ...historyObject, phone: e.target.value })}
          />
        </div>
        <div className={`${sizes.caller} pe-2`}>
          <BwmInput
            className="form-control form-control-cell"
            value={historyObject.caller}
            placeholder={labels.INSERT_CALLER}
            onChange={(e) => {
              onChange({ ...historyObject, caller: e.target.value })
            }}
          />
        </div>
        <div className={`${sizes.notes}`}>
          <BwmInput
            autofocus={true}
            className="form-control form-control-cell"
            placeholder={labels.INSERT_NOTES}
            value={historyObject.notes}
            onChange={(e) => onChange({ ...historyObject, notes: e.target.value })}
          />
        </div>
      </div>
    </>
  )
}
