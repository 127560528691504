import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODALS
import { ModalPecParameters } from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/ModalPecParameters'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'

// POPUPS
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError, closePopupError } from '../../../shared/popups/PopupError'
import { PopupAlert } from '../../../shared/popups/PopupAlert'

// BUTTONS
import { BtnFill } from '../../../shared/BtnFill'
import { BtnEmpty } from '../../../shared/BtnEmpty'

import '../../../../styles/comurei-form.css'
import { testEmail } from '../../../shared/Utility'

const EmailForm = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [formData, updateFormData] = useState()

  const coraSubjectSid = props.subject?.coraSubjectSid
  const subjectId = props.subject?.id

  const [emailAddress, setEmailAddress] = useState(props.emailAddress)
  const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: '' })
  const [flagPec, setFlagPec] = useState(props.flagPec || false)
  const [isPecModalOpen, setPecModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    updateFormData({
      ...formData,
      id: subjectId,
      emailAddress: emailAddress,
      flagPec: flagPec
    })
  }, [])

  const confirmSendCommunicationsEmail = (subjectId, sentCommunications, emailAddress, flagPec) => {
    sentCommunications.map((communication, index) => {
      actions.sendCrsCommunicationMail(subjectId, communication.id, emailAddress, flagPec).then(
        (res) => {
          if (res) PopupSuccess({ text: labels.EMAIL_SENT })
          else PopupError({ text: labels.ERROR_SENDING_EMAIL })
        },
        (errors) => PopupError({ text: labels.ERROR_SENDING_EMAIL })
      )
    })
    props.closeModal()
  }

  const handlerChangeEmailAddress = (e) => {
    handleValidation(e.target.value)
    setEmailAddress(e.target.value)
    updateFormData({ ...formData, emailAddress: e.target.value })
    setFlagPec(false)
  }

  const handlerChangeFlagPec = (e) => {
    let checked = e.target.checked
    setFlagPec(checked)
    updateFormData({
      ...formData,
      flagPec: checked
    })
  }

  const resetError = () => {
    setErrorEmailAddress({ isValid: true, msg: '' })
  }

  const handleValidation = (_emailAddress) => {
    resetError()
    if (!_emailAddress) {
      setErrorEmailAddress({ isValid: false, msg: labels.REQUIRED_FIELD })
      return false
    } else if (_emailAddress.toUpperCase().indexOf('AGENZIAENTRATE') !== -1) {
      setErrorEmailAddress({
        isValid: false,
        msg: labels.INSERT_EMAIL_ADDRESS_DIFFERENT_FROM_AGENCY
      })
      return false
    }
    if (!testEmail(_emailAddress)) {
      setErrorEmailAddress({
        isValid: false,
        msg: labels.INVALIDEMAILFORMAT
      })
      return false
    }
    setErrorEmailAddress({ isValid: true, msg: '' })
    return true
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    if (handleValidation(emailAddress)) {
      confirmSendCommunicationsEmail(subjectId, props.sentCommunications, emailAddress, flagPec)
    }
  }

  const openPecConfirmPopup = () => {
    if (coraSubjectSid?.couldSendPec) {
      PopupAlert({
        handleClickConfirm: () => sendFromOwnPec(),
        title: labels.SEND_MAIL_PEC,
        confirm: labels.OK_SEND,
        innerHtml: `<p>${labels.SENDER}: ${coraSubjectSid?.pecEmail}</p><p>${labels.RECIPIENT}: ${coraSubjectSid?.recipientEmail}</p>`
      })
    } else {
      PopupError({
        text: labels.PEC_NOT_CONFIGURED_CORRECTLY,
        buttonClose: () => (
          <BtnEmpty
            classCustom="pe-5"
            text={labels.CONFIGURATION_PEC_PARAMETERS}
            handlerOnClick={() => {
              closePopupError()
              setPecModalOpen(true)
            }}
          />
        )
      })
    }
  }

  const sendFromOwnPec = () => {
    let communicationIds = []
    props.sentCommunications.forEach((element) => {
      communicationIds.push(element.id)
    })
    setLoading(true)
    actions
      .sendCrsCommunicationFromOwnPec(subjectId, communicationIds)
      .then(
        (res) => PopupSuccess({ text: labels.EMAIL_SENT }),
        (err) => {
          PopupError({
            text: err?.response?.data || labels.UNEXPECTED_ERROR,
            buttonClose: () => (
              <BtnFill
                classCustom="pe-5"
                text={labels.CONFIGURATION_PEC_PARAMETERS}
                handlerOnClick={() => {
                  closePopupError()
                  setPecModalOpen(true)
                }}
              />
            )
          })
        }
      )
      .then(() => setLoading(false))
  }

  const closePecModal = () => {
    setPecModalOpen(false)
  }

  const renderPecModal = () => {
    return (
      <ModalPecParameters
        coraSubjectSid={{ coraSubjectSid }}
        show={isPecModalOpen}
        onClose={closePecModal}
      />
    )
  }

  return (
    <div>
      {loading && <PageSpinner />}
      {renderPecModal()}
      <div className="row-detail comurei mt-2 d-flex radius">
        <div className="tab-content col-12">
          <div className="form-row">
            <div className="col-12 subDetailComurei">
              <div className="row">
                <div className="col-11 mb-2">
                  <BwmInput
                    id="emailAddress"
                    type="email"
                    className="form-control"
                    label={`${labels.EMAIL_ADDRESS_TO_SEND_COMMUNICATIONS}*`}
                    value={emailAddress?.toLowerCase()}
                    showErrorMessage={true}
                    error={errorEmailAddress}
                    touched={errorEmailAddress?.isValid === false}
                    maxLength="100"
                    onChange={handlerChangeEmailAddress}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 pt-1 pb-1">
                  <BwmCheckbox
                    name="flagPec"
                    label={labels.SELECT_IN_CASE_OF_PEC_EMAIL}
                    className="form-control"
                    checked={flagPec}
                    onChange={handlerChangeFlagPec}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex mt-4 justify-content-between align-item-center">
        <div>
          <button className="btn btn-outline-primary" onClick={props.closeModal}>
            {labels.CANCEL}
          </button>
        </div>
        <div className="d-flex align-items-center">
          {props.children}
          <button onClick={() => openPecConfirmPopup()} className="btn btn-outline-primary me-2">
            {labels.SEND_DIRECTLY_FROM_PEC}
          </button>
          <button
            disabled={errorEmailAddress?.isValid === false}
            onClick={(e) => handleFormSubmit(e)}
            className="btn btn-primary">
            {labels.CONFIRM}
          </button>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'EmailForm'
})(EmailForm)
