import { useState } from 'react'
import { useSelector } from 'react-redux'
import { downloadFile } from '../../../shared/Utility'
import axiosService from '../../../../services/axios-service'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// BUTTON
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'
import { BREAKPOINT_MD } from '../../../../config/Constants'

export function YearlyBalanceExportModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const exportCoraYearlyBalances = () => {
    let filename =
      'SALDI_ANNO_' +
      props.selectedYear +
      '_' +
      (props.subject?.companyName ? props.subject.companyName : props.subject?.lastName) +
      '.csv'
    let url = `/api/cora/custom/cora-balances/export-yearly-balances/${props.subjectId}/${props.selectedYear}`
    callDownloadService(url, filename)
  }

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance()
    setLoading(true)
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        if (response) {
          closeModal()
          if (response?.data?.size > 0) {
            downloadFile(response.data, file)
            PopupSuccess({ text: labels.FILE_DOWNLOADED })
          } else {
            closeModal()
            PopupError({ text: labels.IMPOSSIBLE_TO_GENERATE_FILE })
          }
        }
      })
      .catch((error) => {
        closeModal()
        let reader = new FileReader()
        reader.onload = function () {
          PopupError({ text: reader.result })
        }
        reader.readAsText(error.request.response)
      })
      .then(() => setLoading(false))
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        disabled={props.disabled}
        className={`btn btn-outline-primary ${props.disabled ? 'disabled' : ''}`}
        data-tip={labels.EXPORT_YEARLY_BALANCES_DESCRIPTION}
        onClick={openModal}>
        <i className="thx-export thx-icon mt-1" />
        {window.innerWidth > BREAKPOINT_MD && (
          <span className="ms-2">{labels.BALANCES_INSTRUCTIONS}</span>
        )}
      </button>
      <Modal
        size="sm"
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.EXPORT_YEARLY_BALANCES}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            {lang === 'it' ? (
              <p>
                Attraverso questa funzione è possibile scaricare tutti i saldi riferiti all'anno
                selezionato (<strong>{props.selectedYear}</strong>).
              </p>
            ) : (
              <p>
                Through this function it is possible to download all the balances related to the
                selected year (<strong>{props.selectedYear}</strong>).
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button onClick={exportCoraYearlyBalances} type="button" className="btn btn-primary">
              {labels.EXPORT}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
