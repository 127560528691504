import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { base64ToArrayBuffer, downloadFile, formatDateForDisplay } from '../../../shared/Utility'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// TABLE
import { TableUtils, baseSearchObject } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'

// FORM
import { ArcoRegistrationForm } from './NewArcoRegistrationForm'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

import 'react-datepicker/dist/react-datepicker.css'
import { PopupConfirm } from '../../../shared/popups/PopupConfirm'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

export const ArcoRegistrationsList = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [operations, setOperations] = useState([])
  const [operation, setOperation] = useState()

  const [checkedItems, setCheckedItems] = useState([])

  const [searchObject, setSearchObject] = useState({ ...baseSearchObject })

  const tableName = 'arco-operations-subject'

  useEffect(() => {
    setRows(prepareRows(operations))
    setOperation(operation ? operation : operations?.[0])
  }, [operations])

  const searchOperations = (newSearchObject, response = null) => {
    if (response) props.showClipModal(response)
    return actions
      .getArcoOperationsBySubjectId(props.arcoSubjectId, newSearchObject)
      .then((res) => {
        setOperations(res?.content || res?.data, res)
        setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
        return res
      })
  }

  const handleDeleteArcoOperation = () => {
    PopupConfirm({
      titleColor: 'popup-title-delete',
      text: labels.DELETE_SELECTED_ELEMENTS,
      handleClickConfirm: deleteArcoOperationSubject
    })
  }

  const deleteArcoOperationSubject = () => {
    //FIXME implementare delete in batch
    setLoading(true)
    const promises = []
    checkedItems.forEach((el) => promises.push(actions.deleteArcoOperation(el)))

    Promise.all(promises)
      .then(() => {
        searchOperations(searchObject)
        setCheckedItems([])
        PopupSuccess({ text: labels.REMOVED_SUCCESSFULLY })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        PopupError({ text: labels.ERROR_DELETING_SUBJECT })
      })
  }

  const printPersonalData = () => {
    setLoading(true)
    const axiosInstance = axiosService.getInstance()
    let idArray = []
    if (checkedItems.length === 0) {
      idArray.push(0)
    } else {
      checkedItems.forEach((arcoOperation) => {
        idArray.push(arcoOperation)
      })
    }
    axiosInstance({
      url: `/api/arco/custom/arco-registries-list-download/${props.arcoSubjectId}/${idArray}`,
      method: 'GET'
    })
      .then(
        (response) => {
          if (response) downloadFile(base64ToArrayBuffer(response.data), 'Anagrafica.pdf')
          PopupSuccess({ text: labels.FILE_DOWNLOADED })
          setCheckedItems([])
        },
        (err) => {
          if (err.errorKey === 'arcoOperationCheck') PopupError({ text: err.title })
          else PopupError({ text: labels.ERROR_DOWNLOADING_DOCUMENT })
        }
      )
      .finally(() => setLoading(false))
  }

  const printSAR = () => {
    setLoading(true)
    actions
      .downloadSAR(props.arcoSubjectId)
      .then(
        (data) => {
          downloadFile(base64ToArrayBuffer(data), 'Estrazione.zip')
          PopupSuccess({ text: labels.FILE_DOWNLOADED })
          setCheckedItems([])
        },
        (err) => {
          PopupError({
            text: err?.title || labels.ERROR_CREATING_FILE
          })
        }
      )
      .then(() => setLoading(false))
  }

  const printContract = () => {
    setLoading(true)
    const axiosInstance = axiosService.getInstance()
    let idArray = []
    if (checkedItems.length === 0) idArray.push(0)
    else checkedItems.forEach((arcoOperation) => idArray.push(arcoOperation))
    axiosInstance({
      url: `/api/arco/custom/arco-contracts-list-download/${props.arcoSubjectId}/${idArray}`,
      method: 'GET'
    }).then(
      (response) => {
        if (response) downloadFile(base64ToArrayBuffer(response.data), 'Contratti.pdf')
        PopupSuccess({ text: labels.FILE_DOWNLOADED })
        setCheckedItems([])
        setLoading(false)
      },
      (err) => {
        if (err.errorKey === 'arcoOperationCheck') PopupError({ text: err.title })
        else PopupError({ text: labels.ERROR_DOWNLOADING_DOCUMENT })
        setLoading(false)
      }
    )
  }

  const renderHeaderButtons = () => {
    return (
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex">
          <div className="me-2">
            <button
              type="button"
              disabled={checkedItems.length === 0 || props.disabled}
              className={`btn btn-outline-primary ${checkedItems.length === 0 ? 'disabled' : ''}`}
              onClick={handleDeleteArcoOperation}>
              <i className="thx-trash thx-icon me-2" />
              {labels.DELETE}
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-tip={labels.PRINT_PERSONAL_DATA}
              onClick={printPersonalData}>
              <i className="thx-export thx-icon me-2 mt-1" />
              {labels.PRINT_PERSONAL_DATA}
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-tip={labels.PRINT_CONTRACTS}
              onClick={printContract}>
              <i className="thx-export thx-icon me-2 mt-1" />
              {labels.PRINT_CONTRACTS}
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-tip={labels.PRINT_COMPLETE_LIST}
              onClick={printSAR}>
              <i className="thx-export thx-icon me-2 mt-1" />
              {labels.PRINT_COMPLETE_LIST}
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-tip={labels.REGISTRIES_LIST}
              onClick={() => props.setShowRegistryList(true)}>
              <i className="thx-list thx-icon me-2" />
              {labels.REGISTRIES_LIST}
            </button>
          </div>
        </div>
        <div>
          <button
            type="button"
            disabled={props.disabled}
            className="btn btn-primary"
            onClick={() => setOperation()}>
            <i className="thx-plus thx-icon me-2" />
            {labels.NEW_OPERATION}
          </button>
        </div>
      </div>
    )
  }

  const setNewOperations = (content, res) => {
    setOperations(content)
    setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
  }

  const displayClientsOrCounterParts = (array) => {
    let newArray = []
    array.forEach((item) => {
      newArray.push(item?.completeName && item.completeName.toUpperCase())
    })
    return newArray.join(', ')
  }

  const displayProfessionals = (array) => {
    let newArray = []
    array.forEach((item) => {
      newArray.push(item.name.toUpperCase())
    })
    return newArray.join(', ')
  }

  const isProfessionals = () => {
    return props?.subject?.arcoSubjectType?.code === 'PB'
  }

  const onClickRow = (row) => {
    setOperation(row.rowData)
  }

  const header = [
    TableUtils.composeHeader({
      fieldName: 'number',
      displayedName: labels.NUMBER
    }),
    TableUtils.composeHeader({
      fieldName: 'profilingDate',
      displayedName: labels.PROFILING_DATE
    }),
    TableUtils.composeHeader({
      fieldName: 'arcoRegistriesAsCustomers',
      displayedName: labels.CLIENTS
    }),
    TableUtils.composeHeader({
      fieldName: 'arcoProfessionals',
      displayedName: labels.COUNTER_PARTS
    }),
    TableUtils.composeHeader({
      fieldName: 'registrationDate',
      sortable: false,
      searchable: false,
      displayedName: labels.AUI
    })
  ]

  const prepareRows = (fromOperations = []) => {
    return fromOperations.map((operation) => {
      let latestContract = null
      if (operation.arcoContracts) {
        latestContract = operation.arcoContracts
          .filter((operation) => operation.status < 1)
          .sort(
            (a, b) =>
              new Date(b.registrationDate)?.getTime() - new Date(a.registrationDate)?.getTime()
          )[0]
      }
      return TableUtils.composeRow({
        id: operation.id,
        rowData: operation,
        cellData: {
          number: TableUtils.composeCell({
            fieldName: 'number',
            fieldValue: operation.number
          }),
          profilingDate: TableUtils.composeCell({
            fieldName: 'profilingDate',
            fieldValue: formatDateForDisplay(operation.profilingDate)
          }),
          arcoRegistriesAsCustomers: TableUtils.composeCell({
            fieldName: 'arcoRegistriesAsCustomers',
            fieldValue: displayClientsOrCounterParts(operation.arcoRegistriesAsCustomers || [])
          }),
          arcoProfessionals: TableUtils.composeCell({
            fieldName: 'arcoProfessionals',
            fieldValue: isProfessionals()
              ? displayProfessionals(operation.arcoProfessionals || [])
              : displayClientsOrCounterParts(operation.arcoRegistriesAsProfessionals || [])
          }),
          registrationDate: TableUtils.composeCell({
            fieldName: 'registrationDate',
            fieldValue: latestContract?.registrationDate
              ? formatDateForDisplay(latestContract.registrationDate)
              : ''
          })
        }
      })
    })
  }

  return (
    <div style={{ marginBottom: '60px' }}>
      {loading && <PageSpinner />}
      {renderHeaderButtons()}
      <StarTable
        id={operation?.id}
        headerColumns={header}
        checkedItems={checkedItems}
        rows={rows}
        offset={535}
        formOnBottom={true}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => {
          setOperation()
          return searchOperations(searchObject)
        }}
        onExecutedSearch={(content, res) => setNewOperations(content, res)}
        usePagination={true}
        withCheckBoxes={true}
        onClickCheck={setCheckedItems}
        onClickRow={onClickRow}
        formComponent={ArcoRegistrationForm}
        formComponentProps={{
          disabled: props.disabled || loading,
          arcoSubject: props.arcoSubject,
          formOnBottom: true,
          id: operation?.id,
          arcoSubjectId: props.arcoSubjectId,
          setLoading: setLoading,
          getOperations: (response) => searchOperations(searchObject, response),
          close: (newOperation) =>
            setOperation(newOperation ? newOperation : operation ? operation : undefined)
        }}
      />
    </div>
  )
}
