export const BALANCE_DISABLED = '(Non compilare)'

//regole dei saldi per i tipi rapporto in cui sono previsti. Da aggiornare in caso di modifiche alla normativa
//contiene le descrizioni delle regole sui vari importi, se previsti.
const rules = {
  '01': {
    amount1: `Saldo Contabile
        alla data di fine
        anno precedente`,
    amount2: `Saldo Contabile
        alla data di fine
        anno`,
    amount3: `Importo totale
        degli accrediti
        effettuati
        nell'anno`,
    amount4: `Importo totale
        degli addebiti
        effettuati
        nell'anno
        `,
    amount5: BALANCE_DISABLED,
    stock: `Giacenza media`
  },
  '02': {
    amount1: `Controvalore dei
        titoli rilevato
        contabilmente alla
        data di fine anno
        precedente (come
        da estratto
        conto)`,
    amount2: `Controvalore dei
        titoli rilevato
        contabilmente alla
        data di fine anno
        (come da estratto
        conto) `,
    amount3: `Importo totale
        degli acquisti di
        titoli, fondi ecc
        effettuati
        nell'anno, esclusi
        i rinnovi`,
    amount4: `Importo totale dei
        disinvestimenti
        effettuati
        nell'anno`,
    amount5:
      "Controvalore dei Titoli di Stato rilevato alla data di fine anno (a partire dall'anno di riferimento 2023)",
    stock: BALANCE_DISABLED
  },
  '03': {
    amount1: `Saldo Contabile
        alla data di fine
        anno precedente`,
    amount2: `Saldo Contabile
        alla data di fine
        anno`,
    amount3: `Importo totale
        degli accrediti
        effettuati
        nell'anno`,
    amount4: `Importo totale
        degli addebiti
        effettuati
        nell'anno
        `,
    amount5: BALANCE_DISABLED,
    stock: `Giacenza media`
  },
  '04': {
    amount1: `Controvalore
        rilevato
        contabilmente a
        fine anno
        precedente`,
    amount2: `Controvalore
        rilevato
        contabilmente a
        fine anno`,
    amount3: `Importo totale
        distintamente
        individuato dei
        conferimenti
        (parziali/totali)
        effettuati
        nell'anno
        `,
    amount4: `Importo totale
        distintamente
        individuato dei
        prelievi
        (parziali/totali)
        effettuati
        nell'anno
        `,
    amount5: BALANCE_DISABLED,
    stock: BALANCE_DISABLED
  },
  '05': {
    amount1: `Ammontare del
        contratto di
        gestione alla data
        di fine anno
        precedente`,
    amount2: `Ammontare del
        contratto di
        gestione alla data
        di fine anno`,
    amount3: `Importo totale
        delle
        sottoscrizioni di
        quote nell'anno`,
    amount4: `Importo totale dei
        imborsi di quote
        nell'anno
        `,
    amount5: BALANCE_DISABLED,
    stock: BALANCE_DISABLED
  },
  '06': {
    amount1: `Valore globale del
        patrimonio a data
        di fine anno
        precedente`,
    amount2: `Valore globale del
        patrimonio a data
        fine anno`,
    amount3: `Importo totale
        degli apporti
        effettuati
        nell'anno`,
    amount4: `Importo totale dei
        prelievi
        effettuati
        nell'anno`,
    amount5:
      "Valore globale dei Titoli di Stato del patrimonio a fine anno (a partire dall'anno di riferimento 2023)",
    stock: BALANCE_DISABLED
  },
  '07': {
    amount1: `Totale degli
        importi facciali
        dei Certificati o
        dei buoni a fine
        anno precedente`,
    amount2: `Totale degli
        importi facciali
        dei Certificati o
        dei buoni a fine
        anno`,
    amount3: `Importo totale
        delle accensioni
        effettuate
        nell'anno`,
    amount4: `Importo totale
        delle estinzioni
        effettuate
        nell'anno`,
    amount5: `Numero totale dei
        certificati o dei
        buoni fruttiferi
        (deve essere maggiore di 0)`,
    stock: BALANCE_DISABLED
  },
  '09': {
    amount1: `Saldo Contabile
        alla data di fine
        anno precedente`,
    amount2: `Saldo Contabile
        alla data di fine
        anno`,
    amount3: `Importo totale
        degli accrediti
        effettuati
        nell'anno
        `,
    amount4: `Importo totale
        degli addebiti
        effettuati
        nell'anno
        `,
    amount5: BALANCE_DISABLED,
    stock: `Giacenza media`
  },
  10: {
    amount1: `Saldo Contabile
        alla data di fine
        anno precedente`,
    amount2: `Saldo Contabile
        alla data di fine
        anno`,
    amount3: `Importo totale
        degli incassi
        effettuati
        nell'anno`,
    amount4: BALANCE_DISABLED,
    amount5: BALANCE_DISABLED,
    stock: BALANCE_DISABLED
  },
  12: {
    amount1: BALANCE_DISABLED,
    amount2: BALANCE_DISABLED,
    amount3: BALANCE_DISABLED,
    amount4: BALANCE_DISABLED,
    amount5: `Numero totale
        degli accessi
        effettuati
        nell'anno`,
    stock: BALANCE_DISABLED
  },
  14: {
    amount1: BALANCE_DISABLED,
    amount2: BALANCE_DISABLED,
    amount3: `Importo totale
        nozionale dei
        contratti accesi
        nell'anno `,
    amount4: `Importo totale
        nozionale dei
        contratti chiusi
        nell'anno`,
    amount5: `Numero totale dei
        contratti
        stipulati
        nell'anno`,
    stock: BALANCE_DISABLED
  },
  15: {
    amount1: `Utilizzo del
        plafond di spesa a
        fine anno
        precedente`,
    amount2: `Utilizzo del
        plafond di spesa a
        fine anno`,
    amount3: `Per le carte
        prepagate
        Ricaricabili,
        l'importo totale
        delle ricariche
        effettuate
        nell'anno. Per le
        prepagate non
        ricaricabili
        l'importo totale
        del valore delle
        carte acquistate
        nell'anno`,
    amount4: `importo totale
        degli acquisti
        effettuati
        nell'anno`,
    amount5: `Vale zero, ad
        esclusione delle
        carte di credito
        con Plafond
        illimitato per le
        quali deve essere
        impostato a 99`,
    stock: BALANCE_DISABLED
  },
  23: {
    amount1: `Somma totale dei
        premi versati
        dalla data di
        stipula della
        polizza, al netto
        dei riscatti, alla
        data di fine anno
        precedente`,
    amount2: `Somma totale dei
        premi versati
        dalla data di
        stipula della
        polizza, al netto
        dei riscatti, alla
        data di fine anno`,
    amount3: `Importo totale
        degli incrementi
        della polizza
        effettuati
        nell'anno`,
    amount4: `Importo totale dei
        riscatti della
        polizza effettuati
        nell'anno`,
    amount5: BALANCE_DISABLED,
    stock: BALANCE_DISABLED
  },
  24: {
    amount1: BALANCE_DISABLED,
    amount2: BALANCE_DISABLED,
    amount3: `Importo totale del
        valore degli
        acquisti
        effettuati
        nell'anno`,
    amount4: `Importo totale del
        valore delle
        vendite effettuate
        nell'anno`,
    amount5: `Numero totale
        delle operazioni
        effettuate
        nell'anno (deve
        essere maggiore di 0)`,
    stock: BALANCE_DISABLED
  },
  25: {
    amount1: `Saldo Contabile
        alla data di fine
        anno precedente`,
    amount2: `Saldo Contabile
        alla data di fine
        anno`,
    amount3: `Importo totale
        delle ricariche
        effettuate
        nell'anno
        `,
    amount4: `Importo totale
        delle spese
        effettuate
        nell'anno`,
    amount5: BALANCE_DISABLED,
    stock: BALANCE_DISABLED
  },
  26: {
    amount1: BALANCE_DISABLED,
    amount2: BALANCE_DISABLED,
    amount3: BALANCE_DISABLED,
    amount4: `Importo totale
        delle spese
        effettuate
        nell'anno`,
    amount5: BALANCE_DISABLED,
    stock: BALANCE_DISABLED
  },
  27: {
    amount1: `Plafond di spesa
        mensile assegnato
        a fine anno
        precedente`,
    amount2: `Plafond di spesa
        mensile a fine
        anno`,
    amount3: BALANCE_DISABLED,
    amount4: `Importo totale
        degli acquisti
        effettuati
        nell'anno`,
    amount5: `Vale zero, ad
        esclusione delle
        carte di credito
        con Plafond
        illimitato per le
        quali deve essere
        impostato a 99`,
    stock: BALANCE_DISABLED
  },
  28: {
    amount1: `Saldo Contabile
        alla data di fine
        anno precedente`,
    amount2: `Saldo Contabile
        alla data di fine
        anno`,
    amount3: `Importo totale
        delle ricariche
        effettuate
        nell'anno
        `,
    amount4: `Importo totale
        delle spese
        effettuate
        nell'anno`,
    amount5: BALANCE_DISABLED,
    stock: BALANCE_DISABLED
  },
  29: {
    amount1: BALANCE_DISABLED,
    amount2: BALANCE_DISABLED,
    amount3: BALANCE_DISABLED,
    amount4: `Importo totale
        delle spese
        effettuate
        nell'anno`,
    amount5: BALANCE_DISABLED,
    stock: BALANCE_DISABLED
  },
  30: {
    amount1: `Plafond di spesa
        assegnato a fine
        anno precedente`,
    amount2: `Plafond di spesa a
        fine anno`,
    amount3: BALANCE_DISABLED,
    amount4: `Importo totale
        degli acquisti
        effettuati
        nell'anno`,
    amount5: ``,
    stock: BALANCE_DISABLED
  },
  96: {
    amount1: BALANCE_DISABLED,
    amount2: BALANCE_DISABLED,
    amount3: BALANCE_DISABLED,
    amount4: BALANCE_DISABLED,
    amount5: `Numero di POS (oppure 0)`,
    stock: BALANCE_DISABLED
  },
  98: {
    amount1: BALANCE_DISABLED,
    amount2: BALANCE_DISABLED,
    amount3: `Ammontare delle
        operazioni
        nell'anno`,
    amount4: BALANCE_DISABLED,
    amount5: `Numero delle
        operazioni
        effettuate
        nell'anno (deve
        essere maggiore di
        0)`,
    stock: BALANCE_DISABLED
  }
}

/**
 *
 * @param {string} relationshipType codice rapporto
 * @returns oggetto con regole del tipo rapporto. potrebbe essere undefined
 */
export function getBalanceRulesByRelationshipType(relationshipType) {
  return rules[relationshipType]
}

/**
 * a partire da una regola, restituisce un oggetto che ha come chiavi i campi dei saldi, e una bolean che indica se il campo è disabilitato per la data regola
 * @param {*} balanceRule
 * @returns
 */
export function getDisabledFields(balanceRule) {
  return {
    amount1Disabled: balanceRule?.amount1 === BALANCE_DISABLED,
    amount2Disabled: balanceRule?.amount2 === BALANCE_DISABLED,
    amount3Disabled: balanceRule?.amount3 === BALANCE_DISABLED,
    amount4Disabled: balanceRule?.amount4 === BALANCE_DISABLED,
    amount5Disabled: balanceRule?.amount5 === BALANCE_DISABLED,
    stockDisabled: balanceRule?.stock === BALANCE_DISABLED
  }
}
